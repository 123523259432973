<template>
  <div class="relative">
    <loading v-if="loading" />

    <h3 class="text-black font-bold text-xl mb-6" v-if="!user.id">
      Добавить пользователя
    </h3>
    <h3 class="text-black font-bold text-xl mb-6" v-if="user.id">Настройки</h3>

    <div class="flex flex-row mt-2 mb-12 space-x-6 border-b border-gray-100">
      <button
        v-for="(tab, index) in tabs"
        :key="index"
        @click="activeTab = tab.slug"
        class="pb-2 text-sm border-b-2 font-medium"
        :class="
          activeTab == tab.slug
            ? 'text-blue-600 border-blue-600'
            : 'text-gray-400 border-transparent'
        "
      >
        <span v-html="tab.icon"></span>
        {{ tab.title }}
      </button>
    </div>

    <!--  Form  -->
    <form class="" @submit.prevent="save()">
      <div v-show="activeTab == 'main'">
        <main-user-settings :user="user" />
      </div>

      <div v-show="activeTab == 'account'">
        <account-settings :user="user" />
      </div>

      <div v-show="activeTab == 'services'">
        <user-services :user="user" v-if="user.id" :busy="busy" v-on:save="saveServices" />
      </div>

      <!-- <div v-show="activeTab == 'schedule'">
        <schedule-settings :user="user"/>
      </div> -->

      <div class="flex flex-row items-center justify-between mt-6" v-if="activeTab == 'main'">
        <button type="submit" :class="{ busy: busy }" :disabled="busy" class="btn-primary">
          Сохранить
        </button>

        <button
          type="button"
          @click="remove()"
          v-if="user.id"
          class="
            py-1
            px-4
            bg-red-100
            text-red-500
            rounded-md
            font-medium
            text-xs
            opacity-50
            hover:opacity-100
          "
        >
          Удалить
        </button>
      </div>
    </form>

    <div v-if="activeTab == 'main'">
      <div
        class="
          border-green-200 border
          text-green-600
          py-2
          text-sm
          rounded-md
          px-4
          mt-2
        "
        v-if="this.status == 'save-success' && !busy"
      >
        Сохранили успешно.
      </div>

      <div
        class="
          border-green-200 border
          text-green-600
          py-2
          text-sm
          rounded-md
          px-4
          mt-2
        "
        v-if="this.status == 'delete-success' && !busy"
      >
        Пользователь удален.
      </div>
    </div>
  </div>
</template>

<script>
import User from "../../repo/UserRepo";
import Loading from "../Loading";
import ScheduleSettings from "./ScheduleSettings.vue";
import AccountSettings from "./AccountSettings.vue";
import MainUserSettings from "./MainUserSettings.vue";
import UserServices from "./UserServices.vue";

export default {
  name: "edit-user",
  components: {
    Loading,
    MainUserSettings,
    AccountSettings,
    ScheduleSettings,
    UserServices
  },
  props: ["id"],
  data() {
    return {
      activeTab: "main",
      tabs: [
        {
          title: "Основное",
          slug: "main"
        },
        {
          title: "Аккаунт",
          slug: "account",
          icon: '<i class="fas fa-user mr-1 text-xs"></i>'
        },
        {
          title: "Услуги",
          slug: "services"
        }
        // {
        //   title: "Расписание",
        //   slug: "schedule",
        //   icon: '<i class="fas fa-calendar-alt mr-1 text-xs"></i>'
        // },
      ],
      loading: false,
      busy: false,
      status: "",
      user: {
        id: "",
        name: "",
        phone: "",
        email: "",
        picture: "",
        jobTitle: "",
        dep: ""
      }
    };
  },
  mounted() {
    if (this.id !== -1) {
      this.get();
    }
  },
  methods: {
    async saveServices(services){
      this.user.services = services;
      await this.save();
    },
    async save() {
      this.busy = true;
      let vm = this;

      let { success, msg, data } = await User.update(this.user);

      setTimeout(() => {
        vm.busy = false;
      }, 300);

      if (success) {
        this.user = data;
        this.status = "save-success";
        // reload items in store
        this.$store.dispatch("users/pullUsers");
      } else {
        this.status = msg;
      }
    },
    async get() {
      this.loading = true;
      const { data, msg, success } = await User.getByid(this.id);
      if (success) {
        this.user = data;
      }
      this.loading = false;
    },
    async remove() {
      if (!confirm("Удалить этого пользователя?")) {
        return;
      }

      this.loading = true;
      const { data, msg, success } = await User.delete(this.id);
      if (success) {
        this.status = "delete-success";
        this.$store.dispatch("users/pullUsers");
        this.$emit("close");
      }
      /**@todo: add error handlers */
      this.loading = false;
    }
  }
};
</script>

<template>
    <page>
        <template v-slot:title>

            База знаний

            <button @click="syncPages()" class="text-xs ml-2"><i class="fas fa-refresh animate"
                    :class="{ 'animate-spin': syncing }"></i></button>
        </template>

        <template v-slot:content>
            <div>
                <div class="flex gap-x-2">
                    <div class="w-1/4 p-6 bg-white overflow-y-auto text-sm rounded-md">
                        <tree-view :pages="pages" :currentPage="currentPage"
                            @select-page="(page) => navigatePage(page)" />
                    </div>
                    <div class="w-3/4 p-8 bg-white rounded-md">
                        <div>
                            <loading v-if="loading" />
                            <template v-if="!loading && currentPage">
                                <h1 class="font-medium text-2xl mb-4">{{ currentPage.title }}</h1>
                                <render-notion-blocks :blocks="content" v-if="currentPage" />
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </page>
</template>

<script>
import RenderNotionBlocks from "../help/RenderNotionBlocks.vue";
import TreeView from '../help/TreeView.vue';
export default {
    components: { RenderNotionBlocks, TreeView },
    data() {
        return {
            pages: [],
            loading: false,
            currentPage: null,
            syncing: false,
            collapsedPages: {},
            content: []
        };
    },
    mounted() {
        this.fetchPages();
        if (this.$route.params.slug) {
            this.loadPage(this.$route.params.slug)
        }
    },
    // when route params changes
    watch: {
        $route(to, from) {
            if (to.params.slug) {
                this.loadPage(to.params.slug)
            }
        }
    },

    methods: {
        async loadPage(slug) {
            this.loading = true;
            let { success, msg, data } = await this.$api.get(`/help/pages/${slug}`)
            if (success) {
                this.currentPage = data;
                this.content = data.blocks;
            }
            this.loading = false;
        },
        async navigatePage(page) {
            // push this page to router
            // only if new page is not the same that current one
            if (this.currentPage && this.currentPage.pageData.id === page.pageData.id) {
                return
            }
            this.$router.push({ name: 'view-help', params: { slug: page.slug } })
        },
        toggleCollapse(page) {
            this.$set(this.collapsedPages, page.pageData.id, !this.collapsedPages[page.pageData.id]);
        },
        async syncPages() {
            this.syncing = true;
            let { success, msg, data } = await this.$api.get('/help/pages/sync')
            if (success) {
                document.location.reload();
            }
            this.syncing = false;
        },
        async fetchPages() {

            this.loading = true;
            let { success, msg, data } = await this.$api.get('/help/pages/tree')
            if (success) {
                this.pages = data;
            }
            this.loading = false;
        },
    },
};
</script>

<style>
/* TailwindCSS will handle most of the styling */
</style>
<template>
  <modal :styles="'max-w-2xl'">
    <h3 class="text-black font-bold text-xl mb-6">
      Редактирование цены
    </h3>

    <form class="" @submit.prevent="save()">
      <div class="flex flex-col gap-y-2">
        <div class="">
          <div class="">
            <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
              Название модели
            </label>
            <input
              class="w-full "
              id="title"
              name="title"
              type="text"
              required=""
              disabled
              v-model="price.name"
              placeholder="Название модели"
              aria-label="title"
            />
          </div>
        </div>

        <div class="" v-if="products.length == 0">
          <div class="">
            <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">Цена закупочная</label>
            <price v-model="price.basePrice" />
          </div>
          <div class="">
            <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">Цена</label>
            <price v-model="price.price" />
          </div>
        </div>

        <!-- extra prices -->
        <div v-if="products.length > 0">
          <div class="rounded-md border">
            <table class="tiny-table w-full">
              <thead>
                <tr>
                  <th class="text-left px-x2">Название</th>
                  <th class="text-left px-x2">Штрихкод</th>
                  <th class="text-right px-2">Цена закупочная</th>
                  <th class="text-right px-2">Цена</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in products" class="text-sm">
                  <td class="px-2">{{ item.name }}</td>
                  <td class="px-2">{{ item.barcode }}</td>
                  <td class="text-right px-2">
                    <price v-model="item.basePrice" class="px-1 py-0.5 rounded-none text-right w-24" />
                  </td>
                  <td class="text-right px-2">
                    <price v-model="item.price" class="px-1 py-0.5 rounded-none text-right w-24" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="flex flex-col mt-6">
        <button type="submit" :class="{ busy: busy }" :disabled="busy" class="btn-primary-sm">
          Сохранить
        </button>
      </div>
    </form>
  </modal>
</template>

<script>
import Modal from "../modals/Modal.vue";
import ModalMixin from "../modals/modal.mixin";
import api from "../../api/api.js";
import PricingRepo from "../../repo/PricingRepo";
import ProductRepo from "../../repo/ProductRepo";

export default {
  components: { Modal },
  mixins: [ModalMixin],
  props: ["price"],
  data() {
    return {
      loading: false,
      busy: false,
      products: [],
      priceDetails: {}
    };
  },
  mounted() {
    if (this.price.category && this.price.category == "Растворы") {
      this.getProducts();
    }
  },

  methods: {
    async save() {
      let payload = { ...this.price };
      // compile product prices inside this model
      if (this.products.length > 0) {
        payload.items = this.products.map(item => {
          return {
            barcode: item.barcode,
            price: item.price
          };
        });
      }
      let data = await PricingRepo.updatePrice(payload);
      this.close(data);
    },
    async getProducts() {
      let { data } = await this.$api.get("/g/lensmodels/min/" + this.price.name);
      if (data) {
        this.products = data;
        // populate prices
        if (this.price && this.price.items) {
          for (let item of this.price.items) {
            let product = this.products.find(el => el.barcode == item.barcode);
            if (product) {
              product.price = item.price;
            }
          }
        }
        // populate with prices
      }
    }
  }
};
</script>

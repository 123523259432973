<template>

    <modal :styles="'max-w-lg'" @dismiss="onDismiss()">
        <div>
            <h3 class="text-black font-bold text-xl mb-6 text-center">
                Выберите категорию
            </h3>

            <div class="space-y-2">

                <div class="text-sm">

                    <p><i class="fas fa-info-circle text-blue-500"></i> Вы можете выбрать <strong>все категории</strong>
                        или выбрать <strong>конкретную</strong> категорию. Для конкретной категории
                        в инвентаризации будут учитываться только товары этой категории, а
                        товары из других категорий будут выдавать ошибку при сканировании.</p>
                </div>
                <div class="flex flex-row space-x-4">
                    <select v-model="category" class="w-full">
                        <option value="">Категория</option>
                        <option value="all">Все категории</option>
                        <option v-for="cat in categoryList" :value="cat.name">{{ cat.name }}</option>
                    </select>

                    <button @click="startInventory" class="btn-action" :disabled="!category">Продолжить</button>
                </div>



            </div>

        </div>
    </modal>

</template>
<script>

import Modal from "../../modals/Modal.vue";
import ModalMixin from "../../modals/modal.mixin";
import Category from "../../../repo/CategoryRepo";
import Inventory from "../../../repo/InventoryRepo";

export default {
    components: { Modal },
    mixins: [ModalMixin],
    data() {
        return {
            category: '',
            categoryList: []
        }
    },
    mounted() {
        this.getCategories();
    },
    methods: {
        onDismiss() {

        },
        async getCategories() {
            this.categoryList = await Category.get();
        },
        async startInventory() {

            try {
                let data = await Inventory.create(null, this.category);
                // redirect to inventory page
                if (data.id) {
                    this.close();
                    this.$router.push({ path: "/store/inventory/edit/" + data.id });
                }

            } catch (e) {
                alert(e.message);
            }
            // create new inventory for specific category
            // and open inventory page

        }
    }
}


</script>
<template>
  <page>
    <template v-slot:header>
      Справочник поставщиков
    </template>
    <template v-slot:actions>
      <router-link to="/suppliers/new" class="btn-action-sm ml-4">
        + Новый поставщик
      </router-link>
    </template>

    <!-- Filters, collapsable -->
    <template v-slot:content>
      <form @submit.prevent="doFilter()" class="sticky top-0 hidden">
        <div class="filters mb-4 flex flex-row items-center gap-4 bg-white px-4 py-2 rounded-md">
          <div class="mr-8 text-sm whitespace-nowrap">
            Всего:
            <span class="font-semibold">{{ total }}</span>
          </div>

          <div>
            <input type="text" placeholder="Поиск по ИНН" v-model="filter.term" />
          </div>
          <div>
            <select v-model="filter.category">
              <option value="">Категория</option>
              <option value="lenses">Контактные линзы</option>
              <option valaue="glass-lenses">Очковые линзы</option>
              <option value="frames">Оправы</option>
            </select>
          </div>
          <div>
            <button
              :class="{ busy: filterBusy }"
              class="px-4 py-2 bg-blue-500 text-white rounded-md"
              @click="doFilter(true)"
            >
              Искать
            </button>
          </div>
        </div>
      </form>

      <div v-if="total > 0" class="mt-4 mb-4 flex justify-between">
        <div>
          <template v-for="page of generatePageRange()">
            <button
              v-if="page != '...'"
              class="rounded-md w-8 h-8 mr-1 text-sm bg-white"
              @click.prevent="setPage(page)"
              :key="page"
              :class="{ 'bg-blue-600 text-white': page == currentPage }"
            >
              {{ page }}
            </button>

            <span v-if="page == '...'" class="mr-1">
              ...
            </span>
          </template>
        </div>
        <select v-model="filter.limit" class="py-2">
          <option value="10">10</option>
          <option value="30">30</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>

      <div class="w-full overflow-x-auto text-left bg-white rounded-md">
        <div v-if="busy">
          <loading />
        </div>
        <table class="data-table">
          <thead>
            <tr class="font-semibold text-xs border-b-2 text-gray-600">
              <th class="pl-5 pr-0">
                <input type="checkbox" @click="toggleCheckboxes()" />
              </th>
              <th class="px-4 py-3">Название</th>
              <th class="px-4 py-3">ИНН</th>
              <th class="px-4 py-3">Электронная почта</th>
              <th class="px-4 py-3">Телефон</th>
              <th class="px-4 py-3">Категория</th>
            </tr>
          </thead>
          <tbody class="text-sm">
            <tr v-for="item in list" class="hover:bg-blue-50 cursor-pointer">
              <td class="pl-5 pr-0">
                <input type="checkbox" class="w-3 h-3" v-model="checkedItems" :value="item.id" />
              </td>
              <td>
                <router-link :to="`/suppliers/${item.id}`" class="btn-sec">
                  {{ item.name }}
                </router-link>
              </td>
              <td>
                {{ item.inn }}
              </td>
              <td>{{ item.details.email || "" }}</td>
              <td>{{ item.details.phone || "" }}</td>
              <td>
                {{
                  item.category == "lenses"
                    ? "Контактные линзы"
                    : item.category == "glass-lenses"
                    ? "Очковые линзы"
                    : item.category == "frames"
                    ? "Оправы"
                    : ''
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="fixed bottom-4 w-1/3 left-1/3" v-if="checkedItems.length">
        <div class="rounded-md bg-blue-600 shadow-2xl px-8 py-3  flex items-center flex-row">
          <div class=" mr-4 text-white text-sm">
            Выбрано
            <strong>{{ checkedItems.length }}</strong>
          </div>
          <button class="px-4 py-2 text-sm bg-white text-black rounded-md">
            <i class="fas fa-print mr-2"></i>
            Удалить
          </button>
        </div>
      </div>
    </template>
  </page>
</template>
<script>
import EditModal from "./EditSupplierModal";
import api from "../../api/api.js";

import { mapGetters } from "vuex";
import Page from "../Page.vue";
import Info from "../shared/Info.vue";

export default {
  components: { Page, Info },
  name: "Suppliers",
  data() {
    return {
      list: [],
      filterBusy: false,
      filter: {
        term: "",
        limit: 50,
        page: 1,
        category: ""
      },
      currentPage: 1,
      total: 0,
      checkedItems: [],
      busy: false
    };
  },
  watch: {
    "filter.limit": function(oldval, newval) {
      this.doFilter();
    }
  },
  mounted() {
    //this.$store.dispatch("products/getProducts");
    //this.products = productsJson.products;
    this.doFilter();

    let vm = this;

    // subscribe when supplier updated
    this.$events.$on("reload", function(updated) {
      vm.doFilter();
    });
  },
  methods: {
    async doFilter(btnClicked) {
      if (btnClicked) {
        this.filterBusy = true;
        this.setPage(1);
        return;
      }

      if (!this.filterBusy) {
        this.busy = true;
      }

      let vm = this;

      //let { success, msg, data } = await Product.filter(this.filter);
      let query = encodeURIComponent(JSON.stringify(this.filter));
      let { success, msg, data } = await api.call(`/g/suppliers?filter=${query}`, {
        method: "get"
      });
      this.list = data.suppliers;
      this.total = data.total;
      vm.filterBusy = false;
      vm.busy = false;
    },
    addNew() {
      this.$modals.open(EditModal);
    },
    edit(id) {
      this.$modals.open(EditModal, { id: id });
    },
    toggleCheckboxes() {},
    setPage(page) {
      this.currentPage = parseInt(page);
      this.filter.page = page;
      this.doFilter();
    },
    generatePageRange(delta = 4) {
      // creates array with base 1 index
      let currentPage = parseInt(this.currentPage);
      let lastPage = Math.ceil(this.total / this.filter.limit);

      const range = Array(lastPage)
        .fill()
        .map((_, index) => index + 1);

      return range.reduce((pages, page) => {
        // allow adding of first and last pages
        if (page === 1 || page === lastPage) {
          return [...pages, page];
        }

        // if within delta range add page
        if (page - delta <= currentPage && page + delta >= currentPage) {
          return [...pages, page];
        }

        // otherwise add 'gap if gap was not the last item added.
        if (pages[pages.length - 1] !== "...") {
          return [...pages, "..."];
        }

        return pages;
      }, []);
    }
  },
  computed: {
    //...mapGetters("products", ["products"])
  }
};
</script>

<template>
  <div class="" v-cloak>
    <div class="container mt-4 lg:mt-8 max-w-7xl mx-auto lg:mb-6 px-6">
      <div class="w-full">
        <div class="font-semibold text-lg flex flex-row items-center">
          <slot name="title" />
        </div>

        <div class="mb-4 flex flex-row items-center" v-if="!notitle">
          <h1 class="text-lg md:text-lg font-semibold"><slot name="header"></slot></h1>
          <slot name="actions"></slot>
        </div>

        <div class="content">
          <div class="mb-4" v-if="$slots.secondary">
            <slot name="secondary"></slot>
          </div>
          <div class="">
            <slot name="content"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["notitle"],
  name: "PageShort"
};
</script>

<template>
  <div>
    <form>
      <div>
        <div class="relative mb-4">
          <label class="hidden md:block" for="cus_name">ФИО</label>
          <span v-if="customer.gender == 'male'">👨</span>
          <span v-if="customer.gender == 'female'">👩</span>
          {{ customer.fio }}
        </div>

        <div class="grid grid-cols-1 lg:grid-cols-3 lg:gap-4" v-if="customer.phone">
          <div class="mt-4">
            <label class="hidden md:block" for="cus_name">Телефон</label>
            <div class="read-field">
              <i class="fas fa-phone mr-2 text-gray-400"></i>
              {{ customer.phone }}
            </div>
          </div>

          <div class="mt-4" v-if="customer.email">
            <label class="hidden md:block">
              Электронная почта
            </label>
            <div class="read-field">
              <i class="far fa-envelope mr-2 text-gray-400"></i>
              {{ customer.email }}
            </div>
          </div>
          <div class="mt-4" v-if="customer.birthday">
            <label class="hidden md:block">
              Дата рождения
            </label>
            <div class="read-field">
              <i class="fas fa-calendar-day mr-2 text-gray-400"></i>
              {{ customer.birthday }}
            </div>
          </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-3 lg:gap-4 mt-4">
          <div class="" v-if="customer.city">
            <label class="hidden md:block" for="cus_name">Город</label>
            <div class="read-field">
              <i class="fas fa-map-marker-alt mr-2 text-gray-400"></i>
              {{ customer.city }}
            </div>
          </div>

          <div class="md:col-span-2" v-if="customer.shippingAddress">
            <label class="hidden md:block">Адрес доставки</label>
            <div class="read-field">
              <i class="fas fa-shipping-fast mr-1 text-gray-400"></i>
              {{ customer.shippingAddress }}
            </div>
          </div>
        </div>

        <div class="mt-4 grid grid-cols-1 md:grid-cols-3 md:gap-4">
          <div v-if="customer.notifications">
            <label class="hidden md:block">Уведомления</label>
            <div class="flex flex-row gap-x-4 mt-2">
              <label class="text-black" v-if="customer.notifications.sms">
                <input type="checkbox" v-model="customer.notifications.sms" disabled />
                SMS
              </label>
              <label class="text-black" v-if="customer.notifications.email">
                <input type="checkbox" v-model="customer.notifications.email" disabled />
                Email
              </label>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Client from "../../repo/CustomerRepo";
import MainCustomerSettings from "../customers/MainCustomerSettings.vue";

export default {
  props: ["id"],
  components: { MainCustomerSettings },
  data() {
    return {
      customer: {}
    };
  },
  async mounted() {
    // pull customer details
    const { success, msg, data } = await Client.getByid(this.id);
    if (success) {
      this.customer = data;
    }
  }
};
</script>

import Vue from "vue";
import OpticRepo from "../../repo/OpticRepo";
import api from '../../api/api'

const state = {
  optics: [],
  suppliers: [],
  selected: {},
  // current optic
  active: {
    id: false
  }
};

const getters = {
  active: state => {
    // always return fresh data from the optics list
    if (state.active && state.active.id && state.optics.length > 0) {
      // check if active optic is in the optics list
      return state.optics.find(item => item.id == state.active.id);

    }

    return false;
  },
  optics: state => {
    return state.optics;
  },
  getById: state => ({ id }) => {
    return state.optics.find(item => item.id == id);
  },
  getOpticService: state => ({ id, serviceId }) => {
    let optic = state.optics.find(item => item.id == id);
    if (!optic || typeof optic == "undefined") return {};
    return optic.services[serviceId];
  },
  suppliers: state => {
    return state.suppliers;
  }
};

// actions
const actions = {
  wipe: ({ commit }) => {
    commit("wipe");
  },
  async pullOptics(context, payload) {
    const { data } = await OpticRepo.get();
    context.commit("setOptics", data);
  },
  async setActiveOptic(context, id) {
    console.log("se active optic" + id);

    context.commit("setActiveOptic", context.getters.getById({ id: id }));
  },
  async pullSuppliers({ state, commit }) {

    let list = [];
    let { success, msg, data } = await api.get('/g/suppliers');
    if (success) {
      list = data.suppliers;
    }

    commit('setSuppliers', list);
  }

};

// mutations
const mutations = {
  setOptics(state, payload) {
    state.optics = payload;
  },
  setActiveOptic(state, payload) {
    state.active = payload;
  },
  setSuppliers(state, payload) {
    state.suppliers = payload;
  },
  wipe(state) {
    state.optics = [];
    state.selected = {};
    state.active = {};
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

<template>
  <div>
    <div class="bg-white rounded-md">
      <div class="font-semibold text-sm mb-2">Линзы</div>
      <div class="divide-y">
        <div class="grid lg:grid-cols-7 py-2 lg:mb-1 text-sm w-full" v-for="item in sortedLenses">
          <div class="col-span-3 mb-2 font-semibold text-gray-600">
            {{ item.name }}
          </div>
          <div class="col-span-2">
            <div class="flex-row gap-x-2 flex">
              <div
                v-for="attr in item.options"
                @click="(attr.status = attr.status == 2 ? 0 : ++attr.status), updateRecordLenses()"
                :class="{
                  'bg-green-500 text-white': attr.status == 1,
                  'bg-red-500 text-white': attr.status == 2
                }"
                class="px-2 py-1 rounded-md bg-gray-200 text-sm cursor-pointer hover:bg-opacity-70"
              >
                {{ attr.radius }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex flex-row items-center gap-x-4 mb-4 relative hidden"
        v-for="(item, index) in record.testLenses.items"
      >
        <select v-model="item.title" class="py-2 w-full lg:w-auto">
          <option value="">Выбрать линзы</option>
          <option v-for="item in lenses" :value="item">{{ item.name }}</option>
        </select>

        <select v-model="item.state" class="py-2 w-full lg:w-auto">
          <option value="">Выберите...</option>
          <option v-for="state in states" :value="state">{{ state }}</option>
        </select>

        <button @click="record.testLenses.items.splice(index, 1)" class="">
          <i class="fas fa-times text-red-500"></i>
        </button>
      </div>
      <button
        class="btn-link text-sm hidden"
        @click="record.testLenses.items.push({ title: '', state: '' })"
      >
        <i class="mr-1 fas fa-plus-circle"></i>
        Добавить
      </button>

      <div class="font-semibold text-sm mb-2 mt-8">Осмотр после адаптации</div>
      <div class="grid grid-cols-3 mt-2 mb-2 font-semibold text-sm gap-x-2">
        <div></div>
        <div>OD</div>
        <div>OS</div>
      </div>

      <template>
        <div
          class="grid grid-cols-3 text-sm border-b border-gray-100 py-2 gap-x-2"
          v-for="key in ['complaints', 'placement']"
          :key="key"
        >
          <div class="text-sm">{{ $t(key) }}</div>

          <div class="">
            <select
              v-model="record.testLenses.controlCheck.od[key]"
              :type="key"
              class="py-0.5 w-full px-0.5"
            >
              <option v-for="opt in options[key]">{{ opt }}</option>
            </select>
          </div>

          <div class="">
            <select
              v-model="record.testLenses.controlCheck.os[key]"
              :type="key"
              class="py-0.5 w-full px-0.5"
            >
              <option v-for="opt in options[key]">{{ opt }}</option>
            </select>
          </div>
        </div>
      </template>

      <div class="font-semibold text-sm mb-2 mt-6">Комментарий</div>
      <textarea class="w-full" v-model="record.testLenses.comment"></textarea>
    </div>
  </div>
</template>

<script>
export default {
  props: ["record"],
  data() {
    return {
      compkey: 0,
      lenses: [],
      states: ["Подобрано", "Х НЕ подобрано"],
      options: {
        complaints: ["нет", "да"],
        placement: ["норм", "крутая", "плоская"],
        cornea: ["нет", "да"],
        mucous: ["нет", "да"]
      },
      compiledLenses: []
    };
  },
  mounted() {
    this.loadLenses();
  },
  methods: {
    async loadLenses() {
      // get lenses available in this address
      // load lenses from db
      let query = encodeURIComponent(
        JSON.stringify({ diag: true, limit: -1, ids: this.$dept.lenses })
      );
      const { data, msg, success } = await this.$api.get(`/g/products?filter=${query}`);
      if (success) {
        this.lenses = data.products;
      }

      let recordLenses = this.record.testLenses.items;

      this.compiledLenses = [];
      // compile lenses
      for (let p of this.lenses) {
        let attr = this.getAttributes(p);
        let options = attr.map(el => {
          return {
            radius: el,
            status: 0
          };
        });
        let item = {
          name: p.name,
          options: options,
          id: p.id
        };

        // check the states of record
        let recordItem = recordLenses.find(el => el.id == p.id);
        if (recordItem) {
          for (let r of recordItem.options) {
            let lensOptionItem = options.find(el => el.radius == r.radius);
            if (lensOptionItem) {
              lensOptionItem.status = r.status;
            }
          }
        }

        this.compiledLenses.push(item);
      }
    },
    selectAttr(item, attr) {},
    /**
     * Update record data
     */
    updateRecordLenses() {
      // update record test lenses details
      this.record.testLenses.items = this.compiledLenses.reduce((acc, item) => {
        acc.push(item);
        return acc;
      }, []);
    },
    isCopyAvailable(item) {
      return this.countItems(item) < this.getAttributes(item).length;
    },
    /**
     * Copy lense only if we have enought attributes for them
     */
    copyLense(item) {
      if (this.countItems(item) < this.getAttributes(item).length) {
        this.lenses.push({ ...item });
      }
    },
    /**
     * Split attributes
     */
    getAttributes(item) {
      return item.attr.split(";").filter(el => el != "");
    },
    /**
     * How many similar lenses in the list
     */
    countItems(item) {
      return this.lenses.filter(p => p.id == item.id).length;
    }
  },
  computed: {
    sortedLenses() {
      let arr = [...this.compiledLenses];
      return arr.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
    }
  }
};
</script>

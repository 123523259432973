<template>
  <modal :styles="''">
    <div class="relative">
      <button @click="prev()" class="absolute left-0 lg:left-0 text-blue-600 text-xl md:text-2xl" v-if="showPrev">
        <i class="fas fa-angle-left mr-2"></i>
        <span class=" text-xs -top-1 relative">Назад</span>
      </button>
      <div class="w-full px-4 text-center">
        <h1 class="text-lg md:text-xl font-bold mt-1">
          {{ steps[stepIndex].title }}
        </h1>
      </div>
    </div>
    <!-- Steps -->
    <div class="mt-8" ref="form">
      <!--  Default fields -->
      <div class="form mb-4" v-if="steps[stepIndex].slug == 'start'">
        <div class="flex lg:flex-row flex-col gap-x-4">
          <div class="w-1/2">
            <label>Дата приема</label>
            <input type="date" v-model="recordDate" placeholder="Дата приема" class="w-full" required />
          </div>

          <div class="w-1/2">
            <label>Услуга</label>
            <select
              class="w-full"
              v-model="record.service_id"
              required
              ref="serviceId"
              @change="$refs.serviceId.classList.remove('border-red-500')"
            >
              <option value="-1">Выберите услугу</option>
              <option :value="service.id" v-for="(service, index) in services" :key="service.id">
                {{ service.title }} (🕞 {{ service.time }} мин.)
              </option>
            </select>
          </div>
        </div>

        <div class="w-full mt-4" v-if="record.host">
          <label>Врач</label>
          <!-- <input type="text" v-model="record.host.name" placeholder="ФИО врача" class="w-full" /> -->
          <select v-model="record.host_id" class="w-full">
            <option v-for="host in availableHosts" :value="host.id" :key="host.id">
              {{ host.name }}
            </option>
          </select>
        </div>

        <div class="w-full mt-4">
          <label>
            Заметка
          </label>
          <textarea class="w-full" type="text" required="" v-model="record.note" placeholder="Заметка" />
        </div>
      </div>

      <div v-if="steps[stepIndex].slug == 'eye-passport'">
        <eye-passport :record="record" />
      </div>

      <div v-if="steps[stepIndex].slug == 'complaints'">
        <complaints :record="record" />
      </div>
      <div v-if="steps[stepIndex].slug == 'metrics'">
        <metrics :record="record" />
      </div>
      <div v-if="steps[stepIndex].slug == 'current-glasses'">
        <glasses :obj="record.currentGlasses" />
      </div>

      <!-- New glasses -->
      <div v-if="steps[stepIndex].slug == 'new-glasses'">
        <glasses :obj="record.newGlasses" :is-new="true" />
      </div>

      <!-- Select lenses -->
      <div v-if="steps[stepIndex].slug == 'diagnostic'">
        <test-lenses :record="record" />
      </div>

      <!-- Controls -->
      <div class="grid grid-cols-3 mt-8 gap-x-2">
        <div>
          <button @click="prev()" class="bg-gray-200 rounded-md font-medium lg:py-2 py-2 px-4 w-full" v-if="showPrev">
            Назад
          </button>
        </div>
        <button @click="next()" class="btn-primary w-full col-span-2" v-if="!lastStep" :class="{ busy: loading }">
          Продолжить
        </button>
        <button @click="complete()" class="btn-primary w-full  col-span-2" v-if="lastStep">
          Завершить
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapGetters } from "vuex";
import Record from "../../repo/RecordRepo";
import Complaints from "./steps/Complaints.vue";
import Glasses from "./Glasses.vue";
import Metrics from "./Metrics.vue";
import ModalMixin from "../modals/modal.mixin";
import dayjs from "dayjs";
import EyePassport from "./steps/EyePassport";
import TestLenses from "./steps/TestLenses";

export default {
  components: { Complaints, Glasses, Metrics, EyePassport, TestLenses },
  props: ["client_id", "customer", "record_id"],
  mixins: [ModalMixin],
  data() {
    return {
      customerRevision: "",
      loading: false,
      record: {},
      stepIndex: 0,
      recordDate: new Date(),
      availableHosts: [],
      steps: [
        {
          title: "Добавить прием",
          slug: "start"
        },
        {
          title: "Офтальмологический паспорт",
          slug: "eye-passport"
        },
        {
          title: "Жалобы",
          slug: "complaints"
        },
        {
          title: "Показатели",
          slug: "metrics"
        },
        {
          title: "Текущие очки",
          slug: "current-glasses"
        },

        {
          title: "Подбор МКЛ",
          slug: "diagnostic"
        },
        {
          title: "Новые очки",
          slug: "new-glasses"
        }
      ],
      services: []
    };
  },
  mounted() {
    this.record = { ...Record.SCHEMA };

    if (this.record_id) {
      this.getRecord(this.record_id);
      this.stepIndex = 1;
    } else {
      this.record.client_id = this.client_id;
      this.record.opticId = this.active.id;
    }

    this.availableHosts = this.getByDept({ id: this.$dept.id });
    // pre select me
    this.record.host = {
      id: this.$me.id
    };

    // get available services
    this.loadServices();
  },
  methods: {
    async loadServices() {
      let { success, msg, data } = this.$api.get("/optics/" + this.$dept);
      if (success) {
        this.services = data.services.filter(el => el.available == true);
      }
    },
    /**
     * Complete record
     */
    async complete() {
      this.record.status = "completed";
      await this.save();

      this.$events.$emit("update-records");
      this.close();
    },
    async getRecord(id) {
      this.loading = true;
      const { data, msg, success } = await Record.getByid(id);
      if (success) {
        this.record = { ...Record.SCHEMA, ...data };

        // set current step if exists
        if (this.record.step && this.record.step <= this.steps.length) {
          //this.stepIndex = this.record.step;
        }
      }
      this.loading = false;
    },
    async next() {
      if (this.steps[this.stepIndex].slug == "start") {
        if (this.record.service_id == undefined || this.record.service_id == -1) {
          this.$refs.serviceId.classList.add("border-red-500");
          return false;
        }
      }

      await this.save();

      if (this.stepIndex < this.steps.length) {
        this.stepIndex++;
      }

      if (this.steps[this.stepIndex].hidden) {
        this.next();
      }
    },
    prev() {
      if (this.stepIndex > 0) {
        this.stepIndex--;
      }

      if (this.steps[this.stepIndex].hidden) {
        this.prev();
      }
    },
    async save() {
      this.loading = true;

      console.log(this.customer);
      this.record.step = this.stepIndex;
      this.record.client.fio = this.customer ? this.customer.fio : "";

      this.record.startTime = dayjs.utc(this.recordDate).unix();
      this.record.serviceTitle = this.service.title;

      let { success, msg, data } = await Record.update(this.record);
      if (success) {
        this.record = data;
      }
      this.loading = false;
    }
  },
  computed: {
    ...mapGetters("optics", ["optics", "active", "getOpticService", "getById"]),
    ...mapGetters("records", ["getStatus"]),
    ...mapGetters("users", ["getByDept"]),
    progressWidth() {
      let width = Math.abs(100 / this.steps.length);
      return width * (this.stepIndex + 1);
    },
    showPrev() {
      return this.stepIndex > 0;
    },
    lastStep() {
      return this.stepIndex == this.steps.length - 1;
    },
    completed() {
      return this.record.status == "completed";
    },
    elapsedTime() {
      return Math.ceil((this.record.end - this.record.start) / 60);
    }
  }
};
</script>

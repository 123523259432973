<template>
  <page>
    <template v-slot:header>
      <back-btn></back-btn>
      <template v-if="order.id">Поступление от {{ order.created_at | datetime }}</template>
      <template v-else>
        Новое поступление
      </template>
    </template>
    <template v-slot:actions></template>

    <!-- Filters, collapsable -->
    <template v-slot:content>

      <div class="info mb-2 w-2/3" v-if="order.status == 'wait'">
        Начните сканировать товары в поставке, как только все товары будут просканированы, нажмите Подтвердить
      </div>

      <!--  errors -->
      <error v-if="error">{{ $t(error) }}</error>

      <div>
        <div class="py-4 px-4 bg-red-500 text-white rounded-md mt-4 mb-4"
          v-if="order.notFound && order.notFound.length > 0">
          В накладной есть товары, которых нет в базе. Проверьте штрихкоды.
          <div>
            <div v-for="p in order.notFound">
              <strong>{{ p.barcode }}</strong>
              : {{ p.name }}
            </div>
          </div>
        </div>

        <div class="py-4 px-4 bg-red-500 text-white rounded-md mt-4 mb-4" v-if="order.supplier_not_found_in_optic">
          <strong>{{ order.supplierTitle }}</strong>
          поставщик отсутствует в поставщиках для этой оптики, проверьте настройки поставщиков для текущего филиала.
          <p>Или выберите поставщика вручную из списка.</p>
        </div>

        <div class="py-4 px-4 bg-red-500 text-white rounded-md mt-4 mb-4" v-if="order.supplier_not_found">
          <strong>{{ order.supplierTitle }}</strong>
          поставщик отсутствует в системе, обратитесь в службу поддержки.
          <p>Или выберите поставщика вручную из списка.</p>
        </div>
      </div>

      <div class="flex flex-row gap-x-4">
        <div class="w-1/5 rounded-md bg-white px-4 py-4">

          <div class="flex flex-col space-y-4">

            <div class="mt-2">
              <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">Статус</label>
              <span v-if="order.status == 'confirmed'" class="badge green">Проведено</span>
              <span v-if="order.status == 'wait'" class="badge blue">Ожидает подтверждения</span>
              <span v-if="order.status == 'cancelled'" class="badge gray">Отмена</span>
              <span v-if="order.status == 'new'" class="badge blue">Черновик</span>
              <span v-if="order.status == ''" class="badge blue">Новое</span>
            </div>

            <div v-if="!readOnly">
              <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
                Загрузить XML
              </label>
              <input type="file" v-on:change="loadXML()" class="w-full py-1.5" ref="file" />
            </div>


            <div class="">
              <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
                Поставщик
              </label>
              <select :readonly="readOnly" :disabled="readOnly" class="w-full" v-model="order.supplier_id" required
                @change="hideSupplierErrors()">
                <option :value="s.id" v-for="s in suppliers">{{ s.name }}</option>
              </select>
            </div>
            <div class="">
              <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
                Дата
              </label>
              <input type="date" :readonly="readOnly" v-model="order.ttnDate" class="w-full" required />
            </div>


            <div class="">
              <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
                ТТН №
              </label>
              <input type="text" :readonly="readOnly" v-model="order.ttnNumber" class="w-full" required autofocus />
            </div>
            <div class="">
              <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
                Заказ №
              </label>
              <input type="text" :readonly="readOnly" v-model="order.orderNumber" class="w-full" required autofocus />
            </div>

            <div class="" v-if="order.external_order_id">
              <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
                Заявка
              </label>
              <a :href="`/store/external/${order.external_order_id}`" target="_blank"
                class="text-sm text-blue-600 border-b border-blue-200">{{ order.external_order_id }}</a>
            </div>

            <div class="">
              <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
                Заметка
              </label>
              <textarea class="w-full" type="text" v-model="order.note"></textarea>
            </div>

            <div class="">
              <div class="" v-if="!order.id || order.status == 'new' || order.status == ''">
                <button type="button" @click="saveSupply()" :class="{ busy: busy }" :disabled="isDisabled"
                  class="btn-primary-sm">
                  <template v-if="order.status == 'new'">
                    Провести поставку
                  </template>
                  <template v-else>
                    Сохранить
                  </template>
                </button>
              </div>

              <div v-if="order.status == 'wait'">
                <button type="button" @click="confirmSupply()" :class="{ busy: busy }" :disabled="isConfirmDisabled"
                  class="btn-primary-sm">
                  <template>
                    Подтвердить
                  </template>
                </button>
              </div>
              <success v-if="status == 'confirmed'">Поставка проведена. Можно закрыть эту страницу.</success>
              <success v-if="status == 'cancelled'">Поставка отменена. Можно закрыть эту страницу.</success>

              <!-- cancel it -->
              <div class="" v-if="order.status == 'confirmed'">
                <button type="button" @click="cancelSupply()" :class="{ busy: busy }" :disabled="isDisabled"
                  class="btn-danger-sm">
                  Отменить поставку
                </button>
              </div>

            </div>


          </div>

        </div>
        <div class="w-4/5">

          <div class="mb-4 flex flex-col lg:flex-row lg:items-center" v-if="order.status == 'wait'">
            <div class="text-sm font-semibold mr-2 mb-2 lg:mb-0">
              Просканируйте товар:
            </div>
            <div>
              <!-- 733905562730 -->
              <!-- <barcode-scanner @scan="addProductFromBarcode" extend="lot, date" mode="localfirst" /> -->
              <scanner-demo @scan="addProductFromBarcode" />
            </div>
          </div>

          <div class="mb-4 flex flex-col lg:flex-row lg:items-center" v-if="!readOnly && order.status != 'wait'">
            <div class="text-sm font-semibold mr-2 mb-2 lg:mb-0">
              Просканируйте товар:
            </div>
            <div>
              <!-- 733905562730 -->
              <!-- <barcode-scanner @scan="addProductFromBarcode" extend="lot, date" mode="localfirst" /> -->
              <scanner-demo @scan="addProductFromBarcode" />
            </div>
          </div>

          <table class="table-condensed w-full data-table xl:w-3/4">

            <thead>
              <tr>
                <th>Название</th>
                <th>Штрихкод</th>
                <th>Кол-во, шт.</th>
                <th v-if="$requireRole('manager')">Закуп. цена</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in sortedItems" class="highlight-row"
                :class="{ 'highlighted': index == 0 && highlightIndex }">
                <td>
                  <div class="flex flex-row table-condensed">
                    <button class="text-red-500 mr-2" @click="removeItem(item.barcode)">
                      <i class="fas fa-times text-red"></i>
                    </button>
                    <span class="font-semibold">{{ item.name }}</span>
                  </div>

                  <div v-if="item.lots && item.lots.length > 0"
                    class="flex flex-row flex-wrap py-1 opacity-60 hover:opacity-100 transition-opacity">
                    <div v-for="(lot, lotIndex) in item.lots"
                      class="rounded-md border px-2 py-0.5 text-sm flex flex-row mb-0.5 mr-0.5 items-center">
                      <button class="text-yellow-500 text-xs mr-1" @click="removeLot(item, lotIndex)">
                        <i class="fas fa-times text-red"></i>
                      </button>
                      <div class="text-xs font-semibold font-mono">{{ lot.lot }}</div>
                      <div class="text-xs bg-gray-200 rounded-md px-1 py-0.5 ml-1 font-mono">
                        {{ lot.productionDate }} / {{ lot.expirationDate }}
                      </div>
                    </div>
                  </div>

                </td>
                <td>
                  {{ item.barcode }}
                </td>
                <td>
                  <div class="flex flex-row items-center justify-between">
                    <input type="number" v-model="item.qty" class="input-sm w-20 input-number" :readonly="readOnly" />
                    <div v-if="order.status == 'wait'" class="px-1 rounded-md">
                      {{ item.testQty || 0 }}
                    </div>
                  </div>

                </td>
                <td v-if="$requireRole('manager')">
                  <price v-model="item.purchase_price" class="text-right" />
                </td>
              </tr>
            </tbody>

          </table>
        </div>
      </div>


      <loading v-if="importBusy">
        Обработка файла...
      </loading>
    </template>
  </page>
</template>

<script>
import api from "../../../api/api.js";
import Product from "../../../repo/ProductRepo.js";
import dayjs from "dayjs";
const utils = require("../../../utils.js");
import { ndsOptions } from "../../../data/consstants.js";
import ScannerDemo from '../../shared/ScannerDemo.vue'
import router from "../../../router/index.js";

export default {
  props: ["id"],
  components: { ScannerDemo },
  data() {
    return {
      busy: false,
      importBusy: false,
      compKey: 1,
      suppliers: [],
      status: "",
      highlightIndex: false,
      order: {
        items: [],
        supplier_id: "",
        note: "",
        ttnDate: dayjs().format("YYYY-MM-DD"), // default is today
        ttnNumber: "",
        supplierDiscount: 0, // current supplier discount in db
        status: ''
      },
      file: null,
      readOnly: false,
      ndsOptions: ndsOptions,
      error: "",
      confirmBusy: false
    };
  },
  mounted() {
    // get
    this.getSuppliers();

    if (this.id && this.id != "new") {
      this.getItem();
    }
  },
  methods: {
    async cancelSupply() {
      // confirm that user want to do that
      if (!confirm("Вы уверены, что хотите отменить поставку?")) {
        return;
      }
      // cancel it
      this.busy = true;
      let { success, msg, data } = await api.post(`/supplies/${this.order.id}/actions`, { action: "cancel" });
      this.busy = false;
      if (success) {
        this.$router.push({ path: "/store/supply" });
      }
    },
    hideSupplierErrors() {
      this.order.supplier_not_found_in_optic = false;
      this.order.supplier_not_found = false;
    },
    switchFocus() {
      document.getElementsByName("barcode")[0].focus();
    },
    async testProductBarcode(product) {

      // check is this barcode in order?
      let item = this.order.items.find(el => el.barcode == product.barcode);

      if (item) {
        item.testQty = item.testQty ? item.testQty + 1 : 1
        console.log(item)
      } else {
        alert(product.barcode + ' - Товар не найден в поставке')
      }

      this.compKey++;

    },
    // add product by barcode
    async addProductFromBarcode(product) {
      if (product.id) {
        this.addToOrder(product);
      }
    },
    removeItem(barcode) {
      let index = this.order.items.findIndex(el => el.barcode == barcode);
      this.order.items.splice(index, 1);
    },
    removeLot(item, index) {
      item.lots.splice(index, 1);
      --item.qty;
    },
    async addToOrder(product) {
      let vm = this;
      let cartItem = {
        id: product.id,
        name: product.name,
        attr: product.attr,
        sale_price: product.price ?? product.shop_price,
        qty: 1,
        barcode: product.barcode,
        purchase_price: "",
        base_price: product.base_price
      };

      // add lots and dates
      let lotItem = null;

      // if we pass lot, set dates to this lot, if not, no need to set dates
      if (product.lot) {
        lotItem = {
          lot: product.lot,
          productionDate: product.productionDate ?? '',
          expirationDate: product.expirationDate ?? '',
          qrCode: product.qrCode ?? ''
        };
      }

      // check the same lot in database
      if (lotItem != null) {
        let { success, msg, data } = await Product.isLotExists(lotItem.lot);
        if (data.items.length > 0) {
          alert("Такой лот уже стоит на складе.");
          this.failsound();
          return;
        }

        if (!success) {
          alert("Не удается проверить лот на складе. Ошибка: " + msg);
          this.failsound();
        }
      }

      // check if we have it in cart already
      let foundIndex = this.order.items.findIndex(el => el.barcode == cartItem.barcode);

      // check if such lot already scanned, return the error
      if (foundIndex != -1) {
        let cartItem = this.order.items[foundIndex];
        if (cartItem) {
          let isLotExists =
            cartItem.lots && cartItem.lots.length > 0 ? cartItem.lots.findIndex(el => el.lot == lotItem.lot) != -1 : false;

          if (isLotExists) {
            alert("Такой лот уже просканирован.");
            this.failsound();
            return;
          }
        }
      }

      if (foundIndex != -1) {
        cartItem = this.order.items[foundIndex];
        cartItem.qty++;

        if (!cartItem.lots) {
          cartItem.lots = [];
        }

        if (lotItem != null) {
          cartItem.lots.push(lotItem);
        }
      } else {
        if (lotItem != null) {
          if (!cartItem.lots) {
            cartItem.lots = [];
          }
          cartItem.lots.push(lotItem);
        }

        this.order.items.push(cartItem);

      }

      this.oksound();
      this.compKey++;
      cartItem.lastModified = Date.now();
      this.highlightIndex = true;

      setTimeout(() => {
        vm.highlightIndex = false;
      }, 300);
    },
    async getItem() {
      // load suppliers list
      let { success, msg, data } = await api.get(`/supplies/${this.id}`);
      if (success) {
        this.order = data;

        if (this.order.status == 'wait') {
          this.order.items = this.order.items.map(el => {
            el.testQty = el.qty;
            el.qty = 0;
            return el
          })
        }

        if (this.order.status != 'wait') {
          this.readOnly = true;
        }
      }
    },
    async getSuppliers() {
      let { data } = await this.$api.get(`/settings?type=suppliers`);
      this.suppliers = data.data;
    },
    async confirmSupply() {

      // check that all items was scanned, if something missed, user have to remove it from the list
      for (let item of this.order.items) {
        if (item.qty <= 0) {
          alert("Не все товары были отсканированы");
          return;
        }

        if (item.qty < item.testQty) {
          alert(`Товар с ШК ${item.barcode} отсканирован не полностью, проверьте поставку`);
          return;
        }

      }

      this.saveSupply();


    },
    async saveSupply() {
      this.error = "";
      if (this.order.supplier_id == "") {
        alert("Нужно выбрать поставщика");
        return;
      }

      if (this.order.ttnDate == "") {
        alert("Нужно выбрать дату накладной");
        return;
      }

      if (this.order.ttnNumber == "") {
        alert("Нужно указать номер накладной");
        return;
      }

      if (this.order.items.length == 0) {
        alert("В поставке должны быть товары");
        return;
      }

      for (let item of this.order.items) {
        if (item.qty <= 0) {
          alert("Количество товара должно быть больше 0");
          return;
        }

        if (item.purchase_price == "" || item.purchase_price == 0) {
          alert("Не указана цена закупки для товара " + item.name);
          return;
        }

      }

      this.busy = true;
      let { success, msg, data } = await api.post(`/supplies`, this.order);
      this.busy = false;
      console.log(msg)
      if (success) {
        this.status = data.status;
        this.order = data;
      } else {
        console.log()
        this.error = msg;
      }
    },
    async loadXML() {
      const file = await utils.readFile(this.$refs.file.files[0]);
      this.importBusy = true;
      // send api request and fill the form
      let { success, msg, data } = await this.$api.post("/supplies/import", { file: file });

      if (success) {
        this.order = data.supply;
      }

      // check if we have such supplier in supliers list
      if (!this.suppliers.find(el => el.id == this.order.supplier_id)) {
        this.order.supplier_not_found_in_optic = true;
        this.order.supplier_id = "";
      }

      this.importBusy = false;
    },
    oksound() {
      let audio = new Audio("/sounds/success.wav");
      audio.play();
    },
    failsound() {
      let audio = new Audio("/sounds/error.wav");
      audio.play();
    },
  },
  computed: {
    isDisabled() {
      return (
        this.order.supplier_not_found_in_optic || this.order.supplier_not_found || this.busy || this.order.items.length == 0
      );
    },
    isConfirmDisabled() {
      return this.confirmBusy;
    },
    supplierDiscount() {
      if (!this.order.supplier_id) {
        return "";
      }

      let supplier = this.$dept.suppliers.find(el => el.id == this.order.supplier_id);
      if (supplier) {
        return supplier.store_discount;
      }

      return "";
    },
    sortedItems() {
      this.compKey;
      // sort cart when last scanned at the top
      return [...this.order.items].sort((a, b) => {
        if (!a.lastModified) {
          return 1;
        }
        if (!b.lastModified) {
          return -1;
        }

        return b.lastModified - a.lastModified;
      });
    }

  }
};
</script>

<template>
  <span class="rounded-md px-2 py-1 text-xs font-semibold" :class="'task-' + status">
    {{ $t(`task.status.${status}`) }}
  </span>
</template>
<script>
export default {
  props: ["status"]
};
</script>

<template>
  <div>
    <div v-if="users && users.length == 0" class="py-8 text-gray-500 font-thin">
      Сотрудников пока нет
    </div>

    <div>
      <div class="flex flex-row justify-between items-center bg-white rounded-md mb-2 py-2 px-4">
        <div class="filters flex flex-row items-center gap-x-4">
          <div class="mr-8 text-sm">
            Всего:
            <span class="font-semibold">{{ users.length }}</span>
          </div>

          <div>
            <input v-model="filter.search" type="text" placeholder="Поиск.." />
          </div>
          <div>
            <select v-model="filter.deptId">
              <option value="">Выбрать филиал</option>
              <option :value="dept.id" v-for="dept in $depts">{{ dept.title }}</option>
            </select>
          </div>
          <div>
            <select v-model="filter.role">
              <option value="">Выбрать должность</option>
              <option v-for="(title, role) in $roles" :value="role">{{ title }}</option>
            </select>
          </div>
          <div>
            <select v-model="filter.status">
              <option value="">Статус</option>
              <option value="fired">Уволен</option>
              <option value="active">Активен</option>
              <option value="vaction">В отпуске</option>
              <option value="online">Онлайн</option>
            </select>
          </div>
        </div>
        <div>
          <div>
            <button @click="edit('')" to="optics/new" class="btn-action-sm">
              <i class="fas fa-plus-circle mr-1"></i>
              Добавить сотрудника
            </button>
          </div>
        </div>
      </div>

      <div class="">
        <table class="table-fixed min-w-full divide-y divide-gray-200">
          <thead class="bg-white">
            <tr>
              <th scope="col" class="p-4">
                <div class="flex items-center">
                  <input
                    id="checkbox-all"
                    aria-describedby="checkbox-1"
                    type="checkbox"
                    class="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-cyan-200 h-4 w-4 rounded"
                  />
                  <label for="checkbox-all" class="sr-only">checkbox</label>
                </div>
              </th>
              <th scope="col" class="p-4 text-left text-xs font-medium text-gray-500 uppercase">
                Имя
              </th>
              <th scope="col" class="p-4 text-left text-xs font-medium text-gray-500 uppercase">
                Телефон
              </th>
              <th scope="col" class="p-4 text-left text-xs font-medium text-gray-500 uppercase">
                Должность
              </th>
              <!-- <th scope="col" class="p-4 text-left text-xs font-medium text-gray-500 uppercase">
                    Оптика
                  </th> -->
              <th scope="col" class="p-4 text-left text-xs font-medium text-gray-500 uppercase">
                Статус
              </th>
              <th scope="col" class="p-4"></th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <user-row :user="user" v-for="(user, index) of sorted" @edit="edit" :key="index" />
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import Modal from "../shared/Modal.vue";
import EditUserModal from "../users/EditUserModal.vue";

import { mapGetters } from "vuex";
import EditUser from "../users/EditUser.vue";
import UserRow from "../users/UserRow.vue";

export default {
  components: { Modal, EditUser, UserRow, EditUserModal },
  name: "Users",
  props: ["id"],
  data() {
    return {
      editUserModal: false,
      editableId: -1,
      filter: {
        search: "",
        role: "",
        status: "",
        deptId: ""
      }
    };
  },
  mounted() {
    this.$store.dispatch("users/pullUsers");
    this.$store.dispatch("optics/pullOptics");
    if (this.id) {
      // open user by id
      this.edit(this.id);
    }
  },
  methods: {
    edit(id) {
      this.$modals.open(EditUserModal, { id: id });
    }
  },
  computed: {
    ...mapGetters("users", ["users"]),
    sorted() {
      let sorted = Object.assign([], this.users);

      if (this.filter.search) {
        var regex = new RegExp(this.filter.search, "gi");
        sorted = sorted.filter(o => o.name.match(regex));
      }

      if (this.filter.role) {
        sorted = sorted.filter(o => o.roles.indexOf(this.filter.role) !== -1);
      }

      if (this.filter.deptId) {
        sorted = sorted.filter(o => o.depts.indexOf(this.filter.deptId) !== -1);
      }

      if (this.filter.status) {
        sorted = sorted.filter(o => o.status == this.filter.status);
      }

      return sorted;
    }
  }
};
</script>

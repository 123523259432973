import * as axios from "axios";
import api from "./api";

const BASE_URL = process.env.VUE_APP_BACKEND_URL;

async function upload(base64) {
  const url = `${BASE_URL}/upload`;

  return api.call("/upload", { method: "post", data: { data: base64 } });
}

export { upload };

<template>
  <div class="relative">
    <input :class="styles" type="text" :placeholder="placeholder" :value="value" @input="search" @blur="closeHelper()"
      @keypress="checkFormat" ref="input" />
    <div class="absolute top-6 right-10 z-10" v-if="busy">
      <loading />
    </div>
    <div class="absolute rounded shadow-2xl top-18 border-gray-200 border bg-white z-10 w-full"
      v-if="(showHelper && results.length > 0) || (addnew && showHelper && value.length >= 3)">
      <div
        class="px-6 py-4 hover:bg-gray-200 cursor-pointer text-gray-800 border-b last:border-b-0 font-semibold flex flex-row items-center"
        v-for="(item, index) in results" :key="index" @click.prevent="selectItem(item)">
        <slot v-bind:item="item"></slot>
      </div>
      <div v-if="addnew && results.length == 0" class="px-2">
        <div class="text-gray-500 py-1 text-sm">Ничего не найдено</div>
        <button class="py-2 text-blue-500" @click="createCustomer()"><i class="fas fa-plus"></i> добавить клиента</button>
      </div>
    </div>
  </div>
</template>

<script>
const debounce = require("debounce");

import { mapGetters } from "vuex";
import Loading from "../Loading.vue";
import Product from "../../repo/ProductRepo";
import Category from "../../repo/CategoryRepo";
import Customer from "../../repo/CustomerRepo";
import Brand from "../../repo/BrandRepo";
import Supplier from "../../repo/SupplierRepo";
import EditCustomerModal from "../customers/EditCustomerModal.vue";

export default {
  components: { Loading },
  props: ["value", "type", "id", "styles", "placeholder", "component", "field", "focus", 'addnew'],
  data() {
    return {
      results: [],
      busy: false,
      showHelper: false,
      hello: "some text is here",
      disableFirstTouchOnMounted: true
    };
  },
  mounted() {
    if (this.focus) {
      this.$refs.input.focus();
    }
  },
  methods: {
    createCustomer() {
      this.$modals.open(EditCustomerModal, { id: "", opticId: this.$dept.id, showSearch: true });
    },
    checkFormat(evt) {
      // formatting phone
      if (this.field == "phone") {
        const keysAllowed = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "."];
        const keyPressed = evt.key;

        if (!keysAllowed.includes(keyPressed) || evt.target.value.length == 10) {
          evt.preventDefault();
        }
      }
    },
    async search(e) {
      console.log("search event");
      let vm = this;
      let inputValue = e.target.value;
      this.$emit("input", inputValue);
      if (this.disableFirstTouchOnMounted) {
        setTimeout(() => {
          vm.disableFirstTouchOnMounted = false;
        }, 100);
        return;
      }

      this.doSearch(inputValue, this);
    },
    doSearch: debounce(async (inputValue, vm) => {
      if (inputValue.length < 3) {
        vm.results = [];
        return;
      }

      vm.busy = true;

      // search it
      //this.results = ['test1', 'test2', 'test3'];
      if (vm.component == "Product") {
        let { res, msg, data } = await Product.search(inputValue);
        vm.results = data;
      }

      //this.results = ['test1', 'test2', 'test3'];
      if (vm.component == "Category") {
        let { res, msg, data } = await Category.search(inputValue);
        vm.results = data;
      }

      if (vm.component == "Brand") {
        let { res, msg, data } = await Brand.search(inputValue);
        vm.results = data;
      }

      if (vm.component == "Customer") {
        let { res, msg, data } = await Customer.search(inputValue, vm.field);
        vm.results = data;
      }

      if (vm.component == "Supplier") {
        let { success, msg, data } = await Supplier.search({ term: inputValue });
        if (success) vm.results = data.suppliers;
      }

      if (vm.component == "city" || vm.component == "address") {
        //vm.results = ["Москва", "Санкт-Петербург"];
        let token = "e1405103ad34dccf966638ec0a6419e0a6361427";
        let url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address";
        let query = inputValue;

        var options = {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Token " + token
          },
          body: JSON.stringify({
            query: query,
            from_bound: { value: "city" },
            to_bound: { value: vm.component == "city" ? "settlement" : "house-flat" }
          })
        };

        const response = await fetch(url, options);
        const data = await response.json();
        vm.results = data.suggestions;
      }

      vm.showHelper = true;
      setTimeout(function () {
        vm.busy = false;
      }, 300);
    }, 500),
    selectItem(item) {
      let resValue = item;
      if (this.component == "Category") {
        resValue = item.name;
      }
      if (this.component == "Brand") {
        resValue = item.name;
      }

      if (this.component == "Customer") {
        resValue = item;
      }

      if (this.component == "Supplier") {
        resValue = item._id;
      }

      if (this.component == "city" || this.component == "address") {
        resValue = item.value;
      }

      this.$emit("input", resValue);
      this.$emit("select", item);
      this.showHelper = false;
    },
    closeHelper() {
      let vm = this;
      setTimeout(function () {
        vm.showHelper = false;
      }, 500);
    }
  },
  computed: {}
};
</script>

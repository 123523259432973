export const ndsOptions = [
    {
        title: "нет",
        val: -1
    },
    {
        title: "0%",
        val: 0
    },
    {
        title: "10%",
        val: 10
    },
    {
        title: "20%",
        val: 20
    }
];

export const glassesRecipeFields = [
    'sph', 'cyl', 'axis', 'bc', 'dia', 'dpp', 'prism1', 'base1', 'prism2', 'base2'
]
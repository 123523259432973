<template>
  <page>
    <template v-slot:header>
      Товары на складе
    </template>
    <template v-slot:actions>
      <button @click="addProduct()"
        class="text-sm font-medium bg-blue-500 text-white rounded-md px-4 py-2 ml-6 hover:bg-blue-600 hidden">
        <i class="fas fa-plus-circle mr-1"></i>
        Добавить товар
      </button>
    </template>

    <!-- Filters, collapsable -->
    <template v-slot:content>
      <form @submit.prevent="doFilter()">
        <div class="filters mb-4 flex flex-row items-center gap-4 bg-white px-4 py-2 rounded-md">
          <div class="mr-8 text-sm whitespace-nowrap">
            Всего:
            <span class="font-semibold">{{ total }}</span>
          </div>

          <div>
            <input type="text" placeholder="Поиск по ШК" v-model="filter.term" />
          </div>
          <div>
            <select v-model="search.category">
              <option value="">Категория</option>
              <option v-for="cat in categories" :value="cat">{{ cat }}</option>
            </select>
          </div>
          <div>
            <select v-model="search.brand">
              <option value="">Бренд</option>
              <option v-for="brand in brands" :value="brand">{{ brand }}</option>
            </select>
          </div>
          <div>
            <select v-model="search.model">
              <option value="">Модель</option>
              <option v-for="model in models" :value="model">{{ model }}</option>
            </select>
          </div>
          <!-- <div>
            <div class="grid grid-cols-2 gap-1">
              <input type="text" placeholder="Цена от" v-model="filter.price.start" />
              <input type="text" placeholder="Цена до" v-model="filter.price.end" />
            </div>
          </div> -->
          <div>
            <button :class="{ busy: filterBusy }" class="px-4 py-2 bg-blue-500 text-white rounded-md"
              @click="doFilter(true)">
              Искать
            </button>
          </div>
        </div>
      </form>

      <div class="w-full overflow-x-auto text-left bg-white rounded-md">
        <table class="w-full data-table">
          <thead>
            <tr class="">
              <th class="">Название</th>
              <th class="">ШК</th>
              <th class="">Категория</th>
              <th class="">Бренд</th>
              <th class="">Розн. цена</th>
              <th class="">Тек. остатки</th>
              <th class="">В пути</th>
              <th class="">В резерве</th>
            </tr>
          </thead>
          <tbody class="">
            <stock-row :p="p" v-for="p in sortedProducts" :key="p.id" @view="viewStockRecord"
              @checked="handleChecked" />
          </tbody>
        </table>
      </div>

      <div class="sticky mt-2 bottom-2 w-full " v-if="checkedItems.length">
        <div class="rounded-md bg-gray-600 shadow-2xl px-8 py-3 space-x-2  flex items-center flex-row">
          <div class=" mr-4 text-white text-sm">
            Выбрано
            <strong>{{ checkedItems.length }}</strong>
          </div>
          <button class="btn-primary-sm" @click="bulkEdit()">
            <i class="fas fa-pen-square mr-2"></i>
            Массовое редактирование
          </button>
          <button class="btn-primary-sm hidden">
            <i class="fas fa-print mr-2"></i>
            Напечатать ценники
          </button>
          <!-- <button class="px-4 py-2 text-sm bg-white text-black rounded-md">
            <i class="fas fa-print mr-2"></i>
            Напечатать ценники
          </button>
          <button class="px-4 py-2 text-sm bg-white ml-2 text-black rounded-md" disabled>
            <i class="fas fa-file-csv mr-2"></i>
            Экспорт в CSV
          </button> -->
        </div>
      </div>
    </template>
  </page>
</template>

<script>
import EditProductModal from "../products/EditProductModal.vue";
import ViewStockRecord from "./ViewStockRecord.vue";
import ScanProductModal from "./ScanProductModal.vue";
import StockRow from "./StockRow.vue";
import BulkProductsEdit from "../products/BulkProductsEdit.vue";

import { mapGetters } from "vuex";
import Page from "../Page.vue";
import Info from "../shared/Info.vue";

import Product from "../../repo/ProductRepo";
import Category from "../../repo/CategoryRepo";
import Brand from "../../repo/BrandRepo";

export default {
  components: { Page, Info, EditProductModal, StockRow, ScanProductModal, BulkProductsEdit },
  name: "store",
  data() {
    return {
      editProductModal: false,
      editableId: -1,
      products: [],
      filterBusy: false,
      filter: {
        term: "",
        category: "",
        brand: "",
        price: { start: "", end: "" },
        limit: 5000,
        page: 1,
        sort: "name",
        qty: 1
      },
      currentPage: 1,
      total: 0,
      checkedItems: [],
      search: {
        brand: '',
        category: '',
        model: ''
      }
    };
  },
  watch: {
    "filter.limit": function (oldval, newval) {
      this.doFilter();
    }
  },
  mounted() {
    //this.$store.dispatch("products/getProducts");
    //this.products = productsJson.products;
    this.doFilter();

    let vm = this;

    // subscribe to product save event
    this.$events.$on("reload", function () {
      vm.doFilter();
    });
  },
  methods: {
    handleChecked(id) {
      console.log("handle checked " + id);
      let checkIndex = this.checkedItems.indexOf(id);
      if (checkIndex != -1) {
        this.checkedItems.splice(checkIndex, 1);
      } else {
        this.checkedItems.push(id);
      }
    },
    bulkEdit() {
      this.$modals.open(BulkProductsEdit, { ids: this.checkedItems, mode: "addr" });
    },
    // show modal to add product to stock
    addProduct() {
      this.$modals.open(ScanProductModal);
    },

    async doFilter(btnClicked) {
      if (btnClicked) {
        this.setPage(1);
        return;
      }
      let vm = this;
      this.filterBusy = true;
      //let { success, msg, data } = await Product.filter(this.filter);
      let { success, msg, data } = await Product.query(this.filter);
      this.products = data.products;
      this.total = data.total;
      setTimeout(function () {
        vm.filterBusy = false;
      }, 500);
    },
    addNew() {
      this.$modals.open(EditProductModal);
    },
    edit(id) {
      this.$modals.open(EditProductModal, { id: id });
    },
    viewStockRecord(stockRecord) {
      this.$modals.open(ViewStockRecord, { id: stockRecord.id }, async updated => {
        if (updated) {
          this.doFilter(false);
        }
      });
    },
    toggleCheckboxes($event) {
      let items = document.querySelectorAll('input[type="checkbox"]');
      for (let el of items) {
        if (($event.target.checked && !el.checked) || (!$event.target.checked && el.checked)) {
          el.click();
        }
      }
    },
    setPage(page) {
      this.currentPage = parseInt(page);
      this.filter.page = page;
      this.doFilter();
    },
    generatePageRange(delta = 4) {
      // creates array with base 1 index
      let currentPage = parseInt(this.currentPage);
      let lastPage = Math.ceil(this.total / this.filter.limit);

      const range = Array(lastPage)
        .fill()
        .map((_, index) => index + 1);

      return range.reduce((pages, page) => {
        // allow adding of first and last pages
        if (page === 1 || page === lastPage) {
          return [...pages, page];
        }

        // if within delta range add page
        if (page - delta <= currentPage && page + delta >= currentPage) {
          return [...pages, page];
        }

        // otherwise add 'gap if gap was not the last item added.
        if (pages[pages.length - 1] !== "...") {
          return [...pages, "..."];
        }

        return pages;
      }, []);
    },
    uploadCSV() {
      // show modal
      // select a file
      // run upload
      // show preview
      // hit enter to sync
    }
  },
  computed: {
    //...mapGetters("products", ["products"])
    sortedProducts() {

      this.search;

      return this.products.filter(el => {
        if (this.search.brand && el.brand != this.search.brand) return false;
        if (this.search.category && el.category != this.search.category) return false;
        if (this.search.model && el.model != this.search.model) return false;

        return true;
      })
    },
    categories() {
      let cats = this.products.map(el => el.category);
      // make it unique
      return [...new Set(cats)];
    },
    models() {
      let models = this.products.map(el => el.model);
      return [...new Set(models)];
    },
    brands() {
      let brands = this.products.map(el => el.brand);
      return [...new Set(brands)];
    }
  }
};
</script>

<template>
  <input
    type="text"
    v-mask="'+# (###) ###-##-##'"
    placeholder="+7 (000) 000-00-00"
    v-bind:value="value"
    v-on:input="$emit('input', $event.target.value)"
  />
</template>
<script>
export default {
  name: "TelInput",
  props: ["value"]
};
</script>

<template>
  <div class="">

    <div class="flex flex-row items-center justify-between mt-6">
      <button
        type="button"
        @click="$emit('save', services)"
        :class="{ busy: busy }"
        :disabled="busy"
        class="btn-primary"
      >
        Сохранить
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["user", "busy"],
  data() {
    return {
      roles: []
    };
  },
  mounted() {
    this.loadMergedServices();
  },
  methods: {
    loadMergedServices() {

      let services = this.active.services || [];

      // unique array
      services = services.filter((val, index, self) => {
        return self.findIndex(item => item.title == val.title) > -1;
      });

      services = services.map(item => {
        let available = false;
        let earn = 0;
        if (this.user.services) {
          let userService = this.user.services.find(i => i.title == item.title);
          console.log(userService);
          if (userService) {
            available = userService.available || false;
            earn = userService.earnings || 0;
          }
        }
        return { ...item, available: available, earn: earn };
      });

      this.services = services;
    }
  },
  computed: {
    ...mapGetters("optics", ["optics", "active"])
  }
};
</script>

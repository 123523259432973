class Speech {

    constructor() {

    }

    // Function to speak text with a voice selection
    speak(text) {
        const synthesis = window.speechSynthesis;

        // Function to perform the speech
        function performSpeech() {
            synthesis.cancel();
            const utterance = new SpeechSynthesisUtterance(text);

            // Optional: Choose a voice from the list
            const voices = synthesis.getVoices();
            utterance.voice = voices.find(voice => voice.name.includes("Russian"));
            utterance.rate = 2;

            utterance.onend = (event) => {
                console.log('SpeechSynthesisUtterance.onend');
            };

            utterance.onerror = (event) => {
                console.error('SpeechSynthesisUtterance.onerror', event.error);
            };

            // Speak the text
            synthesis.speak(utterance);
        }

        // If speech synthesis is not ready, wait for the 'voiceschanged' event
        if (synthesis.onvoiceschanged !== undefined) {
            synthesis.onvoiceschanged = performSpeech;
        } else {
            performSpeech();
        }
    }



}

export default new Speech();
<template>
  <div class="">
    <div class="flex flex-row items-center mb-4 gap-x-4 text-sm">
      <button class="px-2 py-1 rounded-md" @click="category = ''" :class="{ 'text-blue-600 bg-blue-100': category == '' }">
        Все
      </button>
      <button
        class="px-2 py-1 rounded-md"
        @click="category = 'lenses'"
        :class="{ 'text-blue-600 bg-blue-100': category == 'lenses' }"
      >
        Контактные линзы
      </button>
      <button
        class="px-2 py-1 rounded-md"
        @click="category = 'glass-lenses'"
        :class="{ 'text-blue-600 bg-blue-100': category == 'glass-lenses' }"
      >
        Очковые линзы
      </button>
      <button
        class="px-2 py-1 rounded-md"
        @click="category = 'frames'"
        :class="{ 'text-blue-600 bg-blue-100': category == 'frames' }"
      >
        Оправы
      </button>
    </div>

    <div class="relative">
      <div class="absolute right-0 top-0 w-full h-full" v-if="loadBusy">
        <loading />
      </div>

      <div v-if="list.length == 0 && !loadBusy" class="bg-white px-4 py-2 rounded-md">
        В этой категории поставщиков нет.
      </div>

      <div class="w-full text-left bg-white rounded-md" v-if="list.length > 0">
        <table class="data-table">
          <thead>
            <tr class="">
              <th class=""></th>
              <th class="">Название</th>
              <th class="">Скидка поставщика</th>
            </tr>
          </thead>
          <tbody class="" :key="key">
            <tr v-for="(item, index) in sortedSuppliers" class="hover:bg-blue-50 cursor-pointer" :key="item.id">
              <td class="pl-5 pr-0">
                <input type="checkbox" @click.prevent="event => testChecked(event, item)" v-model="item.checked" />
              </td>
              <td>
                {{ item.name }}
              </td>
              <td>
                <template v-if="item.checked">
                  <input type="text" v-number="{ min: 0, max: 99, len: 2 }" v-model="item.discount" class="w-20 mr-1" />
                  <span class="font-bold">%</span>
                </template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <error v-if="error">{{ error }}</error>
    <success v-if="status == 'success'"></success>

    <button class="btn btn-primary mt-4" :disabled="busy" :class="{ busy: busy }" @click="saveSettings()">
      Сохранить
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      checkedItems: {},
      busy: false,
      loadBusy: false,
      tab: "",
      category: "",
      key: 0,
      suppliers: [],
      status: "",
      error: ""
    };
  },
  mounted() {
    this.loadSuppliers();
    this.getSettings();
  },
  methods: {
    getFilteredSuppliers() {
      return this.list.map(el => {
        return {
          id: el.id,
          store_discount: el.store_discount
        };
      });
    },
    testChecked(event, item) {
      if (item.checked) {
        if (confirm("Вы действительно хотите отключить этого поставщика?")) {
          item.checked = false;
        } else {
          item.checked = true;
        }
        this.key++;

        return;
      }

      if (!item.checked) {
        item.checked = true;
        this.key++;
      }
    },
    async getSettings() {
      let { success, msg, data } = await this.$api.get("/settings?type=suppliers");
      if (success) {
        this.suppliers = data.data;
      }
    },
    async saveSettings() {
      this.busy = true;
      this.status = "";
      this.error = "";
      let { success, msg, data } = await this.$api.post("/settings/suppliers", {
        data: this.sortedSuppliers
          .filter(el => el.checked)
          .map(el => {
            return {
              id: el.id,
              discount: parseFloat(el.discount),
              name: el.name
            };
          })
      });

      if (success) {
        this.status = "success";
      } else {
        this.error = msg;
      }

      setTimeout(() => {
        this.busy = false;
      }, 250);
    },
    async loadSuppliers() {
      // get all available suppliers in a system
      this.loadBusy = true;
      let { data } = await this.$api.get(`/g/suppliers?filter=${JSON.stringify({ category: this.category })}`);
      this.list = data.suppliers;
      setTimeout(() => {
        this.loadBusy = false;
      }, 250);
    }
  },
  computed: {
    sortedSuppliers() {
      let vm = this;

      // merge to arrays
      let items = this.list.map(el => {
        let f = vm.suppliers.find(s => s.id == el.id);
        if (f !== undefined) {
          el.discount = f.discount;
          el.checked = true;
        }

        return el;
      });

      let arr = items.filter(el => el.checked == true);
      arr = arr.sort();

      let arr2 = items.filter(el => el.checked !== true);

      return [...arr, ...arr2];
    }
  },
  watch: {
    category: function(newval, oldval) {
      this.loadSuppliers();
    }
  }
};
</script>

<template>
    <button :disabled="busy" @click="handleClick" :class="{ 'busy': busy }">
        <slot></slot>
    </button>
</template>

<script>
export default {
    props: {
        busy: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        handleClick() {
            if (!this.busy) {
                this.$emit('click');
            }
        },
    },
};
</script>
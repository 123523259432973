import RecordRepo from "../../repo/RecordRepo";

const state = {
  records: []
};

const statuses = {
  new: {
    title: "Новый",
    style: ""
  },
  confirmed: {
    title: "Подтвержден",
    style: ""
  },
  started: {
    title: "Начался",
    style: ""
  },
  canceled: {
    title: "Отмена",
    style: ""
  },
  completed: {
    title: "Завершено",
    style: ""
  },
  expired: {
    title: "Срок истек",
    style: ""
  },
  manual: {
    title: "Добавлен",
    style: ""
  }
};

const getters = {
  records: state => {
    return state.records.sort((a, b) => b.startTime - a.startTime);
  },
  getById: state => ({ id }) => {
    return state.records.find(item => item.id == id);
  },
  getStatus: state => status => {
    return statuses[status] ? statuses[status].title : "no_status";
  },
  recordStatusList: state => {
    return statuses;
  }
};

// actions
const actions = {
  wipe: ({ commit }) => {
    commit("wipe");
  },
  async pull(context, { opticId }) {
    const { data } = await RecordRepo.get({ opticId: opticId });
    context.commit("setRecords", data);
  }
};

// mutations
const mutations = {
  setRecords(state, payload) {
    state.records = payload;
  },
  wipe(state) {
    state.records = [];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

<template>
  <div class="relative">
    <div @click="opened = false" v-if="opened" class="fixed h-full w-full z-10 left-0 right-0 top-0 opacity-10"></div>
    <div class="relative z-20">
      <input
        type="text"
        class="w-full py-1.5 px-4 transition-colors  text-sm rounded-md text-gray-500 border-0 focus:text-black focus:bg-white"
        placeholder="Быстрый поиск..."
        @input="doSearch"
        v-model="term"
        :class="{ 'bg-white': opened, 'bg-gray-700': !opened }"
        @click="opened = true"
      />
      <div class="busy slow absolute right-0 top-3.5" v-if="busy"></div>
      <!-- dropdown -->
      <transition>
        <div class="bg-white px-4 py-4 absolute rounded-md shadow-lg mt-1 w-full" v-if="opened && term.length > 0">
          <div class="absolute bg-white opacity-50 w-full h-full top-0 left-0 right-0" v-if="busy"></div>
          <!-- customers -->
          <div class="flex flex-col">
            <div v-if="results.length==0 && !busy && term.length>0" class="text-sm text-gray-600">
            <i class="fas fa-search mr-2"></i> Ничего не найдено
            </div>
            <!-- result -->
            <div
              @click="pickCustomer(item)"
              v-for="item in results"
              class="row px-4 py-2 hover:bg-gray-100 rounded-md transition-all cursor-pointer"
            >
              <div class="mr-4">
                <i class="far fa-user text-black text-lg"></i>
              </div>
              <div>
                <span class="font-medium text-black text-sm">{{ item.fio }}</span>
                <div class="row text-gray-600 text-xs gap-x-2">
                  <div v-if="item.email">{{ item.email }}</div>
                  <div v-if="item.phone">{{ item.phone | phone }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import Customer from "../../repo/CustomerRepo";
import EditCustomerModal from "../customers/EditCustomerModal.vue";
export default {
  data() {
    return {
      busy: false,
      results: [],
      term: "",
      bounceTimer: null,
      opened: false
    };
  },
  methods: {
    async doSearch() {
      let vm = this;
      vm.busy = true;

      try {
        if (vm.term.length == 0) return;

        clearTimeout(vm.bounceTimer);
        vm.bounceTimer = setTimeout(async () => {
          // search by customer phone/email/name
          let { res, msg, data } = await Customer.search(this.term, "any");
          vm.results = data;
        }, 500);
      } catch (e) {
        console.log(e.message);
      } finally {
        setTimeout(() => {
          vm.busy = false;
        }, 500);
      }
    },
    pickCustomer(item) {
      this.$modals.open(EditCustomerModal, { id: item.id });
    }
  }
};
</script>

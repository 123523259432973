<template>
  <tr class="hover:bg-blue-50 cursor-pointer">
    <td>
      <div class="flex py-1 flex-row items-center gap-4" @click="$emit('view', p)">
        <img :src="p.image" class="w-8 h-8 object-cover rounded-md" v-if="p.image" />
        <div class="w-6 h-6 rounded-md bg-gray-300 overflow-hidden" v-if="!p.image">
          <img src="/img/cross.svg" class="w-full" />
        </div>
        <div class="font-semibold text-blue-500">
          {{ p.name }}
          <div class="text-xs text-gray-500">{{ p.attr }}</div>
        </div>
      </div>

      <div class="text-xs text-gray-500 mt-1" v-if="p.storeNumber">Место: {{ p.storeNumber }}</div>

    </td>
    <td class="">
      <div class="flex flex-col">
        <div class="whitespace-nowrap font-mono" v-if="p.barcode">
          <i class="fas fa-barcode"></i>
          {{ p.barcode }}
        </div>
        <div v-if="p.lots.length > 0" class="text-xs text-gray-600">Лотов: {{ p.lots.length }}</div>
      </div>
    </td>
    <td class="">{{ p.category }}</td>
    <td class="">{{ p.brand }}</td>
    <td class="text-right">
      <span class="font-mono whitespace-nowrap">{{ p.salePrice | money }}</span>
    </td>
    <td class="text-right font-mono">{{ p.quantity }}</td>
    <td class="text-right font-mono">
      <span class="qty-badge" :class="{ 'green': p.shippingQuantity > 0 }">{{ p.shippingQuantity }}</span>
    </td>
    <td class="text-right font-mono">{{ totalReserved }}</td>
  </tr>
</template>

<script>
export default {
  props: ["p", "checkedItems"],
  computed: {
    totalReserved() {
      return this.p.reserved ? this.p.reserved.reduce((a, b) => a + b.quantity, 0) : 0
    }
  }
};
</script>

<template>
  <page-short>
    <template v-slot:header>
      Настройки сети
    </template>
    <template v-slot:secondary>
      <div class="flex flex-row gap-x-1">
        <button @click="tab = 'main'" :class="{ active: tab == 'main' }" class="secondary-menu-link text-left">
          <i class="fas fa-circle "></i>
          Филиалы
        </button>
        <button @click="tab = 'services'" :class="{ active: tab == 'services' }" class="secondary-menu-link">
          <i class="fas fa-list-alt"></i>
          Услуги
        </button>
        <button @click="tab = 'prices'" :class="{ active: tab == 'prices' }" class="secondary-menu-link">
          <i class="fas fa-tags"></i>
          Цены
        </button>
        <button @click="tab = 'users'" :class="{ active: tab == 'users' }" class="secondary-menu-link">
          <i class="fas fa-users "></i>
          Сотрудники
        </button>
        <button @click="tab = 'suppliers'" :class="{ active: tab == 'suppliers' }" class="secondary-menu-link">
          <i class="fas fa-boxes"></i>
          Поставщики
        </button>
        
        <button @click="tab = 'settings'" :class="{ active: tab == 'settings' }" class="secondary-menu-link">
          <i class="fas fa-link"></i>
          Интеграции
        </button>
      </div>
    </template>
    <template v-slot:content>
      <div v-show="tab == 'main'">
        <optics />
      </div>
      <div v-show="tab == 'services'">
        <services />
      </div>
      <div v-show="tab == 'prices'">
        <prices v-if="tab == 'prices'"/>
      </div>
      <div v-show="tab == 'users'">
        <users v-if="tab == 'users'"/>
      </div>
      <div v-show="tab == 'suppliers'">
        <suppliers v-if="tab == 'suppliers'"/>
      </div>
      <div v-show="tab == 'settings'">
        <network-settings/>
      </div>
    </template>
  </page-short>
</template>

<script>
import Optics from "./Optics.vue";
import Users from "./Users.vue";
import Services from "./Services.vue";
import Prices from "./Prices.vue";
import Suppliers from "./Suppliers.vue";
import NetworkSettings from './NetworkSettings.vue';

export default {
  components: {
    Optics,
    Users,
    Services,
    Suppliers,
    NetworkSettings,
    Prices
  },
  props: [],
  data() {
    return {
      tab: "main",
      settings: {}
    };
  },
  watch: {
    tab: function(newval, oldval) {
      //this.status = "";
    },
    $route(to, from) {
      //this.checkHash();
    }
  },
  mounted() {
    // get settings

    this.checkHash();
  },
  methods: {
    getSettings() {
      let { sucess, msg, data } = this.$api.get("/settings");
      if (sucess) {
        this.settings = data.settings;
      }
    },
    checkHash() {
      if (location.hash == "#suppliers") {
        this.tab = "suppliers";
      }
      if (location.hash == "#users") {
        this.tab = "users";
      }
      if (location.hash == "#services") {
        this.tab = "services";
      }
      if (location.hash == "#supliers") {
        this.tab = "supliers";
      }
      if (location.hash == "#prices") {
        this.tab = "prices";
      }
    }
  },
  computed: {}
};
</script>

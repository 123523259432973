<template>
  <div
    class="mb-1 xl:flex xl:flex-row bg-gray-100 border border-gray-200 items-center rounded py-1 px-1 xl:px-2"
  >
    <!-- <img
      :src="user.picture || '/img/face.jpg'"
      class="w-3 h-3  hidden xl:inline-block xl:w-5 xl:h-5 rounded-full ring-1 ring-white object-contain"
    /> -->
    <div class="xl:ml-2 w-full flex-grow-0">
      <span class="whitespace-nowrap text-xs font-semibold block w-full overflow-hidden overflow-ellipsis">
        {{ user.name }}
      </span>
      <span class="block text-xs">
        {{ item.timeStart }} - {{ item.timeStop }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["item"],
  computed: {
    user() {
      let user = this.getUserById({id: this.item.userId});
      if(user) {
        return user;
      } else {
        return {}
      }
    },
    ...mapGetters({ users: "users/users", getUserById: "users/getById" }),
  },
};
</script>
<template>
  <div>
    <div
      class="grid  text-xs font-semibold pb-1 text-gray-600 text-center px-1"
      :class="'grid-cols-' + len"
    >
      <div></div>
      <template v-for="(item, index) in titles">
        <div v-if="item != ''" :key="index">{{ $t(item) }}</div>
      </template>
    </div>

    <div
      class="grid gap-x-2  border-t px-1 py-1 text-xs lg:text-sm"
      :class="'grid-cols-' + len"
      v-for="(item, index) in obj"
      :key="index"
    >
      <template v-if="index != 'isFieldVisible'">
        <div class="uppercase">in:{{ index }}</div>
        <template v-for="(k, index) in item">
          <div v-if="k != ''" class=" text-center" :key="index">{{ k | withzero }}</div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: ["obj"],
  computed: {
    len() {
      let vm = this;
      let len = Object.keys(this.obj.od).reduce((acc, item) => {
        (vm.obj.od[item] && vm.obj.od[item] != "") || (vm.obj.os[item] && vm.obj.os[item] != "")
          ? acc++
          : "";
        return acc;
      }, 0);

      return len + 1;
    },
    titles() {
      let vm = this;
      return Object.keys(this.obj.od).reduce((acc, item) => {
        console.log(item);
        if (
          (vm.obj.od[item] && vm.obj.od[item] != "") ||
          (vm.obj.os[item] && vm.obj.os[item] != "")
        ) {
          acc.push(item);
        }
        return acc;
      }, []);
    }
  }
};
</script>

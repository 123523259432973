<template>
  <div class="">
    <div class="min-w-screen min-h-screen flex items-center justify-center">
      <sign-up-screen />
    </div>
  </div>
</template>

<script>
import CreateCompany from "./auth/CreateCompany.vue";
import SignUpScreen from "./auth/SignUpScreen.vue";
import Login from "./auth/Login.vue";
import Hero from "./Hero.vue";
export default {
  components: { CreateCompany, Login, Hero, SignUpScreen },
  name: "homepage",
  data() {
    return {
      authScreen: "register"
    };
  },
  mounted() {
    // if user logged, redirect to dashboard
    if (this.$logged) {
      this.$router.push("/dashboard");
    }
  },
  methods: {
    login() {}
  }
};
</script>

<template>
  <modal :styles="''">
    <div v-if="sale.id">
      <h3 class="text-black font-bold text-xl">Заказ #{{ sale.saleNumber }}</h3>
      <div class="mb-2 mt-4  flex flex-row space-x-4 items-center">
        <sale-status :status="sale.status" />
        <div class="text-sm">
          <i class="far fa-calendar mr-1"></i>
          {{ sale.created_at | datetime }}
        </div>
        <div :key="keyIndex">
          <customer :data="sale.customer" v-if="sale.customer && sale.customer.id" />
          <span v-if="!sale.client_id" class="text-sm text-gray-500">Без клиента</span>
        </div>
        <div :key="`user-${keyIndex}`">
          <user-card :id="sale.user_id" />
        </div>
      </div>

      <div v-if="sale.expressOrderNumber" class="text-sm rounded-md text-center py-3 bg-yellow-100 mb-2">
        <i class="fas fa-truck-fast"></i> Экспресс заказ <span class="font-bold">№{{ sale.expressOrderNumber }}</span>
      </div>


      <div class="border rounded-md border-gray-200">
        <table class="data-table">
          <thead>
            <tr class=" ">
              <th class="">Название</th>
              <th>Количество</th>
              <th>Подытог</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in sale.cart">
              <td>
                <div class="text-black">{{ item.name }}</div>
                <div class="text-xs text-gray-500">{{ item.attr }}</div>
                <div v-if="item.lots && item.lots.length > 0">
                  <span class="text-xs text-gray-500 border rounded-md px-1 py-0.5 font-semibold font-mono"
                    v-for="lot in item.lots">
                    {{ lot.lot }}
                  </span>
                </div>
                <div class="text-xs text-gray-500">{{ item.barcode }}</div>
              </td>
              <td>
                <span>
                  {{ item.qty }}
                </span>
                <div class="text-xs text-gray-500">{{ item.price | money }} /шт</div>
              </td>
              <td>
                <span>{{ item.subtotal | money }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="flex flex-row items-center justify-between mt-2 text-sm text-green-500" v-if="sale.discount">
        <div class="font-semibold">
          Скидка
          <span>({{ sale.discount.includes("%") ? sale.discount : sale.discount + " руб." }})</span>
        </div>
        <div class="text-right font-semibold">- {{ sale.absDiscount | money }} руб.</div>
      </div>

      <div class="flex flex-row items-center justify-between mt-2">
        <div class="font-bold">Итого</div>
        <div class="text-right font-bold">{{ sale.total | money }} руб.</div>
      </div>

      <div class="text-sm mt-2">
        Способ оплаты:
        <span class="font-bold">{{ sale.paymentMethod.title }}</span>
      </div>


      <!-- actions -->
      <div class="flex flex-row space-x-4 w-full mt-8">
        <!-- Pay it -->
        <button class="btn-action-xs" @click="payOrder(sale.id)" v-if="sale.status == 'new'">
          Оплатить
        </button>

        <button class="btn-danger-sm ml-auto" @click="cancelOrder(sale.id)" v-if="sale.status != 'cancelled'">
          Отменить заказ
        </button>
      </div>
    </div>
    <loading v-if="busy" />
  </modal>
</template>

<script>
import Modal from "../modals/Modal.vue";
import ModalMixin from "../modals/modal.mixin";
import api from "../../api/api.js";

export default {
  components: { Modal },
  mixins: [ModalMixin],
  props: ["id"],
  data() {
    return {
      loading: false,
      busy: false,
      status: "",
      sale: {},
      keyIndex: 0
    };
  },
  mounted() {
    this.getItem(this.id);
  },
  methods: {
    async getItem() {
      this.busy = true;
      let { success, msg, data } = await api.get(`/sales/${this.id}`);
      if (success) {
        this.sale = data;
        this.loadCustomer();
      }
      this.busy = false;
    },
    async loadCustomer() {
      if (this.sale.client_id) {
        let { success, msg, data } = await api.get(`/customers?ids=${this.sale.client_id}`);
        if (success) {
          this.sale.customer = data.customers[0];
          this.keyIndex++;
        }
      }
    },
    async cancelOrder(id) {
      // confirm cancel order
      if (confirm("Вы действительно хотите отменить заказ?")) {
        let { success, msg, data } = await api.post(`/sales/${id}/cancel`);
        if (success) {
          this.getItem();
        }
      }
    },
    async payOrder(id) {
      if (confirm("Вы действительно хотите оплатить заказ?")) {
        let { success, msg, data } = await api.post(`/sales/${id}/pay`);
        if (success) {
          this.getItem();
        }
      }
    },
    getDiscountPrice() {
      let subTotalPrice = this.sale.cart.map(el => el.subtotal_price);
      return subTotalPrice * (this.sale.discount / 100);
    }
  }
};
</script>

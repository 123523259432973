<template>
  <div>
    <!-- One item -->
    <label class="font-semibold mb-4 block cursor-pointer">
      <input type="checkbox" @change="setDefaults()" class="mr-2" v-model="record.eysChecked" />
      Осмотр проведен
    </label>

    <div v-if="record.eysChecked">
      <div v-for="objKey in ['od', 'os']" class="mb-8">
        <div class="mb-4 text-sm font-semibold uppercase">{{ objKey }}</div>
        <div class="grid grid-cols-1 mt-2 gap-y-2" v-if="record.eyes">
          <!-- Inner item -->
          <div v-for="field in fields">
            <div class="flex flex-row items-center">
              <sm-toggle
                v-on:updated="toggleField(objKey, field, $event)"
                :default="record.fieldsVisible[`${objKey}-${field}`]"
              />
              <label class="text-sm ml-2">
                {{ $t(field) }}
              </label>
            </div>
            <div :key="`${objKey}-${field}`" v-if="record.fieldsVisible[`${objKey}-${field}`]">
              <tags :obj="record.eyes[objKey][field]" :field="field" :hide-title="true" />
            </div>
          </div>
        </div>

        <div class="mt-4 mb-2 text-sm font-semibold">Комментарий</div>
        <div v-if="record.eyes">
          <textarea v-model="record.eyes[objKey].comment" class="w-full" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["record"],
  data() {
    return {
      fields: ["eyelids", "сonjunctiva", "lacrimal", "sclera", "cornea", "iris", "lens"],
      compKey: 0,
      options: {},
      defaults: {
        eyelids: [
          "кожа норма",
          "край прилежит",
          "рост ресниц правильный",
          "функция мейбомиевых желёз не изменена"
        ],
        сonjunctiva: ["бледно-розовая", "слизистая верхненго века чистая", "не изменена"],
        lacrimal: [
          "пальпация слёзной железы и слёзного мешка безболезненная",
          "слёзные точки прилегают"
        ],
        sclera: ["белая", "без очаговых изменений"],
        cornea: ["прозрачная", "гладкая", "блестит"],
        iris: ["рельефная", "зрачковый край не изменён", "зрачок круглый", "реакция на свет живая"],
        lens: ["прозрачный"]
      }
    };
  },
  methods: {
    toggleItem(obj, item) {
      let index = obj.indexOf(item);
      if (index != -1) {
        // remove it from the list
        obj.splice(index, 1);
      } else {
        obj.push(item);
      }
      console.log(obj);
    },
    toggleField(key, field, state) {
      this.record.fieldsVisible[`${key}-${field}`] = state;
      this.compKey++;
    },
    setDefaults() {
      //set defaults on click
      let keys = Object.keys(this.defaults).reduce((acc, item) => {
        acc[`od-${item}`] = true;
        acc[`os-${item}`] = true;
        return acc;
      }, {});
      this.record.fieldsVisible = { ...keys };
      // add defaults
      for (let field in this.defaults) {
        this.record.eyes.od[field] = [...this.defaults[field]];
        this.record.eyes.os[field] = [...this.defaults[field]];
      }
      this.compKey++;
    }
  }
};
</script>

<template>
  <div class="">
    <div class="">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "BlankLayout",
  computed: {}
};
</script>

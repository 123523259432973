<template>
  <modal :styles="''">
    <div class="flex flex-row items-center justify-between mb-6">
      <h3 class="text-black font-bold text-xl items-center flex flex-row">
        <span v-if="!record.id">Новая запись</span>
        <span v-else>
          Запись на
          {{
            $dayjs
              .unix(record.startTime)
              .utc()
              .format("D MMMM HH:mm")
          }}
        </span>
      </h3>

      <div>
        <button class="edit-btn ml-4" v-if="mode == 'read'" @click="mode = 'edit'">Изменить</button>
        <button class="edit-btn ml-4" v-if="mode != 'read' && record.id" @click="mode = 'read'">
          Просмотреть
        </button>
      </div>
    </div>

    <form v-if="mode != 'read'" :class="{ 'hide-labels': !record.id }">
      <div>
        <div class="lg:col-span-2 mb-4">
          <label>Статус</label>
          <!-- <select v-model="record.status" class="w-full">
              <option v-for="(st, key) of recordStatusList" :value="key">{{ st.title }}</option>
            </select> -->
          <div class="gap-2 flex flex-row flex-wrap">
            <span @click="record.status = key" class="badge cursor-pointer"
              :class="record.status == key ? key : 'bg-gray-100 text-gray-300 hover:text-black hover:bg-gray-200'"
              v-for="(st, key) of statusList" :key="key">
              {{ st.title }}
            </span>
          </div>
        </div>

        <template v-if="!record.client_id">
          <div class="grid lg:grid-cols-2 mb-4 gap-x-2 gap-y-2">
            <div class="" v-if="!record.id">
              <label class="" for="cus_name">Телефон</label>
              <auto-suggest styles="w-full" placeholder="9110002200" v-model="record.client.phone"
                :component="'Customer'" :field="'phone'" :focus="true" v-on:select="customerFound">
                <template v-slot:default="slotProps">
                  <div>
                    <span class="text-sm font-normal">
                      <i v-if="slotProps.item.fromAcuvue" class="text-blue-500 font-bold text-xs">
                        @acuvue
                      </i>
                      {{ slotProps.item.lastname }} {{ slotProps.item.firstname }}
                    </span>
                    <div class="text-xs">{{ slotProps.item.phone }} {{ slotProps.item.email }}</div>
                  </div>
                </template>
              </auto-suggest>
            </div>

            <div class="" v-if="!record.id">
              <label>
                Электронная почта
              </label>
              <input class="w-full" type="text" required="" v-model="record.client.email"
                placeholder="email@example.com" />
            </div>
          </div>
          <div class="flex flex-col lg:flex-row lg:space-x-2 relative mb-4 space-y-2 lg:space-y-0" v-if="!record.id">
            <div class="">
              <label class="" for="cus_name">Фамилия</label>

              <auto-suggest styles="w-full" placeholder="Фамилия" v-model="record.client.lastname"
                :component="'Customer'" v-on:select="customerFound" v-letters :readonly="record.client_id != ''">
                <template v-slot:default="slotProps">
                  <div class="w-full overflow-hidden">
                    <span class="text-sm font-semibold">{{ slotProps.item.lastname }} {{ slotProps.item.firstname
                      }}</span>
                    <div class="text-xs flex flex-col gap-y-2 text-gray-500">
                      <div>{{ slotProps.item.phone }}</div>
                      <div class="overflow-hidden overflow-ellipsis">
                        {{ slotProps.item.email }}
                      </div>
                    </div>
                  </div>
                </template>
              </auto-suggest>
            </div>
            <div>
              <label class="" for="cus_name">Имя</label>
              <input type="text" v-model="record.client.firstname" class="w-full" placeholder="Имя" v-letters
                :readonly="record.client_id != ''" />
            </div>
            <div>
              <label class="" for="cus_name">Отчество</label>
              <input type="text" v-model="record.client.thirdname" class="w-full" placeholder="Отчество" v-letters
                :readonly="record.client_id != ''" />
            </div>
          </div>
        </template>
        <!-- last records -->
        <div v-if="record.client_id"
          class="flex flex-row gap-x-4 py-3 px-2 items-center bg-green-500 text-white  rounded-md mb-1 text-sm relative">
          <div class="font-semibold text-xs">
            Клиент:
          </div>
          <button class="font-semibold" @click="viewCustomer(record.client_id)" v-if="record.client_id">
            <span v-if="record.client.gender == 'male'">👨</span>
            <span v-if="record.client.gender == 'female'">👩</span>
            {{ record.client.lastname }} {{ record.client.firstname }}
          </button>
          <div>
            {{ record.client.phone }}
          </div>
          <div class="absolute right-2 top-2" v-if="!record.id">
            <button type="button" @click="resetClient()" class="bg-white text-black rounded-md px-2 py-1 font-medium">
              Сбросить клиента
            </button>
          </div>
        </div>

        <div v-if="record.client_id && lastRecords.length > 0" class="mb-4" :key="vKey">
          <div class="text-sm text-gray-600 mt-3 mb-2 px-1 font-medium">Последние посещения</div>
          <div v-for="rec in lastRecords.slice(0, 3)">
            <record-row :record="rec" />
          </div>
        </div>

        <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <div class="md:col-span-2">
            <div class="">
              <label>
                Заметка
              </label>
              <textarea class="w-full" type="text" required="" v-model="record.note" placeholder="Заметка" />
            </div>
          </div>

          <div class="relative">
            <div class="relative">
              <label class="" for="cus_name">Дата</label>
              <div
                class="border rounded-md border-gray-400 px-4 text-center py-3 text-sm relative flex flex-row items-center">
                <button @click.prevent="prevDate()" class="font-extrabold text-blue-500 absolute left-4">
                  &lt;
                </button>
                <!-- <span class="font-semibold">{{ record.startTime | humandate }}</span> -->
                <div>
                  <!-- <datepicker v-model="datepicker" format="D MMMM, dddd, YYYY"></datepicker> -->
                  <!-- <vc-date-picker v-model="datepicker" :popover="{ visibility: 'click' }"/> -->
                  <vc-date-picker v-model="datepicker" @dayclick="selectDate" :locale="'ru'"
                    :masks="{ input: 'D MMMM, YYYY' }" :attributes="attrs">
                    <template v-slot="{ inputValue, inputEvents }">
                      <input class="font-semibold border-none py-0 text-center" :value="inputValue" v-on="inputEvents"
                        readonly />
                      <span class="text-xs font-medium">
                        {{ $dayjs.unix(record.startTime).calendar(null, calendarFormatting) }}
                      </span>
                    </template>
                  </vc-date-picker>
                </div>
                <button @click.prevent="nextDate()" class="font-extrabold text-blue-500 absolute right-4">
                  &gt;
                </button>
              </div>
              <div v-if="record.noTimeRecord" class="absolute inset-0 bg-white w-full h-full z-10 opacity-70"></div>
            </div>
            <!-- Make a record for now -->
            <div class="flex flex-row items-center mt-2">
              <sm-toggle v-on:updated="record.noTimeRecord = $event" :default="record.noTimeRecord" />
              <div class="font-semibold text-sm">Принять сейчас</div>
            </div>
          </div>
          <div class="">
            <label class="">Услуга</label>
            <select class="
                    w-full
                  " v-model="record.service_id" :class="{ error: errors['service'] }"
              @change="resetError('service'), updateTimeSlots()">
              <option value="-1">Выберите услугу</option>
              <option :value="service.id" v-for="(service, index) in services" :key="service.id">
                {{ service.title }} (🕞 {{ service.time }} мин.)
              </option>
            </select>
          </div>
        </div>

        <div class="mt-4" v-if="availableSlots.length > 0 && availableHosts.length > 0">
          <label class="">Сотрудник</label>
          <select v-model="record.host_id" class="w-full">
            <option v-for="host in availableHosts" :value="host.id" :key="host.id">
              {{ host.name }}
            </option>
          </select>
        </div>

        <template v-if="!record.noTimeRecord">
          <div class=""
            v-if="filteredTimeSlots.length == 0 && !record.id && !errors['service'] && record.serviceId > -1 && !loading">
            <template v-if="isSomeoneProvideSelectedService">
              Нет доступного времени для записи, выберите другой день.
            </template>
            <template></template>

            <alert v-if="!isSomeoneProvideSelectedService">
              Никто не оказывает такую услугу в этот день. Проверьте настройки сотрудников.
            </alert>
          </div>

          <div class="mt-4 text-sm text-green-500 font-semibold" v-if="availableSlots.length > 0">
            Выберите время начала записи
          </div>
          <div class="text-sm mt-2 text-white bg-yellow-500 px-3 py-2 border rounded-md"
            v-if="isSelectedHostServiceTimeDifferent">
            Обратите внимание, время выполнения услуги для этого исполнителя отличается от времени установленного в
            настройках филиала
          </div>

          <div class="grid grid-cols-3 md:grid-cols-5 lg:grid-cols-5 xl:grid-cols-8 gap-3 mt-4">
            <div v-for="(slot, index) in filteredTimeSlots" :key="index" class="timeslots">
              <div v-if="slot.status == 0 || (record.startTime == slot.start && record.id)" @click="selectedSlot = slot"
                class="cursor-pointer hover:border-green-600 border-green-300" :class="{
                  'bg-green-500 border-green-600 text-white': selectedSlot == slot
                }">
                {{
                  $dayjs
                    .unix(slot.start)
                    .utc()
                    .format("HH:mm")
                }}
              </div>
            </div>
          </div>
        </template>

        <!-- time slot -->
        <alert v-if="result.status == 'error'">
          {{ $t(result.msg) }}
        </alert>

        <div class="flex flex-row items-center justify-between mt-10">
          <button type="button" :disabled="busy || !isSaveAvailable" @click="save()" v-busy="busy" class="btn-primary">
            Сохранить
          </button>

          <button type="button" @click="remove()" v-if="record.id" class="
            py-1
            px-4
            bg-red-100
            text-red-500
            rounded-md
            font-medium
            text-xs
            opacity-50
            hover:opacity-100
          ">
            Удалить
          </button>
        </div>
      </div>
    </form>

    <template v-if="mode == 'read' && record.id">
      <read-record :record="record" :services="services" v-on:status="updateStatus" />

      <a :href="`/records/${record.id}?step=1`" target="_blank"
        class="text-xs font-semibold ml-2 absolute bottom-3 right-3 opacity-50 hover:opacity-100">
        <i class="fas fa-external-link-alt"></i>
      </a>
    </template>
  </modal>
</template>

<script>
import ModalMixin from "../modals/modal.mixin";
import Record from "../../repo/RecordRepo";
import Customer from "../../repo/CustomerRepo";
import Loading from "../Loading";
import { mapGetters } from "vuex";
import OpticRepo from "../../repo/OpticRepo";
import Autocomplete from "../shared/Autocomplete.vue";
import Result from "../shared/Result.vue";
import AutoSuggest from "../shared/AutoSuggest.vue";
import ReadRecord from "./ReadRecord.vue";
import CustomerModal from "../customers/EditCustomerModal.vue";
import RecordRow from "../records/RecordRow";
import Datepicker from "vuejs-datepicker";

export default {
  name: "edit-record",
  props: ["id", "date", "hosts"],
  components: { Autocomplete, Result, AutoSuggest, ReadRecord, RecordRow, Datepicker },
  mixins: [ModalMixin],
  data() {
    return {
      datepicker: "",
      mode: "read",
      result: { status: "", msg: "" },
      busyCustomer: false,
      showCustomerHelper: false,
      availableCustomers: [],
      calendarFormatting: {
        sameDay: "[Сегодня]", // The same day ( Today at 2:30 AM )
        nextDay: "[Завтра]", // The next day ( Tomorrow at 2:30 AM )
        nextWeek: "dddd", // The next week ( Sunday at 2:30 AM )
        lastDay: "[Вчера]", // The day before ( Yesterday at 2:30 AM )
        lastWeek: "dddd", // Last week ( Last Monday at 2:30 AM )
        sameElse: "dddd"
      },
      record: {
        noTimeRecord: false,
        date: "",
        client: {},
        id: "",
        status: "new",
        service_id: -1,
        serviceTitle: "",
        startTime: "",
        endTime: "",
        opticId: "",
        host: { id: "", name: "" },
        client_id: "",
        host_id: ""
      },
      loading: false,
      busy: false,
      errors: {
        service: false
      },
      availableSlots: [],
      selectedSlot: "",
      availableHosts: [],
      lastRecords: [],
      vKey: 0,
      attrs: [
        {
          key: "today",
          dates: new Date(),
          dot: true
        }
      ],
      services: []
    };
  },
  mounted() {
    if (this.id) {
      this.get();
      // disable for edit inputs
      let inputs = document.getElementsByName("input");
    } else {
      this.getServices();

      this.record.startTime = this.$dayjs(this.date).unix();
      this.record.endTime = this.$dayjs(this.date).unix();
      this.mode = "edit";
      //this.datepicker = this.$dayjs(this.date);
      this.datepicker = new Date(this.record.startTime * 1000);
    }

    // update record on cusotmer updated event
    this.$events.$on("customer-updated", () => {
      this.get();
      this.$events.$emit("record-reload");
    });
  },
  methods: {
    selectDate(date) {
      //console.log(date);
      this.record.startTime = this.$dayjs(date.id).unix();
      this.updateTimeSlots();
    },
    resetClient() {
      this.record.client_id = "";
    },
    viewCustomer() {
      this.$modals.open(CustomerModal, { id: this.record.client_id });
    },
    updateStatus(status) {
      if (status == "canceled" && !confirm("Отменить запись?")) {
        return;
      }

      this.record.status = status;
      this.save();
    },
    prevDate() {
      //this.record.startTime = record.startTime
      this.record.startTime = this.$dayjs
        .unix(this.record.startTime)
        .utc()
        .subtract(1, "day")
        .unix();
      this.updateTimeSlots();
      this.datepicker = new Date(this.record.startTime * 1000);
    },
    nextDate() {
      this.record.startTime = this.$dayjs
        .unix(this.record.startTime)
        .utc()
        .add(1, "day")
        .unix();
      // update time slots
      this.updateTimeSlots();
      this.datepicker = new Date(this.record.startTime * 1000);
    },
    pullHosts(date) {
      let d = this.$dayjs(date).unix();
    },
    async customerFound(customer) {
      this.record.client = {
        firstname: customer.firstname,
        lastname: customer.lastname,
        thirdname: customer.thirdname,
        phone: customer.phone,
        email: customer.email,
        gender: customer.gender
      };

      this.record.client_id = customer.id;

      // last records
      let { success, data, msg } = await Record.getByClientId(this.record.client_id);
      if (success) {
        this.lastRecords = data;
        this.vKey++;
      }
    },
    async save() {
      this.busy = true;

      this.record.opticId = this.active.id;

      if (!this.record.noTimeRecord && this.selectedSlot) {
        this.record.startTime = this.selectedSlot.start;
        this.record.endTime = this.selectedSlot.endSlotServiceTime;
      } else {
        // user current date from datefield
        this.record.startTime = this.$dayjs()
          .utc(new Date())
          .unix();
        this.record.endTime = this.$dayjs()
          .utc(new Date())
          .unix();
        this.record.date = this.$dayjs().format("YYYY-MM-DD");
      }

      // set client name, first uppercase and the rest lowercase
      if (this.record.client) {
        this.record.client.lastname = castClientName(this.record.client.lastname);
        this.record.client.firstname = castClientName(this.record.client.firstname);
        this.record.client.thirdname = castClientName(this.record.client.thirdname);
      }

      this.record.date = this.$dayjs
        .unix(this.record.startTime)
        .utc()
        .format("YYYY-MM-DD");

      if (!this.record.id) {
        //this.record.date = this.$dayjs(this.date).format("YYYY-MM-DD");
      }

      this.record.serviceTitle = this.service ? this.service.title : '';

      let { success, msg, data } = await Record.update(this.record);

      this.busy = false;

      if (success) {
        this.record = data;
        this.result.status = "success";
        // update timeslots
        //await this.updateTimeSlots();
        // reload records in repo
        setTimeout(() => {
          this.$events.$emit("record-reload");
          this.mode = "read";
        }, 1000);

        // close if it was cancel
        if (this.record.status == "canceled") {
          this.$modals.close();
        }
      } else {
        this.result.status = "error";
        this.result.msg = msg;
      }
    },
    async get() {
      // load all services
      await this.getServices();

      this.loading = true;
      const { data, msg, success } = await Record.getByid(this.id);
      if (success) {
        this.record = data;

        this.selectedDate = new Date(this.record.date);
        this.minDate = new Date(this.record.date);
        this.datepicker = new Date(this.record.date);

        // legacy support when we had service index instead of service id
        if (!this.record.service_id) {
          if (this.services.length > 0) {
            this.record.service_id = this.services[this.record.serviceIndex].id;
          }
        }

        await this.updateTimeSlots();
        // select selected timeslot
        let slot = this.availableSlots.find(slot => slot.start == this.record.startTime);

        this.selectedSlot = slot;
      }
      this.loading = false;
    },
    async remove() {
      if (!confirm("Удалить эту запись?")) {
        return;
      }
      await Record.delete(this.record.id);

      // reload
      this.$events.$emit("record-reload");

      // close
      this.close();

      // reset
      this.record.id = "";
    },

    //  get availabble time slots
    async updateTimeSlots() {
      this.loading = true;
      this.resetError("service");

      if (this.record.service_id == -1) {
        this.loading = false;
        this.setError("service", true);
        return;
      }

      let slots = await OpticRepo.getTimeSlots(
        this.record.opticId || this.$dept.id,
        this.$dayjs.unix(this.record.startTime).format("YYYY-MM-DD"),
        this.record.service_id
      );

      console.log(slots);

      this.loading = false;
      this.availableSlots = slots.data;

      // filter slots by host
      this.availableHosts = slots.data.reduce((acc, item, index) => {
        if (acc.findIndex(host => host.id == item.host.id) == -1) {
          acc.push(item.host);
        }
        return acc;
      }, []);

      // set default user
      if (!this.record.host_id) {
        this.record.host_id = this.availableHosts.length > 0 ? this.availableHosts[0].id : "";
      }
    },
    resetError(slug) {
      this.errors[slug] = false;
    },
    setError(slug, val) {
      this.errors[slug] = val;
    },
    /**
     * Retrieves the services associated with the current optic.
     *
     * @return {Array} An array of services.
     */
    async getServices() {
      let { success, msg, data } = await this.$api.get(`/optics/${this.$dept.id}`);

      if (success) {
        this.services = data.services.filter(el => el.available == true);
      }

      return [];
    }
  },
  computed: {
    /**
     * Checks if the selected host service time is different from the optic service time.
     * if it is, show alert that this host service time is different
     */
    isSelectedHostServiceTimeDifferent() {
      if (this.selectedHost) {
        let hostService = this.selectedHost.services[this.record.serviceId];
        let opticService = this.services[this.record.serviceId];
        if (opticService && hostService) {
          return opticService.time != hostService.time;
        }
      }

      return false;
    },
    selectedHost() {
      if (this.hosts == undefined) return;
      return this.hosts.find(host => host.id == this.record.host_id);
    },
    // check that hosts at this day provide selected service
    isSomeoneProvideSelectedService() {
      let vm = this;

      if (this.hosts == undefined) return false;

      let hosts = this.hosts.filter(
        host => host.services.filter(serv => serv.title == vm.record.serviceTitle && serv.available == true).length > 0
      );

      return hosts.length > 0;
    },
    sortedCustomers() {
      return this.availableCustomers;
    },
    ...mapGetters("optics", ["optics", "active", "getOpticService", "getById"]),
    ...mapGetters("records", ["getStatus", "recordStatusList"]),
    ...mapGetters("customers", ["customers"]),

    service() {
      let service;

      if (!this.record.service_id) {
        service = this.services[this.record.serviceId];
      } else {
        console.log(this.record.service_id);
        console.log(this.services);
        service = this.services.find(item => item.id == this.record.service_id);
      }

      return service;
    },
    isSaveAvailable() {
      let isAvailable =
        (this.record.client.firstname && this.record.client.lastname && this.record.client.phone) || this.record.client_id;

      // regular record
      if (!this.record.noTimeRecord) {
        return (
          isAvailable &&
          this.selectedSlot &&
          this.selectedSlot.start &&
          this.availableSlots.length > 0 &&
          this.record.serviceId != -1
        );
      }

      // if not time record, service should be selected as well
      if (this.record.noTimeRecord) {
        return isAvailable && this.record.serviceId != -1;
      }

      return false;
    },
    filteredTimeSlots() {
      // filter time slots by host
      return this.availableSlots.filter(slot => {
        return slot.host.id == this.record.host_id;
      });
    },
    statusList() {
      let items = {};
      let states;
      switch (this.record.status) {
        case "new":
          states = ["new", "confirmed", "started"];
          break;
        case "started":
          states = ["confirmed", "started", "canceled", "completed"];
          break;
        case "confirmed":
          states = ["confirmed", "started", "canceled", "completed"];
          break;
        case "canceled":
          states = ["confirmed", "started", "canceled", "completed"];
          break;
        case "completed":
          states = ["confirmed", "started", "canceled", "completed"];
          break;
      }

      if (!this.record.id) {
        states = ["new"];
      }

      // filter avialable states
      for (let key in this.recordStatusList) {
        if (states.indexOf(key) !== -1) {
          items[key] = this.recordStatusList[key];
        }
      }

      return items;
    }
  }
};


function castClientName(str) {
  if(!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}
</script>

<template>
  <modal :styles="'max-w-2xl'">
    <div v-if="details.os">
      <div class="relative mb-4">
        <div class="font-semibold text-sm mb-2">{{ title }}</div>
        <div class="grid grid-cols-3 text-center mt-2 mb-2 font-semibold text-sm">
          <div></div>
          <div>OD</div>
          <div>OS</div>
        </div>
        <div class="grid grid-cols-3 text-sm border-b border-gray-100 py-2 gap-x-2" v-for="key in fields" :key="key">
          <div class="text-xs">{{ $t(key) }}</div>

          <div class="text-center" :ref="`od-${key}`" :key="`od-${compKey}`">
            <autocomplete v-model="details.od[key]" :type="key" :field="obj.type != 'mkl' ? 'glasses' : ''" />
          </div>

          <div class="text-center" :ref="`os-${key}`" :key="`os-${compKey}`">
            <autocomplete v-model="details.os[key]" :type="key" :field="obj.type != 'mkl' ? 'glasses' : ''" />
          </div>
        </div>
      </div>

      <div class="flex items-center flex-row justify-between">
        <button @click="save()" class="btn-primary">Добавить</button>
        <button @click="remove()" class="btn-delete" v-if="item">Удалить</button>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "../../modals/Modal.vue";
import ModalMixin from "../../modals/modal.mixin";
import api from "../../../api/api.js";

export default {
  props: ["title", "obj", "item"],
  components: { Modal },
  mixins: [ModalMixin],
  data() {
    return {
      busy: false,
      details: {},
      fields: "",
      compKey: 0
    };
  },
  watch: {
    "details.os": {
      deep: true,
      handler() {
        this.checkFields();
      }
    },
    "details.od": {
      deep: true,
      handler() {
        this.checkFields();
      }
    }
  },
  mounted() {
    switch (this.obj.type) {
      case "distance":
        this.fields = ["sph", "cyl", "axis", "visus", "dpp", "prism1", "base1", "prism2", "base2"];
        break;
      case "near":
        this.fields = ["sph", "cyl", "axis", "visus", "dpp"];
        break;
      case "multifocal":
        this.fields = ["sph", "cyl", "axis", "add", "visus_near"];
        break;
      case "progressive":
        this.fields = ["sph", "cyl", "axis", "visus", "dpp", "add"];
        break;
      case "mkl":
        this.fields = ["sph", "cyl", "axis", "add", "visus", "bc", "dia"];
        break;
    }

    if (this.item) {
      this.details = Object.create(this.item);
    } else {
      this.details = {
        os: { ...this.obj.os },
        od: { ...this.obj.od }
      };
    }

    setTimeout(() => {
      this.checkFields();
    }, 500);
  },
  methods: {
    save() {
      this.close(this.details);
    },
    remove() {
      this.close("delete");
    },
    checkFields() {
      let vm = this;
      if (Object.entries(vm.$refs) == 0) {
        console.log("no refs");
        return;
      }

      console.log("check fields");
      // if prism it not empty, then base should be filled also

      for (let odos of ["os", "od"]) {
        let item = vm.details[odos];

        /**
         * Clear errors for all fields
         */
        for (let f of this.fields) {
          if (vm.$refs[`${odos}-${f}`] != undefined) {
            vm.$refs[`${odos}-${f}`][0].classList.remove("input-error");
          }
        }

        // check prism and base
        for (let ind of [1, 2]) {
          if (this.isFilled(`base${ind}`, item)) {
            if (this.isNotFilled(`prism${ind}`, item)) {
              vm.$refs[`${odos}-prism${ind}`][0].classList.add("input-error");
            }
          }

          if (this.isFilled(`prism${ind}`, item)) {
            if (this.isNotFilled(`base${ind}`, item)) {
              vm.$refs[`${odos}-base${ind}`][0].classList.add("input-error");
            }
          }
        }

        // ensure that prism 1 is filled if prism 2 filled
        if (this.isFilled("prism2", item) && this.isNotFilled("prism1", item)) {
          vm.$refs[`${odos}-prism1`][0].classList.add("input-error");
        }

        // sph and dpp always filled
        if (this.isNotFilled("sph", item)) {
          vm.$refs[`${odos}-sph`][0].classList.add("input-error");
        }

        if (this.isFilled("sph", item) && this.isFilled("cyl", item)) {
          if (this.isNotFilled("axis", item)) {
            vm.$refs[`${odos}-axis`][0].classList.add("input-error");
          }
        }

        if (this.isNotFilled("dpp", item)) {
          vm.$refs[`${odos}-dpp`][0].classList.add("input-error");
        }
      }

      return true;
    },
    isFilled(field, item) {
      if (!this.isExists(field)) {
        return false;
      }
      return item[field] != "" && item[field] != "-" && item[field] != undefined;
    },
    /**
     * Check if the specified field in the given item is not filled and exists
     *
     * @param {string} field - The field to check.
     * @param {object} item - The item to check.
     * @return {boolean} Returns true if the field is not filled, false otherwise.
     */
    isNotFilled(field, item) {
      // if such field not exists
      if (!this.isExists(field)) {
        return false;
      }

      return item[field] == "" || item[field] == "-" || item[field] == undefined;
    },
    isExists(field) {
      return this.fields.includes(field);
    }
  },
  computed: {}
};
</script>

<template>
  <div>
    <button class=" text-blue-600 text-left" @click="view">
      <span v-if="data.gender == 'male'">👨</span>
      <span v-else-if="data.gender == 'female'">👨</span>
      <i v-else class="fas fa-user text-gray-500"></i>
      <span class="text-sm font-medium ml-1">{{ data.shortName }}</span>
    </button>
  </div>
</template>

<script>
import CustomerModal from "../customers/EditCustomerModal";

export default {
  props: ["data"],
  methods: {
    view() {
      this.$modals.open(CustomerModal, { id: this.data.id });
    }
  }
};
</script>

<template>
  <modal :styles="'max-w-lg'" @dismiss="onDismiss()">
    <div>
      <h3 class="text-black font-bold text-xl mb-6 text-center">
        {{ $t("doInventory") }}
      </h3>

      <div>
        <!-- scan barcode -->
        <div class="" v-if="screen == 'scanner'">
          <div class="w-full">
            <scanner @scan="next" autofocus="true" />
          </div>

          <!-- 733905562730 -->
          <div
            class="py-2 text-sm mt-2 text-center bg-green-500 text-white border-green-600 rounded-md border"
            v-if="scannedProduct.title"
          >
            <span class="font-semibold">
              {{ scannedProduct.title }}
            </span>
            <div class="text-xs font-mono mt-1">
              <div class="barcode">
                <i class="fas fa-barcode"></i>
                {{ scannedProduct.barcode }}
              </div>
              <div class="barcode ml-0.5" v-if="scannedProduct.lot">
                <span class="uppercase font-extrabold">lot</span>
                {{ scannedProduct.lot.lot }}
              </div>
              <div>
                {{ scannedProduct.attr }}
              </div>
            </div>
          </div>

          <div class="text-green-500 font-semibold mt-2 text-sm" v-if="success">
            Добавлено.
          </div>

          <div class="text-red-500 font-semibold mt-2 text-sm" v-if="etalonError">
            Штрихкод отличается от эталона.
          </div>

          <div class="text-red-500 font-semibold mt-2 text-sm" v-if="error">
            {{ $t(error) }}
          </div>

          <!--  Show scanned items -->
          <div class="text-xs mt-6" v-if="scannedItems > 0">
            <div class="text-sm font-semibold mb-2">Отсканировано товаров: {{ scannedItems }}</div>
            <div
              class="flex flex-row border-t border-gray-200 py-1"
              v-for="item in scannedItemsArray"
            >
              <div class="w-2/3">
                <div>
                  {{ item.title }}
                  <br />
                  {{ item.attr }}
                </div>
                <div
                  v-if="item.lot"
                  class="text-xs border rounded-md text-gray-500 px-1 py-0.5 inline-block"
                >
                  {{ item.lot.lot }}
                </div>
              </div>
              <div class="w-1/3 text-right">{{ item.qty }} шт.</div>
            </div>
          </div>

          <div
            class="mt-4 border-gray-200 pt-4 flex flex-row items-center justify-between"
            v-if="scannedItems > 0"
          >
            <button class="btn-primary-sm" @click="complete()">
              Завершить
            </button>
          </div>
        </div>

        <!-- mode selector -->
        <div v-if="screen == 'selector'">
          <div class="font-semibold mb-6 text-sm mt-2 text-center">Выберите режим</div>
          <div class="flex flex-row gap-x-10 justify-center items-center">
            <button class="btn-outline" @click="setMode('single')">
              Одиночный
            </button>
            <button class="btn-outline" @click="setMode('batch')">
              Пакетный
            </button>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "../../modals/Modal.vue";
import ModalMixin from "../../modals/modal.mixin";
import Product from "../../../repo/ProductRepo";
import Inventory from "../../../repo/InventoryRepo";

export default {
  components: { Modal },
  mixins: [ModalMixin],
  props: ["id"],
  data() {
    return {
      busy: false,
      barcode: "",
      mode: "bulk",
      screen: "",
      product: {},
      scannedProduct: {},
      item: {},
      inventory: {},
      success: false,
      error: false,
      etalonError: false,
      scannedItems: 0,
      scannedItemsArray: []
    };
  },
  mounted() {
    // await this.setMode("batch");
    // this.barcode = "733905562730";
    // await this.next();
    this.setMode("bulk");
  },
  methods: {
    closeme() {
      alert(1);
    },
    failsound() {
      let audio = new Audio("/sounds/error.wav");
      audio.play();
    },
    oksound() {
      let audio = new Audio("/sounds/success.wav");
      audio.play();
    },
    peaksound() {
      let audio = new Audio("/sounds/peak.wav");
      audio.play();
    },
    async next(product) {
      let vm = this;

      vm.success = false;
      vm.error = false;
      vm.etalonError = false;

      vm.scannedProduct = {};

      // check if we already scanned this product with the same lot
      if (this.scannedItemsArray.length > 0) {
        let isLotScanned = this.scannedItemsArray.find(el => el.lot.lot == product.lot.lot);
        if (isLotScanned) {
          alert("Такой лот уже просканирован.");
          this.failsound();
          return;
        }
      }

      try {
        let scannedItem = await Inventory.add(this.inventory.id, product.id, product.lot);

        this.scannedProduct = scannedItem.found;
        this.scannedItemsArray = scannedItem.items;

        this.scannedItems++;
      } catch (e) {
        vm.error = e.message;

        if (e.message == "product_not_found") {
          this.scannedItems++;
        }

        this.failsound();
      }
    },
    async setMode(mode) {
      this.mode = mode;
      this.screen = "scanner";
      this.model = {};
      // create inventory
      this.inventory = await Inventory.create(this.mode);
      this.scannedItems = 0;
    },
    async onDismiss() {
      // console.log(this.scannedItems);

      // if (this.scannedItems > 0) {
      //   if (!confirm("Вы уверены что хотите закрыть окно? Текущая инвентаризация будет удалена.")) {
      //     return;
      //   }
      // }

      // console.log("on dismiss");
      await Inventory.delete(this.inventory.id);
    },
    /**
     * Complete the inventory
     * save it and show result
     */
    async complete() {
      this.inventory = await Inventory.complete(this.inventory.id);
      await Inventory.sync(this.inventory.id);
      this.close({ status: "completed", id: this.inventory.id });
      this.$router.push({ path: "/store/inventory/" + this.inventory.id });
    }
  }
};
</script>

<template>
  <div class="">
    <!-- markups -->
    <div class="flex flex-row gap-x-4" v-if="typeview == 'price'">
      <div class="bg-white px-5 py-4 rounded-md mb-2">
        <div class="grid grid-cols-12 gap-x-4 text-xs mb-2 font-semibold border-b pb-2 " v-if="markups.length > 0">
          <div class="col-span-2">Наценка</div>
          <div class="col-span-10">
            <div class="grid grid-cols-12 gap-x-4">
              <div class="col-span-3">Категория</div>
              <div class="col-span-3">Производитель</div>
              <div class="col-span-3">Бренд</div>
            </div>
          </div>
        </div>
        <div v-if="markups.length == 0" class="text-gray-500 text-sm">
          У вас пока нет наценок
        </div>
        <div class="grid grid-cols-12 gap-x-4 items-center space-y-1" v-for="(m, index) in markups">
          <div class="col-span-2">
            <div class="flex flex-row items-center gap-x-4">
              <button type="button" @click="removeByIndex(index, markups)" class="text-red-500 text-sm">
                <i class="fas fa-times"></i>
              </button>
              <input-group v-model="m.price" :type="m.priceType" @changeType="type => (m.priceType = type)" />
            </div>
          </div>
          <div class="col-span-10">
            <div class="grid grid-cols-12 gap-x-4">
              <div class="col-span-3">
                <select v-model="m.category" class="w-full" @change="m.maker = ''">
                  <option value="">Категория</option>
                  <option v-for="cat in categories" :value="cat.name">{{ cat.name }}</option>
                </select>
              </div>
              <div class="col-span-3">
                <select class="w-full" v-model="m.maker" @change="m.brand = ''">
                  <option value="">Не выбран</option>
                  <option v-for="maker in filteredMakers(m.category)" :value="maker.name">{{ maker.name }}</option>
                </select>
              </div>
              <div class="col-span-3" v-if="m.maker">
                <select class="w-full" v-model="m.brand">
                  <option value="">Не выбран</option>
                  <option v-for="brand in filteredBrands(m.maker)" :value="brand">{{ brand }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <button type="button" class="btn-default mt-2" @click="addMarkup()">
          <i class="mr-1 fas fa-plus"></i>
          Добавить наценку
        </button>
        <div class="mt-4 pt-4 border-t border-gray-200">
          <button type="button" @click="saveMarkups()" :class="{ busy: markupBusy }" :disabled="markupBusy"
            class="btn-primary-sm">
            Сохранить
          </button>
        </div>
      </div>
    </div>

    <div class="flex flex-row gap-x-4">
      <!-- makers -->
      <div class="w-1/6 flex-none bg-white rounded-md py-2">
        <div class="mb-2 text-gray-700 font-medium text-xs px-3">
          <select class="w-full text-xs p-0" v-model="selectedCategory"
            @change="selectedMaker = null; models = []; getMakers">
            <option v-for="item in categories" :key="item.id" :value="item.name">{{ item.name }}</option>
          </select>
        </div>
        <div class="mb-2 text-gray-700 font-medium text-xs px-3">Производитель</div>
        <div class="flex flex-col">
          <div class="absolute top-0 bottom-0 h-full w-full" v-if="makerBusy">
            <loading />
          </div>
          <button @click="selectMaker(maker.name)" class="list-selector" v-for="maker of sortedMakers"
            :class="{ 'list-selector-active': maker.name == selectedMaker }">
            {{ maker.name }}
          </button>
        </div>
      </div>
      <!-- models -->
      <div class="w-1/5 flex-none bg-white rounded-md py-2 relative" v-if="typeview != 'price'">
        <div class="mb-2 text-gray-700 font-medium text-xs px-3">Модель</div>
        <template v-for="typeKey of Object.keys(models)">
          <div class="text-gray-700 font-bold text-sm px-3 pl-3 bg-gray-200 py-1"
            v-if="Object.keys(models[typeKey]).filter(periodKey => models[typeKey][periodKey].length).length">
            {{ typeKey }}
          </div>
          <div class="flex flex-col mb-4"
            v-if="Object.keys(models[typeKey]).filter(periodKey => models[typeKey][periodKey].length).length">
            <div class="absolute top-0 bottom-0 h-full w-full" v-if="modelBusy">
              <loading />
            </div>
            <span v-if="models.length == 0 && !modelBusy && selectedMaker" class="px-3 text-sm text-gray-500">
              Ничего не найдено
            </span>

            <template v-for="periodKey of Object.keys(models[typeKey])">
              <div class="text-gray-700 font-medium text-xs px-3 pl-3 bg-gray-100 py-0.5"
                v-if="models[typeKey][periodKey].length">
                {{ periodKey }}</div>

              <template>
                <button :class="{ 'list-selector-active': model.name == selectedModel.name }" class="list-selector"
                  @click="selectModel(model)" v-for="model of models[typeKey][periodKey]">
                  {{ model.name || 'Без model' }}
                </button>
              </template>

            </template>

          </div>
        </template>
      </div>
      <!-- table with min values || current stock || prices -->
      <div class="w-4/6 bg-white rounded-md py-2 relative" v-if="selectedModel.name && typeview == 'inventory'">
        <div class="mb-2 text-gray-700 font-medium text-xs px-3">Минимальные остатки</div>

        <div class="absolute right-2 top-2">
          <transition name="fade">
            <div class="busy text-blue-500 text-xs" v-if="saveSettings">Сохраняем...</div>
            <div class="text-xs text-green-500" v-if="saveSuccess != false">
              Сохранено
              <i class="fas fa-check ml-1"></i>
            </div>
            <div v-if="saveError" class="text-xs text-red-500">{{ saveError }}</div>
          </transition>
        </div>
        <!-- solutions  -->
        <div v-if="selectedModel.category != 'Контактные линзы'" class="px-3">
          <div class="rounded-md border">
            <table class="tiny-table w-full">
              <thead>
                <tr>
                  <th class="text-left px-x2">Название</th>
                  <th class="text-left px-x2">Штрихкод</th>
                  <th class="text-right px-2">Количество</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for=" item in solutionsMatrix " class="text-sm">
                  <td class="px-2">{{ item.name }}</td>
                  <td class="px-2">{{ item.barcode }}</td>
                  <td class="text-right px-2">
                    <qty-input @change="saveModelInventoryValues()" v-model="inventory[item.barcode]"
                      class="qty-input w-10" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="absolute top-0 h-full w-full" v-if="tableBusy || loadSettings">
          <loading />
        </div>

        <div v-if="!tableBusy && selectedModel.category == 'Контактные линзы'">
          <div v-if="mode == 'astig'">
            <div v-for="( bc, key ) of  compileMatrix " class="px-3 rounded-md">
              <div class="text-center mb-2">BC: {{ key }}</div>
              <div v-for="( cyl, cylKey ) of  bc " class="mb-2">
                <div class="text-center font-medium mb-1">Cyl: {{ cylKey }}</div>

                <!--  with cyl & axis -->
                <div v-for=" tbl of cyl " class="mb-2 rounded-md border">
                  <table class="tiny-table">
                    <thead>
                      <tr>
                        <th></th>
                        <th v-for=" col of tbl.cols " class="text-right">{{ col }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="( row, rowIndex ) of  tbl.rows " class="">
                        <td class="text-xs" :class="`${row < 0 ? 'text-red-700' : 'text-green-700'}`">
                          {{ row }}
                        </td>
                        <td v-for="( col, colIndex ) of  tbl.cols " class="px-0.5 "
                          :title="tbl.matrix[rowIndex][colIndex]"
                          @click="copyToClipboard(tbl.matrix[rowIndex][colIndex])">
                          <qty-input @change="saveModelInventoryValues()" v-if="tbl.matrix[rowIndex][colIndex] != 0"
                            v-model="inventory[tbl.matrix[rowIndex][colIndex]]"
                            class="text-right py-0 px-1 rounded-none w-full" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <!-- colors & simple & multifocal -->
          <div v-else :class="{ 'grid-cols-1': mode == 'simple', 'grid-cols-3': mode == 'multifocal' }"
            class="grid space-x-2">
            <div class="mb-2" v-for="( tbls, key ) of  simpleMatrix ">

              <div class="text-center font-medium mb-1" v-if="key != 0">{{ key }}</div>

              <div class="px-3">
                <div class="grid grid-cols-2 gap-x-3 max-w-96">
                  <div v-for=" tbl in tbls " class="border rounded-md">
                    <table class="tiny-table w-full">
                      <thead>
                        <tr>
                          <th></th>
                          <th v-for=" col of tbl.cols " class="text-center">{{ col }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="( row, rowIndex ) of  tbl.rows " class="hover:bg-gray-200">
                          <td class="text-xs text-center" :class="`${row < 0 ? 'text-red-700' : 'text-green-700'}`">
                            {{ row }}
                          </td>
                          <td v-for="( col, colIndex ) of  tbl.cols " class="text-center">
                            <qty-input @change="saveModelInventoryValues()"
                              v-model="inventory[tbl.matrix[rowIndex][colIndex]]"
                              class="text-right py-0 px-1 rounded-none w-10" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-4/6 bg-white rounded-md py-2 relative" v-if="selectedModel.name && typeview == 'stock'">
        <div class="mb-2 text-gray-700 font-medium text-xs px-3">Текущие остатки</div>

        <div class="absolute right-2 top-2">
          <transition name="fade">
            <div class="busy text-blue-500 text-xs" v-if="saveSettings">Сохраняем...</div>
            <div class="text-xs text-green-500" v-if="saveSuccess != false">
              Сохранено
              <i class="fas fa-check ml-1"></i>
            </div>
            <div v-if="saveError" class="text-xs text-red-500">{{ saveError }}</div>
          </transition>
        </div>
        <!-- solutions  -->
        <div v-if="!tableBusy && selectedModel.category != 'Контактные линзы'" class="px-3">
          <div class="rounded-md border">
            <table class="tiny-table w-full">
              <thead>
                <tr>
                  <th class="text-left px-x2">Название</th>
                  <th class="text-left px-x2">Штрихкод</th>
                  <th class="text-right px-2">Количество</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for=" item in solutionsMatrix " class="text-sm">
                  <td class="px-2">{{ item.name }}</td>
                  <td class="px-2">{{ item.barcode }}</td>
                  <td class="text-right px-2">
                    <qty-input @change="saveModelInventoryValues()" v-model="inventory[item.barcode]"
                      class="qty-input w-10" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="absolute top-0 h-full w-full" v-if="tableBusy || loadSettings">
          <loading />
        </div>

        <div v-if="!tableBusy && selectedModel.category == 'Контактные линзы'">
          <div v-if="mode == 'astig'">
            <div v-for="( bc, key ) of  compileMatrix " class="px-3 rounded-md">
              <div class="text-center mb-2">BC: {{ key }}</div>
              <div v-for="( cyl, cylKey ) of  bc " class="mb-2">
                <div class="text-center font-medium mb-1">Cyl: {{ cylKey }}</div>

                <!--  with cyl & axis -->
                <div v-for=" tbl of cyl " class="mb-2 rounded-md border">
                  <table class="tiny-table">
                    <thead>
                      <tr>
                        <th></th>
                        <th v-for=" col of tbl.cols " class="text-right">{{ col }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="( row, rowIndex ) of  tbl.rows " class="">
                        <td class="text-xs" :class="`${row < 0 ? 'text-red-700' : 'text-green-700'}`">
                          {{ row }}
                        </td>
                        <td v-for="( col, colIndex ) of  tbl.cols " class="px-0.5 ">
                          <qty-input @change="saveModelInventoryValues()"
                            v-model="inventory[tbl.matrix[rowIndex][colIndex]]" readonly
                            class="text-right py-0 px-1 rounded-none w-full" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <!-- colors & simple & multifocal -->
          <div v-else :class="{ 'grid-cols-1': mode == 'simple', 'grid-cols-3': mode == 'multifocal' }"
            class="grid space-x-2">
            <div class="mb-2" v-for="( tbls, key ) of  simpleMatrix ">

              <div class="text-center font-medium mb-1" v-if="key != 0">{{ key }}</div>

              <div class="px-3">
                <div class="grid grid-cols-2 gap-x-3 max-w-96">
                  <div v-for=" tbl in tbls " class="border rounded-md">
                    <table class="tiny-table w-full">
                      <thead>
                        <tr>
                          <th></th>
                          <th v-for=" col of tbl.cols " class="text-center">{{ col }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="( row, rowIndex ) of  tbl.rows " class="hover:bg-gray-200">
                          <td class="text-xs text-center" :class="`${row < 0 ? 'text-red-700' : 'text-green-700'}`">
                            {{ row }}
                          </td>
                          <td v-for="( col, colIndex ) of  tbl.cols " class="text-center">
                            <qty-input @change="saveModelInventoryValues()" readonly
                              v-model="inventory[tbl.matrix[rowIndex][colIndex]]"
                              class="text-right py-0 px-1 rounded-none w-10" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-5/6 bg-white rounded-md py-2 relative" v-if="typeview == 'price'">
        <div class="mb-2 text-gray-700 font-medium text-xs px-3">Цены <span class="p-2 cursor-pointer"
            title="Распечатать прайс">🖨️</span></div>
        <template v-for="typeKey of Object.keys(prices)">
          <div class="text-gray-700 font-bold text-sm px-3 pl-3 bg-gray-200 py-1"
            v-if="Object.keys(prices[typeKey]).filter(periodKey => prices[typeKey][periodKey].length).length">
            {{ typeKey }}
          </div>
          <template v-for="periodKey of Object.keys(prices[typeKey])">
            <div v-if="prices[typeKey][periodKey].length"
              class="grid grid-cols-12 gap-x-4 text-xs mb-2 font-semibold border-b p-2 bg-gray-100">
              <div class="col-span-3">{{ periodKey }}</div>
              <div class="col-span-1">Упаковка</div>
              <div class="col-span-8">
                <div class="grid grid-cols-12 gap-x-4">
                  <div class="col-span-3 text-right">Закупочная</div>
                  <div class="col-span-3 text-right">Розничная</div>
                  <div class="col-span-3 text-right">LensGo</div>
                  <div class="col-span-3 text-right">LinziSPB</div>
                </div>
              </div>
            </div>
            <div class="grid grid-cols-12 gap-x-4 text-xs mb-2 border-b pb-2"
              v-for="price of prices[typeKey][periodKey]">
              <div class="col-span-3 px-2">{{ price.name }}</div>
              <div class="col-span-1 px-2">{{ price.package }}</div>
              <div class="col-span-8 px-2">
                <div class="grid grid-cols-12 gap-x-4">
                  <div class="col-span-3 text-right cursor-pointer" @click="editPrice(price)">
                    <div v-if="price.basePriceMin && price.basePriceMax && price.basePriceMin == price.basePriceMax">
                      <span class="font-mono">{{ price.basePriceMin | money }}</span>
                    </div>
                    <div v-else-if="price.basePrice">
                      <span class="font-mono">{{ price.basePrice | money }}</span>
                    </div>
                    <div v-else>
                      <span class="font-mono" v-if="price.basePriceMin">{{ price.basePriceMin | money }}</span>
                      <span class="font-mono" v-if="price.basePriceMax">...{{ price.basePriceMax | money }}</span>
                      <span v-else>-</span>
                    </div>
                  </div>
                  <div class="col-span-3 text-right cursor-pointer" @click="editPrice(price)">
                    <div class="whitespace-nowrap font-medium">
                      <template
                        v-if="price.price !== false && price.price !== '0' && price.price != '0,00' && price.price != 0">
                        <span class="font-mono">
                          {{ price.price | money }}
                        </span>
                      </template>
                      <template v-else>
                        <!-- price calculated with markup -->
                        <!--  sale price calculated -->
                        <div
                          v-if="price.salePriceMin && price.salePriceMax && price.salePriceMin == price.salePriceMax">
                          <span class="font-mono text-black">{{ price.salePriceMin | money }}</span>
                        </div>
                        <div v-else>
                          <span class="font-mono text-black" v-if="price.salePriceMin">{{ price.salePriceMin | money
                            }}</span>
                          <span class="font-mono text-black" v-if="price.salePriceMax">...{{ price.salePriceMax | money
                            }}</span>
                        </div>

                        <div v-if="price.markup" v-tooltip="getMarkupTooltip(price.markup)" class="markup ml-1">
                          {{ price.markup.price }} {{ price.markup.priceType }}
                        </div>
                      </template>
                    </div>
                    <template v-if="price.items && price.items.length > 0">
                      <span class="font-mono">
                        {{ rangePrice(price.items) }}
                      </span>
                    </template>
                  </div>
                  <div class="col-span-3 text-right">
                    <span class="font-mono text-black" v-if="price.competitorPrices.find(e => e.seller == 'lensgo')"><a
                        :href="price.competitorPrices.find(e => e.seller == 'lensgo').link">{{
                          price.competitorPrices.find(e => e.seller
                            == "lensgo").price
                          | money }}</a></span>
                  </div>
                  <div class="col-span-3 text-right">
                    <span class="font-mono text-black"
                      v-if="price.competitorPrices.find(e => e.seller == 'linzispb')"><a
                        :href="price.competitorPrices.find(e => e.seller == 'linzispb').link">{{
                          price.competitorPrices.find(e => e.seller
                            == "linzispb").price
                          | money }}</a></span>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </template>

        <div class="absolute top-0 h-full w-full" v-if="tableBusy || loadSettings">
          <loading />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { makers } from "../../data/makers";
import ModelRepo from "../../repo/ModelRepo";
import CategoryRepo from "../../repo/CategoryRepo"
import MakerRepo from "../../repo/MakerRepo"
import EditPriceModal from "@/components/network/EditPriceModal.vue";
import PricingRepo from "@/repo/PricingRepo";

export default {
  components: {},
  props: ["store", "typeview"], // typeview = 'stock' || 'inventory' || 'price'
  data() {
    return {
      makers: [],
      categories: [],
      models: [],
      categoryBusy: false,
      markupBusy: false,
      makerBusy: false,
      modelBusy: false,
      tableBusy: false,
      selectedCategory: null,
      selectedMaker: "",
      selectedModel: "",
      items: [],
      counter: 0,
      inventory: {},
      prices: {},
      markups: [],
      markupCategory: false,
      markupMaker: false,
      mode: null,
      loadSettings: false,
      saveSettings: false,
      test: 1,
      saveDebounce: null,
      saveSuccess: false,
      saveError: false
    };
  },
  mounted() {
    // this.selectMaker(this.makers[0]);
    this.getCategories();
    this.getMakers();
    this.getModels();
    this.pullModelValues('price');
    if (this.typeview === 'price') this.getMarkups()
  },
  methods: {
    async getCategories() {
      let vm = this;
      vm.categoryBusy = true;
      let data = await CategoryRepo.get();
      data.push({ id: 0, name: "Все" })

      setTimeout(() => {
        vm.categories = data;
        vm.categoryBusy = false;
        vm.selectedCategory = 'Все'

        // vm.getMakers();
      }, 250);
    },
    async getMakers() {
      let vm = this;
      this.makerBusy = true;
      let data = await MakerRepo.get();

      setTimeout(() => {
        vm.makers = data;
        vm.makerBusy = false;
        // vm.selectModel(vm.models[0]);
      }, 250);
    },
    async getModels() {
      let vm = this;
      this.modelBusy = true;
      let data = await ModelRepo.getByMaker(this.selectedMaker);
      let resultGroups = {};

      // group by type
      let groupedByType = {
        "Сферические": [],
        'Мультифокальные': [],
        'Астигматические': [],
        'Цветные': [],
        'Растворы': [],
        'Капли': [],
        'Тип не указан': [],
      }
      data.items.filter(e => {
        if (this.selectedCategory !== "Все") return e.category === this.selectedCategory
        return true
      }).forEach(model => groupedByType[model.type ?? 'Тип не указан'].push(model));

      // regroup and sort by period (zamena)
      for (let type in groupedByType) {
        let arr = groupedByType[type];

        let innerGroups = {
          'Однодневные': [],
          'Двухнедельные': [],
          'На 1 Месяц': [],
          'На 3 Месяца': [],
          'Период не указан': []
        }
        arr.forEach(model => innerGroups[model.zamena ?? 'Период не указан'].push(model))

        resultGroups[type] = innerGroups;
      }

      setTimeout(() => {
        vm.models = resultGroups;
        vm.modelBusy = false;
        vm.selectModel(vm.models[0]); //6
      }, 250);
    },
    async getMarkups() {
      let data = await PricingRepo.getMarkups();
      this.markups = data //.filter(m => m.maker == this.selectedMaker);
    },
    selectMaker(maker) {
      this.inventory = {};
      this.selectedMaker = maker;
      this.selectedModel = "";
      this.models = [];
      this.getModels();
      if (this.typeview == 'price') this.pullModelValues('price');
    },
    selectModel(model) {
      this.inventory = {};
      if (!model) return;
      this.selectedModel = model;
      this.pullModelTable();
      if (model.type == "Астигматические") {
        this.mode = "astig";
      } else if (model.type == "Мультифокальные") {
        this.mode = "multifocal";
      } else {
        this.mode = "simple";
      }
    },
    async pullModelTable() {
      this.tableBusy = true;
      let { data } = await this.$api.get("/g/lensmodels/min/" + this.selectedModel.name);
      this.items = data;

      setTimeout(() => {
        this.tableBusy = false;
        this.pullModelValues(this.typeview);
      }, 250);
    },
    async pullModelValues(typeview) {
      this.loadSettings = true;
      switch (typeview) {
        case "stock":
          try {
            let { data } = await this.$api.get(`/prices/stock-current/${this.store.id}/${this.selectedModel.name}`);
            if (data) {
              this.inventory = { ...data };
            }
          } catch (e) {
            console.log(e);
          } finally {
            setTimeout(() => {
              this.loadSettings = false;
            }, 250);
          }
          break;
        case "inventory":
          try {
            let { data } = await this.$api.get(`/prices/stock-threshold/${this.store.id}/${this.selectedModel.name}`);
            if (data) {
              this.inventory = data.inventory;
            }
          } catch (e) {
            console.log(e);
          } finally {
            setTimeout(() => {
              this.loadSettings = false;
            }, 250);
          }
          break;
        case "price": {
          let query = encodeURIComponent(JSON.stringify({ "maker": this.selectedMaker, "model": this.selectedModel.name, "page": 1, "limit": 50 }));
          let data = await ModelRepo.query(query);
          const competitorPrices = await ModelRepo.getCompetitorPrices([...new Set(data.items.map(e => e.name))])

          if (data && data.items) {
            let resultGroups = {};

            // group by type
            let groupedByType = {
              "Сферические": [],
              'Мультифокальные': [],
              'Астигматические': [],
              'Цветные': [],
              'Растворы': [],
              'Капли': [],
              'Тип не указан': [],
            }
            data.items.filter(e => {
              if (this.selectedCategory !== "Все") return e.category === this.selectedCategory
              return true
            }).forEach(model => groupedByType[model.type ?? 'Тип не указан'].push(model));


            // regroup and sort by period (zamena)
            for (let type in groupedByType) {
              let arr = groupedByType[type];
              let innerGroups = {
                'Однодневные': [],
                'Двухнедельные': [],
                'На 1 Месяц': [],
                'На 3 Месяца': [],
                'Период не указан': []
              }

              // sort by period
              arr.forEach(model => {
                const period = model.zamena ?? 'Период не указан';
                innerGroups[period].push({
                  ...model,
                  competitorPrices: competitorPrices.length > 0 ? competitorPrices.filter(price => price.mnklModel === model.name) : [],
                });
              });

              resultGroups[type] = innerGroups;
            }
            this.prices = resultGroups
          }

          setTimeout(() => {
            this.loadSettings = false;
          }, 250);
        }


          break;
        default:
          break;
      }

    },
    async saveModelInventoryValues() {
      // debounce
      let vm = this;
      vm.saveSuccess = false;
      vm.saveError = false;
      vm.saveSettings = true;
      clearTimeout(vm.saveDebounceTimer);
      vm.saveDebounceTimer = setTimeout(async () => {
        let isSuccess = false;
        try {
          let { data } = await vm.$api.post(
            `/prices/stock-threshold/${this.store.id}/${this.selectedModel.name}`,
            vm.inventory
          );
          isSuccess = true;
        } catch (e) {
          vm.saveError = e.message;
        } finally {
          setTimeout(() => {
            vm.saveSettings = false;
            if (isSuccess) vm.saveSuccess = true;
            setTimeout(() => {
              vm.saveSuccess = false;
            }, 1000);
          }, 250);
        }
      }, 1000);
    },
    rangePrice(items) {
      let arr = items.filter(el => el.price > 0);
      // return first and last items price
      if (arr.length > 0) {
        return arr[0].price + " ... " + arr[arr.length - 1].price;
      }
      return "";
    },
    getMarkupTooltip(markup) {
      return `Наценка ${markup.price} ${markup.priceType} на ${markup.category} ${markup.maker} ${markup.brand}`;
    },
    async editPrice(price) {
      let vm = this;
      this.$modals.open(EditPriceModal, { price: price }, data => {
        if (data && data.inventoryRules) {
          price.inventoryRules = data.inventoryRules;
        }
        if (data && data.items) {
          price.items = data.items;
        }
      });
    },
    printPrice() {

    },
    removeByIndex(index, array) {
      if (confirm("Удалить?")) {
        array.splice(index, 1);
      }
    },
    addMarkup(type, value) {
      this.markups.push({
        priceType: "%",
        price: 0,
        category: "",
        brand: "",
        maker: ""
      });
    },
    async saveMarkups() {
      let vm = this;
      vm.saveSuccess = false;
      vm.saveError = false;
      vm.saveSettings = true;

      this.markupBusy = true;
      clearTimeout(vm.saveDebounceTimer);
      vm.saveDebounceTimer = setTimeout(async () => {
        let isSuccess = false;
        try {
          let data = await PricingRepo.updateMarkups(this.markups);
          this.markups = data;
          this.pullModelValues('price')
          vm.markupBusy = false;
          isSuccess = true;
        } catch (e) {
          vm.saveError = e.message;
        } finally {
          setTimeout(() => {
            vm.saveSettings = false;
            if (isSuccess) vm.saveSuccess = true;
            setTimeout(() => {
              vm.saveSuccess = false;
            }, 1000);
          }, 250);
        }
      }, 1000);

    },
    filteredMakers(category) {
      if (category) {
        return this.makers.filter(el => el.categories.includes(category));
      }
      return this.makers;
    },
    filteredBrands(makerName) {
      let maker = this.makers.find(el => el.name == makerName);
      if (maker != undefined) {
        return maker.brands;
      }
      return [];
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
    }
  },
  computed: {
    solutionsMatrix() {
      return [...this.items];
    },
    compileMatrix() {
      // console.log("compile matrix" + this.items.length);

      let matrix = [];
      let sph = new Set();
      let bc = new Set();
      let diameter = new Set();
      let axis = new Set();
      let cyl = new Set();

      let tables = [];
      let bcTables = {};

      if (this.items.length > 0) {
        this.items.forEach(item => {
          if (item.sph) {
            sph.add(item.sph);
          }
          if (item.bc) {
            bc.add(item.bc);
          }
          if (item.diameter) {
            diameter.add(item.diameter);
          }
          if (item.cyl) {
            cyl.add(item.cyl);
          }
        });

        // generate a matrix with size of sph
        // console.log(`sph: ${sph}`);
        // console.log(`bc: ${bc}`);
        // console.log(`diameter: ${diameter}`);

        sph = Array.from(sph);
        bc = Array.from(bc);
        cyl = Array.from(cyl);

        // astigmratism
        if (cyl[0] != null) {
          for (let bcEl of bc) {
            // get available cyl for that bc
            let cyl = this.items.filter(item => item.bc == bcEl).map(item => item.cyl);
            // create set from array
            cyl = Array.from(new Set(cyl)).sort((a, b) => parseFloat(b) - parseFloat(a));
            // console.log(`BC: ${bcEl}`);
            // console.log(cyl);

            // split power to multuple tables
            let cylTables = {};

            for (let cylEl of cyl) {
              // get available axis
              let products = this.items.filter(item => item.bc == bcEl && item.cyl == cylEl);
              let axis = products.map(item => item.axis);
              axis = Array.from(new Set(axis));
              // sort it before manipulations
              axis.sort((a, b) => parseInt(a) - parseInt(b))
              // sph
              let power = products.map(item => item.sph);
              power = Array.from(new Set(power));
              // console.log(`Cyl: ${cylEl}`);
              //console.log(axis);
              //console.log(power);

              let tables = [];
              for (var i = 0; i <= 1; i++) {
                let tableMatrix = [];
                let sphTable = power.filter(sph => (i == 0 ? parseFloat(sph) >= 0 : parseFloat(sph) < 0));
                // sort
                sphTable = sphTable.sort((a, b) => {
                  return (parseFloat(a) - parseFloat(b)) * (i == 0 ? 1 : -1);
                });

                // generate final matrix
                for (var j = 0; j < sphTable.length; j++) {
                  tableMatrix[j] = new Array(axis.length).fill(0); // Fill the matrix with zeros
                }

                // fill table with barcodes
                products.forEach(product => {
                  var SPHIndex = sphTable.findIndex(el => el == product.sph); // Map SPH to matrix index
                  var AxisIndex = axis.findIndex(el => el == product.axis);
                  if (SPHIndex == -1 || AxisIndex == -1) {
                    return;
                  }

                  tableMatrix[SPHIndex][AxisIndex] = product.barcode; // Store product name in the corresponding cell
                });

                tables.push({
                  matrix: tableMatrix,
                  rows: sphTable,
                  cols: axis
                });
              }

              cylTables[cylEl] = tables;
            }

            bcTables[bcEl] = cylTables;
          }
        }
      }

      // console.log(bcTables);
      return bcTables;
    },
    simpleMatrix() {
      let tables = [],
        sph = new Set(),
        bc = new Set(),
        colors = new Set(),
        add = new Set();

      function generateTableByField(products, { sph, bc }) {
        let generatedTables = [];
        for (var i = 0; i <= 1; i++) {
          let tableMatrix = [];
          let sphTable = Array.from(sph).filter(el => (i == 0 ? parseFloat(el) >= 0 : parseFloat(el) < 0));

          // sort
          sphTable = sphTable.sort((a, b) => {
            return (parseFloat(a) - parseFloat(b)) * (i == 0 ? 1 : -1);
          });

          for (var j = 0; j <= sphTable.length; j++) {
            tableMatrix[j] = new Array(bc.length).fill(0); // Fill the matrix with zeros
          }

          products.forEach(product => {
            var SPHIndex = sphTable.findIndex(el => el == product.sph); // Map SPH to matrix index
            var RIndex = bc.findIndex(el => el == product.bc);
            if (SPHIndex == -1 || RIndex == -1) {
              return;
            }

            tableMatrix[SPHIndex][RIndex] = product.barcode; // Store product name in the corresponding cell
          });

          generatedTables.push({
            matrix: tableMatrix,
            rows: sphTable,
            cols: bc
          });
        }

        return generatedTables;
      }

      if (this.items.length > 0) {
        this.items.forEach(item => {
          if (item.sph) {
            sph.add(item.sph);
          }
          if (item.bc) {
            bc.add(item.bc);
          }
          if (item.color != 'Прозрачные') {
            colors.add(item.color);
          }
          if (item.add) {
            add.add(item.add);
          }
        });

        bc = Array.from(bc);
        sph = Array.from(sph);
        colors = Array.from(colors);
        add = Array.from(add);

        // if colors exists
        let colorTables = {};
        if (colors.length > 0) {
          // for each color generate a table

          for (let color of colors) {
            let products = this.items.filter(item => item.color == color);
            let sph = new Set(products.map(item => item.sph));
            let bc = new Set(products.map(item => item.bc));
            sph = Array.from(sph);
            bc = Array.from(bc);
            let tbls = generateTableByField(products, { sph, bc });
            colorTables[color] = tbls;
          }
          // console.log('color tables')
          return colorTables;
        }

        // multi focal
        if (add.length > 0) {
          // for each color generate a table
          let multifocalTables = {};
          for (let ad of add) {
            let products = this.items.filter(item => item.add == ad);
            let sph = new Set(products.map(item => item.sph));
            let bc = new Set(products.map(item => item.bc));
            sph = Array.from(sph);
            bc = Array.from(bc);
            let tbls = generateTableByField(products, { sph, bc });
            multifocalTables[ad] = tbls;
          }
          // console.log('multifocal')
          return multifocalTables;
        }

        // no colors and no multi focalsfocals
        let products = this.items;
        let tbls = generateTableByField(products, { sph, bc });
        // console.log('no colors')
        return [tbls];
      }

      // console.log('default products')
      return tables;
    },
    sortedMakers() {
      if (this.selectedCategory !== 'Все') return [...this.makers.filter(e => e.categories.includes(this.selectedCategory))].sort();

      return [...this.makers].sort();
    }
  },
  watch: {}
};
</script>

<template>
  <div>
    <div class="flex flex-row items-stretch min-h-screen">
      <!-- primary sidebar -->
      <primary-sidebar :show="showPrimarySidebar" />
      <!-- <div class="w-1/5">secondary</div> -->
      <div class="page-content w-full" :class="{ 'hide-sidebar': !showPrimarySidebar }">
        <top-bar />
        <router-view></router-view>
      </div>
    </div>

    <div
      class="z-30 fixed w-screen h-screen top-0 left-0 right-0 bottom-0 flex flex-col items-center justify-center bg-busy"
      v-if="logoutBusy">
      <div class="text-white text-2xl">
        {{ $t('logoutProgress') }}
        <span class="dots"></span>
      </div>
      <div class="mt-8">
        <router-link to="/auth/login" class="text-white text-sm font-medium border-b">Войти</router-link>
      </div>
    </div>

  </div>

  <!-- <router-view></router-view> -->
</template>

<script>
import { mapGetters } from "vuex";
import PrimarySidebar from "./layouts/PrimarySidebar.vue";
import SecondarySidebar from "./layouts/SecondarySidebar.vue";
import TopBar from "./layouts/TopBar.vue";
export default {
  name: "BasicLayout",
  components: { PrimarySidebar, SecondarySidebar, TopBar },
  data() {
    return {
      viewAdminNav: false,
      showPrimarySidebar: false,
      logoutBusy: false
    };
  },
  mounted() {
    let vm = this;

    // toggle sidebar
    vm.showPrimarySidebar = this.showSidebar;
    console.log(this.showPrimarySidebar)

    this.$events.$on("togglePrimarySidebar", function () {
      vm.showPrimarySidebar = !vm.showPrimarySidebar;
      // save to user settings
      vm.$store.dispatch("app/setSetting", { key: "sidebar", value: vm.showPrimarySidebar });
    });

    this.$events.$on('logout', function () {
      vm.logoutBusy = true;
      vm.$store.dispatch("app/logoutUser");
      vm.$store.dispatch("optics/wipe");
      vm.$store.dispatch("users/wipe");
      vm.$store.dispatch("records/wipe");
      vm.$store.dispatch("customers/wipe");
      setTimeout(function () {
        document.location.href = '/auth/login';
      }, 1000);
    })

  },
  methods: {
    hideAdminNavbar() {
      let vm = this;
      setTimeout(function () {
        vm.viewAdminNav = false;
      }, 300);
    },
  },
  computed: {
    ...mapGetters("optics", ["active"]),
    ...mapGetters("app", { me: "getUser", showSidebar: "getSidebarState" })
  }
};
</script>

<template>
  <div class="relative">
    <input
      type="text"
      name="price"
      id="price"
      class="block w-full rounded-md border-0 py-1.5 pl-4 pr-16 text-black ring-1 ring-inset ring-gray-400 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
      placeholder="5"
      v-bind:value="value"
      v-on:input="$emit('input', $event.target.value)"
    />
    <div class="absolute inset-y-0 right-1 flex items-center">
      <select
        id="currency"
        name="currency"
        v-bind:value="type"
        v-on:input="$emit('changeType', $event.target.value)"
        class="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-0 text-gray-500 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
      >
        <option value="%">%</option>
        <option value="fix">руб.</option>
      </select>
    </div>
  </div>
</template>
<script>
export default {
  name: "InputGroup",
  props: ["value", "type"]
};
</script>

<template>
  <div>
    <!-- Complaints -->
    <div class="relative">
      <div class="font-semibold text-sm mb-2" v-if="!hideTitle">{{ $t(field) }}</div>
      <div class="flex flex-row flex-wrap gap-x-1 gap-y-1 mt-2 relative" :class="{ 'mb-4': sorted.length <= limit }">
        <Loading v-if="loading" />
        <div
          v-for="item in visibleTags"
          class="select-tag text-sm rounded-md px-2 py-0.5"
          @click="toggleItem(field, item.title)"
          :class="obj.indexOf(item.title) != -1 ? 'bg-green-500 text-white' : 'bg-gray-200 text-gray-600'"
        >
          {{ item.title }}
        </div>
        <button class="text-black bg-gray-200  rounded-md text-sm px-2" @click="addTag()">
          <i class="fa fa-plus"></i>
        </button>
      </div>
      <button class="text-xs text-blue-500 border-b mt-2 mb-4" @click="toggle = !toggle" v-if="sorted.length > limit">
        <template v-if="!toggle">
          Показать все
        </template>
        <template v-else>
          Скрыть
        </template>
      </button>
    </div>
  </div>
</template>

<script>
import TagModal from "../tags/TagModal";
import Loading from "../Loading";

export default {
  components: { Loading },
  props: ["field", "obj", "hideTitle"],
  data() {
    return {
      loading: true,
      options: [],
      limit: 6,
      toggle: false
    };
  },
  mounted() {
    // get tags options
    this.getTags();
    // subscribe on update tags by field
    this.$events.$on("update-tags", ({ field }) => {
      if (field == this.field) {
        this.getTags();
      }
    });

    // this.$events.$on(`toggle-tag-${this.field}`, (field, tag) => {
    //   this.toggleItem(this.field, tag.title);
    // });
  },
  methods: {
    addTag() {
      let vm = this;
      this.$modals.open(TagModal, { field: vm.field }, tag => {
        if (tag !== false && tag != undefined) {
          console.log(tag);
          //this.obj.push(tag.title);
          vm.toggleItem(vm.field, tag.title);
          // sync tags
          //this.getTags();
          // add to options if not exists
          let exists = vm.options.findIndex(el => el.id == tag.id);
          if (!exists) {
            vm.options.push(tag);
          }
          // emit event to sync all tags on a page with this field
          this.$events.$emit("update-tags", { field: this.field });
        }
      });
    },
    async getTags() {
      this.loading = true;
      let { success, msg, data } = await this.$api.get(`/tags?field=${this.field}`);
      this.options = data;
      this.loading = false;
    },
    toggleItem(field, item) {
      let index = this.obj.indexOf(item);
      if (index != -1) {
        // remove it from the list
        this.obj.splice(index, 1);
      } else {
        this.obj.push(item);
      }
    }
  },
  computed: {
    sorted() {
      let arr = [...this.options];
      return arr.sort((a, b) => a.title.localeCompare(b.title));
    },
    visibleTags() {
      let arr = this.sorted; //[...this.options];
      // arr = arr.sort((a, b) => {
      //   if (
      //     this.obj.findIndex(el => el == a.title) != -1 &&
      //     this.obj.findIndex(el => el == b.title) != -1
      //   ) {
      //     return a.title.localeCompare(b.title);
      //   }

      //   if (
      //     this.obj.findIndex(el => el == a.title) == -1 &&
      //     this.obj.findIndex(el => el == b.title) != -1
      //   ) {
      //     return -1;
      //   }

      //   return a.title.localeCompare(b.title);
      // });
      if (!this.toggle) {
        return arr.slice(0, this.limit);
      }

      return arr;
    }
  }
};
</script>

<template>
  <div class="bg-white px-5 py-4 rounded-md">

    <div
      class="grid grid-cols-7 gap-2 text-center mb-4 text-sm text-gray-400 font-medium"
      v-if="workingHours && workingHours.length == 7"
    >
      <div
        class="border rounded border-gray-200 px-4 py-1 cursor-pointer hover:bg-opacity-70"
        :key="index"
        v-for="(day, index) in ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс']"
        @click="toggleDay(index)"
        :class="{ 'bg-blue-500 text-white border-blue-600': workingHours[index][0] != -1 }"
      >
        {{ day }}
      </div>
    </div>

    <div class="grid grid-cols-7 gap-2 text-sm" v-if="workingHours && workingHours.length == 7">
      <div v-for="(day, index) in days" :key="key + index" class="flex flex-col even:bg-gray-50">
        <template v-if="workingHours[index][0] != -1">
          <select v-model="workingHours[index][0]" class="px-2 py-2 mb-2">
            <option value="-1">Закрыто</option>
            <option v-for="(opt, ind) of timeSlots" :key="ind">
              {{ opt }}
            </option>
          </select>

          <select
            v-model="workingHours[index][1]"
            :disabled="workingHours[index][0] == -1"
            class="py-2 px-2"
          >
            <option v-for="(opt, ind) of timeSlots" :key="ind">
              {{ opt }}
            </option>
          </select>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["workingHours"],
  data() {
    return {
      defaultWorkingHours: [
        [10, 20],
        [10, 20],
        [10, 20],
        [10, 20],
        [10, 20],
        [10, 20],
        [10, 20]
      ],
      key: 0
    };
  },
  mounted() {
    if (this.workingHours && this.workingHours.length < 7) {
      for (let slot of this.defaultWorkingHours) {
        this.workingHours.push(slot);
      }
    }

  },
  methods: {
    toggleDay(index) {
      if (this.workingHours[index][0] == -1) {
        this.workingHours[index][0] = "09";
      } else {
        this.workingHours[index][0] = -1;
      }

      this.key++;
    }
  },
  computed: {
    days() {
      return ["Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота", "Воскресенье"];
    },
    timeSlots() {
      let slots = [];

      for (let t = 0; t <= 24; ) {
        let slotTime = t;
        if (t <= 9) {
          slotTime = 0 + "" + t;
        }

        slots.push(slotTime);
        t = t + 1;
      }

      return slots;
    }
  }
};
</script>

<template>
    <ul class="space-y-4">
        <tree-item v-for="page in pages" :key="page.pageData.id" :is-root="true" :page="page"
            :current-page="currentPage" @select-page="selectPage" :is-current-path="isCurrentPagePath(page)"/>
    </ul>
</template>

<script>
import TreeItem from './TreeItem.vue';

export default {
    name: 'TreeView',
    components: {
        TreeItem
    },
    props: {
        pages: Array,
        currentPage: Object
    },
    mounted() {
        this.checkCurrentPagePath(this.pages)
    },
    methods: {
        selectPage(page) {
            this.$emit('select-page', page);
        },
        checkCurrentPagePath(pages) {
            pages.forEach(page => {
                if (this.currentPage && this.isCurrentPagePath(page)) {
                    this.$set(page, 'isCurrentPath', true);
                }
                if (page.childrens && page.childrens.length > 0) {
                    this.checkCurrentPagePath(page.childrens);
                }
            });
        },
        isCurrentPagePath(page) {
            if (this.currentPage && this.currentPage.pageData.id === page.pageData.id) {
                return true;
            }
            if (page.childrens && page.childrens.some(child => this.isCurrentPagePath(child))) {
                return true;
            }
            return false;
        }
    }
};
</script>

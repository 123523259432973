<template>
  <button type="button" @click="$emit('click')" class="text-blue-600 hover:text-blue-800  text-sm" :class="styles">
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: ["styles"],
  name: "btn-link",
  data() {
    return {};
  },
};
</script>
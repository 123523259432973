<template>
  <div class="relative">
    <loading v-if="loading" />

    <h3 class="text-black font-bold text-xl mb-6" v-if="!optic.id">
      Добавить оптику
    </h3>

    <!--  Form  -->
    <form class="" @submit.prevent="save()">
      <div class="">
        <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">Название</label>
        <input
          class="w-full "
          id="title"
          name="title"
          type="text"
          required=""
          v-model="optic.title"
          placeholder="Название для оптики"
          aria-label="title"
        />
      </div>

      <div class="flex flex-row items-center justify-between mt-6">
        <button type="submit" :class="{ busy: busy }" :disabled="busy" class="btn-primary-sm">
          Добавить
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import Optic from "../../repo/OpticRepo";
import Loading from "../Loading";

export default {
  name: "edit-optic",
  components: { Loading },
  props: ["id"],
  data() {
    return {
      loading: false,
      busy: false,
      status: "",
      optic: {
        id: "",
        title: ""
      }
    };
  },
  mounted() {
    if (this.id !== -1) {
      this.get();
    }
  },
  methods: {
    async save() {
      this.busy = true;

      let { success, msg, data } = await Optic.update(this.optic);

      this.busy = false;

      if (success) {
        this.optic = data;
        this.status = "save-success";
        // reload items in store
        this.$store.dispatch("optics/pullOptics");
        // redirect to optic page
        console.log("open " + this.optic.id);
        this.$router.push({ path: "optics/" + this.optic.id });
      } else {
        this.status = msg;
      }
    },
    async get() {
      this.loading = true;
      const { data, msg, success } = await Optic.getByid(this.id);
      if (success) {
        this.optic = data;
      }
      this.loading = false;
    }
  }
};
</script>

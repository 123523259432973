<template>
  <div class="relative" ref="autocomplete">
    <input
      class="w-full py-1 px-1 lg:px-2 text-xs lg:text-sm focus:ring-2 ring-indigo-500"
      type="text"
      :readonly="true"
      required=""
      :class="{ 'text-indigo-500': id, 'ring-2': showKeyboard }"
      @focus="search"
      v-bind:value="value"
      v-on:input="search($event)"
      @click="search"
      ref="input"
      @keydown.esc.stop="showHelper = false"
      @blur="$emit('blur')"
      v-if="type != 'leading' && type != 'duo_test'"
    />

    <!--  select box  -->
    <label v-if="type == 'duo_test'">
      <select class="py-0.5 w-full px-0.5 text-xs md:text-sm" v-bind:value="value" @change="$emit('input', $event.target.value)">
        <option value=""></option>
        <option value="green">зеленый</option>
        <option value="red">красный</option>
        <option value="same">одинаково</option>
      </select>
    </label>

    <!-- Checkbox -->
    <label class="block cursor-pointer hover:bg-gray-100">
      <input
        type="checkbox"
        v-if="type == 'leading'"
        v-bind:value="value"
        :checked="value"
        @change="$emit('input', $event.target.checked)"
      />
    </label>

    <div class="absolute top-6 right-10 z-10" v-if="busy">
      <loading />
    </div>
    <div
      class="absolute rounded shadow-2xl border-gray-200 mt-2 border bg-white z-10 w-full max-h-80 overflow-y-scroll"
      :style="{ visibility: showHelper ? 'visible' : 'hidden' }"
      :ref="'parent'"
    >
      <div
        class="px-1 lg:px-2 py-1 hover:bg-gray-200 cursor-pointer text-gray-800 border-b last:border-b-0 font-semibold flex flex-row items-center"
        v-for="(item, index) in sortedItems"
        :key="index"
        @click="selectItem(item)"
        :class="{ 'font-bold': value && item == parseFloat(value.toString().replace(',', '.')) }"
      >
        <div class="leading-normal text-xs lg:text-sm" :ref="'item' + item">
          {{ item | withzero }}
        </div>
      </div>
    </div>
    <!-- Virtual keyboard -->
    <div class="absolute z-10 right-0 w-52 mt-2  text-sm lg:text-base" v-if="showKeyboard">
      <div class="grid grid-cols-3 px-2 py-2 mx-auto bg-black w-full gap-x-1 gap-y-1 shadow-2xl  rounded-md border">
        <button
          class="bg-white text-black font-semibold rounded-md py-2"
          v-for="symbol in [1, 2, 3, 4, 5, 6, 7, 8, 9, '.', 0, '-']"
          @click="cmd('type', symbol)"
        >
          {{ symbol }}
        </button>
        <button class="bg-white text-black font-semibold rounded-md py-2 col-span-2" @click="cmd('backspace')">
          ⌫ Удалить
        </button>
        <button class="bg-white text-black font-semibold rounded-md py-2" @click="showKeyboard = false">
          ✔️
        </button>
      </div>
    </div>
  </div>
</template>

<script>
const debounce = require("debounce");

import { mapGetters } from "vuex";
import CustomerRepo from "../../repo/CustomerRepo";
import Loading from "../Loading.vue";
import { sph, add, bc, dia, addGlasses } from "../../data/values";

export default {
  components: { Loading },
  props: ["value", "type", "items", "id", "format", "field", "focus"],
  data() {
    return {
      foundCustomers: [],
      busy: false,
      showHelper: false,
      typeFormat: "",
      showKeyboard: false,
      selfModel: false
    };
  },
  mounted() {
    let vm = this;

    if (!this.format) {
      if (["sph", "cyl", "axis", "dpp"].indexOf(this.type) != -1) {
        this.typeFormat = "float";
      }
    } else {
      this.typeFormat = this.format;
    }

    //this.inputValue = this.value;
    document.addEventListener("click", function(event) {
      let target = vm.$refs.autocomplete;
      const withinBoundaries = event.composedPath().includes(target);
      if (!withinBoundaries) {
        vm.showHelper = false;
        vm.showKeyboard = false;
      }
    });

    if (this.focus) {
      this.$refs.input.focus();
    }
  },
  methods: {
    /**
     * Virtual keyboard commands
     */
    cmd(command, symbol) {
      let val = this.$refs.input.value;

      if (command == "backspace") {
        this.$refs.input.value = val.slice(0, -1);
        //this.$emit("input", val);
      }

      if (command == "type") {
        this.$refs.input.value = val + symbol;
      }

      this.$emit("input", this.$refs.input.value);
    },
    search(e) {
      //console.log("search---");
      // filter input, always replace colon with dot
      let val = e.target.value;
      //console.log(val);
      if (this.typeFormat && this.typeFormat == "float") {
        val = val
          .toString()
          .replace(/,/g, ".")
          .replace(/[^.,-?\d+]/g, ""); //replace all that not a number and not a minus sign
        /// console.log(val);
        // if last symbol not a dot
        let lastSymbol = val.slice(val.length - 1, val.length);

        if (val !== "" && val.slice(val.length - 1, val.length) != "." && lastSymbol != 0) {
          if (!isNaN(parseFloat(val))) {
            val = parseFloat(val);
          }
        }

        //console.log(val);
      } else {
        // always replace dot in numbers
        val = val.replace(/(\d)(,)(\d)/g, "$1.$3");
      }

      // console.log(val);
      this.$refs.input.value = val;
      this.$emit("input", val);

      if (this.sortedItems.length == 0) {
        this.showKeyboard = true;
        return;
      }

      if (this.sortedItems.length == 0) return;

      this.showHelper = true;

      let refItem = 0;

      if (e.target.value != "") {
        refItem = val;
      }

      if (this.$refs["item" + refItem] != undefined) {
        //this.$refs["item" + refItem][0].scrollIntoView({ block: "center" });
        let childRect = this.$refs["item" + refItem][0];
        let parent = this.$refs.parent;
        parent.scrollTop = childRect.offsetTop - parent.offsetHeight / 2;
      }
    },
    selectItem(item) {
      this.showHelper = false;
      //this.inputValue = item
      if (Number.isInteger(item)) {
        item = item.toFixed(1);
      }

      this.$emit("input", item);
    }
  },
  computed: {
    sortedItems() {
      // filter that starts with value
      let vm = this;
      let items = vm.items || [];

      // generate axis
      const range = (start, stop, step = 1) =>
        Array(Math.ceil((stop - start) / step))
          .fill(start)
          .map((x, y) => x + y * step);

      let axis = range(0, 185, 10);
      let cyl = range(-6, 6.25, 0.25);
      let prism = ["-", 0.25, ...range(0.5, 40.5, 0.5)];
      let base = ["-", ...range(0, 280, 10)]

      switch (this.type) {
        case "sph":
          items = sph;
          break;
        case "cyl":
          items = cyl;
          break;
        case "prism1":
          items = prism;
          break;
        case "base1":
          items = base;
          break;
        case "base2":
          items = base;
          break;
        case "prism2":
          items = prism;
          break;
        case "add":
          items = add;

          if (this.field == "glasses") {
            items = addGlasses;
          }

          break;
        case "bc":
          items = bc;
          break;
        case "dia":
          items = dia;
          break;
        case "axis":
          items = axis;
          if (this.field == "glasses") {
            items = "";
          }
          break;
        case "metric_distance":
          items = [35, 40, 45, 50];
          break;
        case "nocorr":
        case "corr":
          break;
      }

      return items;
    }
  }
};
</script>

<template>
    <page>
        <template v-slot:header>
            Входящая почта
        </template>
        <template v-slot:actions>
        </template>

        <!-- Filters, collapsable -->
        <template v-slot:content>

            <div>
                <pagination :total="total" :limit="filter.limit" :items="items" :activePage="filter.page"
                    @page="page => ((filter.page = page), getItems())"
                    @limit="limit => ((filter.limit = limit), getItems())" />

                <div class="w-full overflow-x-auto text-left bg-white rounded-md">
                    <table class="data-table">
                        <thead>
                            <tr class="f">
                                <th class="">Статус</th>
                                <th class="">От кого</th>
                                <th class="">Когда</th>
                                <th class="">Тема</th>
                                <th class="">Файлы</th>
                                <th class="">Поставщик</th>
                                <th class="">Филиал</th>
                                <th class="">Поступление</th>
                            </tr>
                        </thead>
                        <tbody class="">
                            <tr v-for="item in items" :key="item.id">
                                <td>
                                    <span v-if="item.status == 'new'" class="badge blue">Новое</span>
                                    <span v-if="item.status == 'processed'" class="badge green">Обработано</span>
                                    <span v-if="item.status == 'error'" class="badge red">Ошибка</span>
                                </td>
                                <td>{{ item.fromEmail }}</td>
                                <td>{{ item.created_at }}</td>
                                <td>{{ item.subject }}</td>
                                <td>
                                    <template v-if="item.attachments">
                                        <div class="text-xs" v-for="attach in item.attachments">
                                            <button @click="downloadAttachment(item, attach.name)" class="btn-link ">
                                                {{ attach.name }}
                                            </button>
                                            <div class="font-mono text-gray-600">
                                                {{ printParsedData(item, attach.name) }}
                                            </div>
                                        </div>
                                    </template>

                                </td>
                                <td>
                                    <div v-for="res in item.results" class="text-xs">{{ res.supplierTitle }}</div>
                                </td>
                                <td>
                                    <div v-for="res in item.results">
                                        <select v-model="res.addr_id" class="" @change="updateItem(item)"
                                            :disabled="!res.supply_id">
                                            <option v-for="addr in $depts" :value="addr.id">{{ addr.title }}</option>
                                        </select>
                                    </div>
                                </td>
                                <td>
                                    <router-link class="btn-link" :to="`/store/supply/${res.supply_id}`" :key="index"
                                        v-for="res, index in item.results">
                                        {{ res.supply_id }}
                                    </router-link>
                                </td>

                            </tr>
                        </tbody>
                    </table>
                </div>

                <div v-if="error" class="text-red-500 font-semibold py-2">{{ error }}</div>
                <loading v-if="busy" />

            </div>
        </template>
    </page>
</template>

<script>
import api from "../../api/api";

export default {
    name: "supply",
    data() {
        return {
            items: [],
            currentPage: 1,
            total: 0,
            searchDate: "",
            busy: false,
            error: "",
            suppliers: [],
            filter: {
                page: 1,
                limit: 10,
                term: "",
                date: ""
            },
        };
    },
    mounted() {
        this.getItems();
    },
    methods: {
        // get supply list
        async getItems() {
            this.busy = true;
            let query = encodeURIComponent(JSON.stringify(this.filter));
            let { success, msg, data } = await api.get(`/emails?filter=${query}`);
            if (success) {
                this.items = data.items;
                this.total = data.total;
            }

            this.busy = false;
        },
        // update email details
        async updateItem(item) {
            this.busy = true;
            let { success, msg, data } = await api.post(`/emails/${item.id}`, item);
            if (success) {
                item = data.item;
            } else {
                alert(msg);
            }
            this.busy = false;
        },
        async downloadAttachment(item, fileName) {

            let { success, msg, data } = await api.get(`/emails/${item.id}/xml?fileName=${fileName}`, { responseType: "blob" });

            if (success) {
                var blob = new Blob([data], { type: "text/xml" });
                var link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = fileName;
                link.click();
            } else {
                this.errorXML = msg;
            }

        },
        printParsedData(item, filename) {

            if (item.results && typeof item.results == "object") {
                let result = item.results.find(el => el.fileName == filename);
                if (result) {
                    let parsedData = result.parsedData;
                    let fields = ['ttnDate', 'ttnNumber', 'note', 'salonName', 'salonID', 'salonID']
                    return fields.map(field => `${field}: ${parsedData ? parsedData[field] : ''}`).join('; ');
                }
            } else {
                return item.results;
            }
        }
    },
    computed: {

    }
};
</script>
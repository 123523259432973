<template>
  <modal :styles="''">
    <h3 class="text-black font-bold text-xl mb-6">
      Заказ оплачен успешно
    </h3>
  </modal>
</template>

<script>
import Modal from "../modals/Modal.vue";
import ModalMixin from "../modals/modal.mixin";
import api from "../../api/api.js";

export default {
  components: { Modal },
  mixins: [ModalMixin],
  data() {
    return {};
  },
  mounted() {},
  methods: {}
};
</script>

<template>
  <modal :styles="'max-w-xl'">
    <div>
      <h3 class="text-black font-bold text-xl mb-6 text-center">
        <template v-if="!order.id">Новая заявка</template>
        <template v-else>Редактировать заявку</template>
      </h3>

      <div class="grid grid-cols-2 gap-6 justify-between text-sm mb-2 bg-gray-100 py-2 px-3 rounded-md">
        <div>
          <template v-if="order.id">
            <i class="fas fa-calendar"></i>
            {{ order.created_at | datetime }}
          </template>
        </div>
        <div>
          <template v-if="order.id && order.created_by">
            <i class="fas fa-date"></i>
            {{ order.created_by }}
          </template>
          <template v-else>
            <div>
              <i class="fas fa-robot"></i>
              Автоматическая
            </div>
          </template>
        </div>
        <div>
          <button class="btn-link" @click="downloadXML()">
            <i class="fas fa-download mr-1"></i>
            Скачать XML
          </button>
        </div>
        <div>
          {{ order.status }}
          <button v-if="order.status == 'new'" @click="sendToSupplier()" class="btn">
            Отправить поставщику
          </button>
          <span v-if="order.sent_at" class="text-xs text-gray-500">Отправлено {{ order.sent_at | datetime }}</span>
        </div>
      </div>

      <form class="" @submit.prevent="saveOrder()">
        <div class="grid grid-cols-6 gap-6">
          <div class="col-span-6">
            <div class="">
              <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
                Поставщик
              </label>
              <select class="w-full" v-model="order.supplier_id">
                <option :value="s.id" v-for="s in suppliers">{{ s.name }}</option>
              </select>
            </div>
          </div>
        </div>

        <label class="block mb-1 mt-4 text-sm text-gray-600 font-medium" for="cus_name">
          Позиции в заказе
        </label>

        <div>
          <div class=" mb-2 relative lg:w-full" v-for="(item, index) in order.items">
            <div class="flex flex-row space-x-4">
              <input type="text" placeholder="Название" v-model="item.barcode" class="w-4/5" />
              <input type="text" placeholder="Количество" v-model="item.qty" class="w-1/5" />
              <button type="button" @click="order.items.splice(index, 1)" class="absolute -right-5 top-3 text-red-500 text-sm">
                <i class="fas fa-times"></i>
              </button>
            </div>
            <div class="px-4 text-xs text-gray-400 mt-0.5">{{ item.name }}</div>
          </div>
          <button type="button" class="text-xs text-blue-500 border-b mt-2 mb-4" @click="order.items.push({})">
            + Добавить
          </button>
        </div>

        <div class="col-span-6">
          <div class="">
            <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
              Комментарий к заказу
            </label>
            <textarea class="w-full" type="text" v-model="order.comment"></textarea>
          </div>
        </div>

        <div class="">
          <div class="">
            <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
              Заметка
            </label>
            <textarea class="w-full" type="text" v-model="order.note"></textarea>
          </div>
        </div>

        <div class="flex flex-col mt-6">
          <button type="submit" :class="{ busy: busy }" :disabled="busy" class="btn-primary-sm">
            Сохранить
          </button>
        </div>

        {{ status }}
      </form>
    </div>
  </modal>
</template>

<script>
import Modal from "../../modals/Modal.vue";
import ModalMixin from "../../modals/modal.mixin";
import api from "../../../api/api.js";

export default {
  components: { Modal },
  mixins: [ModalMixin],
  props: ["id"],
  data() {
    return {
      busy: false,
      suppliers: [],
      status: "",
      order: {
        items: [],
        supplier_id: "",
        note: "",
        comment: ""
      }
    };
  },
  mounted() {
    // get

    if (this.id) {
      this.getItem();
    } else {
      this.getSuppliers();
    }
  },
  methods: {
    async getItem() {
      // load suppliers list
      await this.getSuppliers();
      this.busy = true;
      let { success, msg, data } = await api.get(`/external-orders/${this.id}`);
      this.order = data;
      this.busy = false;
    },
    async getSuppliers() {
      let { success, msg, data } = await api.get(`/g/suppliers`);
      this.suppliers = data.suppliers;
    },
    async saveOrder() {
      this.busy = true;

      let { success, msg, data } = await api.post("/external-orders", this.order);

      this.busy = false;

      if (success) {
        this.status = "save-success";
        this.close();
        this.$events.$emit("reload");
      } else {
        this.status = msg;
      }
    },
    async sendToSupplier() {
      let { success, msg, data } = await api.post(`/external-orders/${this.order.id}/send`);
      if (success) {
        this.order = data;
      }
    }
  }
};
</script>

<template>
  <div v-if="record">
    <div v-for="field in fields">
      <label class="text-sm font-semibold">
        {{ $t(field) }}
      </label>

      <tags :obj="record.medic[field]" :field="field" :hide-title="true" />
    </div>

    <div class="mt-4 mb-2 text-sm font-semibold">Комментарий</div>
    <div>
      <textarea v-model="record.medic.comment" class="w-full" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["record"],
  name: "medic-details",
  data() {
    return {
      fields: ["pastDiseases", "chronic", "eyeOperations", "allergic"]
    };
  },
  mounted() {
    
  },
  methods: {}
};
</script>

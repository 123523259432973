<template>
  <div
    @click="showUser()"
    v-if="host"
    class="cursor-pointer relative w-full flex-row items-center text-xs lg:text-sm text-blue-700 font-semibold inline-flex overflow-hidden"
  >
    <img
      class="h-4 w-4 rounded-full object-cover absolute"
      v-if="host && host.picture"
      :src="$pic(host.picture)"
      alt=""
    />
    <div v-else class="w-4 h-4 rounded-full bg-gray-300 absolute">

    </div>
    <div class="overflow-hidden overflow-ellipsis text-sm pl-6 whitespace-nowrap">{{ host.shortName }}</div>
  </div>
</template>

<script>
import EditUserModal from "../users/EditUserModal.vue";
export default {
  props: ["id"],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    showUser() {
      // only if user is admin
      if (this.$requireRole("manager")) {
        this.$modals.open(EditUserModal, { id: this.id });
      }
    }
  },
  computed: {
    host() {
      return this.$hosts.find(item => item.id == this.id);
    }
  }
};
</script>

<template>
  <button type="button" @click="goBack()" class="back-btn">
    <i class="fas fa-angle-left"></i>
    <slot />
  </button>
</template>

<script>
export default {
  props: ["styles"],
  name: "btn-link",
  data() {
    return {};
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>

import api from "../api/api";
const resource = "/g/products";

export default {
  get(id) {
    if (id) return api.call(`${resource}/${id}`, { method: "get" });
    else return api.call(`${resource}`);
  },
  query(filter) {
    let query = encodeURIComponent(JSON.stringify(filter));
    return api.call(`${resource}?filter=${query}`, { method: "get" });
  },
  search(term) {
    if (term) return api.call(`${resource}/search/${term}`, { method: "get" });
  },
  filter(filter) {
    return api.call(`${resource}/filter`, { method: "post", data: filter });
  },
  forFilters(filter) {
    return api.call(`${resource}/for-filters`, { method: "get" });
  },
  getByid(id) {
    return api.call(`${resource}/${id}`, { method: "get" });
  },
  update(payload) {
    return api.call(`${resource}`, { method: "post", data: payload });
  },
  delete(id) {
    return api.call(`${resource}/${id}`, { method: "delete" });
  },
  bulkUpdate(ids, payload) {
    return api.post(`${resource}/bulk`, { ids: ids, edit: payload })
  },
  async scanBarcode(barcode) {
    return await api.get(`${resource}/barcode/${barcode}`);
  },
};

<template>
  <page>
    <template v-slot:header>
      <back-btn></back-btn>
      Инвентаризация #{{ inventory.number }} от {{ inventory.created_at | datetime }}
    </template>
    <template v-slot:actions>
      <div>

      </div>
    </template>

    <!-- Filters, collapsable -->
    <template v-slot:content>


      <div v-if="inventory.status == 'error'" class="alert alert-info mb-4">
        В инвентаризации есть расхождения с текущими остатками на складе. Чтобы обновить информацию на складе нажмите
        Скорректировать остатки. И тогда количество товара на складе пересчитается и станет равным количеству при
        проведении
        инвентаризации.
      </div>

      <div class="flex flex-row gap-x-4">
        <div class="w-1/5">

          <div class="flex flex-col space-y-4  rounded-md bg-white px-4 py-4">

            <div>
              <span class="form-label"> Статус </span>

              <div class="flex flex-row space-x-4">
                <div>
                  <span v-if="inventory.status == 'success'" class="badge green">Отличий нет</span>
                  <span v-if="inventory.status == 'corrected'" class="badge green">Скорректировано</span>
                  <span v-if="inventory.status == 'error'" class="badge red">Требуется корректировка</span>
                  <span v-if="inventory.status == 'draft'" class="badge gray">Черновик</span>
                  <span v-if="inventory.status == ''" class="badge blue">Новое</span>
                </div>
                <div>
                  <button type="button" @click="saveInventory()" :class="{ busy: busy }" :disabled="busy"
                    class="btn-default" v-if="inventory.status == 'draft'" title="Сохранить черновик">
                    <i class="fas fa-save"></i>
                  </button>
                </div>
              </div>

            </div>

            <div>
              <span class="form-label"> Категория </span>
              <span class="font-medium text-sm">{{ inventory.category }}</span>
            </div>

            <template v-if="inventory.status == 'corrected'">
              <div>
                <span class="form-label">Дата корректировки</span>
                <span class="font-semibold text-sm">
                  {{ inventory.corrected_at | datetime }}
                </span>
              </div>

              <div>
                <span class="form-label">Автор корректировки</span>
                <user-card :id="inventory.corrected_by" />
              </div>
            </template>

            <div class="">
              <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
                Заметка
              </label>
              <textarea class="w-full" type="text" v-model="inventory.note"></textarea>
            </div>

            <!-- actions -->
            <template v-if="inventory.status == 'draft'">
              <button type="button" @click="doProcessing()" :class="{ busy: busyProcessing }"
                :disabled="busyProcessing || sortedCart.length == 0" class="btn-primary-sm">
                Провести
              </button>
              <span class="info-sm">После нажатия, система сравнит количество отсканированных товаров с текущими
                остатками. Если будут расхождения, вы сможете это проверить.</span>
            </template>

            <template v-if="$requireRole('manager') && inventory.processed && inventory.status == 'error'">
              <button type="button" @click="doCorrection()" :class="{ busy: busyCorrection }" :disabled="busyCorrection"
                class="btn-primary-sm">
                Скорректировать остатки
              </button>
              <span class="info-sm">После нажатия, все остатки будут скорректированы и количество товара изменится на
                то, которое
                указано в столбце отсканировано.</span>
            </template>
          </div>

          <button type="button" @click="deleteDraft()" class="text-red-500 text-center text-xs mt-4"
            v-if="inventory.status == 'draft' || inventory.status == ''">
            Удалить черновик
          </button>

          <div class="w-full mt-4 opacity-50" v-if="inventory.log && inventory.log.length > 0">
            <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
              История
            </label>
            <div class="text-gray-500 text-xs whitespace-normal">
              <div v-for="log in inventory.log">{{ log }}</div>
            </div>
          </div>

          <!-- let admin delete processed inventory -->
          <button type="button" @click="deleteDraft()" class="text-red-500 text-center text-xs mt-4"
            v-if="inventory.status == 'error' && inventory.processed && $requireRole('manager')">
            Удалить инвентаризацию
          </button>

        </div>
        <div class="w-4/5">

          <div class="mb-4 flex flex-col lg:flex-row lg:items-center" v-if="inventory.status == 'draft'">
            <div class="text-sm font-semibold mr-2 mb-2 lg:mb-0">
              Просканируйте товар:
            </div>
            <div>
              <scanner-demo @scan="addProductFromBarcode" :global="true" />
            </div>
          </div>

          <table class="table-condensed data-table">
            <thead>
              <tr>
                <th>Название</th>
                <th>ШК</th>
                <th>Отсканировано</th>
                <th v-if="inventory.processed">Числится на складе</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(cartItem, cartItemIndex) in sortedCart" class="highlight-row"
                :class="{ highlighted: cartItemIndex == 0 && highlightIndex }">
                <td class="">
                  <div class="flex flex-row table-condensed">
                    <button class="text-red-500 mr-2" @click="removeItem(cartItem.barcode)" v-if="!inventory.processed">
                      <i class="fas fa-times text-red"></i>
                    </button>

                    <template v-if="inventory.status == 'error'">
                      <span v-if="cartItem.db_stock == cartItem.qty" class="mr-1">✔️</span>
                      <span v-else class="mr-1">⚠️</span>
                    </template>

                    <span class="font-semibold">{{ cartItem.name }}</span>
                    <span v-if="cartItem.notScanned" class="text-xs rounded-md border ml-2 px-1 bg-gray-100">не
                      отсканировано</span>
                  </div>

                  <div v-if="cartItem.lots && cartItem.lots.length > 0"
                    class="flex flex-row flex-wrap py-1 opacity-60 hover:opacity-100 transition-opacity">
                    <div v-for="(lot, index) in cartItem.lots"
                      class="rounded-md border px-2 py-0.5 text-sm flex flex-row mb-0.5 mr-0.5 items-center">
                      <button class="text-yellow-500 text-xs mr-1" @click="removeLot(cartItem, cartItemIndex, index)">
                        <i class="fas fa-times text-red"></i>
                      </button>
                      <div class="text-xs font-semibold font-mono">{{ lot.lot }}</div>
                      <div class="text-xs bg-gray-200 rounded-md px-1 py-0.5 ml-1 font-mono">
                        {{ lot.date }}
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <span class="font-mono">
                    <i class="fas fa-barcode mr-1"></i>
                    {{ cartItem.barcode }}
                  </span>
                  <span v-if="cartItem.lots && cartItem.lots.length > 0">({{ cartItem.lots.length }})</span>
                </td>
                <td class="text-right">
                  <span :class="{
                    'bg-green-500 text-white border-green-600': cartItem.db_stock == cartItem.qty
                  }" class="px-2 py-0 rounded-md font-mono border text-black font-medium">
                    {{ cartItem.qty }}
                  </span>
                </td>
                <td v-if="inventory.processed" class="text-right">
                  <span v-if="!cartItem.corrected" :class="{
                    'bg-red-500 text-white border-red-700': cartItem.db_stock != cartItem.qty,
                    'bg-green-500 text-white border-green-600': cartItem.db_stock == cartItem.qty
                  }" class="px-2 py-0 rounded-md font-mono border font-medium">
                    {{ cartItem.db_stock }}
                  </span>
                  <span v-else>
                    <s class="text-gray-400 px-2 py-0 rounded-md font-mono border">{{ cartItem.db_stock }}</s>
                    <i class="fas fa-angle-right mx-2 text-xs"></i>
                    <span
                      class="bg-green-500 text-white font-medium px-2 py-0 rounded-md font-mono border border-green-600">{{
                        cartItem.qty }}</span>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="py-4" v-if="debug">
        {{ cart }}
      </div>
    </template>
  </page>
</template>

<script>
import api from "../../../api/api.js";
import Product from "../../../repo/ProductRepo.js";
import dayjs from "dayjs";
import ScannerGlobal from "./ScannerGlobal.vue";
import ScannerDemo from "../../shared/ScannerDemo.vue";

export default {
  name: "add-inventory",
  components: { ScannerGlobal, ScannerDemo },
  props: ["id"],
  data() {
    return {
      debug: false,
      busy: false,
      busyProcessing: false,
      busyCorrection: false,
      highlightIndex: false,
      note: "",
      cart: [],
      readOnly: false,
      inventory: {},
      step: 0,
      stockProducts: [],
      key: 0
    };
  },
  mounted() {
    this.getItem();
  },
  methods: {
    // get all products that has qty > 0
    async getStockProducts() {

      let filter = { qty: 1, limit: -1 }
      if (this.inventory.category != 'all') {
        filter.category = this.inventory.category;
      }

      let query = encodeURIComponent(
        JSON.stringify(filter)
      );

      let { success, msg, data } = await api.get(`/products/stock?filter=${query}`);
      if (success) {
        this.stockProducts = data.items;
      }

    },
    async doCorrection() {
      if (!confirm("Вы уверены, что хотите скорректировать остатки?")) {
        return;
      }

      this.busyCorrection = true;
      let { success, msg, data } = await api.post(`/inventory/${this.inventory.id}/correct`, this.inventory);
      this.busyCorrection = false;

      if (success) {
        this.$swal({
          icon: "success",
          title: "Остатки скорректированы",
          showConfirmButton: false,
          timer: 1000
        });

        this.getItem();
      } else {
        this.$swal({
          icon: "error",
          title: "Что-то пошло не так",
          text: msg,
          showConfirmButton: false
        });
      }
    },
    switchFocus() {
      document.getElementsByName("barcode")[0].focus();
    },
    // add product by barcode
    async addProductFromBarcode(product) {
      let vm = this;
      // check category
      if (this.inventory.category && this.inventory.category != "all") {
        if (product.category != this.inventory.category) {
          vm.failsound();
          alert(
            "Товар не соответствует категории. Вы должны сканировать только товары с категорией " + this.inventory.category
          );
          return;
        }
      }

      if (product.id) {
        this.addToInventory(product);
      }
    },
    removeItem(barcode) {
      let index = this.cart.findIndex(el => el.barcode == barcode);
      this.cart.splice(index, 1);
      this.saveInventory();
    },
    removeLot(item, cartItemIndex, index) {
      item.lots.splice(index, 1);
      --item.qty;
      if (item.qty == 0) {
        this.cart.splice(cartItemIndex, 1);
      }
    },
    async addToInventory(product) {
      let vm = this;
      let newCartItem = {
        product_id: product.id,
        name: product.name,
        attr: product.attr,
        price: product.price,
        qty: 0,
        barcode: product.barcode,
        available: product.quantity,
        lots: [],
        global: product.global
      };

      // check if we have it in cart already
      let foundIndex = this.cart.findIndex(el => el.product_id == newCartItem.product_id);

      if (foundIndex == -1) {
        this.cart.push(newCartItem);
        foundIndex = this.cart.length - 1;
      }

      let cartItem = this.cart[foundIndex];

      if (!cartItem.lots) {
        cartItem.lots = [];
      }

      /**
       * If addable product has lot, need to check is it already exists in this cart item
       * if not, add lot to the same cart item
       * if yes, show the error lot_already_added
       *
       */
      if (product.lot) {
        // add lot
        cartItem.lots.push(product.lot);
        // update cartitem with lots qty
        cartItem.qty = cartItem.lots.length;
      } else {
        // increment cart item quantity
        cartItem.qty++;
      }

      this.highlightIndex = true;

      // resort cart
      cartItem.lastModified = Date.now();
      this.key++;

      this.saveInventory();

    },
    async getItem() {
      // get inventory data
      let { success, msg, data } = await api.get(`/inventory/${this.id}`);

      if (success) {
        this.inventory = data.item;
        this.cart = data.item.cart;
      }

      this.getStockProducts()
    },
    async saveInventory() {
      this.inventory.cart = this.cart;

      this.busy = true;
      let { success, msg, data } = await api.put(`/inventory/${this.inventory.id}`, this.inventory);
      setTimeout(() => {
        this.busy = false;
      }, 500);
    },
    async doProcessing() {
      this.busyProcessing = true;
      this.highlightIndex = false;
      let { success, msg, data } = await api.get(`/inventory/${this.inventory.id}/sync`);
      if (success) {
        this.busyProcessing = false;
        this.$swal({
          icon: "success",
          title: "Инвентаризация проведена, проверьте результаты и нажмите скорректировать остатки если необходимо",
          showConfirmButton: false,
          timer: 1000
        });
      }
      this.getItem();
    },
    async deleteDraft() {
      if (!confirm("Вы уверены, что хотите удалить?")) {
        return;
      }

      let { success, msg, data } = await api.delete(`/inventory/${this.inventory.id}`);
      if (success) {
        this.$router.push({ name: "inventory" });
      }
    },
    failsound() {
      let audio = new Audio("/sounds/error.wav");
      audio.play();
    },
    oksound() {
      let audio = new Audio("/sounds/success.wav");
      audio.play();
    },
    peaksound() {
      let audio = new Audio("/sounds/peak.wav");
      audio.play();
    }
  },
  computed: {
    sortedCart() {
      this.key;
      // sort cart when last scanned at the top
      return [...this.cart].sort((a, b) => {
        if (!a.lastModified) {
          return 1;
        }
        if (!b.lastModified) {
          return -1;
        }

        return b.lastModified - a.lastModified;
      });
    }
  }
};
</script>

class Sockets {

    constructor(userId, token, eventBus, subdomain) {

        this.ws = null
        // The WebSocket URL to connect to
        this.wsRoot = process.env.VUE_APP_MODE === 'dev' ? process.env.VUE_APP_SOCKET_BACKEND_URL : `wss://${subdomain}.api.mnkl.ru/api/v1/sockets/ws`;
        this.userId = userId;
        this.token = token;
        // Boolean to track connection status
        this.isConnected = false
        // Handle automatic reconnection
        this.reconnectTimeout = null
        // Reconnection interval in milliseconds
        this.reconnectInterval = 5000

        this.connectToWebSocket();

        this.eventBus = eventBus;

    }

    connectToWebSocket() {
        // Close existing WebSocket connection if any
        if (this.ws) {
            this.ws.close();
        }

        let url = `${this.wsRoot}/${this.userId}?token=${this.token}`
        console.log(url)
        this.ws = new WebSocket(url);

        // Handle the open event
        this.ws.onopen = () => {
            console.log('WebSocket connected');
            this.isConnected = true;
            // Do any additional setup here
            this.eventBus.$emit('system', { type: 'connected' });
        };

        // Handle messages from the server
        this.ws.onmessage = (message) => {

            try {
                let msg = JSON.parse(message.data)

                if (msg.type) {
                    this.eventBus.$emit('message', msg);
                }
            } catch (e) {
                console.log(e);
            }
        };

        // Handle error event
        this.ws.onerror = (error) => {
            console.error('WebSocket error', error);
            this.isConnected = false;
        };

        // Handle close event
        this.ws.onclose = (event) => {
            console.log('WebSocket disconnected', event);
            this.isConnected = false;
            if (!event.wasClean) {
                // Attempt to reconnect if the connection was not closed cleanly
                this.reconnectToWebSocket();
            }
            this.eventBus.$emit('system', { type: 'disconnected' });
        };
    }
    closeWebSocket() {
        // Clear the reconnection timeout if it exists
        if (this.reconnectTimeout) {
            clearTimeout(this.reconnectTimeout);
        }
        // Close the WebSocket connection if it exists
        if (this.ws) {
            this.ws.close();
        }
    }
    reconnectToWebSocket() {
        // Try to reconnect after a specified amount of time
        this.reconnectTimeout = setTimeout(() => {
            console.log('Attempting to reconnect WebSocket...');
            this.eventBus.$emit('system', { type: 'connecting' });
            this.connectToWebSocket();
        }, this.reconnectInterval);
    }
    send(message) {
        // Send a message if the WebSocket connection is open
        console.log(`Sending message: ${JSON.stringify(message)}`);
        if (this.ws && this.isConnected) {
            this.ws.send(JSON.stringify(message));
        } else {
            console.error('WebSocket is not connected.');
        }
    }
}

export default Sockets;

<template>
  <div v-if="total > 0" class="mt-4 mb-4 flex justify-between">
    <div>
      <template v-for="page of generatePageRange()">
        <button v-if="page != '...'" class="rounded-md w-8 h-8 mr-1 text-sm bg-white" @click.prevent="setPage(page)"
          :key="page" :class="{ 'bg-blue-600 text-white': page == currentPage }">
          {{ page }}
        </button>

        <span v-if="page == '...'" class="mr-1">
          ...
        </span>
      </template>
    </div>
    <select @change="setLimit($event)" class="py-2" v-model="pageLimit">
      <option value="5">5</option>
      <option value="10">10</option>
      <option value="30">30</option>
      <option value="50">50</option>
      <option value="100">100</option>
    </select>
  </div>
</template>

<script>
export default {
  /**
   * name: name of the pagination, store settings in storage about limits
   */
  props: ["total", "limit", "items", "activePage", 'name'],
  data() {
    return {
      currentPage: 1,
      pageLimit: 10
    };
  },
  mounted() {
    // get page limit from the store
    let limit = localStorage.getItem(this.name + '-limit');

    if (limit && limit > 0) {
      this.pageLimit = parseInt(limit);
    } else {
      this.pageLimit = this.limit;
    }

    this.$emit("limit", limit);
    if (this.activePage) {
      this.currentPage = this.activePage;
    }
  },
  // watch for activePage changes
  watch: {
    activePage(val) {
      this.currentPage = val;
    }
  },

  methods: {
    setLimit(event) {
      // save limit to storage
      localStorage.setItem(this.name + '-limit', event.target.value);
      this.$emit("limit", event.target.value);
      this.setPage(1);
    },
    setPage(page) {
      this.currentPage = parseInt(page);
      this.$emit("page", this.currentPage);
    },
    generatePageRange(delta = 4) {
      // creates array with base 1 index
      let currentPage = parseInt(this.currentPage);
      let lastPage = Math.ceil(this.total / this.pageLimit);


      const range = Array(lastPage)
        .fill()
        .map((_, index) => index + 1);

      return range.reduce((pages, page) => {
        // allow adding of first and last pages
        if (page === 1 || page === lastPage) {
          return [...pages, page];
        }

        // if within delta range add page
        if (page - delta <= currentPage && page + delta >= currentPage) {
          return [...pages, page];
        }

        // otherwise add 'gap if gap was not the last item added.
        if (pages[pages.length - 1] !== "...") {
          return [...pages, "..."];
        }

        return pages;
      }, []);
    }
  }
};
</script>

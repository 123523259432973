<template>
  <page>
    <template v-slot:header>
      Заявки поставщикам
    </template>
    <template v-slot:actions>
      <router-link to="/store/external/new" class="btn-action-xs ml-2">
        <i class="fas fa-plus mr-1"></i>
        Добавить заявку
      </router-link>

      <button @click="generateOrders()" class="btn-action-xs ml-2" :class="{ 'busy': generateBusy }"
        :disabled="generateBusy">
        Сгенерировать заявки
      </button>
    </template>

    <!-- Filters, collapsable -->
    <template v-slot:content>
      <div class="filters mb-4 flex flex-row items-center gap-4 bg-white px-4 py-2 rounded-md">
        <div class="mr-8 text-sm whitespace-nowrap">
          Всего:
          <span class="font-semibold">{{ total }}</span>
        </div>

        <div>
          <input type="text" placeholder="Поиск по ШК" v-model="filter.barcode" />
        </div>
        <div>
          <button class="px-4 py-2 bg-blue-500 text-white rounded-md" @click="getItems()">
            Искать
          </button>
        </div>
      </div>

      <pagination :total="total" :limit="filter.limit" :items="items" :activePage="filter.page"
        @page="page => ((filter.page = page), getItems())"
        @limit="limit => ((filter.limit = limit, filter.page = 1), getItems())" />

      <div class="w-full overflow-x-auto text-left bg-white rounded-md">
        <table class="data-table">
          <thead>
            <tr class="font-semibold text-xs  text-gray-600">
              <th class="px-4 py-2">№</th>
              <th class="px-4 py-2">Статус</th>
              <th class="px-4 py-2">Дата</th>
              <th class="px-4 py-2">Тип</th>
              <th class="px-4 py-2">Поставщик</th>
              <th class="px-4 py-2">Товаров</th>
            </tr>
          </thead>
          <tbody class="text-sm">
            <tr v-for="item in items" :key="item.id">
              <td>
                <router-link :to="`/store/external/${item.id}`" class="btn-sec">#{{ item.number | pad }}</router-link>
              </td>
              <td>
                <template v-if="item.status == 'draft'">
                  <span class="badge gray">Черновик</span>
                </template>
                <template v-if="item.status == 'sent'">
                  <span class="badge blue">Отправлен</span>
                </template>
                <template v-if="item.status == 'completed'">
                  <span class="badge green">Завершен</span>
                </template>
              </td>
              <td>
                {{ item.created_at | datetime }}
              </td>
              <td>
                <span v-if="!item.auto">Ручная</span>
                <span v-if="item.auto">Автоматическая</span>
              </td>
              <td>{{ $getSupplier(item.supplier_id).name }}</td>
              <td>{{ item.items.length }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="error" class="text-red-500 font-semibold py-2">{{ error }}</div>
      <loading v-if="busy" />
    </template>
  </page>
</template>

<script>
import { mapGetters } from "vuex";
import Page from "../../Page.vue";
import Info from "../../shared/Info.vue";
import Loading from "../../Loading.vue";
import ExternalModal from "./ExternalModal";

import api from "../../../api/api.js";

export default {
  components: { Page, Info, Loading },
  name: "external",
  data() {
    return {
      items: [],
      currentPage: 1,
      total: 0,
      busy: false,
      error: "",
      generateBusy: false,
      filter: {
        page: 1,
        limit: 10,
        term: "",
        date: "",
        barcode: ''
      },
    };
  },
  mounted() {
    this.getItems();
    let vm = this;
    this.$events.$on("reload", function (updated) {
      vm.getItems();
    });
  },
  methods: {
    // get external orders list
    async getItems() {
      this.busy = true;
      let query = encodeURIComponent(JSON.stringify(this.filter));
      let { success, msg, data } = await api.get(`/external-orders?filter=${query}`);
      if (success) {
        this.items = data.items;
        this.total = data.total;
      }

      this.busy = false;
    },
    addExternal() {
      this.$modals.open(ExternalModal);
    },
    edit(id) {
      this.$modals.open(ExternalModal, { id: id });
    },
    async generateOrders() {
      this.generateBusy = true;

      let { success, msg, data } = await api.post(`/external-orders/generate`);
      if (success) {
        let msg = `Создано: ${data.created}, Обновлено: ${data.draftsUpdated}`;
        if (data.skipped > 0) {
          msg += `<br/><br/>Пропущено товаров ${data.skipped} так как есть заказы, которые в процессе поставки`
        }
        this.getItems();
        this.$swal({
          icon: "success",
          title: "Заказы сгенерированы",
          html: msg,
        })

      }

      this.generateBusy = false;
    }
  },
  computed: {
    //...mapGetters("products", ["products"])
  }
};
</script>

<template>
  <div class="leading-loose">
    <div class="">
      <label class="block mb-1 text-base text-gray-500" for="cus_name">Имя для входа</label>
      <span class="font-medium">{{ user.email }}</span>
    </div>

    <btn-link v-on:click="editPassword = true" v-if="!editPassword">
      Изменить пароль
    </btn-link>

    <div class="mt-4" v-if="editPassword">
      <div class="">
        <label class="block mb-1 text-base text-gray-500" for="cus_name">Новый пароль</label>
        <input
          class="w-full px-5 py-2 text-black bg-gray-100 focus:bg-gray-200 rounded-md font-medium"
          type="password"
          required=""
          v-model="password"
          placeholder=""
        />
        <small class="block text-xs text-gray-400">Как минимум 5 символов</small>
      </div>

      <button
        type="button"
        :class="{ busy: busy }"
        :disabled="busy || isPwdDisabled"
        @click="changePassword()"
        class="py-1 px-2 mt-4 text-sm bg-green-500 text-white rounded-md font-medium disabled:opacity-50"
      >
        Изменить пароль
      </button>

      <result v-if="!busy && result.status == 'success'">Пароль обновлен.</result>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import User from "../../repo/UserRepo";
import BtnLink from "../shared/BtnLink.vue";
import Result from "../shared/Result.vue";

export default {
  components: { Result, BtnLink },
  props: ["user"],
  data() {
    return {
      editPassword: false,
      password: "",
      busy: false,
      result: {}
    };
  },
  mounted() {},
  methods: {
    async changePassword() {
      this.busy = true;

      let resp = await User.update({
        id: this.user.id,
        password: this.password
      });

      this.result = resp.data;
      this.busy = false;
      this.password = "";
    }
  },
  computed: {
    isPwdDisabled() {
      return this.password.length < 5;
    }
  }
};
</script>

import api from "../api/api";
const resource = "/g/lensmodels";

export default {
  get(id) {
    if (id) return api.call(`${resource}/${id}`, { method: "get" });
    else return api.call(`${resource}`);
  },
  getGlobal() {
    return api.call(`${resource}?all`);
  },
  search(term) {
    if (term) return api.call(`${resource}/search/${term}`, { method: "get" });
  },
  getByid(id) {
    return api.call(`${resource}/${id}`, { method: "get" });
  },
  update(payload) {
    return api.call(`${resource}`, { method: "post", data: payload });
  },
  delete(id) {
    return api.call(`${resource}/${id}`, { method: "delete" });
  }
};

<template>
  <modal :styles="'max-w-lg'">
    <h3 class="text-black font-bold text-xl mb-6">
      Подключить Телеграм бота
    </h3>

    <div class="flex flex-col">
      <div>
        <p>
          Чтобы получать уведомления о новых событиях, присоединитесь к нашему телеграм-боту.
        </p>
        <p>
          Затем в телеграме нажмите на кнопку "START"
        </p>
      </div>
      <div class="mt-4"></div>

      <div class="flex flex-row">
        <div class="w-1/2 border-r border-gray-200 px-2  text-center">
          <p class="text-sm mb-4">
            Просто нажмите на кнопку ниже, и телеграм автоматически откроется в новом окне, перейдите в телеграм
          </p>
          <a
            :href="getTelegramLink()"
            @click="startCheckingConnection()"
            target="_blank"
            class="btn-primary"
            :class="{ busy: waitForResponse }"
            :disabled="token == false || busy"
          >
            <template v-if="!waitForResponse">Подключить</template>
            <template v-if="waitForResponse">Подключение...</template>
          </a>
        </div>
        <div class="w-1/2 px-2">
          <p class="text-sm text-center">Или отсканируйте QR-код камерой телефона.</p>
          <div class="text-center">
            <img class="w-64 m-auto" :src="qrcode" v-if="qrcode" />
          </div>
        </div>
      </div>

      <div v-if="waitForResponse" class="text-sm text-green-500 mt-4 font-medium">
        <p>
          Ожидаем ответа от бота в телеграме, если вы выполнили все действия, окно закроется автоматически.
        </p>
        Если не получилось, попробуйте еще раз перейти по ссылке:
        <a :href="getTelegramLink()" class="text-blue-600 border-b border-gray-200">
          Перейти в телеграм
        </a>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "../modals/Modal.vue";
import ModalMixin from "../modals/modal.mixin";
import api from "../../api/api.js";
var QRCode = require("qrcode");

export default {
  components: { Modal },
  mixins: [ModalMixin],
  props: ["user_id", "botname"],
  data() {
    return {
      busy: false,
      waitForResponse: false,
      token: "",
      interval: "",
      qrcode: ""
    };
  },
  mounted() {
    // if user not connected, generate once time token
    if (!this.$me.telegramChatId) {
      this.getConnectionToken();
    }
  },
  methods: {
    /**
     * Generate once time token to connect telegram bot
     * it needed to avoid a case when someone tries to connect bot for other user
     * on backend we test auth.user_id & token
     * don't wanna ask for user phone on backend
     */
    async getConnectionToken() {
      this.busy = true;
      let { success, msg, data } = await api.get("/telegram/token");
      if (success) {
        this.token = data.token;
        // generate qrcode
        let vm = this;
        QRCode.toDataURL(this.getTelegramLink(), function(err, url) {
          vm.qrcode = url;
        });
      }
      this.busy = false;
    },
    /**
     * periodically check status after user click on connect button
     */
    async startCheckingConnection() {
      this.waitForResponse = true;
      let self = this;
      this.interval = setInterval(() => {
        self.checkConnection();
      }, 2000);
    },
    /**
     * Call api to check user telegram connection
     */
    async checkConnection() {
      let { success, msg, data } = await api.get("/me");
      if (success) {
        if (data.tgChatId != "") {
          this.$me.tgChatId = data.tgChatId;
          this.$store.dispatch("app/setUserField", {
            key: "tgChatId",
            value: data.tgChatId
          });

          clearInterval(this.interval);
          this.waitForResponse = false;
          this.close();
        }
      }
    },
    /**
     * Generate telegram invite link with user_id and once time token
     * once time token generates each time when modal window opened
     */
    getTelegramLink() {
      return `https://t.me/${this.botname}?start=${this.$me.id}_${this.token}`;
    },
    onSuccess() {
      this.close();
    }
  }
};
</script>

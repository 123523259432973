import api from "../api/api";
const resource = "/inventory";

export default {
  async get(id) {
    let success, data, msg;

    if (id) {
      ({ success, data, msg } = await api.call(`${resource}/${id}`, { method: "get" }));
    } else {
      ({ success, data, msg } = await api.call(`${resource}`));
    }

    console.log(success);

    if (success) {
      return data;
    } else {
      throw new Error(msg);
    }
  },
  /**
   * Do sync from inventory and real stock
   *
   * @param {*} id
   * @returns
   */
  async sync(id) {
    let { success, data, msg } = await api.call(`${resource}/${id}/sync`, { method: "get" });
    if (success) {
      return data;
    } else {
      throw new Error(msg);
    }
  },
  async create(mode, category) {
    let { success, data, msg } = await api.call(`${resource}/create`, {
      method: "post",
      data: {
        mode: mode,
        category: category
      }
    });

    if (success) {
      return data;
    } else {
      throw new Error(msg);
    }
  },
  async add(id, product_id, lot) {
    let { success, data, msg } = await api.call(`${resource}/${id}/item/${product_id}`, {
      method: "put",
      data: {
        lot: lot,
        product_id: product_id
      }
    });

    if (success) {
      return data;
    } else {
      throw new Error(msg);
    }
  },
  async patch(id, payload) {
    let { success, data, msg } = await api.call(`${resource}/${id}`, {
      method: "patch",
      data: payload
    });
    if (success) {
      return data;
    } else {
      throw new Error(msg);
    }
  },
  async complete(id) {
    return await this.patch(id, { status: "completed" });
  },
  async delete(id) {
    return await api.delete(`${resource}/${id}`);
  },
  /**
   * Add product to products by scanned barcode
   */
  async scanProduct(barcode, lot, expire) {
    let { success, data, msg } = await api.call(
      `${resource}/scan/${barcode}?lot=${lot}&expire=${expire}`,
      {
        method: "get"
      }
    );

    if (success) {
      return data;
    } else {
      throw new Error(msg);
    }
  },

  /**
   * Get product by barcode
   */
  async getProduct(barcode) {
    let { success, data, msg } = await api.call(`${resource}/check/${barcode}`, {
      method: "get"
    });

    if (success) {
      return data;
    } else {
      throw new Error(msg);
    }
  },
  /**
   * Add heap of scanned items
   */
  async addScannedItems(items) {
    let { success, data, msg } = await api.call(`${resource}/add`, {
      method: "post",
      data: items
    });

    if (success) {
      return data;
    } else {
      throw new Error(msg);
    }
  }
};

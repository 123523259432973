import api from "../api/api";
const resource = "/users";

export default {
  get() {
    return api.call(`${resource}`);
  },
  getByid(id) {
    return api.call(`${resource}/${id}`, { method: "get" });
  },
  update(payload) {
    return api.call(`${resource}`, { method: "post", data: payload });
  },
  delete(id) {
    return api.call(`${resource}/${id}`, { method: "delete" });
  },
};
<template>
  <div>
    <div class="bg-white rounded-md">
      <div class="flex items-center mb-2 flex-row">
        <sm-toggle v-on:updated="obj.old0.isFieldVisible = $event" :default="obj.old0.isFieldVisible" />
        <div class="font-semibold text-sm ">{{ isNew ? "Новые" : "Старые" }} очки для дали</div>
      </div>

      <!-- list of items -->
      <div class="flex flex-row flex-wrap gap-x-1 gap-y-1 mt-2 mb-4 relative" v-if="obj.old0.isFieldVisible">
        <div
          class="select-tag text-sm rounded-md px-2 py-0.5 bg-green-500 text-white cursor-pointer hover:opacity-80"
          v-for="(g, index) in obj.old0.items"
          @click="addGlasses(`${isNew ? 'Новые' : 'Старые'} очки для дали`, obj.old0, g, index)"
        >
          Очки {{ index + 1 }}
        </div>
        <button
          class="text-black bg-gray-200  rounded-md text-sm px-2"
          @click="addGlasses(`${isNew ? 'Новые' : 'Старые'} очки для дали`, obj.old0)"
        >
          <i class="fa fa-plus"></i>
        </button>
      </div>
    </div>

    <!-- Near -->
    <div class="bg-white rounded-md">
      <div class="flex flex-row items-center mb-2">
        <sm-toggle v-on:updated="obj.old1.isFieldVisible = $event" :default="obj.old1.isFieldVisible" />
        <div class="font-semibold text-sm ">{{ isNew ? "Новые" : "Старые" }} очки для близи</div>
      </div>

      <!-- list of items -->
      <div class="flex flex-row flex-wrap gap-x-1 gap-y-1 mt-2 mb-4 relative" v-if="obj.old1.isFieldVisible">
        <div
          class="select-tag text-sm rounded-md px-2 py-0.5 bg-green-500 text-white cursor-pointer hover:opacity-80"
          v-for="(g, index) in obj.old1.items"
          @click="addGlasses(`${isNew ? 'Новые' : 'Старые'} очки для близи`, obj.old1, g, index)"
        >
          Очки {{ index + 1 }}
        </div>
        <button
          class="text-black bg-gray-200  rounded-md text-sm px-2"
          @click="addGlasses(`${isNew ? 'Новые' : 'Старые'} очки для близи`, obj.old1)"
        >
          <i class="fa fa-plus"></i>
        </button>
      </div>
    </div>

    <!--  Multifocal -->
    <div class="bg-white rounded-md">
      <div class="flex flex-row items-center mb-2">
        <sm-toggle v-on:updated="obj.old2.isFieldVisible = $event" :default="obj.old2.isFieldVisible" />
        <div class="font-semibold text-sm">{{ isNew ? "Новые" : "Старые" }} очки офисные</div>
      </div>
      <!-- list of items -->
      <div class="flex flex-row flex-wrap gap-x-1 gap-y-1 mt-2 mb-4 relative" v-if="obj.old2.isFieldVisible">
        <div
          class="select-tag text-sm rounded-md px-2 py-0.5 bg-green-500 text-white cursor-pointer hover:opacity-80"
          v-for="(g, index) in obj.old2.items"
          @click="addGlasses(`${isNew ? 'Новые' : 'Старые'} очки офисные`, obj.old2, g, index)"
        >
          Очки {{ index + 1 }}
        </div>
        <button
          class="text-black bg-gray-200  rounded-md text-sm px-2"
          @click="addGlasses(`${isNew ? 'Новые' : 'Старые'} очки офисные`, obj.old2)"
        >
          <i class="fa fa-plus"></i>
        </button>
      </div>
    </div>
    <!-- progressive -->
    <div class="bg-white rounded-md">
      <div class="flex flex-row items-center mb-2">
        <sm-toggle v-on:updated="obj.oldProgressive.isFieldVisible = $event" :default="obj.oldProgressive.isFieldVisible" />
        <div class="font-semibold text-sm">{{ isNew ? "Новые" : "Старые" }} очки прогрессивные</div>
      </div>

      <!-- list of items -->
      <div class="flex flex-row flex-wrap gap-x-1 gap-y-1 mt-2 mb-4 relative" v-if="obj.oldProgressive.isFieldVisible">
        <div
          class="select-tag text-sm rounded-md px-2 py-0.5 bg-green-500 text-white cursor-pointer hover:opacity-80"
          v-for="(g, index) in obj.oldProgressive.items"
          @click="addGlasses(`${isNew ? 'Новые' : 'Старые'} очки прогрессивные`, obj.oldProgressive, g, index)"
        >
          Очки {{ index + 1 }}
        </div>
        <button
          class="text-black bg-gray-200  rounded-md text-sm px-2"
          @click="addGlasses(`${isNew ? 'Новые' : 'Старые'} очки прогрессивные`, obj.oldProgressive)"
        >
          <i class="fa fa-plus"></i>
        </button>
      </div>
    </div>

    <!-- Lenses -->
    <div class="bg-white rounded-md">
      <div class="flex flex-row items-center mb-2">
        <sm-toggle v-on:updated="obj.old3.isFieldVisible = $event" :default="obj.old3.isFieldVisible" />
        <div class="font-semibold text-sm">{{ isNew ? "Новые" : "Старые" }} МКЛ</div>
      </div>

      <div class="mb-4" v-if="obj.old3.isFieldVisible">
        <!-- list of items -->
        <div class="flex flex-row flex-wrap gap-x-1 gap-y-1 mt-2 mb-4 relative" v-if="obj.old3.isFieldVisible">
          <div
            class="select-tag text-sm rounded-md px-2 py-0.5 bg-green-500 text-white cursor-pointer hover:opacity-80"
            v-for="(g, index) in obj.old3.items"
            @click="addGlasses(`${isNew ? 'Новые' : 'Старые'} МКЛ`, obj.old3, g, index)"
          >
            мкл {{ index + 1 }}
          </div>
          <button
            class="text-black bg-gray-200  rounded-md text-sm px-2"
            @click="addGlasses(`${isNew ? 'Новые' : 'Старые'} МКЛ`, obj.old3)"
          >
            <i class="fa fa-plus"></i>
          </button>
        </div>

        <!-- How long -->
        <div class="mt-2 mb-2 flex flex-row items-center" v-if="!isNew">
          <label class="text-xs mr-2">Стаж ношения мкл</label>
          <autocomplete v-model="obj.old3.experience" :type="'experience'" class="w-10" />
          <div class="text-xs ml-2">лет</div>
        </div>

        <!--  selected mkls -->
        <div class="mt-4 mb-4">
          <div v-for="p in obj.old3.products" class="text-sm flex flex-col bg-gray-100 shadow-md mb-4 rounded-md  px-2 py-3 relative">
            <div class=" mb-2">
              <button class="text-sm text-red-400 absolute right-1 top-1" @click="removeItem(p, obj.old3.products)">
                <i class="fas fa-times"></i>
              </button>
              <span class="font-semibold">
                {{ p.name }}
              </span>
            </div>
            <div class="grid grid-cols-4 gap-x-2 ">
              <div class="flex flex-row items-center gap-x-1">
                <span class="text-2xs font-bold">SPH</span>
                <autocomplete v-model="p.add" :type="'sph'" :field="'glasses'" />
              </div>

              <div class="flex flex-row items-center gap-x-1" v-if="p.multifocal">
                <span class="text-2xs font-bold">Add</span>
                <autocomplete v-model="p.add" :type="'add'" :field="'mkl'" />
              </div>

              <!--  -->
              <div class="flex flex-row items-center gap-x-1" v-if="p.astigmatism">
                <span class="text-2xs font-bold">CYL</span>
                <autocomplete v-model="p.cyl" :type="'cyl'" :field="'glasses'" />
              </div>

              <!--  -->
              <div class="flex flex-row items-center gap-x-1" v-if="p.astigmatism">
                <span class="text-2xs font-bold">AX</span>
                <autocomplete v-model="p.ax" :type="'ax'" :field="'glasses'" />
              </div>

              <div class="flex flex-row items-center gap-x-1">
                <span class="text-2xs font-bold">BC</span>
                <autocomplete v-model="p.bc" :type="'bc'" :field="'glasses'" />
              </div>
            </div>
          </div>
        </div>

        <button class="btn-action-sm w-full" @click="openCatalog(obj.old3.products)">
          <i class="fas fa-border-all mr-2"></i>
          Выбрать линзы из каталога
        </button>
      </div>
    </div>

    <!-- Comment -->
    <div class="bg-white py-4 pb-4 mt-2 rounded-md">
      <div class="font-semibold text-sm mb-2">Комментарий</div>
      <textarea class="w-full" v-model="obj.comment"></textarea>
    </div>
  </div>
</template>

<script>
import Autocomplete from "../shared/Autocomplete.vue";
import ProductsCatalog from "../shared/ProductsCatalogModal.vue";
import EditGlasses from "./modals/EditGlassesModal.vue";

export default {
  components: { Autocomplete },
  props: ["obj", "isNew", "metrics"],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    addGlasses(title, obj, item, index) {
      this.$modals.open(EditGlasses, { title: title, obj: obj, item: item }, ret => {
        console.log("glasses modal clsoed");
        console.log(ret);

        if (typeof ret == "string") {
          console.log("delete item" + index);
          obj.items.splice(index, 1);
          return;
        }

        if (ret != undefined) {
          obj.items.push(ret);
        }

        //console.log("edit item");
        //item = { ...ret };
      });
    },
    openCatalog(items) {
      let self = this;
      this.$modals.open(ProductsCatalog, {}, async product => {
        console.log(product);
        if (!product) {
          return;
        }
        items.push({
          name: product.name,
          barcode: product.barcode,
          add: 0,
          bc: "",
          ax: "",
          cyl: "",
          astigmatism: product.params && product.params.astigmatism,
          multifocal: product.params && product.params.multifocal
        });
        // pull variations for this item
        await self.loadVariations();
      });
    },
    async loadVariations() {
      let names = this.obj.old3.products.map(el => el.name);

      // console.log('load variations')
      // if (names.length > 0) {
      //   let titles = encodeURIComponent(JSON.stringify(names));
      //   let { success, msg, data } = await this.$api.get(`/g/products/variations/?names=${titles}`);
      // }
    },
    removeItem(item, items) {
      let index = items.findIndex(el => el.name == item.name);
      console.log(index);
      if (index != -1) {
        items.splice(index, 1);
      }
    }
  }
};
</script>

<template>
  <button
    v-if="id"
    @click="view()"
    class="flex flex-row items-center text-sm text-blue-700 font-semibold"
  >
    <img
      class="h-4 w-4 rounded-full object-cover mr-2"
      v-if="client.picture"
      :src="$pic(client.picture)"
      alt=""
    />
    <div>{{ client.fio }}</div>
  </button>
</template>

<script>
import Client from "../../repo/CustomerRepo";
import CustomerModal from "../customers/EditCustomerModal.vue";

export default {
  props: ["id"],
  data() {
    return {
      client: {}
    };
  },
  mounted() {
    this.get();
  },
  methods: {
    view() {
      this.$modals.open(CustomerModal, { id: this.id });
    },
    async get() {
      // find client by
      const { success, msg, data } = await Client.getByid(this.id);
      if (success) {
        this.client = data;
      }
    }
  },
  computed: {}
};
</script>

<template>
  <modal :styles="''">
    <h3 class="text-black font-bold text-xl mb-6">
      Пробить чек
    </h3>

    <form class="" @submit.prevent="printReceipt()">
      <div class="flex flex-col gap-6">
        <div class="grid grid-cols-2 gap-x-2 gap-y-2">
          <div class="">
            <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
              Тип чека
            </label>
            <select v-model="receipt.type" required class="w-full">
              <option v-for="t in types" :key="t" :value="t">{{ t }}</option>
            </select>
          </div>

          <div class="">
            <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
              СНО
            </label>
            <select v-model="receipt.nalog" required class="w-full">
              <option v-for="t in nalogList" :key="t" :value="t">{{ t }}</option>
            </select>
          </div>

          <div class="">
            <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
              Сумма
            </label>
            <input type="text" v-model="receipt.amount" class="w-full"/>
          </div>

          <div class="">
            <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
              Дата для коррекции
            </label>
            <input type="date" v-model="receipt.date" class="w-full"/>
          </div>

        </div>
        <div class="mt-2">
          <label>Позиции в чеке</label>
          <div v-for="p in receipt.items" class="flex flex-row gap-x-2 mb-2">
            <input type="text" v-model="p.title" placeholder="Название товара" class="w-full" />
            <input type="text" v-model="p.qty" placeholder="Количество" class="text-right w-20" />
            <input type="text" v-model="p.price" placeholder="Цена" class="text-right w-20" />
            <select v-model="p.vat" class="w-20" placeholder="НДС">
              <option v-for="v in vatList" :key="v" :value="v">{{ v }}</option>
            </select>
          </div>
          <button type="button" class="btn-link text-xs" @click="receipt.items.push({})">
            <i class="fas fa-plus mr-2"></i>
            Добавить товар
          </button>
        </div>
      </div>

      <div class="mt-6">
        <button type="submit" :class="{ busy: busy }" :disabled="busy" class="btn-primary-sm">
          Напечатать
        </button>
      </div>
    </form>
  </modal>
</template>

<script>
import Modal from "../modals/Modal.vue";
import ModalMixin from "../modals/modal.mixin";
import api from "../../api/api.js";

export default {
  components: { Modal },
  mixins: [ModalMixin],
  data() {
    return {
      busy: false,
      nalogList: [
        'LIBFPTR_TT_OSN - общая;',
        'LIBFPTR_TT_USN_INCOME - упрощенная доход;',
        'LIBFPTR_TT_USN_INCOME_OUTCOME - упрощенная доход минус расход;',
        'LIBFPTR_TT_ESN - единый сельскохозяйственный доход;',
        'LIBFPTR_TT_PATENT - патентная система налогообложения.'
      ],
      vatList: [
        "LIBFPTR_TAX_VAT10 - НДС 10%",
        "LIBFPTR_TAX_VAT110 - НДС рассчитанный 10/110",
        "LIBFPTR_TAX_VAT0 - НДС 0%",
        "LIBFPTR_TAX_NO - не облагается",
        "LIBFPTR_TAX_VAT20 - НДС 20%",
        "LIBFPTR_TAX_VAT120 - НДС рассчитанный 20/120"
      ],
      types: [
        "LIBFPTR_RT_SELL - чек прихода (продажи)",
        "LIBFPTR_RT_SELL_RETURN - чек возврата прихода (продажи)",
        "LIBFPTR_RT_SELL_CORRECTION - чек коррекции прихода",
        "LIBFPTR_RT_SELL_RETURN_CORRECTION - чек коррекции возврата прихода",
        "LIBFPTR_RT_BUY - чек расхода (покупки)",
        "LIBFPTR_RT_BUY_RETURN - чек возврата расхода (покупки)",
        "LIBFPTR_RT_BUY_CORRECTION - чек коррекции расхода",
        "LIBFPTR_RT_BUY_RETURN_CORRECTION - чек коррекции возврата расхода."
      ],
      receipt: {
        type: "",
        items: [],
        id: "",
        amount: ""
      }
    };
  },
  mounted() {},
  methods: {
    async printReceipt() {
      // send a task to print new receipt
    }
  }
};
</script>

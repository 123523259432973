<template>
  <page>
    <template v-slot:content>

      <div class="flex flex-row space-x-8">
        <div class="w-2/3">
          <div class="bg-white rounded-lg shadow-md px-8 py-4">
            <create-sale />
          </div>
        </div>
        <div class="w-1/3">
          <!-- today records -->
          <today-records-widget />
        </div>
      </div>



      <div v-if="!!$dept && !$dept.id && !busy" class="mt-4">
        <p class="bg-yellow-200 rounded-md px-2 py-2 max-w-max text-sm">
          Адрес не выбран, выберите. {{ busy }}
          <a href="/optics" class="text-blue-500">Список адресов</a>
        </p>
      </div>
    </template>
  </page>
</template>
<script>
import CreateSale from "./sales/CreateSale";
import Alerts from "./alerts/Alerts.vue";
import ScannerDemo from './shared/ScannerDemo.vue';
import TodayRecordsWidget from './dashboard/widgets/TodayRecordsWidget.vue';

export default {
  name: "Dashboard",
  components: { CreateSale, Alerts, ScannerDemo, TodayRecordsWidget },
  data() {
    return {
      busy: true,
      alertMsg: "",
      alertType: "info"
    };
  },
  async mounted() {
    // on first login today, generate greetings
  },
  methods: {
    sendNotification() {
      let msg = {
        title: `@${this.$me.firstName} ${this.$me.lastName}`,
        type: this.alertType,
        text: this.alertMsg,
        to: "all",
        from: "root",
        store_id: this.$dept.id,
        created_by: this.$me.id
      };
      this.$sockets.send(msg);
    }
  }
};
</script>

<template>
  <div class="md:flex w-full rounded-md my-4 overflow-hidden bg-gray-50" style="max-width:1000px">
    <div class="w-full h-64 md:h-auto md:block md:w-1/2 bg-indigo-500 relative">
      <img src="/img/login2.jpg" class="object-cover h-full w-full" />
      <div class="absolute bottom-0 left-8">
        <h1 class="mb-6 text-3xl font-extrabold leading-relaxed tracking-normal text-white md:text-5xl md:tracking-tight">
          Простое управление
          <br />
          оптикой 👌
        </h1>
        <p class="mb-6 text-lg text-white md:text-x font-semibold">
          Умная система управления
        </p>
      </div>
    </div>

    <div class="w-full md:w-1/2 py-10 px-5 md:px-10" v-if="screen == 'signup'">
      <div class="text-center mb-10">
        <h1 class="font-bold text-3xl text-gray-900">Регистрация</h1>
        <p>Создайте новую компанию</p>
      </div>
      <div>
        <form @submit.prevent="doRegister()">
          <div class="flex -mx-3">
            <div class="w-full md:w-1/2 px-3 mb-5">
              <label for="" class="text-xs font-semibold px-1">Имя</label>
              <div class="flex relative  mt-1">
                <div class="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                  <i class="far fa-user text-gray-400 text-lg"></i>
                </div>
                <input
                  required
                  ref="nameEl"
                  v-model="register.name"
                  type="text"
                  class="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                  placeholder="Александр"
                />
                <div class="absolute top-2 right-3" v-if="testName(register.name)">
                  <i class="fas fa-check text-green-400  text-sm"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="flex -mx-3">
            <div class="w-full px-3 mb-5">
              <label for="" class="text-xs font-semibold px-1">Электронная почта</label>
              <div class="flex relative  mt-1">
                <div class="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                  <i class="far fa-envelope text-gray-400 text-lg"></i>
                </div>
                <input
                  v-model="register.email"
                  required
                  ref="emailEl"
                  :class="{ error: emailFailed }"
                  type="email"
                  class="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                  placeholder="johnsmith@example.com"
                />
                <div class="absolute top-2 right-3" v-if="testEmail(register.email)">
                  <i class="fas fa-check text-green-400  text-sm"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="flex -mx-3">
            <div class="w-full px-3 mb-5">
              <label for="" class="text-xs font-semibold px-1">Пароль</label>
              <div class="flex relative  mt-1">
                <div class="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                  <i class="fas fa-key text-gray-400 text-lg"></i>
                </div>
                <input
                  required
                  v-model="register.password"
                  ref="passwordEl"
                  type="password"
                  class="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                  placeholder="************"
                />
                <button
                  class="absolute right-3 top-3 text-gray-400 text-sm"
                  type="button"
                  @click="showPassword"
                  title="Показать пароль"
                >
                  <i class="fas fa-eye"></i>
                </button>
                <div class="absolute top-2 right-9" v-if="testPassword(register.password)">
                  <i class="fas fa-check text-green-400 text-sm"></i>
                </div>
              </div>
              <div class="mt-1 text-gray-400 text-sm">Пароль должен быть не меньше 5 символов</div>
            </div>
          </div>

          <div class="flex -mx-3">
            <div class="w-full px-3 mb-5">
              <label for="" class="text-xs font-semibold px-1">Название</label>
              <div class="flex relative  mt-1">
                <div class="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                  <i class="far fa-building text-gray-400 text-lg"></i>
                </div>
                <input
                  required
                  v-model="register.companyTitle"
                  @change="translitDomain()"
                  type="text"
                  ref="companyEl"
                  class="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                  placeholder="Компания Старт"
                />
                <div class="absolute top-2 right-3" v-if="register.companyTitle.length > 0">
                  <i class="fas fa-check text-green-400  text-sm"></i>
                </div>
              </div>
            </div>
          </div>

          <div class="flex -mx-3">
            <div class="w-full px-3 mb-12">
              <label for="" class="text-xs font-semibold px-1">Домен для компани</label>
              <div class="flex mt-1">
                <div class="flex w-full">
                  <div class="relative w-full">
                    <input
                      type="text"
                      name="domain"
                      id="domain"
                      v-model="register.domain"
                      placeholder="bestco"
                      ref="domainEl"
                      :class="{ error: domainFailed }"
                      required
                      class="w-full px-3 py-2 border-r-0 rounded-r-none placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
                    />
                    <div class="absolute top-2 right-3" v-if="testDomain(register.domain)">
                      <i class="fas fa-check text-green-400  text-sm"></i>
                    </div>
                  </div>
                  <div class="flex -mr-px">
                    <span
                      class="flex items-center leading-normal bg-gray-50 rounded rounded-l-none border border-l-1 px-6 whitespace-no-wrap text-grey-dark text-sm"
                    >
                      .mnkl.ru
                    </span>
                  </div>
                </div>
              </div>
              <div class="mt-1 text-gray-400 text-sm">Только латинские буквы и цифры</div>
            </div>
          </div>

          <div class="flex -mx-3">
            <div class="w-full px-3 mb-5">
              <button
                type="submit"
                class="block w-full max-w-xs mx-auto bg-indigo-500 hover:bg-indigo-700 focus:bg-indigo-700 text-white rounded-lg px-3 py-3 font-semibold"
                :disabled="register.busy"
                :class="{ busy: register.busy }"
              >
                Зарегистрироваться
              </button>

              <div class="text-center mt-4 flex flex-row gap-4 justify-center ">
                <a href="" class="text-gray-600 hover:text-black text-sm" @click.prevent="screen = 'signin'">
                  Уже есть аккаунт
                </a>
              </div>
            </div>
          </div>

          <!--  Errors -->
          <div class="">
            <div
              v-if="result == 'such_domain_not_available'"
              class="rounded text-sm my-4 py-2 px-4 bg-yellow-100 text-yellow-800 border border-yellow-300"
            >
              Такой домен уже занят, попробуйте другой.
            </div>

            <div
              v-if="result == 'such_email_exists'"
              class="rounded text-sm my-4 py-2 px-4 bg-yellow-100 text-yellow-800 border border-yellow-300"
            >
              Такая почта уже зарегистрирована, используйте другую.
            </div>

            <div
              v-if="result == 'something_broken'"
              class="rounded text-sm my-4 py-2 px-4 bg-yellow-100 text-yellow-800 border border-yellow-300"
            >
              Что-то сломалось, обратитесь в службу поддержки.
              {{ error }}
            </div>
          </div>
        </form>
      </div>
    </div>

    <!--  success -->
    <div class="w-full md:w-1/2 py-10 px-5 md:px-10" v-if="screen == 'success'">
      <div class=" flex items-center h-full">
        <div class=" text-center mb-10 ">
          <h1 class="font-bold text-3xl text-green-400 mb-8">🎉</h1>
          <p class="text-lg">Поздравляем, вы создали новую организацию.</p>
          <p class="text-lg">Постоянный адрес для доступа:</p>
          <div class="font-semibold text-2xl mt-10 mb-12">
            {{ companyUrl }}
          </div>
          <a
            :href="companyUrl"
            class="text-xl bg-blue-500 hover:bg-indigo-700 focus:bg-indigo-700 text-white rounded-lg px-5 py-3 font-semibold"
          >
            <i class="fas fa-lock mr-2"></i>
            Авторизоваться
          </a>
        </div>
      </div>
      <!--  progress -->
    </div>

    <!--  Sign in to workspace -->
    <div class="w-full md:w-1/2 py-10 px-5 md:px-10" v-if="screen == 'signin'">
      <div class="text-center mb-10">
        <h1 class="font-bold text-3xl text-gray-900">Авторизация</h1>
      </div>
      <div>
        <form @submit.prevent="doLogin()" autocomplete="on">
          <div class="flex -mx-3">
            <div class="w-full px-3 mb-5">
              <label for="" class="text-xs font-semibold px-1">Электронная почта</label>
              <div class="flex relative  mt-1">
                <div class="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                  <i class="far fa-envelope text-gray-400 text-lg"></i>
                </div>
                <input
                  v-model="auth.email"
                  required
                  ref="emailEl"
                  type="email"
                  name="email"
                  class="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                  placeholder="johnsmith@example.com"
                />
                <div class="absolute top-2 right-3" v-if="testEmail(auth.email)">
                  <i class="fas fa-check text-green-400  text-sm"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="flex -mx-3">
            <div class="w-full px-3 mb-5">
              <label for="" class="text-xs font-semibold px-1">Пароль</label>
              <div class="flex relative  mt-1">
                <div class="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
                  <i class="fas fa-key text-gray-400 text-lg"></i>
                </div>
                <input
                  required
                  v-model="auth.password"
                  ref="passwordEl"
                  type="password"
                  name="password"
                  class="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                  placeholder="************"
                />
                <button
                  class="absolute right-3 top-3 text-gray-400 text-sm"
                  type="button"
                  @click="showPassword"
                  title="Показать пароль"
                >
                  <i class="fas fa-eye"></i>
                </button>
                <div class="absolute top-2 right-9" v-if="testPassword(auth.password)">
                  <i class="fas fa-check text-green-400 text-sm"></i>
                </div>
              </div>
              <div class="mt-1 text-gray-400 text-sm">Пароль должен быть не меньше 5 символов</div>
            </div>
          </div>

          <div class="flex -mx-3">
            <div class="w-full px-3 mb-5">
              <button
                type="submit"
                class="block w-full max-w-xs mx-auto bg-indigo-500 hover:bg-indigo-700 focus:bg-indigo-700 text-white rounded-lg px-3 py-3 font-semibold"
                :disabled="auth.busy"
                :class="{ busy: auth.busy }"
              >
                Авторизоваться
              </button>
            </div>
          </div>

          <!--  Errors -->
          <div class="text-center">
            <div
              v-if="auth.error == 'password_wrong'"
              class="rounded text-sm my-4 py-2 px-4 bg-yellow-100 text-yellow-800 border border-yellow-300"
            >
              Пароль неверный, обратитесь к администратору для восстановления.
            </div>

            <div
              v-if="auth.error == 'user_not_found'"
              class="rounded text-sm my-4 py-2 px-4 bg-yellow-100 text-yellow-800 border border-yellow-300"
            >
              Такого пользователя не существует.
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Hero from "../Hero.vue";
import cyrillicToTranslit from "cyrillic-to-translit-js";
import slugify from "slugify";

export default {
  components: { Hero },
  name: "create-company",
  data() {
    return {
      screen: "signup",
      register: {
        name: "",
        email: process.env.VUE_APP_MODE == "dev" ? "dev@aleksey.co" : "",
        domain: "",
        password: "",
        companyTitle: "",
        busy: false
      },
      auth: {
        email: process.env.VUE_APP_MODE == "dev" ? "dev@aleksey.co" : "",
        password: process.env.VUE_APP_MODE == "dev" ? "12341234" : "",
        company: "",
        busy: false,
        error: ""
      },
      result: "",
      error: "",
      companyUrl: "",
      domainFailed: false,
      emailFailed: false
    };
  },
  mounted() {
    this.auth.company = window.location.host.split(".")[0];

    if (this.auth.company == process.env.VUE_APP_DOMAIN) {
      this.authScreen = "siginup";
    } else {
      this.screen = "signin";
    }

    if (this.auth.company == "mnkl") {
      this.auth.company = "";
    }
  },
  methods: {
    translitDomain() {
      if (this.register.domain == "") {
        this.register.domain = slugify(this.register.companyTitle);
      }
    },
    testEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.$refs.emailEl ? this.$refs.emailEl.classList.remove("error") : "";
      return re.test(String(email).toLowerCase());
    },
    testName(name) {
      const re = /^[a-zа-я]+$/;
      this.$refs.nameEl ? this.$refs.nameEl.classList.remove("error") : "";
      return re.test(String(name).toLowerCase()) && name && name.length > 3;
    },
    testDomain(domain) {
      const re = /[a-z0-9_-]+/;
      this.$refs.domainEl ? this.$refs.domainEl.classList.remove("error") : "";

      return re.test(String(domain).toLowerCase()) && domain && domain.length > 0;
    },
    testPassword(password) {
      this.$refs.passwordEl ? this.$refs.passwordEl.classList.remove("error") : "";
      return password.length >= 8;
    },
    showPassword() {
      if (this.$refs.passwordEl.type == "text") {
        this.$refs.passwordEl.type = "password";
      } else {
        this.$refs.passwordEl.type = "text";
      }
    },
    async doRegister() {
      // test form data
      if (this.register.password.length < 5) {
        this.$refs.passwordEl.classList.add("error");
        return false;
      }

      if (!this.testEmail(this.register.email)) {
        this.$refs.emailEl.classList.add("error");
        return false;
      }

      if (!this.testDomain(this.register.domain)) {
        this.$refs.domainEl.classList.add("error");
        return false;
      }

      if (!this.testName(this.register.name)) {
        this.$refs.nameEl.classList.add("error");
        return false;
      }

      this.register.busy = true;

      try {
        let resp = await this.$axios.post("/auth/register", this.register);
        this.result = resp.data.msg;

        switch (this.result) {
          case "such_domain_not_available":
            this.domainFailed = true;
            this.$refs.domainEl.classList.add("error");
            break;
          case "such_email_exists":
            this.emailFailed = true;
            this.$refs.emailEl.classList.add("error");
            break;
        }

        if (resp.data.status == "success") {
          this.companyUrl = "https://" + resp.data.tenant.domain + "." + process.env.VUE_APP_URL;
          this.screen = "success";
        }
      } catch (err) {
        this.result = "something_broken";
        this.error = err;
      } finally {
        this.register.busy = false;
      }

      return false;
    },
    async doLogin() {
      // test form data
      if (this.auth.password.length < 5) {
        this.$refs.passwordEl.classList.add("error");
        return false;
      }

      if (!this.testEmail(this.auth.email)) {
        this.$refs.emailEl.classList.add("error");
        return false;
      }

      if (!this.testDomain(this.auth.company)) {
        this.$refs.companyEl.classList.add("error");
        return false;
      }

      this.auth.busy = true;

      try {
        let resp = await this.$axios.post("/auth/login", this.auth);

        if (resp.data.status == "error") {
          this.auth.error = resp.data.msg;
          console.log(resp.data.user);
        } else {
          // login user
          let user = resp.data.user;
          user.lastLogin = Date.now();
          await this.$store.dispatch("app/loginUser", user);

          document.location = "/dashboard";
        }
      } catch (err) {
        this.auth.result = "something_broken";
        this.auth.error = err;
      } finally {
        this.auth.busy = false;
      }

      return false;
    }
  }
};
</script>

import api from "../api/api";


export default {
  async getMarkups(id = false) {
    let { success, msg, data } = await api.call(`/markups${id ? '/' + id : ''}`);
    return data;
  },
  async updateMarkups(payload) {
    let { success, msg, data } = await api.call(`/markups`, { method: "post", data: payload });
    return data;
  },
  async updatePrice(payload) {
    let { success, msg, data } = await api.call(`/prices`, { method: "post", data: payload });
    return data;
  }
};

<template>
  <div class="primary-sidebar z-20 min-h-full py-2 " :class="!show ? 'sm' : 'show'">
    <div class="w-full top-0 left-0 px-4 z-10 h-screen flex flex-col justify-between box">
      <button class="text-2xl absolute md:hidden -right-8 top-4 text-white"
        @click="$events.$emit('togglePrimarySidebar')">
        <i class="fas fa-times"></i>
      </button>
      <div class="mb-4">
        <!-- Company selector -->
        <div class="selector relative" v-if="isSelectorAvailable">
          <div @click.prevent="toggleProjects" :class="active && active.id ? '' : ''"
            class="py-1 mt-0.5 px-3 relative flex items-center border border-blue-900 bg-blue-800 cursor-pointer rounded-md shadow-lg">
            <div v-if="!active || !active.id" class="h-4 w-4 bg-red-500 rounded-md m-auto placeholder"></div>

            <div class="m-auto" v-if="active && active.id">
              <div v-if="!active.picture" class="h-4 w-4 rounded-md bg-yellow-400"></div>
              <img v-if="active.picture" class="h-4 w-4 rounded-md object-cover"
                :src="'https://178107.selcdn.ru/monkl-picture/' + active.picture" />
            </div>
            <div class="ml-2 flex-1 hide-on-small" v-if="active && active.id">
              <div class="overflow-hidden ">
                <span class="block font-semibold text-white text-sm">
                  {{ active.title }}
                </span>
              </div>
            </div>

            <div class="ml-4 flex-1 py-2 hide-on-small" v-if="!active || !active.id">
              <p class="font-semibold text-white text-sm">
                Выберите филиал
              </p>
            </div>

            <a href="" class="hide-on-small">
              <img src="/img/selector.svg" class="h-5" />
            </a>
          </div>

          <div class="absolute w-full bg-white rounded-md z-10 mt-1 overflow-hidden dropdown" v-if="showProjects">
            <div v-for="(optic, index) in $depts" :key="index" @click="changeDepartment(optic)">
              <project-card :optic="optic" />
            </div>
          </div>
        </div>
        <template v-else>
          <div class="flex flex-row gap-x-4 mb-2 mt-1 py-0 items-start ml-4">
            <div>
              <div v-if="!active.picture" class="h-8 w-8 rounded-md bg-yellow-400"></div>
              <img v-if="active.picture" class="h-8 w-8 rounded-md object-cover"
                :src="'https://178107.selcdn.ru/monkl-picture/' + active.picture" />
            </div>
            <div class="leading-0">
              <span class="font-semibold text-white text-sm">
                {{ active.title }}
              </span>
            </div>
          </div>
        </template>

        <template v-if="active">
          <!-- Primary menu -->
          <div class="links-section" :class="{ 'mt-4': isSelectorAvailable }">

            <div class="flex flex-col text-sm">
              <router-link to="/dashboard" class="primary-menu-link" title="Главная">
                <i class="fas fa-home "></i>
                <span>Главная</span>
                <div class="menu-tooltip">
                  Главная
                </div>
              </router-link>
              <router-link to="/schedule" class="primary-menu-link" title="Расписание" v-if="active.id">
                <i class="fas fa-calendar"></i>
                <span>Расписание</span>
                <div class="menu-tooltip">
                  Расписание
                </div>
              </router-link>
              <router-link to="/sales" class="primary-menu-link" title="Заказы" v-if="active.id">
                <i class="fas fa-list "></i>
                <span>Продажи</span>
                <div class="menu-tooltip">
                  Продажи
                </div>
              </router-link>

              <!-- receipts -->
              <router-link to="/kassa" class="primary-menu-link" title="Касса" v-if="active.id">
                <i class="fas fa-cash-register"></i>
                <span>Касса</span>
                <div class="menu-tooltip">
                  Касса
                </div>
              </router-link>

              <div class="submenu" v-if="$route.path.indexOf('kassa') !== -1">
                <router-link to="/kassa/t" class="submenu-link" v-if="active.id && $requireRole('manager')">
                  <i class="mr-2">∟</i>
                  <span>Транзакции</span>
                  <div class="menu-tooltip">
                    Транзакции
                  </div>
                </router-link>
                <router-link to="/kassa/reports" class="submenu-link" v-if="active.id">
                  <i class="mr-2">∟</i>
                  <span>Отчеты</span>
                  <div class="menu-tooltip">
                    Отчеты
                  </div>
                </router-link>
              </div>

              <!-- end -->

              <!-- store -->
              <router-link to="/store" class="primary-menu-link" title="Склад" v-if="active.id">
                <i class="fas fa-store"></i>
                <span>Склад</span>
                <div class="menu-tooltip">
                  Склад
                </div>
              </router-link>

              <div class="submenu" v-if="$route.path.indexOf('store') !== -1">
                <router-link to="/store" class="submenu-link" v-if="active.id && $requireRole('manager')">
                  <i class="mr-2">∟</i>
                  <span>Товары</span>
                  <div class="menu-tooltip">
                    Товары
                  </div>
                </router-link>
                <router-link to="/store/external" class="submenu-link" v-if="active.id">
                  <i class="mr-2">∟</i>
                  <span>Заявки</span>
                  <div class="menu-tooltip">
                    Заявки
                  </div>
                </router-link>

                <router-link to="/store/history" class="submenu-link hidden" v-if="active.id">
                  <i class="mr-2">∟</i>
                  <span>Движение товаров</span>
                </router-link>
                <router-link to="/store/inventory" class="submenu-link" v-if="active.id">
                  <i class="mr-2">∟</i>
                  <span>Журнал инвентаризации</span>
                  <div class="menu-tooltip">
                    Журнал инвентаризации
                  </div>
                </router-link>
                <router-link to="/store/stock" class="submenu-link" v-if="active.id">
                  <i class="mr-2">∟</i>
                  <span>Текущие остатки</span>
                  <div class="menu-tooltip">
                    Текущие остатки
                  </div>
                </router-link>
                <router-link to="/store/supply" class="submenu-link" v-if="active.id">
                  <i class="mr-2">∟</i>
                  <span>Поступления</span>
                  <div class="menu-tooltip">
                    Поступления
                  </div>
                </router-link>
              </div>
              <!-- store END -->
              <!-- docs -->
              <!-- <router-link to="/docs" class="primary-menu-link" title="Документы">
                <i class="fas fa-file "></i>
                <span>Документы</span>
                <div class="menu-tooltip">
                  Документы
                </div>
              </router-link> -->
            </div>
          </div>

          <!-- Address settings -->
          <div class="mt-4 links-section" v-if="$requireRole('manager')">
            <span class="text-gray-500 text-xs px-4">Настройки филиала</span>
            <div class="flex flex-col text-sm">
              <router-link to="/settings#main" class="primary-menu-link" title="Настройки"
                v-if="active.id && $requireRole('manager')">
                <i class="fas fa-cog"></i>
                <span>Основное</span>
                <div class="menu-tooltip">
                  Основное
                </div>
              </router-link>
              <router-link to="/settings#inventory" class="primary-menu-link" title="Минимальные остатки"
                v-if="active.id && $requireRole('manager')">
                <i class="fas fa-boxes-stacked"></i>
                <span>Минимальные остатки</span>
                <div class="menu-tooltip">
                  Минимальные остатки
                </div>
              </router-link>
              <router-link to="/settings#services" class="primary-menu-link" title="Услуги"
                v-if="active.id && $requireRole('manager')">
                <i class="fas fa-list-alt"></i>
                <span>Услуги</span>
                <div class="menu-tooltip">
                  Услуги
                </div>
              </router-link>
              <router-link to="/settings#hours" class="primary-menu-link" title="Настройки"
                v-if="active.id && $requireRole('manager')">
                <i class="fas fa-cog"></i>
                <span>Режим работы</span>
                <div class="menu-tooltip">
                  Режим работы
                </div>
              </router-link>
              <router-link to="/settings#suppliers" class="primary-menu-link" title="Поставщики"
                v-if="active.id && $requireRole('manager')">
                <i class="fas fa-truck"></i>
                <span>Поставщики</span>
                <div class="menu-tooltip">
                  Поставщики
                </div>
              </router-link>
              <router-link to="/calendar" class="primary-menu-link" title="Табель работы"
                v-if="active.id && $requireRole('manager')">
                <i class="fas fa-calendar"></i>
                <span>Табель</span>
                <div class="menu-tooltip">
                  Табель
                </div>
              </router-link>
              <router-link v-if="$requireRole('admin')" to="/customers" class="primary-menu-link" title="Клиенты">
                <span>
                  <i class="fas fa-users F"></i>
                  <span>Клиенты</span>
                  <div class="menu-tooltip">
                    Клиенты
                  </div>
                </span>
              </router-link>
            </div>
          </div>
          <!-- Address settings end -->

          <!-- Manage menu -->
          <div class="mt-4 links-section">
            <div class="flex flex-col text-sm">
              <router-link to="/network" class="primary-menu-link" title="Все адреса" v-if="$requireRole('manager')">
                <i class="fas fa-cog "></i>
                <span>Настройки сети</span>
                <div class="menu-tooltip">
                  Настройки сети
                </div>
              </router-link>
              <router-link to="/emails" class="primary-menu-link" title="Входящая почта" v-if="$requireRole('manager')">
                <i class="fas fa-at "></i>
                <span>Входящая почта</span>
                <div class="menu-tooltip">
                  Входящая почта
                </div>
              </router-link>
            </div>

            <!-- <span class="text-gray-500 text-xs px-4">Настройки сети</span>
          <div class="flex flex-col text-sm">
            <router-link to="/optics" class="primary-menu-link" title="Все адреса" v-if="$requireRole('admin')">
              <i class="fas fa-list "></i>
              <span>Филиалы</span>
            </router-link>

            <router-link to="/services" class="primary-menu-link" title="Все услуги" v-if="$requireRole('admin')">
              <i class="fas fa-list-alt"></i>
              <span>Услуги</span>
            </router-link>

            <router-link to="/users" class="primary-menu-link" title="Сотрудники" v-if="$requireRole('manager')">
              <i class="fas fa-users "></i>
              <span>Сотрудники</span>
            </router-link>

            <router-link to="/reports" class="primary-menu-link" title="Отчеты" v-if="$requireRole('manager')">
              <i class="fas fa-chart-pie"></i>
              <span>Отчеты</span>
            </router-link>
          </div> -->
          </div>

          <!-- System settings -->
          <div class="mt-4 links-section" v-if="$requireRole('root')">
            <span class="text-red-500 text-xs px-4 ">Глобальные настройки</span>
            <div class="flex flex-col text-sm mt-1">
              <router-link to="/suppliers" class="primary-menu-link" title="Поставщики"
                v-if="active.id && $requireRole('manager')">
                <i class="fas fa-boxes"></i>
                <span>Справочник поставщиков</span>
                <div class="menu-tooltip">
                  Справочник поставщиков
                </div>
              </router-link>

              <router-link to="/products" class="primary-menu-link" title="Каталог товаров"
                v-if="active.id && $requireRole('manager')">
                <i class="fas fa-calendar"></i>
                <span>Справочник товаров</span>
                <div class="menu-tooltip">
                  Справочник товаров
                </div>
              </router-link>
            </div>
          </div>
        </template>



      </div>

      <!-- bottom -->
      <div class="px-4 mb-4 flex flex-col gap-y-3 links-section">
        <router-link to="help" class="b-link"><i class="fas fa-life-ring mr-2"></i>
          <span>База знаний</span>
          <div class="menu-tooltip">
            База знаний
          </div>
        </router-link>
        <a href="#" class="b-link"><i class="fas fa-comment mr-2"></i>
          <span>Обратная связь</span>
          <div class="menu-tooltip">
            Обратная связь
          </div>
        </a>
      </div>

    </div>



    <div class="fixed w-full h-full top-0 left-0" v-if="logoutBusy">
      <loading />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import ProjectCard from "../shared/ProjectCard.vue";

export default {
  props: ["show"],
  components: { ProjectCard },
  name: "PrimarySidebar",
  data() {
    return {
      showProjects: false,
      logoutBusy: false
    };
  },
  mounted() {
    document.addEventListener("click", this.close);
    this.$store.dispatch("users/pullUsers");
    // get suppliers
    this.$store.dispatch("optics/pullSuppliers");
    this.loadOptics();
  },
  methods: {
    async loadOptics() {
      // pull optic list
      await this.pullOptics();
      // if active not selected, set the first one from the list
      if (!this.active || !this.active.id) {
        if (this.optics.length > 0) {
          this.$store.dispatch("optics/setActiveOptic", this.optics[0].id);
          window.axios.defaults.headers["x-addr-id"] = this.optics[0].id;
          this.$events.$emit("store-changed");
        }
      }
    },

    close(e) {
      if (!this.$el.contains(e.target)) {
        this.showProjects = false;
      }
    },
    toggleProjects() {
      this.showProjects = !this.showProjects;
    },
    changeDepartment(optic) {
      console.log("set optic");
      this.$store.dispatch("optics/setActiveOptic", optic.id);
      window.axios.defaults.headers["x-addr-id"] = this.optics[0].id;
      document.location.reload();
      this.toggleProjects();
    },
    ...mapActions("optics", ["pullOptics"])
  },
  computed: {
    ...mapState("optics", ["optics"]),
    ...mapGetters("optics", ["active"]),
    isSelectorAvailable() {
      // let roles = ['admin', 'manager', 'operator'];
      // return roles.includes(this.$me.role);
      return true;
    },
  }
};
</script>

<template>
  <div>
    <div class="flex flex-row items-center space-x-4 mb-4">
      <h2 class="font-semibold text-lg">Расписание</h2>
      <button class="back-btn" @click="prev()"><i class="fas fa-angle-left"></i></button>
      <div class="text-center font-semibold">{{ dateStart.format("DD MMM") }} - {{ dateEnd.format("DD MMM") }}</div>
      <button class="back-btn" @click="next()"><i class="fas fa-angle-right"></i></button>
      <div>
        <button @click="showDay()" to="optics/new" class="btn-action-sm ml-2">
          <i class="fas fa-plus-circle mr-2"></i>
          Запись на приём
        </button>
      </div>
    </div>

    <!--  generate calendar -->
    <div class="grid grid-cols-7 pl-8">
      <div v-for="day in days" class="text-sm border-r text-left bg-white border-t-rounded-md">
        <div class="px-6 py-2" :class="{ 'opacity-30': isDayOutOfSchedule(day) }">
          <span class="font-medium"
            :class="{ 'text-blue-500 font-semibold': $dayjs().format('dd DD/MM') == day.format('dd DD/MM') }">
            {{ day.format("dddd") }}
          </span>
          <div class="text-xs text-gray-500">
            {{ day.format("DD MMMM") }}
          </div>
        </div>
        <div class="border-t py-0.5 px-2 flex flex-col">
          <!-- who work this day -->
          <button :data-key="`${day.format('YYYY-MM-DD')}-${host.userId}`"
            v-for="host in getAvailableHosts(day.format('YYYY-MM-DD'))"
            class="text-left whitespace-nowrap overflow-hidden relative text-2xs xl:text-xs text-gray-500 host-item cursor-pointer hover:text-gray-700">
            <span :style="`color: ${host.color}`" class="relative -top-0.5 mr-0.5" style="font-size:8px;">⬤</span>
            {{ host.shortName}}
            <span class="badge-intern" v-if="host.isIntern">{{ $t('intern') }}</span>
          </button>
        </div>
      </div>
    </div>
    <!-- time rows -->
    <div class="relative">
      <div class="grid grid-cols-7 divide-y divide-gray-200 pl-8 relative group" v-for="time in timeRows">
        <div class="absolute -left-1.5 -top-0.5">
          <span class="text-xs  font-semibold group-hover:text-blue-500"
            :class="{ 'text-gray-300': time.split(':')[1] != '00' && time.split(':')[1] != '30' }">
            {{ time }}
          </span>
        </div>
        <div :data-key="`${day.format('YYYY-MM-DD')} ${time}`" v-for="day in days" class="day-slot relative"
          :class="{ 'opacity-30': isDayOutOfSchedule(day) }">
          <!-- schedule items -->
          <!--  print who working on this time -->
          <!-- border-color: ${host.color};left:${index * 6}px; -->
          <div v-for="(host, index) in getAvailableHosts(day.format('YYYY-MM-DD'), time)"
            class="text-left pl-2 text-2xs text-gray-500 absolute top-0 h-full border-l-4 left-0 opacity-5 transition-opacity"
            :style="`border-color: ${host.color};left:${index * 6}px;`"
            :data-host-key="`${day.format('YYYY-MM-DD')}-${host.userId}`"></div>

          <!--  print records -->
          <div v-for="(item, index) in getMatrixItems(day, time)" class="relative slot-item">
            <template v-if="item.type == 'record'">
              <div class="record-item absolute w-full top-0"
                :style="`height:${getSlotHeight(item)}px;top:${index * 14}px;left:${index * 6}px;`"
                @click="editRecord(item.id)" :class="item.status">
                <div class="pr-2 overflow-ellipsis overflow-hidden">
                  {{ item.client.lastname }} {{ item.client.firstname }}
                </div>
                <div class="text-2xs font-semibold mt-1">
                  {{ $dayjs(item.startTime).format("HH:mm") }} - {{ $dayjs(item.endTime).format("HH:mm") }}
                </div>
                <!-- source / status -->
                <div class="absolute top-1 right-1">
                  <div class="flex flex-col items-center text-xs">
                    <span v-tooltip="'Подтверждено'" v-if="item.status == 'confirmed'" class="text-2xs">🟢</span>
                    <span v-tooltip="'Требует подтверждения'" v-if="item.status == 'new'">⚠️</span>
                    <span v-tooltip="'Идет прием'" v-if="item.status == 'started'">⏳</span>
                    <span v-tooltip="'Прием завершен'" v-if="item.status == 'completed'" class="">✔</span>
                    <span v-tooltip="'Виджет на сайте'" v-if="item.source == 'widget'" class="">🌐</span>
                  </div>
                </div>
              </div>
            </template>
          </div>

          <button
            class="absolute top-0 left-0 w-full h-full z-10 hover:bg-green-200 hover:ring-2 ring-0 opacity-50 transition-all"
            @click="editRecord('', day.format('YYYY-MM-DD'), time)"></button>
        </div>
      </div>

      <loading v-if="busy" />
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import Record from "../../repo/RecordRepo";
import { TimeColsSlatsCoords } from "@fullcalendar/timegrid";
import EditRecordModal from "./EditRecordModal.vue";
import { mapGetters } from "vuex";

export default {
  components: { EditRecordModal },
  props: [""],
  data() {
    return {
      busy: false,
      mode: "week", // month, week, day
      dateStart: dayjs().startOf("week"),
      dateEnd: dayjs().endOf("week"),
      selectedDate: dayjs(),
      hosts: [],
      records: [],
      matrix: new Map(),
      isMounted: false
    };
  },
  watch: {
    // if datestart or ate end chages, reload records
    dateStart: function () {
      console.log("date start is changed");
      this.getRecords();
    }
  },
  mounted() {
    this.busy = false;
    this.getRecords(true);

    let vm = this;
    this.$events.$on("record-reload", () => {
      console.log("record-reload. is mounted?", vm.isMounted);
      if (vm.isMounted) {
        vm.getRecords();
      }
    });
  },
  methods: {
    setHoverListeners() {
      // on hover of each .host-item, get attribute data-key, find element with attribue data-host-key
      // set opacity to 1
      // on mouseout, set opacity to 0
      const hostIcons = document.querySelectorAll(".host-item");

      hostIcons.forEach(icon => {
        // or by click
        icon.addEventListener("click", () => {
          const hostKey = icon.getAttribute("data-key");
          const hostDivs = document.querySelectorAll(`[data-host-key="${hostKey}"]`);
          hostDivs.forEach(div => {
            div.classList.toggle("opacity-5");
          });
        });

        // icon.addEventListener("mouseover", () => {
        //   const hostKey = icon.getAttribute("data-key");
        //   const hostDivs = document.querySelectorAll(`[data-host-key="${hostKey}"]`);
        //   hostDivs.forEach(div => div.classList.remove("opacity-5"));
        // });

        // icon.addEventListener("mouseout", () => {
        //   const hostKey = icon.getAttribute("data-key");
        //   const hostDivs = document.querySelectorAll(`[data-host-key="${hostKey}"]`);
        //   hostDivs.forEach(div => div.classList.add("opacity-5"));
        // });
      });
    },
    /**
     *
     * @param {*} item Record
     */
    getSlotHeight(record) {
      // search the distance between this layer and day-time of end
      let endKey = dayjs(record.endTime).format("YYYY-MM-DD HH:mm");
      let startKey = dayjs(record.startTime).format("YYYY-MM-DD HH:mm");
      let startDiv = document.querySelector('[data-key="' + startKey + '"]');
      let endDiv = document.querySelector('[data-key="' + endKey + '"]');
      let distance = this.findVerticalDistance(startKey, endKey);
      return distance;
      ///console.log(endKey)
    },
    findVerticalDistance(dataKey1, dataKey2) {
      // Select the two divs based on their data-key attribute values
      const div1 = document.querySelector(`div[data-key="${dataKey1}"]`);
      const div2 = document.querySelector(`div[data-key="${dataKey2}"]`);

      // Ensure both divs were found
      if (!div1 || !div2) {
        console.error("One or both of the divs could not be found.");
        return;
      }

      // Get the bounding rectangles of the elements
      const rect1 = div1.getBoundingClientRect();
      const rect2 = div2.getBoundingClientRect();

      // Calculate the vertical distance
      // Depending on the order of the elements, calculate the distance differently
      let distance;
      if (rect1.top <= rect2.top) {
        // div1 is above div2
        distance = rect2.top - rect1.bottom;
      } else {
        // div1 is below div2
        distance = rect1.top - rect2.bottom;
      }

      return distance + div1.clientHeight - 4;
    },
    getMatrixItems(date, time) {
      date = dayjs(date).format("YYYY-MM-DD");
      return this.matrix.get(`${date} ${time}`);
    },
    createMatrix() {
      // generate times
      let min = Infinity;
      let max = -Infinity;

      this.$dept.workingHours.forEach(subArr => {
        subArr.forEach(num => {
          num = parseInt(num);
          if (num < min) min = num;
          if (num > max) max = num;
        });
      });

      // generate time slots from min to max with 30 mins period, use 24 time format
      let slots = [];
      let decimalTime = min;
      while (decimalTime <= max) {
        const hours = Math.floor(decimalTime);
        const decimalMinutes = decimalTime - hours;
        // Convert the decimal part into minutes (out of 60)
        const minutes = Math.round(decimalMinutes * 60);
        const hoursString = hours.toString().padStart(2, "0");
        const minutesString = minutes.toString().padStart(2, "0");
        slots.push(`${hoursString}:${minutesString}`);

        decimalTime = decimalTime + 0.25;
      }
      // let today = new Date();
      let startOfWeek = this.dateStart.startOf("week");

      let current = startOfWeek;

      // generate days
      for (let i = 0; i <= 6; i++) {
        // per each time slot add array
        let key = current.format("YYYY-MM-DD");
        slots.forEach(slot => {
          this.matrix.set(`${key} ${slot}`, []);
        });

        current = current.add(1, "day");
      }
    },
    getAvailableHosts(date, time = false) {
      // get hosts for this day
      // filter hosts for selected date
      // create a map with list of hosts per each day and time
      // 'date time' => [hosts]
      // create a plain array of host & available date and time

      let datetime = dayjs(`${date} ${time}`);
      let hosts = [];
      if (time !== false) {
        hosts = this.hosts.filter(host => datetime <= host.dateTimeEnd && datetime >= host.dateTimeStart);
        return hosts;
      } else {
        let filtered = this.hosts.filter(host => dayjs(host.dateTimeStart).format("YYYY-MM-DD") == date);
        filtered.forEach(h => {
          let host = hosts.find(el => el.id == h.id);
          if (host == undefined) {
            hosts.push(h);
          }
        });
        return filtered;
      }
    },
    getRecordsForDay(date, time) {
      // get hosts for this day
      // filter hosts for selected date
      // create a map with list of hosts per each day and time
      // 'date time' => [hosts]
      // create a plain array of host & available date and time
      let datetime = dayjs(`${date} ${time}`);
      let records = this.records.filter(record => datetime <= record.endTime && datetime >= record.startTime);
      return records;
    },
    prev() {
      if (this.mode == "week") {
        this.dateStart = this.dateStart.subtract(1, "week");
        this.dateEnd = this.dateEnd.subtract(1, "week");
      }
    },
    next() {
      if (this.mode == "week") {
        this.dateStart = this.dateStart.add(1, "week");
        this.dateEnd = this.dateEnd.add(1, "week");
      }
    },
    async getRecords(flag = false) {
      this.busy = true;
      let vm = this;
      const { data, msg, success } = await Record.get({
        opticId: this.$dept.id,
        start: this.dateStart.format("YYYY-MM-DD"),
        end: this.dateEnd.format("YYYY-MM-DD")
      });

      console.log("request is done");
      this.records = data;

      // reset matrix
      this.matrix = new Map();
      this.createMatrix();

      // put records to matrix
      this.records.forEach(record => {
        let date = dayjs(record.startTime).format("YYYY-MM-DD");
        let time = dayjs(record.startTime).format("HH:mm");
        let item = this.matrix.get(`${date} ${time}`);
        console.log(`Fill matrix: ${date} ${time}`);
        record.type = "record";
        if (item != undefined) {
          item.push(record);
        }
      });

      await this.getWorkingSlots();

      setTimeout(function () {
        vm.busy = false;
        if (flag) {
          vm.isMounted = true;
        }
      }, 150);
    },
    async getWorkingSlots() {
      let vm = this;
      let r = await this.$schedule.getByDates(
        this.$dept.id,
        this.dateStart.format("YYYY-MM-DD"),
        this.dateEnd.format("YYYY-MM-DD")
      );

      let hostColors = new Map();

      this.hosts = r.data.reduce((acc, item) => {
        let slots = item.slots.map(slot => {
          let h = this.getUserById({ id: slot.userId });
          let color = "#4ade14"; // green color by default
          if (!h) {
            return acc;
          }
          if (h.roles.includes("optometrist") || h.roles.includes("medic")) {
            color = "#FF5733";
          }
          return {
            userId: slot.userId,
            dateTimeStart: dayjs(item.date + " " + slot.timeStart),
            dateTimeEnd: dayjs(item.date + " " + slot.timeStop),
            name: h.name,
            color: color,
            isIntern: h.roles.includes("intern"),
            shortName: h.shortName
          };
        });
        acc = [...acc, ...slots];
        return acc;
      }, []);

      setTimeout(function () {
        vm.setHoverListeners();
      });
    },
    showDay(date, time) {
      //this.editDayModal = true;
      if (date) {
        this.selectedDate = this.$dayjs(`${date} ${time}`);
      } else {
        this.selectedDate = this.$dayjs();
      }

      // if this date is not disabled
      let aDay = this.selectedDate.day();
      // is address work at this day?
      aDay = aDay == 0 ? 6 : aDay - 1;
      let isAvailable = this.$dept.workingHours[aDay][0] != -1;
      // get hosts for this day
      // filter hosts for selected date
      if (isAvailable) {
        let hosts = this.hosts.filter(host => host.date == this.selectedDate.utc().format("YYYY-MM-DD"));

        this.$modals.open(EditRecordModal, { id: "", date: this.selectedDate, hosts: hosts });
      }
    },
    editRecord(id, date, time) {
      this.$modals.open(EditRecordModal, {
        id: id ? id : "",
        date: date ? dayjs(`${date} ${time}`) : new Date()
      });
    },
    // calculate the height of the item in a schedule
    calcItemHeight(item) {
      // calc the distance in pixels between start of the time and end
      // make the height of the element with this y
      // each element is absolute position
    },
    isDayOutOfSchedule(dayDate) {
      let dayNumber = dayDate.day();
      if (dayNumber == 0) {
        dayNumber = 6;
      } else {
        dayNumber -= 1;
      }

      return this.$dept.workingHours[dayNumber][0] == -1;
    }
  },
  computed: {
    ...mapGetters({ users: "users", getUserById: "users/getById" }),
    days() {
      // create array of days for current week
      let items = [];
      // let today = new Date();
      let startOfWeek = this.dateStart.startOf("week");

      items.push(startOfWeek);
      let current = startOfWeek;

      for (let i = 0; i < 6; i++) {
        current = current.add(1, "day");
        items.push(current);
      }

      return items;
    },
    timeRows() {
      // get minimum business hour start and end for the week
      // find minimum of workingHours for each day
      let min = Infinity;
      let max = -Infinity;

      if (this.$dept == undefined) return [];

      this.$dept.workingHours.forEach(subArr => {
        subArr.forEach(num => {
          num = parseInt(num);
          if (num != -1) {
            if (num < min) min = num;
            if (num > max) max = num;
          }
        });
      });

      // generate time slots from min to max with 30 mins period, use 24 time format
      let slots = [];
      let decimalTime = min;
      while (decimalTime <= max) {
        const hours = Math.floor(decimalTime);
        const decimalMinutes = decimalTime - hours;
        // Convert the decimal part into minutes (out of 60)
        const minutes = Math.round(decimalMinutes * 60);
        const hoursString = hours.toString().padStart(2, "0");
        const minutesString = minutes.toString().padStart(2, "0");
        slots.push(`${hoursString}:${minutesString}`);

        decimalTime = decimalTime + 0.25;
      }

      return slots;
    }
  }
};
</script>

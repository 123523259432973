<template>
  <div>

    <div class="flex flex-row items-center mb-4 gap-x-4 text-sm">
      <button class="px-2 py-1 rounded-md" @click="tab = 'telegram'"
        :class="{ 'text-white bg-gray-800': tab == 'telegram' }">
        <i class="fab fa-telegram"></i> Телеграм
      </button>
      <button class="px-2 py-1 rounded-md" @click="tab = 'widget'"
        :class="{ 'text-white bg-gray-800': tab == 'widget' }">
        Виджет самозаписи
      </button>
      <button class="px-2 py-1 rounded-md" @click="tab = 'itigris'"
        :class="{ 'text-white bg-gray-800': tab == 'itigris' }">
        Итигрис
      </button>
      <button class="px-2 py-1 rounded-md" @click="tab = 'email'" :class="{ 'text-white bg-gray-800': tab == 'email' }">
        Электронная почта
      </button>
      <button class="px-2 py-1 rounded-md" @click="tab = 'telephony'"
        :class="{ 'text-white bg-gray-800': tab == 'telephony' }">
        Телефония
      </button>
    </div>

    <div v-if="tab == 'telegram'">
      <h2 class="font-semibold text-lg mb-4 flex flex-row items-center">
        <div>
          Интеграция c Telegram
        </div>
      </h2>

      <div class="bg-white px-5 py-4 rounded-md ">
        <div class="mb-3">
          <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">Название бота</label>
          <input class="w-1/3" v-model="tg.botname" type="text" />
        </div>

        <div class="mb-3">
          <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">Token</label>
          <input class="w-2/3" v-model="tg.token" type="text" />
        </div>

        <error v-if="tgError">{{ tgError }}</error>
        <button class="btn-action mt-4" @click="saveSettings()" :class="{ busy: tgBusy }">Сохранить</button>
      </div>
    </div>

    <div v-if="tab == 'widget'">
      <h2 class="font-semibold text-lg mb-4 mt-4 flex flex-row items-center">
        <div>
          Виджет самозаписи
        </div>
      </h2>
      <div class="bg-white px-5 py-4 rounded-md max-w-xl ">
        <p>Чтобы установить виджет, скопируйте код ниже и вставьте в нужное место на вашем сайте.</p>
        <div class="font-mono px-6 py-4 bg-black text-white rounded-md shadow relative">
          {{ getWidgetCode() }}
          <button @click="copyToClipboard(getWidgetCode())" class="absolute right-3 top-2 text-yellow-400">
            Скопировать код {{ copied ? "✔️" : "" }}
          </button>
        </div>
      </div>
    </div>

    <div v-if="tab == 'itigris'">
      <h2 class="font-semibold text-lg mb-4 flex flex-row items-center">
        <div>Интеграция c Itigris</div>
      </h2>
      <div class="flex flex-row space-x-4">
        <div class="bg-white px-5 py-4 rounded-md w-1/3">
          <div class="mb-3">
            <label class="block mb-1 text-sm text-gray-600 font-medium" for="itigris_login">Логин</label>
            <input class="w-3/3" v-model="itigris.login" type="text" id="itigris_login" />
          </div>

          <div class="mb-3">
            <label class="block mb-1 text-sm text-gray-600 font-medium" for="itigris_password">Пароль</label>
            <input class="w-3/3" v-model="itigris.password" type="text" id="itigris_password" />
          </div>

          <!--      <error v-if="tgError">{{ tgError }}</error>-->
          <button class="btn-action mt-4" @click="saveSettingsItigris()"
            :class="{ busy: itigrisBusy }">Сохранить</button>
        </div>
        <div class="w-2/3">
          <div class="flex flex-row items-center justify-between mb-4  px-5 bg-white rounded-md py-4">
            <span class="">История обновлений</span>
            <button class="btn-default" :disabled="itigrisCreateTaskBusy" @click="createImportItigrisTask()"
              :class="{ busy: itigrisCreateTaskBusy }">
              <img src="/img/refresh.svg" class="w-4 inline-block mr-0.5"/> Запустить обновление</button>
          </div>

          <div class="bg-white">
            <table class="data-table">
              <thead>
                <tr>
                  <th></th>
                  <th>Создано</th>
                  <th>Завершено</th>
                  <th>Обработано</th>
                  <th>Добавлено</th>
                  <th>Обновлено</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in itigrisTaskList" class="">
                  <td class="text-center">
                    <template v-if="item.status == 'completed'">✅</template>
                    <template v-if="item.status == 'processing'">⏳</template>
                    <template v-if="item.status == 'new'">⏱️</template>
                  </td>
                  <td>{{ item.created_at | datetime }}</td>
                  <td>{{ item.completed_at | datetime }}</td>
                  <td>
                    <template v-if="item.result">
                      {{ item.result.processed }}
                    </template>
                  </td>
                  <td>
                    <template v-if="item.result">
                      {{ item.result.added }}
                    </template>
                  </td>
                  <td>
                    <template v-if="item.result">
                      {{ item.result.updated }}
                    </template>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>

    <!-- email white list -->
    <div v-if="tab == 'email'">
      <EmailSettings />
    </div>

    <div v-if="tab == 'telephony'">
      <h2 class="font-semibold text-lg mb-4 flex flex-row items-center">
        <div></div>
      </h2>
      <div class="bg-white px-5 py-4 rounded-md w-2/3">
        <img class="mb-3" src="/img/mango-logo.png" alt="MANGO OFFICE">
        <div class="mb-3">
          <label class="block mb-1 text-sm text-gray-600 font-medium" for="mango_login">API key</label>
          <input class="w-full" v-model="mango.apiKey" type="text" id="mango_login" />
        </div>

        <div class="mb-3">
          <label class="block mb-1 text-sm text-gray-600 font-medium" for="itigris_password">API salt</label>
          <input class="w-full" v-model="mango.apiSalt" type="text" id="itigris_password" />
        </div>
        <error v-if="mangoError">{{ mangoError }}</error>
        <button class="btn-action mt-4" @click="saveSettingsMango()" :class="{ busy: mangoBusy }">Сохранить</button>
      </div>
    </div>
  </div>
</template>

<script>
import EmailSettings from "./sections/EmailSettings.vue";

export default {
  name: "integration-settings",
  props: ["settings"],
  components: {
    EmailSettings
  },
  data() {
    return {
      keys: [],
      tgBusy: false,
      itigrisBusy: false,
      mangoBusy: false,
      copied: false,
      tg: {
        botname: "",
        token: ""
      },
      itigris: {
        login: "1",
        password: "3"
      },
      mango: {
        apiKey: "",
        apiSalt: ""
      },
      tgError: '',
      itigrisError: '',
      mangoError: '',
      tab: 'telegram',
      itigrisTaskList: [],
      itigrisTasksBusy: false,
      itigrisCreateTaskBusy: false
    };
  },
  mounted() {
    this.getSettings();
    this.getSettingsItigris();
    this.getSettingsMango();
    this.getItigrisTaskList();
  },
  methods: {
    async createImportItigrisTask() {

      this.itigrisCreateTaskBusy = true;
      let { success, msg, data } = await this.$api.post("/itigris/tasks");
      if (success) {
        this.getItigrisTaskList();
      } else {
        alert(msg)
      }
      this.itigrisCreateTaskBusy = false;
    },
    async getItigrisTaskList() {
      this.itigrisTasksBusy = true;
      let { success, msg, data } = await this.$api.get("/itigris/tasks");

      if (success) {
        this.itigrisTaskList = data.items;
      }
      this.itigrisTasksBusy = false;

    },
    async getSettings() {
      let { success, msg, data } = await this.$api.get("/settings?type=telegram");
      if (success) {
        this.tg = data.data;
      }
    },
    async saveSettings() {
      this.tgBusy = true;
      this.status = "";
      this.tgError = "";

      let { success, msg, data } = await this.$api.post("/settings/telegram", {
        data: {
          botname: this.tg.botname,
          token: this.tg.token
        }
      });

      if (success) {
        this.status = "success";
      } else {
        this.tgError = msg;
      }

      setTimeout(() => {
        this.tgBusy = false;
      }, 250);
    },
    async getSettingsItigris() {
      let { success, msg, data } = await this.$api.get("/settings?type=itigris");
      if (success || data.data) {
        this.itigris = data.data;
      }
    },
    async saveSettingsItigris() {
      this.itigrisBusy = true;
      this.status = "";
      this.itigrisError = "";

      let { success, msg, data } = await this.$api.post("/settings/itigris", {
        data: {
          login: this.itigris.login,
          password: this.itigris.password
        }
      });

      if (data.data) {
        this.status = "success";
      } else {
        this.itigrisError = msg;
      }

      setTimeout(() => {
        this.itigrisBusy = false;
      }, 250);
    },
    async getSettingsMango() {
      let { success, msg, data } = await this.$api.get("/settings?type=mango");
      if (success || data.data) {
        this.mango = data.data;
      }
    },
    async saveSettingsMango() {
      this.mangoBusy = true;
      this.status = "";
      this.mangoError = "";

      let { success, msg, data } = await this.$api.post("/settings/mango", {
        data: {
          apiKey: this.mango.apiKey,
          apiSalt: this.mango.apiSalt
        }
      });

      if (data.data) {
        this.status = "success";
      } else {
        this.mangoError = msg;
      }

      setTimeout(() => {
        this.mangoBusy = false;
      }, 250);
    },
    getWidgetCode() {
      let ids = this.$depts.map(el => el.id);
      var host = window.location.host;
      var subdomain = host.split(".")[0];
      let mode = 0;
      let code = `<div id="mnkl-widget" data-id="${ids.join(",")}" data-mode="${mode}" data-domain="${subdomain}"> </div>`;
      let scriptString = `
      (function() { 
          var script = document.createElement('script'); 
          script.src = 'https://${subdomain}.mnkl.ru/widget/v2/widget.js'; 
          document.body.appendChild(script); 
      })(); 
      `;

      return code + `${["<", "script", ">"].join("")}` + scriptString + `${["<", "/script", ">"].join("")}`;
    },
    copyToClipboard(text) {
      let self = this;

      if (!navigator.clipboard) {
        // Clipboard API is not available, fallback to older method
        var textArea = document.createElement("textarea");
        textArea.value = text;
        textArea.style.position = "fixed"; // avoid scrolling to bottom
        textArea.style.opacity = "0";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
          var successful = document.execCommand("copy");
          if (successful) {
            console.log("Fallback: Copying text command was successful");
            this.copied = true;
          } else {
            console.error("Fallback: Copying text command was unsuccessful");
          }
        } catch (err) {
          console.error("Fallback: Oops, unable to copy", err);
        }

        setTimeout(() => {
          self.copied = false;
        }, 500);

        document.body.removeChild(textArea);
        return;
      }

      // Clipboard API method
      navigator.clipboard.writeText(text).then(
        function () {
          console.log("Async: Copying to clipboard was successful!");
        },
        function (err) {
          console.error("Async: Could not copy text: ", err);
        }
      );
    }
  }
};
</script>

<template>
  <div>

    <div class="flex flex-auto flex-col items-start">
      <div class="grid grid-cols-2 xl:grid-cols-4 gap-12">
        <optic-card v-for="(item, index) in optics" :key="index" :item="item" @edit="edit" @login="login" />
        <add-optic-card @addoptic="addNew" />
      </div>
    </div>
  </div>
</template>
<script>
import Modal from "../shared/Modal.vue";
import OpticCard from "../shared/OpticCard.vue";
import EditOptic from "./EditOptic.vue";
import { mapGetters } from "vuex";

import AddOpticCard from "./AddOpticCard.vue";
import CustomModal from "../shared/CustomModal.vue";

import AddOpticModal from "./AddOpticModal.vue";

export default {
  components: { OpticCard, Modal, EditOptic, AddOpticCard, CustomModal },
  name: "Optics",
  data() {
    return {
      editOpticModal: false,
      editableId: -1
    };
  },
  mounted() {
    this.$store.dispatch("optics/pullOptics");
  },
  methods: {
    addNew() {
      //this.editableId = -1;
      //this.editOpticModal = true;
      this.$modals.open(AddOpticModal);
    },
    edit(id) {
      this.editableId = id;
      this.editOpticModal = true;
    },
    save() {
      // save editable optic
    },
    login(id) {
      this.$store.dispatch("optics/setActiveOptic", id);
    }
  },
  computed: {
    ...mapGetters("optics", ["optics"])
  }
};
</script>

import api from "../api/api";

const resource = "/customers";

export default {
  get({ opticId, page = 1, limit = 10 }) {
    //return api.call(`${resource}?opticId=${opticId}`);
    return api.call(`${resource}?page=${page}&limit=${limit}`);
  },
  getByid(id) {
    return api.call(`${resource}/${id}`, { method: "get" });
  },
  search(term, field = "fio") {
    term = term.replace(/\+/g, "");
    if (term) return api.call(`${resource}/search/${term}?field=${field}`, { method: "get" });
  },
  update(payload) {
    return api.call(`${resource}`, { method: "post", data: payload });
  },
  delete(id) {
    return api.call(`${resource}/${id}`, { method: "delete" });
  }
};

/**
 * Cuatomer model
 */
export let CustomerSchema = {
  opticId: "",
  firstname: "",
  lastname: "",
  thirdname: "",
  phone: "",
  email: "",
  depts: [],
  birthday: "",
  gender: "",
  extra: { auto: false, pc: false },
  notifications: { sms: false, email: false },
  city: "",
  shippingAddress: "",
  complains: { common: [], mkl: [], eyeStrain: [] },
  eyeOperations: [],
  allergic: [],
  pastDiseases: [],
  chronic: [],
  heritable: [],
  medicComment: "",
  eyes: {
    os: {
      sclera: [],
      lens: [],
      iris: [],
      cornea: [],
      lacrimal: [],
      сonjunctiva: [],
      eyelids: [],
      comment: ""
    },
    od: {
      sclera: [],
      lens: [],
      iris: [],
      cornea: [],
      lacrimal: [],
      сonjunctiva: [],
      eyelids: [],
      comment: ""
    }
  }
};

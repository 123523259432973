<template>
  <modal :styles="''">
    <div v-if="record.client != undefined">
      <h3 class="text-black font-bold text-xl mb-6 items-center flex flex-row">
        {{ record.client ? record.client.fio : "Новая запись" }}

        <button class="edit-btn ml-4" @click="editRecord()">Изменить</button>
      </h3>
    </div>

   
    <read-record :record="record" :services="services" v-if="record.id" />
  </modal>
</template>

<script>
import ModalMixin from "../modals/modal.mixin";
import Record from "../../repo/RecordRepo";
import Customer from "../../repo/CustomerRepo";
import ReadRecord from "../schedule/ReadRecord.vue";
import EditRecordModal from "../records/AddRecordModal.vue";
import { mapGetters } from "vuex";

export default {
  components: { ReadRecord },
  mixins: [ModalMixin],
  props: ["id"],
  data() {
    return {
      record: {},
      loading: false,
      services: []
    };
  },
  mounted() {
    // pull record details
    this.get();
  },
  methods: {
    async get() {
      this.loading = true;
      let { data, msg, success } = await Record.getByid(this.id);
      if (success) {
        this.record = data;

        // get client details
        console.log(this.record.client_id);
        ({ data, msg, success } = await Customer.getByid(this.record.client_id));
        if (success) {
          this.record.client = data;
        }
      }
      this.loading = false;
    },
    editRecord() {
      this.$modals.open(EditRecordModal, { record_id: this.id }).then(() => {
        console.log("resolved");
      });
    },
    async getServices() {
      let { success, msg, data } = await this.$api.get(`/optics/${this.$dept.id}`);

      if (success) {
        this.services = data.services.filter(el => el.available == true);
      }

      return [];
    }
  },
  computed: {
    ...mapGetters("optics", ["optics", "active", "getOpticService", "getById"]),
    ...mapGetters("records", ["getStatus"])
  }
};
</script>

<template>
  <div>
    <form @submit.prevent>
      <div class="flex flex-row flex-wrap">
        <div>
          <div>
            <i class="fas fa-barcode" v-if="!icon"></i>
            <input
              ref="barcode"
              type="text"
              :placeholder="placeholder ? placeholder : 'Штрихкод'"
              v-model="barcode"
              class="py-1 px-2 ml-2"
              :autofocus="autofocus"
              required
              name="barcode"
              :disabled="wait"
              tabindex="1"
              :class="{ wait: wait }"
              v-on:keyup.enter="scanBarcode()"
            />
          </div>
          <div class="row text-gray-300  text-xs font-semibold cursor-pointer mt-2" v-if="extend">
            <div
              class="mr-2"
              :class="{ 'text-black': extendMode == false }"
              @click="(extendMode = false), tgkey++"
            >
              ШК
            </div>
            <sm-toggle
              v-on:updated="extendMode = $event"
              class=""
              title="Расширенный режим"
              :default="extendMode"
              :key="tgkey"
            />
            <div :class="{ 'text-black': extendMode }" @click="(extendMode = true), tgkey++">
              Лот
              <span v-if="showDateField">+ Дата</span>
            </div>
          </div>
        </div>

        <template v-if="extendMode">
          <div v-if="showLotField">
            <input
              ref="lot"
              type="text"
              :placeholder="placeholder ? placeholder : 'Лот'"
              v-model="lot"
              class="py-1 px-2 ml-2"
              :disabled="wait"
              :class="{ wait: wait }"
              tabindex="2"
              v-on:keyup.enter="scanLot()"
            />
          </div>

          <div v-if="showDateField">
            <input
              ref="month"
              type="text"
              :placeholder="placeholder ? placeholder : '00'"
              v-model="date.month"
              class="py-1 px-2 ml-2 w-12 text-right"
              maxlength="2"
              :disabled="wait"
              tabindex="3"
              :class="{ wait: wait }"
              v-on:keyup.enter="scanDate('month')"
            />
            /
            <input
              ref="year"
              type="text"
              :placeholder="placeholder ? placeholder : '0000'"
              v-model="date.year"
              class="py-1 px-2 ml-0 w-16 text-right"
              maxlength="4"
              :disabled="wait"
              tabindex="3"
              :class="{ wait: wait }"
              v-on:keyup.enter="scanDate('year')"
            />
            <a :href="pdfUrl" class="text-xs text-blue-600 ml-2">
              <i class="fas fa-print mr-0.5"></i>
              Месяц / Год
            </a>
          </div>
        </template>
      </div>
    </form>
  </div>
</template>

<script>
import Product from "../../repo/ProductRepo.js";
import GlobalProduct from "../../repo/GlobalProductRepo.js";

export default {
  props: ["autofocus", "placeholder", "icon", "extend", "mode"],
  data() {
    return {
      barcode: "",
      lot: "",
      date: {
        month: "",
        year: ""
      },
      error: "",
      wait: false,
      product: {},
      extendMode: false,
      tgkey: 0,
      pdfUrl: process.env.VUE_APP_BACKEND_URL + "/utils/print-barcodes"
    };
  },
  mounted() {
    if (this.autofocus) {
      this.$refs.barcode.focus();
    }

    if (this.extend) {
      this.extendMode = true;
      this.tgkey++;
    }
  },
  methods: {
    scanLot() {
      // move focus next
      this.product.lot = this.lot;

      if (this.showDateField) {
        this.$refs.month.focus();
      } else {
        this.emitProduct();
      }
      // check code length and so on
      this.peaksound();
    },
    scanDate(param) {
      // move focus next
      if (param == "month") {
        this.product.date = `${this.date.month}/${this.date.year}`;
        this.$refs.year.focus();
      }

      if (param == "year") {
        this.product.date = `${this.date.month}/${this.date.year}`;
        this.emitProduct();
      }

      this.peaksound();
    },
    async scanBarcode() {
      this.error = "";
      let success, msg, data;

      if (this.barcode == "") return;

      // "global" means that we search in global products table
      // "localfirst", then try to find product in local products table
      // otherwise, use local by default

      if (this.mode == "global") {
        ({ success, msg, data } = await GlobalProduct.query({ barcode: this.barcode }));
      } else if (this.mode == "localfirst") {
        ({ success, msg, data } = await Product.query({ barcode: this.barcode }));
        if (!success || data.products.length == 0) {
          ({ success, msg, data } = await GlobalProduct.query({ barcode: this.barcode }));
        }
      } else {
        ({ success, msg, data } = await Product.query({ barcode: this.barcode }));
      }

      if (success) {
        if (data.products.length > 1 || data.products.length == 0) {
          // error
          this.failsound();
          this.error = "product_not_found";
          alert(this.i18n.t(this.error));
          return;
        }

        if (data.products.length > 0) {
          this.product = data.products[0];

          this.peaksound();
          // go next
          if (this.extendMode) {
            this.$refs.lot.focus();
          } else {
            this.emitProduct();
          }
        }
      }
    },
    emitProduct() {
      if (this.barcode == "") return;

      this.$emit("scan", this.product);

      this.barcode = "";
      this.lot = "";
      this.date = { month: "", year: "" };

      this.$refs.barcode.focus();

      this.oksound();
    },
    failsound() {
      let audio = new Audio("/sounds/error.wav");
      audio.play();
    },
    oksound() {
      let audio = new Audio("/sounds/success.wav");
      audio.play();
    },
    peaksound() {
      let audio = new Audio("/sounds/peak.wav");
      audio.play();
    }
  },
  computed: {
    showDateField() {
      if (this.extend) {
        return this.extend.indexOf("date") != -1;
      }

      return false;
    },
    showLotField() {
      if (this.extend) {
        return this.extend.indexOf("lot") != -1;
      }
      return false;
    }
  }
};
</script>

<template>
  <div>
    <page>
      <template v-slot:header>Запись на прием</template>

     
      <template v-slot:content>
        <div class="flex flex-col records">
          <div
            class="grid xl:grid-cols-7 gap-3 text-xs mb-4 border-b border-gray-100 pb-2 font-semibold"
          >
            <div>#</div>
            <div>Клиент</div>
            <div>Телефон</div>
            <div>Дата</div>
            <div>Время</div>
            <div>Услуга</div>
            <div>Статус</div>
          </div>

          <div class="tbody">
            <record-row
              :record="record"
              v-for="record in records"
              :key="record.id"
              v-on:edit="editRecordAction"
              v-on:view="viewRecordAction"
            />
          </div>
        </div>
      </template>
    </page>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import RecordRow from "./records/RecordRow.vue";

export default {
  components: { RecordRow },
  name: "records",
  data() {
    return {
      showRecordDialog: false,
      recordId: null,
      editRecord: false
    };
  },
  mounted() {
    //this.$store.dispatch("records/pull", {opticId: this.active.id});
    this.loadRecords();
  },
  methods: {
    editRecordAction(id) {
      this.recordId = id;
      this.showRecordDialog = true;
      this.editRecord = true;
    },
    viewRecordAction(id) {
      this.recordId = id;
      this.showRecordDialog = true;
      this.editRecord = false;
    },
    addRecord() {
      this.recordId = null;
      this.showRecordDialog = true;
    },
    loadRecords() {
      this.$store.dispatch("records/pull", { opticId: this.active.id });
      this.$store.dispatch("customers/pull", { opticId: this.active.id });
    },
  },
  computed: {
    ...mapGetters("optics", ["active"]),
    ...mapGetters("records", ["records"]),
    ...mapGetters("customers", ["customers"])
  }
};
</script>

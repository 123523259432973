<template>
  <div class="relative">
    <div class="flex flex-row items-center">
      <button @click="isVisible = !isVisible" class="transform rotate-12 w-7 h-7 relative hover:bg-black rounded-full px-2">
        <i class="far fa-bell"></i>
        <span class="absolute top-0.5 -right-0.5 w-2 h-2 bg-red-500 rounded-full" v-if="unreadMsgs > 0"></span>
      </button>
    </div>
    <div
      v-show="isVisible"
      class="fixed top-10 overflow-hidden rounded-md border  mt-1 shadow-md bg-gray-100 w-300 h-full right-0"
    >
      <div class="overflow-y-auto h-full -mr-10 pr-10 text-black">
        <div class="row border-b py-4 items-center">
          <div class="font-semibold text-sm pl-4">Уведомления</div>
          <button @click="toggleVolume()" class="relative ml-2">
            <img src="/img/mute.svg" v-if="muted" class="w-4" v-tooltip="'Звук выключен'" />
            <img src="/img/volume.svg" v-if="!muted" class="w-4" v-tooltip="'Звук включен'" />
          </button>
        </div>

        <send-alert-root/>

        <div v-if="msgs.length == 0" class="py-4 text-sm px-4 text-gray-400">
          Пока ничего нет
        </div>
        <div class="flex flex-col">
          <div v-for="(msg, index) in sortedMessages" class="border-b" :key="msg._id">
            <div
              @click="readMessage(msg)"
              :class="!msg.read ? 'border-green-500 ' : 'border-transparent'"
              class="cursor-pointer border-l-4 transition-all flex flex-row px-4 py-2 text-sm items-start  leading-none hover:bg-white"
            >
              <div class="">
                <div class="rounded-md bg-green-500 w-2 h-2 mr-4 mt-2" v-if="!msg.read"></div>
                <div class="rounded-md bg-gray-400 w-2 h-2 mr-4 mt-2" v-else></div>
              </div>
              <div class="">
                <div class="text-sm font-semibold mb-1">{{ msg.title }}</div>
                <div class="text-sm mb-1">{{ msg.text }}</div>
                <span class="text-2xs text-gray-400 font-medium">{{ msg.created_at | recently }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  alerts container -->
    <transition-group
      name="bounce"
      tag="div"
      class="fixed top-4 left-1/2 transform -translate-x-1/2 w-3/4 md:w-1/3 z-20 space-y-2"
    >
      <float-alert v-for="(alert, index) in alerts" :key="index" :alert="alert" @dismiss="dismissAlert(index)" />
    </transition-group>
  </div>
</template>

<script>
import FloatAlert from "./FloatAlert.vue";
import SendAlertRoot from './SendAlertRoot.vue';

export default {
  name: "alerts",
  components: { FloatAlert, SendAlertRoot },
  data() {
    return {
      userId: this.$me.id,
      token: this.$me.token,
      msgs: [],
      msg: "",
      isVisible: false,
      limit: 0,
      total: 0,
      refreshKey: 0,
      alerts: [],
      audio: null,
      muted: true // mute by default
    };
  },
  mounted() {
    // check is notifications are muted
    this.muted = this.$me.muted ?? true;
    let vm = this;

    // lsiten for alerts
    this.$events.$on("message", msg => {
      vm.msgs.push(msg);

      if (!msg.record == undefined || (msg.record != undefined && msg.record.status == "new")) {
        vm.alerts.push(msg);
      }

      // and show desktop notification
      let isFocused = !document.hidden;

      if (!isFocused) {

        let notification = new Notification(msg.title, {
          body: msg.text,
          icon: "/favicon-32x32.png"
        });

        notification.onclick = event => {
          window.parent.parent.focus();
        };
      }

      // if it's new record
      if (msg.type == "info" && msg.record) {
        // reload records

        if (msg.record.status == "new" && msg.record.source == "widget") {
          if (!vm.muted) {
            try {
              vm.audio = new Audio("/sounds/loop.wav");
              vm.audio.volume = 0.5;
              vm.audio.play();
            } catch (e) {
              console.log(e.emssage);
            }
          }
          // convert unix time to date
          let date = vm.$dayjs
            .unix(msg.record.startTime)
            .utc()
            .format("DD.MM.YYYY HH:mm");
        }

        vm.$events.$emit("record-reload");
      }
    });

    this.getAlerts();

    this.$events.$on("store-changed", msg => {
      this.getAlerts();
    });

    if (this.$me.notificationsAsked == false) {
      // ask permission to send desktop notifications
      if (!("Notification" in window)) {
        //alert("Ваш браузер не поддерживает уведомления");
      } else if (Notification.permission === "granted") {
        // granted
      } else if (Notification.permission !== "denied") {
        // check if user never asked
        Notification.requestPermission().then(permission => {
          console.log(`Notification permission is ${permission}`);
        });
      } else {
        // denied
      }
      // set that we already asked about permission
      this.$store.dispatch("app/setUserField", { key: "notificationsAsked", value: true });
      this.$api.post("/me/settings", { notificationsAsked: true });
    }
  },
  methods: {
    toggleVolume() {
      this.muted = !this.muted;
      this.$store.dispatch("app/setUserField", { key: "muted", value: this.muted });
    },
    dismissAlert(index) {
      // make it as read
      // search msg
      console.log("dismiss alert");
      let alert = this.alerts[index];
      let find = this.msgs.find(m => m._id == alert._id);
      if (find != undefined) {
        this.readMessage(find);
        this.alerts.splice(index, 1);
      }

      if (this.audio != null) this.audio.pause();
    },
    async getAlerts() {
      let vm = this;
      let { success, msg, data } = await this.$api.get("/alerts");
      if (success) {
        this.msgs = data.list;
        // add not read alerts to alerts
        let index = 0;
        for (let msg of this.msgs.filter(msg => !msg.read)) {
          index++;
          setTimeout(() => {
            vm.alerts.push(msg);
          }, index * 1000);
        }
      }
    },
    clearAlerts() {},
    async readMessage(msg) {
      let vm = this;
      let findMessage = this.msgs.find(m => m._id == msg._id);
      findMessage.read = true;
      await this.$api.post(`/alerts/${msg._id}/read`);
      vm.refreshKey++;
    },
  },
  computed: {
    unreadMsgs() {
      this.refreshKey;
      return this.msgs.filter(msg => !msg.read).length;
    },
    sortedMessages() {
      this.refreshKey;
      let sorted = [...this.msgs];
      return sorted.sort((a, b) => this.$dayjs(b.created_at) - this.$dayjs(a.created_at));
    }
  }
};
</script>

<template>
  <div class="">
    <div class="flex flex-row space-x-4 mb-2 text-sm text-gray-400 font-medium">
      <div class="w-3/5">Название услуги</div>
      <div class="w-1/5">Длительность</div>
      <div class="w-1/5">Вознаграждение</div>
    </div>

    <template v-if="services.length > 0">
      <div class="flex flex-row space-x-4 py-1 relative border-b border-gray-100" v-for="(service, index) in services"
        :key="`${key}-${index}`">
        <div class="w-3/5 py-2">
          <toggle v-model="service.available" @input="++key">
            <span class="" :class="service.available ? 'text-black' : 'text-gray-400'">
              {{ service.title }}
            </span>
          </toggle>
        </div>

        <select v-if="service.available" v-model="service.time" class="w-1/5 px-5 py-2 ">
          <option v-for="(opt, ind) of $timeslots()" :key="ind">
            {{ opt }}
          </option>
        </select>

        <input class="w-1/5 input-sm" v-if="service.available" type="text" v-model="service.earn" placeholder="100" />
      </div>
    </template>

    <!--  -->
    <div class="mt-4">
      <div class="text-sm text-gray-400 font-medium mb-2">Стоимость смены, руб.</div>
      <input type="number" v-model="user.costPerShift" />
    </div>

    <div class="flex flex-row items-center justify-between mt-6">
      <button type="button" @click="$emit('save', services)" :class="{ busy: busy }" :disabled="busy"
        class="btn-primary">
        Сохранить
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["user", "busy"],
  data() {
    return {
      services: [],
      key: 0
    };
  },
  mounted() {
    this.loadServices();
  },
  methods: {
    async loadServices() {
      // get services from the system
      let { success, msg, data } = await this.$api.get("/services");
      this.services = data;
      // match it with user services
      this.services = this.services.map(item => {
        let f = this.user.services.find(i => i.id == item.id);
        if (f !== undefined) {
          item.available = f.available;
        } else {
          item.available = false;
        }

        return item;
      });
    }
  },
  computed: {
    ...mapGetters("optics", ["optics", "active"])
  }
};
</script>

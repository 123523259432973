<template>
    <div class="flex flex-col px-4 py-2 bg-white" v-if="$me.root">
        <div class="mb-2 text-xs text-gray-600">Отправить уведомление всем сотрудникам. Сообщение будет отправлено всем
            пользователям, если они не в сети, то увидят его после логина.</div>
        <div class="mb-2">
            <select v-model="alertType" class=" text-xs py-0 px-2">
                <option value="info">Информация</option>
                <option value="error">Ошибка</option>
                <option value="success">Успешно</option>
            </select>
        </div>
        <textarea v-model="alertMsg" placeholder="Сообщение" class="text-xs"></textarea>
        <div class="mt-2">
            <button class="btn-default" @click="sendNotification()" :disabled="busy" :class="{ busy: busy }">
                Отправить
            </button>
            <div class="text-xs mt-2 text-gray">

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SendAlert",
    data() {
        return {
            busy: false,
            alertMsg: "",
            alertType: "info"
        };
    },
    async mounted() {
        // on first login today, generate greetings
    },
    methods: {
        sendNotification() {
            this.busy = true;
            let msg = {
                title: `@${this.$me.firstName} ${this.$me.lastName}`,
                type: this.alertType,
                text: this.alertMsg,
                to: "all",
                from: "root",
                store_id: this.$dept.id,
                created_by: this.$me.id
            };
            this.$sockets.send(msg);

            setTimeout(() => {
                this.busy = false;
            }, 1000);
        }
    }
};
</script>
<template>
  <page-short>
    <template v-slot:title>
      <back-btn></back-btn>
      <template v-if="supplier.id">Поставщик {{ supplier.name }}</template>
      <template v-else>Новый поставщик</template>
    </template>
    <template v-slot:content>
      <div class="">
        <form class="" @submit.prevent>
          <div class="flex flex-row gap-x-4">
            <div class="w-2/3 bg-white rounded-md px-3 py-2">
              <div class="grid grid-cols-6 gap-6">
                <div class="col-span-4">
                  <div class="">
                    <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">Название</label>
                    <input
                      class="w-full "
                      id="title"
                      name="title"
                      type="text"
                      required=""
                      v-model="supplier.name"
                      placeholder="Название"
                      aria-label="title"
                    />
                  </div>
                </div>

                <div class="col-span-2">
                  <div class="">
                    <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">ИНН</label>
                    <input class="w-full" type="text" v-model="supplier.inn" placeholder="ИНН" maxlength="11" />
                  </div>
                </div>

                <div class="col-span-2">
                  <div class="">
                    <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">Общий телефон</label>
                    <tel-input class="w-full" type="text" v-model="supplier.details.phone" placeholder="Телефон" />
                  </div>
                </div>
                <div class="col-span-3">
                  <div class="">
                    <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">Общая почта</label>
                    <input class="w-full" type="text" v-model="supplier.details.email" placeholder="Электронная почта" />
                  </div>
                </div>

                <div class="col-span-6">
                  <div class="">
                    <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
                      Название в XML файлах
                    </label>
                    <div class="flex flex-row gap-x-1 flex-wrap gap-y-1 rounded-md border border-gray-200 py-1 px-1">
                      <div v-for="(tag, index) in supplier.altnames" class="input-tag" :key="index">
                        {{ tag }}
                        <button class="ml-1" type="button" @click="supplier.altnames.splice(index, 1)">
                          <i class="fas fa-times text-xs"></i>
                        </button>
                      </div>
                      <input
                        type="text"
                        class="py-0.5 px-1 border-0 bg-gray-50 flex-1"
                        placeholder="Введите новый..."
                        @keydown.enter.self="addTag"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-1/3">
              <div class="bg-white px-3 py-2 rounded-md mb-4">
                <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
                  Категория
                </label>
                <select v-model="supplier.category">
                  <option value="">Категория</option>
                  <option value="lenses">Контактные линзы</option>
                  <option value="glass-lenses">Очковые линзы</option>
                  <option value="frames">Оправы</option>
                </select>
              </div>

              <div class="bg-white px-3 py-2 rounded-md">
                <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
                  Заметка
                </label>
                <textarea class="w-full" type="text" v-model="supplier.details.comment"></textarea>
              </div>
            </div>
          </div>

          <div class="mt-4 w-full  bg-white rounded-md">
            <table class="data-table">
              <thead>
                <tr>
                  <th>Город</th>
                  <th>Адрес</th>
                  <th>Телефон</th>
                  <!-- <th>Режим работы</th> -->
                  <th>Email для заказов</th>
                  <th>ID Контрагента</th>
                  <th>Шаблон</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(city, index) in supplier.cities">
                  <td>
                    <select v-model="city.name" @change="checkCity(city)">
                      <option></option>
                      <option v-for="c in cities" :value="c">{{ c }}</option>
                    </select>
                  </td>
                  <td><input class="w-full" type="text" v-model="city.address" placeholder="" /></td>
                  <td><input class="w-full" type="text" v-model="city.phone" placeholder="" /></td>
                  <!-- <td><input class="w-full" type="text" v-model="city.hours" placeholder="" /></td> -->
                  <td><input class="w-full" type="text" v-model="city.email" placeholder="" /></td>
                  <td><input class="w-full" type="text" v-model="city.guid" placeholder="" /></td>
                  <td><input class="w-full" type="text" v-model="city.xml_template" placeholder="" /></td>
                  <td><button type="button" class="btn-secondary" @click="supplier.cities.splice(index, 1)">Удалить</button></td>
                </tr>
              </tbody>
            </table>

            <div class="px-3 py-2">
              <button type="button" class="text-xs text-blue-500" @click="addCity()" v-if="supplier.cities.length < cities.length">
                + Добавить город
              </button>
            </div>
          </div>

          <error v-if="error">{{ error }}</error>
          <success v-if="status == 'success'"></success>

          <div class="">
            <button type="button" @click="save()" :class="{ busy: saveBusy }" :disabled="saveBusy" class="btn-primary-sm mt-4">
              Сохранить
            </button>
          </div>
        </form>
      </div>
    </template>
  </page-short>
</template>

<script>
import api from "../../api/api.js";
const cities = [
  "Москва",
  "Санкт-Петербург",
  "Новосибирск",
  "Екатеринбург",
  "Казань",
  "Нижний Новгород",
  "Красноярск",
  "Челябинск",
  "Самара",
  "Уфа",
  "Ростов-на-Дону",
  "Краснодар",
  "Омск",
  "Воронеж",
  "Пермь",
  "Волгоград",
  "Саратов",
  "Тюмень"
];
export default {
  components: {},
  name: "external",
  data() {
    return {
      cities: cities,
      supplier: {
        id: "",
        name: "",
        inn: "",
        details: {
          phone: "",
          contact: "",
          address: "",
          comment: "",
          hours: ""
        },
        altnames: [],
        cities: []
      },
      busy: false,
      error: "",
      status: "",
      statusMessage: "",
      saved: false,
      saveBusy: false
    };
  },
  mounted() {
    if (this.$route.params.id != "new") {
      this.getItem();
    }
  },
  methods: {
    addTag(event) {
      // check if such tag not exist yet
      if (event.target.value != "") {
        if (!this.supplier.altnames.includes(event.target.value)) {
          this.supplier.altnames.push(event.target.value);
          event.target.value = "";
        }
      }
    },
    addCity() {
      this.supplier.cities.push({
        name: "",
        email: "",
        addr: "",
        phone: "",
        xml_template: "",
        guid: "",
        hours: ""
      });
    },
    async getItem() {
      this.busy = true;
      let { success, msg, data } = await api.get(`/g/suppliers/${this.$route.params.id}`);
      this.supplier = data;
      this.busy = false;
    },
    async save() {
      this.saveBusy = true;

      let { success, msg, data } = await api.post("/g/suppliers", this.supplier);

      this.saveBusy = false;

      if (success) {
        this.supplier = data;
        this.status = "success";

        // update suppliers list in store
        this.$store.dispatch("optics/pullSuppliers");
      } else {
        this.status = msg;
      }
    },
    /**
     * Be sure that we have 1 city per each supplier
     */
    checkCity(city) {
      // check if such city already added
      if (this.supplier.cities.filter(c => c.name == city.name && c.name != "").length > 1) {
        city.name = "";
      }
    }
  }
};
</script>

<template>
  <modal :styles="'max-w-lg'">
    <div>
      <h3 class="text-black font-bold text-xl mb-6 text-center">
        {{ $t("changeStockQty") }}
      </h3>

      <div class="mt-2">
        <input type="text" class="text-sm py-1 px-2 w-2/4" ref="qty" v-model="qty" v-number="{ min: 0 }"
          placeholder="Актуальное количество" required />
      </div>

      <div class="flex flex-col mt-6">
        <button type="button" class="btn-primary-sm" @click="save()">
          Сохранить
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "../modals/Modal.vue";
import ModalMixin from "../modals/modal.mixin";

export default {
  components: { Modal },
  mixins: [ModalMixin],
  props: ["product"],
  data() {
    return {
      qty: 0
    };
  },
  mounted() {
    this.$refs.qty.focus();
    this.qty = this.product.quantity;
  },
  methods: {
    async save() {
      // save lot list to product in db
      if (this.qty == '') {
        this.qty = 0;
      }

      let { success, msg, data } = await this.$api.post(`/products/${this.product.id}/qty`, { qty: this.qty });
      if (success) {
        // close modal
        this.close(true);
      } else {
        alert(msg);
      }
    }
  }
};
</script>

<template>
  <div>
    <form @submit.prevent>
      <div class="flex flex-row flex-wrap">
        <div>
          <div class="relative">
            <i class="fas fa-barcode"></i>
            <input ref="code" type="text" placeholder="Штрихкод / Лот" v-model="code" class="py-1 px-2 ml-2" autofocus
              required name="barcode" :disabled="wait" tabindex="1" v-on:keyup.enter="scan()" />
            <div class="busy absolute -right-1 top-3.5 blue" v-if="wait"></div>
            <div class="absolute -bottom-4.5 text-2xs left-6 text-gray-500 font-medium" v-if="lastCode != ''">
              <i class="fas fa-clock-rotate-left mr-1"></i> {{ lastCode }}
            </div>
          </div>
        </div>
        <div v-if="showLotField">
          <input ref="lot" type="text" placeholder="Лот" v-model="lot" class="py-1 px-2 ml-2" :disabled="wait"
            tabindex="2" v-on:keyup.enter="scanLot()" />
        </div>
        <!--  Show date fields -->
        <div v-if="showDateField">
          <input ref="month" type="text" placeholder="00" v-model="month" class="py-1 px-2 ml-2 w-12 text-right"
            maxlength="2" :disabled="wait" tabindex="3" v-on:keyup.enter="scanDate('month')" />
          /
          <input ref="year" type="text" placeholder="0000" v-model="year" class="py-1 px-2 ml-0 w-16 text-right"
            maxlength="4" :disabled="wait" tabindex="3" v-on:keyup.enter="scanDate('year')" />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Product from "../../../repo/ProductRepo.js";
import GlobalProduct from "../../../repo/GlobalProductRepo.js";

let alertTimeoutMs = 900;

export default {
  props: ["cart", "category"],
  data() {
    return {
      code: "",
      wait: false,
      product: {},
      showLotField: false,
      showDateField: false,
      lot: "",
      year: "",
      month: "",
      date: "",
      lastCode: ''
    };
  },
  mounted() {
    this.$refs.code.focus();
  },
  methods: {
    scanDate(param) {
      // move focus next
      if (param == "month") {
        this.date = `${this.month}/${this.year}`;
        this.$refs.year.focus();
      }

      if (param == "year") {
        this.date = `${this.month}/${this.year}`;
        this.emitProduct();
      }

      this.peaksound();
    },
    async scanLot() {
      this.peaksound();
      let vm = this;

      if (this.isLotAlreadyScanned(this.lot)) {
        this.failsound();
        this.$swal({ icon: 'error', text: 'Такой лот уже отсканирован', timer: alertTimeoutMs, showConfirmButton: false });
        return;
      }

      // search for lot
      let lotFound = this.product.lots ? this.product.lots.find(el => el.lot == this.lot) : false;
      if (lotFound) {
        this.product.lot = lotFound;
        this.emitProduct();
        this.oksound();
      } else {
        //this.failsound();
        // such lot not found in product, need to add date
        this.showDateField = true;
        setTimeout(function () {
          vm.$refs.month.focus();
        }, 0);
      }

      //   let { success, msg, data } = await Product.isLotExists(this.lot);
      //   if (success) {
      //     if (data.items.length > 0) {
      //       this.product = data.items[0];
      //       this.product.lot = this.lot;
      //       this.emitProduct();
      //     }
      //   }
    },
    async scan() {
      let vm = this;

      this.code = this.code.replace(/ /gi, "");

      if (this.code == '' && this.lot.length == 0) return;

      if (this.code != "" && this.lot.length != "") {
        // search product by barcode and attach lot
      }

      this.peaksound();

      //let lot = "L003G0326X"; // 10 symbols
      if (this.code.length == 10) {
        // check lot in cart
        if (this.isLotAlreadyScanned(this.code)) {
          this.failsound();
          this.$swal({ icon: 'error', text: 'Такой лот уже отсканирован', timer: alertTimeoutMs, showConfirmButton: false });
          return;
        }

        let { success, msg, data } = await Product.isLotExists(this.code);
        if (success) {
          if (data.items.length > 0) {
            this.product = data.items[0];
            this.lot = this.code;
            this.scanLot();
            return;
          } else {
            // ask to scan a barcode ad date field
            this.showLotField = true;
            this.lot = this.code;
            this.showDateField = true;
            this.code = "";
            this.$refs.code.focus();
          }
        }
      }

      

      // if barcode scanned
      if (this.code.length > 10) {
        this.wait = true;
        let { success, msg, data } = await Product.scanBarcode(this.code);

        // if product not found in our database for current address
        // search in global products
        if (success && data.items.length == 0) {
          ({ success, msg, data } = await GlobalProduct.scanBarcode(this.code));
        }

        this.wait = false;

        if (success) {
          if (data.items.length > 0) {
            this.product = data.items[0];

            // check if such barcode already in a list and has a lots

            // check lot
            if (
              (this.product.lots && this.product.lots.length > 0 && this.lot == "") ||
              this.isBarcodeHasLots(this.code)
            ) {
              this.showLotField = true;
              this.lastCode = this.code;

              setTimeout(function () {
                vm.$refs.lot.focus();
              }, 0);
              return;
            }

            // else emit product
            this.emitProduct();
            return;
          }
        }
      }

      this.failsound();
      this.$swal({ icon: 'error', text: 'Такого штрихкода не существует', timer: alertTimeoutMs, showConfirmButton: false });
      this.lastCode = this.code;
      this.code = '';

      setTimeout(() => {
        vm.$refs.code.focus();
      }, 100)


    },
    emitProduct() {
      let vm = this;

      if (this.showDateField) {
        if (this.month == "") {
          this.$refs.month.focus();
          return;
        }
        if (this.year == "") {
          this.$refs.year.focus();
          return;
        }

        // add data and lot to product
        this.product.lot = { lot: this.lot, date: this.date };
      }

      // check category if is not empty
      // check category
      if (this.category && this.category != 'all') {
        if (this.product.category != this.category) {
          this.failsound();
          this.lastCode = this.code;
          this.code = '';
          this.$swal('Товар не соответствует категории. Вы должны сканировать только товары с категорией ' + this.category);
          setTimeout(() => {
            vm.$refs.code.focus();
          }, 100)
          return;
        }
      }

      this.$emit("scan", this.product);
      this.oksound();
      setTimeout(() => {
        vm.$refs.code.focus();
      }, 100)
      this.product = {};
      this.lot = "";
      this.lastCode = this.code;
      this.code = "";
      this.showDateField = this.showLotField = false;
      this.month = this.year = "";
    },
    failsound() {
      let audio = new Audio("/sounds/error.wav");
      audio.play();
    },
    oksound() {
      let audio = new Audio("/sounds/success.wav");
      audio.play();
    },
    peaksound() {
      let audio = new Audio("/sounds/peak.wav");
      audio.play();
    },
    isBarcodeHasLots(barcode) {
      return this.cart.findIndex(el => el.barcode == barcode && el.lots.length > 0) != -1;
    },
    isLotAlreadyScanned(lot) {
      let isScanned =
        this.cart.findIndex(
          el =>
            el.lots && el.lots.length > 0 && el.lots.findIndex(elliot => elliot.lot == lot) != -1
        ) != -1;

      console.log("is scanned?" + isScanned);
      return isScanned;
    }
  }
};
</script>

<template>
  <modal>
    <h3 class="text-black font-bold text-xl mb-6">
      Новый адрес
    </h3>

    <form class="" @submit.prevent="save()">
      <div class="">
        <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">Название</label>
        <input
          class="w-full "
          id="title"
          name="title"
          type="text"
          required=""
          v-model="optic.title"
          placeholder=""
          aria-label="title"
        />
      </div>

      <div class="flex flex-row items-center justify-between mt-6">
        <button type="submit" :class="{ busy: busy }" :disabled="busy" class="btn-primary-sm">
          Добавить
        </button>
      </div>
    </form>
  </modal>
</template>

<script>
import Modal from "../modals/Modal.vue";
import ModalMixin from "../modals/modal.mixin";
import Optic from "../../repo/OpticRepo";

export default {
  components: { Modal },
  mixins: [ModalMixin],
  data() {
    return {
      loading: false,
      busy: false,
      status: "",
      optic: {
        id: "",
        title: ""
      }
    };
  },
  methods: {
    async save() {
      this.busy = true;

      let { success, msg, data } = await Optic.create(this.optic);

      this.busy = false;

      if (success) {
        this.optic = data;
        this.status = "save-success";
        // reload items in store
        //this.$store.dispatch("optics/pullOptics");
        this.close();
        // redirect to optic page
        this.$router.push({ path: "optics/" + this.optic.id });
      } else {
        this.status = msg;
      }
    }
  }
};
</script>

<template>
  <div>
    <!-- Complaints -->
    <div v-for="field in fields">
      <tags :obj="record[field]" :field="field" />
    </div>

    <div class="mt-4">
      <div class="font-semibold text-sm mb-2">Комментарий</div>
      <textarea class="w-full" v-model="record.complaintsComment"></textarea>
    </div>
  </div>
</template>

<script>
export default {
  props: ["record"],
  data() {
    return {
      fields: ["complaints", "complaintsLenses", "eyeStrain"]
    };
  },
  mounted() {},
  methods: {}
};
</script>

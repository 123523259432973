<template>
  <button @click="viewSale(sale.id)" class="">
    <template v-if="sale.saleNumber">
      <span class="text-blue-600 font-bold hover:text-blue-500">
        {{ sale.saleNumber | pad }}
      </span>
    </template>
    <template v-else>
      <span class="btn-default whitespace-nowrap">
        {{ $t('viewOrder') }}
      </span>
    </template>
  </button>
</template>
<script>
import ViewSale from "../sales/ViewSale";

export default {
  props: ["sale"],
  methods: {
    viewSale() {
      this.$modals.open(ViewSale, { id: this.sale.id });
    }
  }
};
</script>

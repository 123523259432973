<template>
  <div>

    <div class="flex flex-row items-center mb-4 gap-x-4 text-sm">
      <button class="px-2 py-1 rounded-md" @click="tab = 'keys'" :class="{ 'text-white bg-gray-800': tab == 'keys' }">
        <i class="fab fa-telegram"></i> Ключи API
      </button>
      <button class="px-2 py-1 rounded-md" @click="tab = 'widget'"
        :class="{ 'text-white bg-gray-800': tab == 'widget' }">
        Самозапись
      </button>
      <button class="px-2 py-1 rounded-md" @click="tab = 'telephony'"
        :class="{ 'text-white bg-gray-800': tab == 'telephony' }">
        Телефония
      </button>
    </div>

    <div class="bg-white px-5 py-4 rounded-md" v-if="tab == 'keys'">
      <div class="mb-4">
        <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
          Идентификатор филиала
        </label>
        <input class="w-full" :value="optic.id" type="text" readonly disabled />
      </div>

      <div v-for="key in keys">
        <div class="mb-3">
          <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">Токен</label>
          <input class="w-full" :value="key.token" type="text" readonly disabled />
        </div>
      </div>

      <div v-if="keys.length == 0" class="text-gray-400 py-4">
        У вас пока нет ключей
      </div>

      <button class="btn-action mt-4" @click="generateNewKey()">Сгенерировать новый ключ</button>

      <loading v-if="busy" />
    </div>

    <div class="bg-white px-5 py-4 rounded-md max-w-xl " v-if="tab == 'widget'">
      <p>Чтобы установить виджет, скопируйте код ниже и вставьте в нужное место на вашем сайте.</p>
      <div class="font-mono px-6 py-4 bg-black text-white rounded-md shadow relative">
        {{ getWidgetCode() }}
        <button @click="copyToClipboard(getWidgetCode())" class="absolute right-3 top-2 text-yellow-400">
          Скопировать код {{ copied ? "✔️" : "" }}
        </button>
      </div>
    </div>

    <div class="bg-white px-5 py-4 rounded-md max-w-xl relative" v-if="tab == 'telephony'">

      <h2 class="font-semibold text-xl mb-4 mt-4 flex flex-row items-center">
        <div>
          Пользователи MANGO
        </div>
      </h2>

      <loading v-if="mangoBusy" />
      <template v-if="mangoUsers && mangoUsers.length > 0">
        <label class="block py-2 rounded-md cursor-pointer px-3  bg-white "
          v-for="{ general, telephony } of mangoUsers">
          <span class="text-sm">
            <input type="checkbox" v-model="telephony.checked">
            <span class="font-semibold ml-2">{{ general.name }}</span>
          </span>
          <div class="text-xs mt-1 text-gray-700"> extension: {{ telephony.extension }} </div>
          <div class="text-xs mt-1 text-gray-700"> numbers: {{ telephony.numbers.map(e => e.number).join(", ") }} </div>
        </label>
        <button class="btn-action mt-4" :class="{ busy: mangoBusy }" :disabled="mangoBusy"
          @click="saveMangoUsers">Сохранить</button>
      </template>

      <error v-if="mangoError">{{ $t(`sip.errors.${mangoError}`) }}</error>

    </div>
  </div>
</template>

<script>
export default {
  name: "integration-settings",
  data() {
    return {
      keys: [],
      busy: false,
      mangoBusy: false,
      copied: false,
      mangoUsers: [],
      mangoError: null,
      tab: "keys"
    };
  },
  props: ["optic"],
  mounted() {
    //get secrets via api
    this.loadKeys();
    this.loadMangoUsers()
  },
  methods: {
    async loadKeys() {
      this.busy = true;
      let { success, msg, data } = await this.$api.get(`/optics/${this.optic.id}?field=keys`);
      if (success) {
        this.keys = data;
      }

      this.busy = false;
    },
    async loadMangoUsers() {
      // get mango users via api
      this.mangoBusy = true
      let { success, msg, data } = await this.$api.get(`/calls/users`);

      if (success) {
        this.mangoUsers = data.sipAccounts

      } else {
        this.mangoError = msg;
      }

      setTimeout(() => {
        this.mangoBusy = false
      }, 500)
    },
    async saveMangoUsers() {
      this.mangoBusy=true
      const mangoNumbers=this.mangoUsers.filter(e=>e.telephony.checked).map(e=>e.telephony.numbers.map(el=>el.number)).flat().filter(e=>e.indexOf("sip")+1)
      let { success, msg } = await this.$api.put(`/optics/${this.optic.id}?action=save-mango-users`,mangoNumbers);
      if (!success) {
        console.log('saveMangoUsers neok');
        console.log(msg);
      }
      this.mangoBusy = false
    },

    async generateNewKey() {
      let { success, msg, data } = await this.$api.put(`/optics/${this.optic.id}?action=new-key`);
      if (success) {
        this.loadKeys();
      }
    },
    getWidgetCode() {
      let ids = [this.optic.id]; //this.$depts.map(el => el.id);
      var host = window.location.host;
      var subdomain = host.split(".")[0];
      let mode = 0;
      let code = `<div id="mnkl-widget" data-id="${ids.join(",")}" data-mode="${mode}" data-domain="${subdomain}"> </div>`;
      let scriptString = `
      (function() { 
          var script = document.createElement('script'); 
          script.src = 'https://${subdomain}.mnkl.ru/widget/v2/widget.js'; 
          document.body.appendChild(script); 
      })(); 
      `;

      return code + `${["<", "script", ">"].join("")}` + scriptString + `${["<", "/script", ">"].join("")}`;
    },
    copyToClipboard(text) {
      let self = this;

      if (!navigator.clipboard) {
        // Clipboard API is not available, fallback to older method
        var textArea = document.createElement("textarea");
        textArea.value = text;
        textArea.style.position = "fixed"; // avoid scrolling to bottom
        textArea.style.opacity = "0";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
          var successful = document.execCommand("copy");
          if (successful) {
            console.log("Fallback: Copying text command was successful");
            this.copied = true;
          } else {
            console.error("Fallback: Copying text command was unsuccessful");
          }
        } catch (err) {
          console.error("Fallback: Oops, unable to copy", err);
        }

        setTimeout(() => {
          self.copied = false;
        }, 500);

        document.body.removeChild(textArea);
        return;
      }

      // Clipboard API method
      navigator.clipboard.writeText(text).then(
        function () {
          console.log("Async: Copying to clipboard was successful!");
        },
        function (err) {
          console.error("Async: Could not copy text: ", err);
        }
      );
    }
  }
};
</script>

<template>
  <div class="secondary-sidebar min-h-full py-4">
    <div class="mt-3 px-7 ">
      <span class="font-semibold text-2xl text-black">Настройки адреса</span>
    </div>

    {{ $secondaryComponent }}
    <ul class="flex flex-col my-4">
      <li class="secondary-menu-link">
        <a href="">
          <i class="fas fa-cogs mr-1"></i>
          Основное
        </a>
      </li>
      <li class="secondary-menu-link">
        <a href="">
          <i class="fas fa-clock mr-1"></i>
          Режим работы
        </a>
      </li>
      <li class="secondary-menu-link">
        <a href="">
          <i class="fas fa-cogs mr-1"></i>
          Настройки
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "SecondarySidebar"
};
</script>

import Vue from "vue";
import CustomerRepo from "../../repo/CustomerRepo";

const state = {
  customers: []
};

const getters = {
  customers: state => {
    return state.customers;
  },
  getById: state => ({ id }) => {
    return state.customers.find(item => item.id == id);
  }
};

// actions
const actions = {
  async pull(context, { opticId }) {
    const { data } = await CustomerRepo.get({ opticId: opticId });
    context.commit("setCustomers", data);
  },
  wipe: ({ commit }) => {
    commit("wipe");
  }
};

// mutations
const mutations = {
  setCustomers(state, payload) {
    state.customers = payload;
  },
  wipe(state) {
    state.customers = [];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

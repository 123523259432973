import api from "../api/api";
const resource = "/g/brands";

export default {
  async get(id = false) {
    let { success, msg, data } = await api.call(`${resource}${id ? '/' + id : ''}`);
    return data;
  },
  getGlobal() {
    return api.call(`${resource}?all`);
  },
  search(term) {
    if (term) return api.call(`${resource}/search/${term}`, { method: "get" });
  },
  getByid(id) {
    return api.call(`${resource}/${id}`, { method: "get" });
  },
  update(payload) {
    return api.call(`${resource}`, { method: "post", data: payload });
  },
  delete(id) {
    return api.call(`${resource}/${id}`, { method: "delete" });
  }
};

<template>
  <div class="-mt-0.5">
    <div>
      <div
        @click="(state = !state), emitt(state)"
        class="relative inline-block w-8 mr-2 align-middle select-none transition duration-200 ease-in"
      >
        <div>
          <input type="checkbox" @change="emitt" v-model="state" class="toggle-checkbox sm" />
          <span
            class="toggle-label block overflow-hidden h-4 rounded-full bg-gray-200 cursor-pointer"
          ></span>
        </div>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["default"],
  data() {
    return {
      state: false
    };
  },
  mounted() {
    this.state = this.default;
  },
  methods: {
    emitt(event) {
      console.log('emitt')
      this.$emit("updated", this.state);
    }
  }
};
</script>

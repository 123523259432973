<template>
  <modal :styles="'max-w-2xl'">
    <div>
      <!-- Filter -->
      <div class="filters mb-4 gap-4 bg-white rounded-md">
        <div class="py-10" v-if="filterBusy">
          <loading />
        </div>

        <div class="text-lg md:text-lg font-semibold">
          Рецепт
<!--          filter["params.Оптическая сила"] || filter["params.Оптическая сила цилиндра"] || filter["params.Ось линзы"] || filter["params.Аддидация линзы"]-->
          <a
            href="#"
            v-if='Object.keys(filter).filter(key=>key.indexOf("params")+1).length'
            @click.prevent='clearFilters'
            class="ml-5 text-red-700"
          >очистить</a>
        </div>
        <div class="flex flex-row">
          <div class="m-2">
            <div class="text-gray-700"><strong>PWR/SPH</strong>
              <div class="relative group inline-block cursor-pointer">
                <div><i class="fas fa-info-circle text-blue-300 hover:text-blue-500"></i></div>
                <div class="absolute min-w-120 top-4 shadow-lg bg-blue-500 text-white rounded-md px-3 py-2 left-0 group-hover:block hidden z-10">Оптическая сила</div>
              </div>
            </div>
            <select @change="getProducts()" :disabled="!params.dio.length" class="form-select" name="select-power" v-model='filter["params.Оптическая сила"]'>
              <option v-for="item in params.dio" :value="item">{{ item }}</option>
            </select>
          </div>
          <div class="m-2">
            <div class="text-gray-700"><strong>CYL</strong>
              <div class="relative group inline-block cursor-pointer">
                <div><i class="fas fa-info-circle text-blue-300 hover:text-blue-500"></i></div>
                <div class="absolute min-w-120 top-4 shadow-lg bg-blue-500 text-white rounded-md px-3 py-2 left-0 group-hover:block hidden z-10">Оптическая сила цилиндра</div>
              </div>
            </div>
            <select  name="select-power" v-model='filter["params.Оптическая сила цилиндра"]'>
              <option v-for="item in params.cyl" :value="item">{{ item }}</option>
            </select>
          </div>
          <div class="m-2">
            <div class="text-gray-700"><strong>AX</strong>
              <div class="relative group inline-block cursor-pointer">
                <div><i class="fas fa-info-circle text-blue-300 hover:text-blue-500"></i></div>
                <div class="absolute min-w-120 top-4 shadow-lg bg-blue-500 text-white rounded-md px-3 py-2 left-0 group-hover:block hidden z-10">Ось линзы</div>
              </div>
            </div>
            <select name="select-ax" v-model='filter["params.Ось линзы"]'>
              <option v-for="item in params.ax" :value="item">{{ item }}</option>
            </select>
          </div>
          <div class="m-2">
            <div class="text-gray-700"><strong>ADD</strong>
              <div class="relative group inline-block cursor-pointer">
                <div><i class="fas fa-info-circle text-blue-300 hover:text-blue-500"></i></div>
                <div class="absolute min-w-120 top-4 shadow-lg bg-blue-500 text-white rounded-md px-3 py-2 left-0 group-hover:block hidden z-10">Аддидация линзы</div>
              </div>
            </div>
            <select name="select-add" v-model='filter["params.Аддидация линзы"]'>
              <option v-for="item in params.add" :value="item">{{ item }}</option>
            </select>
          </div>
        </div>
        <div class="flex flex-row mb-5">
          <div class="m-2">
            <div class="text-gray-700"><strong>Срок</strong>
              <div class="relative group inline-block cursor-pointer">
                <div><i class="fas fa-info-circle text-blue-300 hover:text-blue-500"></i></div>
                <div class="absolute min-w-120 top-4 shadow-lg bg-blue-500 text-white rounded-md px-3 py-2 left-0 group-hover:block hidden z-10">Срок ношения</div>
              </div>
            </div>
            <select @change="getProducts()" :disabled="!params.zamena.length" class="form-select" name="select-power" v-model='filter["params.Срок замены"]'>
              <option v-for="item in params.zamena" :value="item">{{ item }}</option>
            </select>
          </div>
          <div class="m-2">
            <div class="text-gray-700"><strong>Тип</strong>
              <div class="relative group inline-block cursor-pointer">
                <div><i class="fas fa-info-circle text-blue-300 hover:text-blue-500"></i></div>
                <div class="absolute min-w-120 top-4 shadow-lg bg-blue-500 text-white rounded-md px-3 py-2 left-0 group-hover:block hidden z-10">Тип линз</div>
              </div>
            </div>
            <select @change="getProducts()" :disabled="!params.type.length" class="form-select" name="select-power" v-model='filter["params.Тип линз"]'>
              <option v-for="item in params.type" :value="item">{{ item }}</option>
            </select>
          </div>
          <div class="m-2">
            <div class="text-gray-700"><strong>Радиус</strong>
              <div class="relative group inline-block cursor-pointer">
                <div><i class="fas fa-info-circle text-blue-300 hover:text-blue-500"></i></div>
                <div class="absolute min-w-120 top-4 shadow-lg bg-blue-500 text-white rounded-md px-3 py-2 left-0 group-hover:block hidden z-10">Радиус кривизны</div>
              </div>
            </div>
            <select @change="getProducts()" :disabled="!params.radius.length" class="form-select" name="select-power" v-model='filter["params.Радиус кривизны"]'>
              <option v-for="item in params.radius" :value="item">{{ item }}</option>
            </select>
          </div>
          <!--        <div class="m-2">-->
          <!--          <div class="text-gray-700"><strong>Кол-во</strong>-->
          <!--            <div class="relative group inline-block cursor-pointer">-->
          <!--              <div><i class="fas fa-info-circle text-blue-300 hover:text-blue-500"></i></div>-->
          <!--              <div class="absolute min-w-120 top-4 shadow-lg bg-blue-500 text-white rounded-md px-3 py-2 left-0 group-hover:block hidden z-10">Кол-во в упаковке</div>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <select @change="getProducts()" :disabled="!params.pack.length" class="form-select" name="select-power" v-model='filter["params.Упаковка"]'>-->
          <!--            <option v-for="item in params.pack" :value="item">{{ item }}</option>-->
          <!--          </select>-->
          <!--        </div>-->
        </div>


        <div>
          <span class="text-lg md:text-lg font-semibold mb-2">Категория: {{ filter.category }}</span>
          <a href="#" v-if="filter.category" @click.prevent="filter.category = ''" class="ml-5 text-red-700">очистить</a>
        </div>
        <div class="mb-5">
      <span
        id="badge-dismiss-dark"
        class="inline-flex items-center px-2 py-1 me-2 mr-2 mb-2 text-sm font-medium bg-blue-200 text-gray-800 rounded dark:bg-gray-700 dark:text-gray-300 cursor-pointer"
        v-for="(cat, indx) of wrap(categories, isWrap.categories)"
        :key="'category-'+indx"
        :class="{'list-selector-active':filter.category==cat.name}"
        @click="filter.category=cat.name"
      >
{{ cat.name }} </span>
          <span
            v-if="categories.length>5"
            class="inline-flex items-center px-2 py-1 me-2 mr-2 mb-2 text-sm font-medium underline text-gray-800 rounded dark:bg-gray-700 dark:text-gray-300 cursor-pointer"
            @click.prevent="isWrap.categories=!isWrap.categories"
          >{{ (isWrap.categories) ? 'показать' : 'свернуть'}} все
        </span>
        </div>

        <div>
          <span class="text-lg md:text-lg font-semibold mb-2">Производитель: {{ filter.manufacturer }}</span>
          <a href="#" v-if="filter.manufacturer" @click.prevent="filter.manufacturer = ''" class="ml-5 text-red-700">очистить</a>
        </div>
        <div class="mb-5">
          <span
        id="badge-dismiss-dark"
        class="inline-flex items-center px-2 py-1 me-2 mr-2 mb-2 text-sm font-medium disabled:bg-slate-50 bg-blue-200 text-gray-800 rounded dark:bg-gray-700 dark:text-gray-300 cursor-pointer"
        v-for="(maker, indx) of wrap(filteredMakers, isWrap.manufacturers)"
        :key="'maker-'+indx"
        :class="{'list-selector-active':filter.manufacturer==maker.name}"
        @click="filter.manufacturer = maker.name"
      >
{{ maker.name }}
</span>
          <span
            v-if="filteredMakers.length>5"
            class="inline-flex items-center px-2 py-1 me-2 mr-2 mb-2 text-sm font-medium underline text-gray-800 rounded dark:bg-gray-700 dark:text-gray-300 cursor-pointer"
            @click.prevent="isWrap.manufacturers=!isWrap.manufacturers"
          >{{ (isWrap.manufacturers) ? 'показать' : 'свернуть'}} все
        </span>
        </div>
      </div>


      <div>
        <span class="text-lg md:text-lg font-semibold mb-2">
          Модель линз: {{ filter.model }}</span>
        <a href="#" v-if="filter.model" @click.prevent="filter.model = ''" class="ml-5 text-red-700">очистить</a>
      </div>
      <div class="mb-5">
        <span
        id="badge-dismiss-dark"
        class=" inline-flex items-center px-2 py-1 me-2 mr-2 mb-2 text-sm font-medium bg-blue-200 text-gray-800 rounded dark:bg-gray-700 dark:text-gray-300 cursor-pointer hover:bg-blue-400"
        :class="{'list-selector-active':filter.model==model.name}"
        v-for="(model, indx) of wrap(filteredModels, isWrap.models)"
        :key="'model-'+indx"
        @click.prevent="filter.model=model.name"
      >
{{ model.name }}
</span>
        <span
          v-if="filteredModels.length>5"
          class="inline-flex items-center px-2 py-1 me-2 mr-2 mb-2 text-sm font-medium underline text-gray-800 rounded dark:bg-gray-700 dark:text-gray-300 cursor-pointer"
          @click.prevent="isWrap.models=!isWrap.models"
        >{{ (isWrap.models) ? 'показать' : 'свернуть'}} все
        </span>

      </div>

      <div v-if="total > 0" class="mt-4 mb-4 flex justify-between">
        <div>
          <template v-for="page of generatePageRange()">
            <button
              v-if="page != '...'"
              class="rounded-md w-8 h-8 mr-1 text-sm bg-white"
              @click.prevent="setPage(page)"
              :key="page"
              :class="{ 'bg-blue-600 text-white': page == currentPage }"
            >
              {{ page }}
            </button>

            <span v-if="page == '...'" class="mr-1">
              ...
            </span>
          </template>
        </div>
        <select v-model="filter.limit" class="py-2">
          <option value="10">10</option>
          <option value="30">30</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>
      <div v-else class="mt-4 mb-4 flex justify-center">
        Товары не найдены
      </div>
      <hr>
      <!--  Results -->
      <div class="relative py-10" v-if="productsBusy">
        <loading />
      </div>
      <div class="mt-4" v-if="!productsBusy">
        <div
          class="flex flex-row items-center justify-between text-sm  border-b border-gray-200 py-1 px-2"
          v-for="p in filteredProducts"
        >
          <div>{{ p.name }}</div>
          <div class="text-right">
            <button class="text-blue-500 font-semibold" @click="select(p)">Выбрать</button>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "../modals/Modal.vue";
import ModalMixin from "../modals/modal.mixin";
import CategoryRepo from "@/repo/CategoryRepo";
import MakerRepo from "@/repo/MakerRepo";
import ModelRepo from "@/repo/ModelRepo";
import GlobalProductRepo from "@/repo/GlobalProductRepo";

export default {
  props: ["field"],
  components: { Modal },
  mixins: [ModalMixin],
  data() {
    return {
      busy: false,
      productsBusy: false,
      filterBusy: false,
      filter:{term:'',limit:10, page:1, category:'', manufacturer:"", model:"", package:'', zamena:'', type:''},
      list: [
        "однодневные",
        "двухнедельные",
        "на 1 месяц",
        "на 3 месяца",
        "традиционные",
        "астигматические",
        "мультифокальные"
      ],
      categories: [],
      makers: [],
      models: [],
      params: {
        dio: [],
        cyl: [],
        ax: [],
        add: [],
        zamena: [],
        type: [],
        radius: [],
        pack: [],
      },
      products: [],
      isWrap:{
        manufacturers:true,
        models:true,
        types:true,
        radiuses:true,
        packages:true,
      },
      total:0,
      currentPage: 1
    };
  },
  mounted() {
    this.getCategoriesAndMakers();
    // this.getProducts();
    this.getModels();

    GlobalProductRepo.forFilters().then(({ data })=>{
      this.params = {
        dio: data.dio,
        cyl: data.cyl,
        ax: data.ax,
        add: data.add,
        zamena: data.zamena,
        type: data.type,
        radius: data.radius,
        pack: data.pack,
      }

    })
  },
  methods: {
    clearFilters() {
      Object.keys(this.filter)
        .forEach(key=>{
          if(key.indexOf('params')+1) {
            console.log(key);
            delete this.filter[key]
          }
        })
      this.getProducts()
    },
    setPage(page) {
      this.currentPage = parseInt(page);
      this.filter.page = page;
    },
    generatePageRange(delta = 4) {
      // creates array with base 1 index
      let currentPage = parseInt(this.currentPage);
      let lastPage = Math.ceil(this.total / this.filter.limit);

      const range = Array(lastPage)
        .fill()
        .map((_, index) => index + 1);

      return range.reduce((pages, page) => {
        // allow adding of first and last pages
        if (page === 1 || page === lastPage) {
          return [...pages, page];
        }

        // if within delta range add page
        if (page - delta <= currentPage && page + delta >= currentPage) {
          return [...pages, page];
        }

        // otherwise add 'gap if gap was not the last item added.
        if (pages[pages.length - 1] !== "...") {
          return [...pages, "..."];
        }

        return pages;
      }, []);
    },
    async select(product) {
      this.close(product);
    },
    wrap(data, isWrap=false) {
      if(isWrap) return data.slice(0,5)
      if(!isWrap) return data
    },
    async getCategoriesAndMakers() {
      let vm = this;
      vm.categoryBusy = true;
      let [makers, categories] = await Promise.all([await MakerRepo.get(), await CategoryRepo.get()])

      setTimeout(() => {
        vm.categories = categories;
        vm.makers = makers;
        vm.categoryBusy = false;
      }, 250);
    },
    async getModels() {
      let vm = this;
      vm.filterBusy = true;
      let data = await ModelRepo.getByMaker(this.filter.manufacturer);


      setTimeout(() => {
        vm.models = data.items;
        vm.filterBusy = false;
      }, 250);
    },

    async getProducts() {
      let vm = this;
      this.productsBusy = true;

      let { success, msg, data } = await GlobalProductRepo.query(this.filter);

      setTimeout(() => {
        vm.products = data.products
        vm.total = data.total;
        vm.productsBusy = false;
        // vm.selectModel(vm.models[0]); //6
      }, 250);
    },
  },
  watch: {
    'filter': {
      handler: function (after, before) {
        this.getProducts();
      },
      deep: true
    },
  },
  computed: {
    filteredMakers() {
      if (this.filter.category) return this.makers.filter(maker=>maker.categories.includes(this.filter.category))
      return this.makers
    },
    filteredModels() {
      let m = this.models
      if(this.filter.category) m = m.filter(model=>model.category==this.filter.category)
      if(this.filter.manufacturer) m = m.filter(model=>model.maker==this.filter.manufacturer)

      return m
      },
    filteredProducts() {
      let p = this.products

      if(this.filter["params.Оптическая сила"]) p = p.filter(p=>p.params["Оптическая сила"]===this.filter["params.Оптическая сила"])
      if(this.filter["params.Оптическая сила цилиндра"]) p = p.filter(p=>p.params["Оптическая сила цилиндра"]===this.filter["params.Оптическая сила цилиндра"])
      if(this.filter["params.Ось линзы"]) p = p.filter(p=>p.params["Ось линзы"]===this.filter["params.Ось линзы"])
      if(this.filter["params.Аддидация линзы"]) p = p.filter(p=>p.params["Аддидация линзы"]===this.filter["params.Аддидация линзы"])

      return p
    },
  }
};
</script>

let ru = {
  bc: 'Bc',
  dia: 'Dia',
  sph: "Sph",
  cyl: "Cyl",
  axis: "Axis",
  base1: "Base2",
  prism1: 'Prism1',
  base2: 'Base2',
  prism2: 'Prism2',
  nocorr: "Без коррекции",
  corr_near: "С коррекцией (для близи)",
  metric_distance: 'Расстояние',
  wide: "С широк. зрач.",
  corr: "С коррекцией",
  visus: "Visus",
  dpp: "Dpp",
  add: "Add",
  visus_near: "Visus (близь)",
  visus_distance: "Visus (даль)",
  doInventory: "Инвентаризация",
  doSupply: "Новое поступление",
  product_not_found: "Такой товар не найден",
  complaints: "Жалобы",
  placement: "Посадка",
  cornea: "Роговица",
  mucous: "Слизистая",
  addProduct: "Добавить товар",
  eyelids: "Веки",
  сonjunctiva: "Конъюнктива",
  lacrimal: "Слёзные органы",
  sclera: "Склера",
  iris: "Радужка",
  lens: "Хрусталик",
  barcode_not_found: "Такой товар не найден",
  pastDiseases: "Заболевания глаз",
  allergic: "Аллергостатус",
  chronic: "Хронические заболевания",
  eyeOperations: "Операции на глазах",
  complaintsLenses: "Жалобы на МКЛ",
  eyeStrain: "Зрительные нагрузки",
  leading: 'Ведущий глаз',
  duo_test: 'Дуохром.тест',
  duo_test_green: "Зеленый",
  duo_test_red: "Красный",
  duo_test_same: "Одинаково",
  errors: {
    customer_phone_exists: 'Такой телефон существует. Выберите клиента из списка или укажите новый'
  },
  stock: {
    sale: 'Продажа',
    purchase: 'Поступление',
    supply: 'Поступление',
    correction: 'Коррекция',
    edit_product: 'Ручной ввод',
    scan_and_add: 'scan_and_add',
    'supply-cancel': "Отмена поставки",
    'sale-cancel': 'Отмена продажи',
    "set_product_lots": "Редактирование лотов",
    set_product_qty: "Ручное редактирование количества",

  },
  'distance': "Очки для дали",
  'near': "Очки для близи",
  'multifocal': "Очки офисные",
  'progressive': "Прогрессивные",
  'mkl': "МКЛ",
  'currentGlasses': 'Старые',
  'newGlasses': 'Новые',
  sale: {
    status: {
      new: 'Новый',
      awaiting_payment: 'Ожидает оплаты',
      paid: 'Оплачен',
      awaiting_fullfillment: 'Собирается',
      awaiting_shipment: 'Ожидает доставки',
      shipping: 'Доставляется',
      shipped: 'Доставлен',
      awaiting_pickup: 'Ожидает получения',
      completed: 'Завершен',
      refunded: 'Возврат',
      cancelled: 'Отменен',
    }
  },
  task: {
    status: {
      new: 'Новая',
      processing: 'Выполняется',
      completed: 'Выполнена',
      failed: 'Ошибка'
    }
  },
  no_results: 'Записей не найдено',
  'services': 'Услуги',
  'optics': 'Филиалы',
  'users': 'Сотрудники',
  'settings': 'Настройки',
  'calendar': 'Табель',
  'customers': 'Клиенты',
  'suppliers': 'Поставщики',
  'products': 'Товары',
  'reports': 'Отчеты',
  'sales': 'Продажи',
  'dashboard': 'Главная',
  'schedule': 'Календарь',
  'addProductLot': "Редактировать лоты",
  changeStockQty: "Изменить количество товара",
  info: {
    current_stock_details: 'В этом разделе представлены актуальные данные о количестве товаров, доступных на складе на данный момент.'
  },
  'ttn_supply_already_exists': "Поставка с таким номером ТТН и поставщиком уже существует",
  'sip': {
    errors: {
      'mango_keys_invalid': 'Ключи манго офис неверны. Проверьте их и повторите попытку.',
      'mango_keys_not_found': 'Ключи манго офис не найдены. Укажите их в настройках сети.'
    }
  },
  'intern': 'Стажер',
  viewOrder: 'Просмотреть заказ',
  logoutProgress: 'Завершение сеанса'
};
module.exports = ru;

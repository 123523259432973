<template>
  <div>
    <div class="flex flex-row flex-wrap items-start">
      <div class="flex flex-row items-center">
        <div class="relative">
          <i class="fas fa-barcode" v-if="!icon"></i>
          <input ref="scannerInputField" type="text" :placeholder="placeholder ? placeholder : 'Штрихкод'"
            v-model="code" class="py-1 px-2 ml-2" autofocus required name="barcode" :disabled="busy" tabindex="1"
            :class="{ wait: wait }" />
          <div class="busy absolute -right-1 top-3.5 blue" v-if="busy"></div>
          <div class="absolute -top-4 w-full text-2xs left-0 text-gray-500 font-medium text-right"
            v-if="lastCode != ''">
            <i class="fas fa-clock-rotate-left mr-1"></i>
            {{ lastCode }}
          </div>
        </div>
        <div class="row text-gray-300  text-xs font-semibold cursor-pointer ml-2">
          <div class="mr-2" :class="{ 'text-black': extendMode == false }" @click="(extendMode = false), tgkey++">
            ШК
          </div>
          <sm-toggle v-on:updated="extendMode = $event" class="" title="Расширенный режим" :default="extendMode"
            :key="tgkey" />
          <div :class="{ 'text-black': extendMode }" @click="(extendMode = true), tgkey++">
            Лот
          </div>
        </div>
      </div>

      <div v-if="extendMode">
        <input ref="lot" type="text" :placeholder="placeholder ? placeholder : 'Лот'" v-model="lot"
          class="py-1 px-2 ml-2" :disabled="busy" :class="{ wait: wait }" tabindex="2" v-on:keyup.enter="scanLot()" />
        <input type="text" v-model="date" class="py-1 px-2 ml-2 w-20 text-right" placeholder="Произв." maxlength="6" />
        <input type="text" v-model="expire" class="py-1 px-2 ml-2 w-20 text-right" placeholder="Годен до"
          maxlength="6" />
      </div>

      <div v-if="isBarCodeMissed"
        class="text-sm rounded-md px-2 bg-yellow-50 text-yellow-600 py-1 ml-1 border-yellow-300 border">
        ШК не отсканирован, попробуйте еще раз.
      </div>
    </div>
  </div>
</template>

<script>
import Product from "../../repo/ProductRepo.js";
import GlobalProduct from "../../repo/GlobalProductRepo.js";

export default {
  name: "scanner-demo",
  props: ["placeholder", "icon", "global"],
  data() {
    return {
      code: "",
      lot: "",
      barcode: "",
      lastChangeTimer: "",
      product: {},
      showLot: false,
      wait: false,
      autofocus: true,
      date: "",
      expire: "",
      qrCode: "",
      extendMode: false,
      tgkey: 0,
      busy: false,
      isBarCodeMissed: false,
      lastCode: ""
    };
  },
  mounted() {
    let vm = this;
    let scannedCode = "";

    vm.$refs.scannerInputField.addEventListener("keypress", function (event) {
      // set timer for 2 seconds, if no new key pressed, then we are done
      clearTimeout(vm.lastChangeTimer);
      vm.lastChangeTimer = setTimeout(() => {
        clearTimeout(vm.lastChangeTimer);
        vm.processCodes();
      }, 500);

      // Check if the key pressed is CR (Carriage Return) 
      if (event.keyCode === 13) {
        // Carriage Return is represented as 13 in ASCII
        vm.processBarcodeInput(scannedCode);
        scannedCode = ""; // Reset the barcode variable.
      } else {
        scannedCode += event.key; // Append the scanned character to the barcode variable.
      }
    });
  },
  methods: {
    scanLot() {
      this.processCodes();
    },
    resetFields() {
      this.lastCode = this.barcode;
      this.barcode = "";
      this.code = "";
      this.lot = "";
      this.showLot = false;
      this.date = "";
      this.expire = "";
      this.qrCode = "";

      setTimeout(() => {
        this.$refs.scannerInputField.focus();
      }, 100);
    },
    emitProduct() {
      if (this.barcode == "") return;

      this.$emit("scan", this.product);

      this.resetFields();
    },
    failsound() {
      let audio = new Audio("/sounds/error.wav");
      audio.play();
    },
    oksound() {
      let audio = new Audio("/sounds/success.wav");
      audio.play();
    },
    peaksound() {
      let audio = new Audio("/sounds/peak.wav");
      audio.play();
    },
    async processCodes() {

      this.code = this.barcode;
      this.showLot = this.lot != "";

      if (this.lot && !this.barcode) {
        this.isBarCodeMissed = true;
      }

      if (this.barcode) {
        this.isBarCodeMissed = false;
      }

      // if we have barcode, search product by barcode
      if (this.barcode) {
        this.busy = true;

        // search in store, if not found, return global product details
        let success, msg, data;
        if (!this.global) {
          ({ success, msg, data } = await Product.query({ barcode: this.barcode }));
          if (!success || data.products.length == 0) {
            ({ success, msg, data } = await GlobalProduct.query({ barcode: this.barcode }));
          }
        } else {
          ({ success, msg, data } = await GlobalProduct.query({ barcode: this.barcode }));
        }

        this.busy = false;
        if (success) {
          if (data.products.length > 1 || data.products.length == 0) {
            // error
            //this.failsound();
            this.error = "product_not_found";
            alert(this.i18n.t(this.error));
            this.resetFields();
            return;
          }

          if (data.products.length > 0) {
            this.product = data.products[0];

            this.product.expirationDate = this.expire;
            this.product.productionDate = this.date;
            this.product.qrCode = this.qrCode;

            if (this.lot && this.extendMode) {
              this.product.lot = this.lot;
              this.product.qrCode = this.qrCode;
            }

            this.emitProduct();
          }
        }
      }
    },
    processBarcodeInput(scannedCode) {

      // remove spaces
      scannedCode = scannedCode.replace(/\s/g, "");
      this.code = this.code.replace(/\s/g, "");
      // probably we put barcode manually with copy/paste
      if (scannedCode == "") {
        if (this.code != "") {
          scannedCode = this.code;
        }
      }

      let productBarcode = null;
      let lotBarcode = null;

      if (scannedCode.length > 20 && scannedCode.indexOf("0100") == 0) {
        this.splitStringByFormat(scannedCode);
        return;
      }

      // Check if the scanned code is a product barcode or a lot barcode
      if (scannedCode.length >= 12 && scannedCode.length <= 15 && /^\d+$/.test(scannedCode)) {
        // product barcode.
        productBarcode = scannedCode;
        this.barcode = productBarcode;
      } else if (
        (scannedCode.length < 15 && /[a-zA-Z]/.test(scannedCode)) ||
        (scannedCode.length > 15 && !/[a-zA-Z]/.test(scannedCode))
      ) {
        // lot barcode.
        lotBarcode = scannedCode;
        this.lot = lotBarcode;
      }
    },
    /**
     * Scan QR code that cotains barcode, lot and date
     * @param {*} str
     */
    splitStringByFormat(str) {
      this.qrCode = str;

      // extract the first parameter: starts after '0100' and ends with '11'
      const firstParamStartIndex = str.indexOf("0100") + 4;
      const firstParamEndIndex = str.indexOf("11", firstParamStartIndex);
      const firstParam = str.substring(firstParamStartIndex, firstParamEndIndex);

      // extract the second parameter: 6 digits between '11' and '17'
      const secondParamStartIndex = firstParamEndIndex + 2; // skip '11'
      const secondParam = str.substring(secondParamStartIndex, secondParamStartIndex + 6);

      // extract the third parameter: everything between '17' and the next '10'
      const thirdParamStartIndex = str.indexOf("17", secondParamStartIndex) + 2; // skip '17'
      const thirdParamEndIndex = str.indexOf("10", thirdParamStartIndex);
      const thirdParam = str.substring(thirdParamStartIndex, thirdParamEndIndex);

      // extract the rest : everything after the previous '10'
      const lastParam = str.substring(thirdParamEndIndex + 2); // skip '10'

      this.lot = lastParam;
      this.barcode = firstParam;
      this.expire = thirdParam;
      this.date = secondParam;
    }
  }
};
</script>

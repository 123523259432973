<template>
    <li class="" :class="{ 'mt-2': isRoot }">
        <div @click="toggleCollapse"
            :class="currentPage && currentPage.pageData.id == page.pageData.id ? 'text-blue-500' : 'text-gray-700'"
            class="cursor-pointer hover:text-blue-500 px-1 text-black flex items-center">
            <span v-if="hasChildren" class="mr-2 text-xs">
                <i class="fas fa-angle-right" :class="{ 'fa-rotate-90': !isCollapsed }"></i>
            </span>

            <span @click.stop="selectPage" :class="{ 'font-semibold': isRoot }">
                {{ page.title }}
            </span>

        </div>
        <ul v-if="hasChildren && !isCollapsed" class="flex flex-col pl-5 mt-1 space-y-2">
            <tree-item v-for="child in page.childrens" :key="child.pageData.id" :page="child"
                :current-page="currentPage" @select-page="$emit('select-page', $event)"
                :is-current-path="isCurrentPath" />
        </ul>
    </li>
</template>

<script>
export default {
    name: 'TreeItem',
    props: {
        page: Object,
        currentPage: Object,
        isRoot: {
            type: Boolean,
            default: false
        },
        isCurrentPath: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        if (this.isCurrentPath) {
            this.isCollapsed = false;
        }
    },
    data() {
        return {
            isCollapsed: true
        };
    },
    watch: {
        isCurrentPath(newVal) {
            if (newVal) {
                this.isCollapsed = false;
            }
        }
    },
    computed: {
        hasChildren() {
            return this.page.childrens && this.page.childrens.length > 0;
        }
    },
    methods: {
        toggleCollapse() {
            if (this.hasChildren) {
                this.isCollapsed = !this.isCollapsed;
            }
        },
        selectPage() {
            this.toggleCollapse();
            this.$emit('select-page', this.page);
        }
    }
};
</script>

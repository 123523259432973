<template>
  <div class="relative">
    <input
      :class="styles"
      class="pl-10"
      type="text"
      v-model="value"
      :placeholder="placeholder"
      @input="search"
      @blur="closeHelper()"
      @keypress="checkFormat"
      ref="input"
    />
    <div class="absolute top-6 right-10 z-10" v-if="busy">
      <loading />
    </div>

    <button
      type="button"
      class="text-blue-500 text-sm absolute top-2.5 left-4 z-10"
      @click="toggleList()"
    >
      <i class="fas fa-bars"></i>
    </button>

    <div
      class="absolute rounded shadow-2xl border-gray-200 mt-2 border bg-white z-10 w-full"
      v-if="showHelper && results.length > 0"
    >
      <div
        class="px-6 py-4 hover:bg-gray-200 cursor-pointer text-gray-800 border-b last:border-b-0 font-semibold flex flex-row items-center"
        v-for="(item, index) in results"
        :key="index"
        @click.prevent="selectItem(item)"
      >
        <slot v-bind:item="item"></slot>
      </div>
    </div>
  </div>
</template>

<script>
const debounce = require("debounce");

import { mapGetters } from "vuex";
import Loading from "../Loading.vue";
import Product from "../../repo/ProductRepo";
import Category from "../../repo/CategoryRepo";
import Customer from "../../repo/CustomerRepo";
import Brand from "../../repo/BrandRepo";
import Supplier from "../../repo/SupplierRepo";

export default {
  components: { Loading },
  props: ["type", "id", "styles", "placeholder", "component", "field", "focus"],
  data() {
    return {
      results: [],
      busy: false,
      showHelper: false,
      hello: "some text is here",
      disableFirstTouchOnMounted: true,
      value: ""
    };
  },
  mounted() {
    if (this.focus) {
      this.$refs.input.focus();
    }
  },
  methods: {
    toggleList() {
      if (this.showHelper) {
        this.showHelper = false;
      } else {
        this.doSearch("", this);
      }
    },
    checkFormat(evt) {},
    search(e) {
      console.log("search event");
      let vm = this;
      let inputValue = e.target.value;
      this.$emit("input", inputValue);

      if (inputValue.length < 3) return;
      this.doSearch(inputValue, this);
    },
    doSearch: debounce(async (inputValue, vm) => {
      vm.busy = true;

      // search it
      //this.results = ['test1', 'test2', 'test3'];
      if (vm.component == "Product") {
        let { res, msg, data } = await Product.search(inputValue);
        vm.results = data;
      }

      //this.results = ['test1', 'test2', 'test3'];
      if (vm.component == "Category") {
        let { res, msg, data } = await Category.search(inputValue);
        vm.results = data;
      }

      if (vm.component == "Brand") {
        let { res, msg, data } = await Brand.search(inputValue);
        vm.results = data;
      }

      if (vm.component == "Customer") {
        let { res, msg, data } = await Customer.search(inputValue, vm.field);
        vm.results = data;
      }

      if (vm.component == "Supplier") {
        let { success, msg, data } = await Supplier.search({ term: inputValue });
        if (success) vm.results = data.suppliers;
      }

      vm.showHelper = true;
      setTimeout(function() {
        vm.busy = false;
      }, 300);
    }, 500),
    selectItem(item) {
      let resValue = item;
      if (this.component == "Category") {
        resValue = item.name;
      }
      if (this.component == "Brand") {
        resValue = item.name;
      }

      if (this.component == "Customer") {
        resValue = item;
      }

      if (this.component == "Supplier") {
        resValue = item._id;
      }

      if (this.component == "city" || this.component == "address") {
        resValue = item.value;
      }

      this.value = "";

      this.$emit("select", item);
      this.showHelper = false;
    },
    closeHelper() {
      let vm = this;
      setTimeout(function() {
        vm.showHelper = false;
      }, 500);
    }
  },
  computed: {}
};
</script>

<template>
  <div
    @click="$emit('addoptic')"
    class="flex flex-col items-center justify-center rounded-lg cursor-pointer border-2 border-gray-300 border-dashed hover:bg-white transition-colors duration-150 ease-in-out"
  >
    <div class="h-16 w-16 text-gray-400">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        fit=""
        height="100%"
        width="100%"
        preserveAspectRatio="xMidYMid meet"
        focusable="false"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M12 6v6m0 0v6m0-6h6m-6 0H6"
        ></path>
      </svg>
    </div>
  </div>
</template>
<script>
export default {
  name: "AddOpticCard"
};
</script>

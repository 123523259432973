<template>
  <modal :styles="'md:w-160 md:min-w-160'">
    <div v-if="day">
      <div class="mt-4">
        <div class="flex flex-row items-center gap-2">
          <img
            v-if="active.picture"
            class="h-6 w-6 rounded-md"
            :src="'https://178107.selcdn.ru/monkl-picture/' + active.picture"
          />
          <div class="text-xl">{{ $dept.title }}</div>
          <div class="ml-2 text-xs text-gray-400">
            <i class="fas fa-map-marker-alt"></i>
            {{ $dept.addr }}
          </div>
        </div>

        <div class="mt-4 font-semibold">{{ day.ariaLabel }}</div>
      </div>

      <!--  Schedule list -->
      <div class="flex flex-row space-x-4 mb-2 mt-8 relative">
        <div class="w-1/3 text-sm font-medium text-gray-400">Сотрудник</div>
        <div class="w-1/3 text-sm font-medium text-gray-400">Начало</div>
        <div class="w-1/3 text-sm font-medium text-gray-400">Конец</div>
      </div>
      <div
        class="flex flex-row space-x-4 mb-2 relative"
        v-for="(slot, index) in slots"
        :key="index"
      >
        <select
          v-model="slot.userId"
          class="w-1/3 px-5 py-2 text-black bg-gray-100 focus:bg-gray-200 rounded-md font-medium"
        >
          <option :value="user.id" v-for="(user, index) in users" :key="index">
            {{ user.name }}
          </option>
        </select>

        <select
          v-model="slot.timeStart"
          class="w-1/3 px-5 py-2 text-black bg-gray-100 focus:bg-gray-200 rounded-md font-medium"
        >
          <option v-for="(opt, ind) of timeSlots.slice(0,-1)" :key="ind">{{ opt }}</option>
        </select>

        <select
          v-model="slot.timeStop"
          class="w-1/3 px-5 py-2 text-black bg-gray-100 focus:bg-gray-200 rounded-md font-medium"
        >
          <option v-for="(opt, ind) of timeSlots.slice(1)" :key="ind">{{ opt }}</option>
        </select>

        <button
          type="button"
          @click="removeByIndex(index)"
          class="absolute -right-5 top-3 text-red-500 text-sm"
        >
          <i class="fas fa-times"></i>
        </button>
      </div>

      <div v-if="slots && slots.length == 0" class="mb-2">
        <span class="text-sm text-gray-600">Ничего нет, добавьте новые.</span>
      </div>

      <button type="button" class="text-sm font-medium text-indigo-500 mt-4" @click="addSlot()">
        <i class="mr-1 fas fa-plus-circle"></i>
        Добавить слот
      </button>

      <div class="mt-6 px-4 py-2 border border-gray-100 bg-gray-50 rounded">
        <!-- <div>
          <label class="text-sm">
            <input type="checkbox" v-model="copyOptions.currentMonth" />
            Обновить до конца месяца
          </label>
        </div> -->

        <div>
          <label class="text-sm">
            <input type="checkbox" v-model="copyOptions.currentMonthOverride" />
            Продублировать до конца месяца
          </label>
        </div>

        <div>
          <label class="text-sm">
            <input type="checkbox" v-model="copyOptions.nextMonth" />
            Продублировать на следующий месяц с первого числа
          </label>
        </div>
      </div>

      <div class="mb-12"></div>

      <button
        type="button"
        class="btn-save"
        @click="saveDay()"
        :disabled="busy"
        :class="{ busy: busy }"
      >
        Сохранить
      </button>
    </div>
  </modal>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["day"],
  data() {
    return {
      slots: [],
      busy: false,
      copyOptions: {
        currentMonth: false,
        nextMonth: false
      }
    };
  },
  mounted() {
    this.loadSchedule();
  },
  methods: {
    // save day schedule
    async saveDay() {
      let vm = this;
      this.busy = true;
      await this.$schedule.update({
        date: this.day.id, //YYYY-MM-DD
        opticId: this.active.id,
        slots: this.slots,
        copyOptions: this.copyOptions
      });

      setTimeout(function() {
        vm.busy = false;
        vm.$events.$emit("reload-calendar");
      }, 1000);
    },
    async loadSchedule() {
      let schedule = await this.$schedule.getByDate(this.active.id, this.day.id);
      this.slots = schedule.data.slots;
    },
    toLocalTime(date) {
      return new Date(date).toLocaleDateString("ru-Ru", {
        year: "numeric",
        month: "long",
        day: "numeric"
      });
    },
    removeByIndex(index) {
      this.slots.splice(index, 1);
    },
    // add slot to selected date
    addSlot() {
      this.slots.push({
        userId: "",
        timeStart: (this.active.workingHours[this.weekDay][0] || "09") + ":00",
        timeStop: (this.active.workingHours[this.weekDay][1] || "18") + ":00"
      });
    }
  },
  computed: {
    timeSlots() {
      // use working hours by current dept
      // selected slot day of the week
      let hours = this.$dept.workingHours[this.day.weekdayPosition - 1];

      let slots = [];
      let start = parseInt(hours[0]);
      let end = parseInt(hours[1]);

      for (let h = start; h <= end; h++) {

        var hh = h;
        hh = hh < 10 ? "0" + hh : hh;

        slots.push(hh + ":00");
      }

      // for (let t = 0; t < 24 * 60; t++) {
      //   var hh = Math.floor(t / 60);

      //   hh = hh < 10 ? "0" + hh : hh;
      //   var mm = t % 60;
      //   mm = mm < 10 ? "0" + mm : mm;

      //   slots.push(hh + ":" + mm);
      //   t = t + 59;
      // }

      return slots;
    },
    ...mapGetters("users", { users: "users", getUser: "getById" }),
    ...mapGetters("optics", ["active"]),
    weekDay() {
      if (this.day.weekday == 1) {
        return 6;
      } else {
        return this.day.weekday - 2;
      }
    }
  }
};
</script>

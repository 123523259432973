//import axios from "axios";

class Api {
  constructor() {
    this.config = {
      baseURL: ''
    };
  }

  async call(path, { method = "get", data, query, responseType = "" } = {}) {
    const url = this.config.baseURL + path;

    try {
      const response = await window.axios.request(url, { method, data, responseType });

      let success = (!response.data.error && response.data.status != 'error') || response.data.status == undefined;

      if (response.data.error != undefined && response.data.error == true) {
        success = false;
      }

      return {
        success: success,
        msg: response.data.msg || "",
        data: response.data
      };
    } catch (e) {
      if (e.response) {
        if (e.response.data == 'need_login') {
          document.location = '/auth/logout';
        }
      }
      return {
        success: false,
        msg: e.message,
        data: ""
      };
    }
  }

  /**
   * Post alias
   *
   * @param {*} path
   * @param {*} data
   * @returns
   */
  async post(path, data) {
    return await this.call(path, { method: "post", data: data });
  }

  /**
   * Put alias
   *
   * @param {*} path
   * @param {*} data
   * @returnsF
   */
  async put(path, data) {
    return await this.call(path, { method: "put", data: data });
  }

  /**
   * Patch alias
   *
   * @param {*} path
   * @param {*} data
   * @returnsF
   */
  async patch(path, data) {
    return await this.call(path, { method: "patch", data: data });
  }

  /**
   * Get alias
   *
   * @param {*} path
   * @param {*} data
   * @returns
   */
  async get(path) {
    return await this.call(path);
  }

  /**
   * Delete alias
   *
   * @param {*} path
   * @param {*} data
   * @returns
   */
  async delete(path, data) {
    return await this.call(path, { method: "delete", data: data });
  }

  /**
   * Get file arraybuffer via POST method that let use do authorization etc
   *
   * @param {*} path
   * @param {*} data
   * @returns
   */
  async file(path, data) {
    return await this.call(path, { method: "post", data: data, responseType: "blob" });
  }

}

export default new Api();

<template>
  <button @click="view()">
    <div class="font-semibold text-blue-500 text-left">
      {{ name }}
    </div>
  </button>
</template>

<script>
import ViewProductModal from "../store/ViewProductModal.vue";
export default {
  props: ["name", "id"],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    view() {
      this.$modals.open(ViewProductModal, { id: this.id });
    }
  }
};
</script>

<template>
    <div class="p-4 ">
        <h2 class="text-lg font-semibold mb-4">Записи на сегодня</h2>
        <div v-for="item in items" :key="item.id" class="bg-white mb-4 pl-10 border rounded-md px-4 py-2 relative">
            <div class="flex flex-col text-gray-500 px-4">
                <div class="text-black font-medium text-sm">
                    {{ $dayjs(item.startTime).format("HH:mm") }} - {{ $dayjs(item.endTime).format("HH:mm") }}
                </div>
                <div class="text-xs">
                    {{ item.serviceTitle }}
                </div>
                <div class="absolute left-4 top-3 flex flex-col">
                    <span v-tooltip="'Подтверждено'" v-if="item.status == 'confirmed'" class="text-2xs">🟢</span>
                    <span v-tooltip="'Требует подтверждения'" v-if="item.status == 'new'">⚠️</span>
                    <span v-tooltip="'Идет прием'" v-if="item.status == 'started'">⏳</span>
                    <span v-tooltip="'Прием завершен'" v-if="item.status == 'completed'" class="">✔</span>
                    <span v-tooltip="'Виджет на сайте'" v-if="item.source == 'widget'" class="">🌐</span>
                </div>
            </div>
            <div class="px-4">
                <span class="font-medium text-xs xl:text-sm text-gray-900">{{ item.client.lastname }} {{ item.client.firstname
                    }}</span>
            </div>

            <button class="btn-default absolute top-3 right-2" @click="viewRecord(item)">
                <span class="hidden xl:block">Открыть карточку</span>
                <span class="xl:hidden text-xs">
                    Показать
                </span>
            </button>

        </div>
    </div>
</template>
<script>
import EditRecordModal from "../../schedule/EditRecordModal.vue";
import dayjs from "dayjs";

export default {
    components: { EditRecordModal },
    name: "TodayRecordsWidget",
    data() {
        return {
            items: [],
            start: this.$dayjs().startOf('day').toISOString(),
            end: this.$dayjs().endOf('day').toISOString(),
            busy: false
        }
    },
    mounted() {
        this.getRecords()
        let vm = this;

        /** @todo: test how it works on different tabs */
        this.$events.$on("record-reload", () => {
            vm.getRecords();
        });

    },
    methods: {
        viewRecord(item, date = false, time = false) {
            this.$modals.open(EditRecordModal, {
                id: item.id ? item.id : "",
                date: date ? dayjs(`${date} ${time}`) : new Date()
            });
        },
        async getRecords() {

            let { success, msg, data } = await this.$api.get(`/records?start=${this.start}&end=${this.end}&opticId=${this.$dept.id}`);
            if (success) {
                this.items = data;
            }

        }
    }
}
</script>
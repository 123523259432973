<template>
    <div class="-mt-0.5">
      <div>
        <div @click="toggleState"
          class="relative inline-block w-8 mr-2 align-middle select-none transition duration-200 ease-in">
          <div>
            <input type="checkbox" v-model="internalState" class="toggle-checkbox sm" />
            <span class="toggle-label block overflow-hidden h-4 rounded-full bg-gray-200 cursor-pointer"></span>
          </div>
        </div>
        <slot></slot>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      value: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        internalState: this.value !== undefined ? this.value : false
      };
    },
    watch: {
      value(newValue) {
        this.internalState = newValue !== undefined ? newValue : false;
      },
      internalState(newValue) {
        this.$emit('input', newValue);
      }
    },
    methods: {
      toggleState() {
        this.internalState = !this.internalState;
      }
    }
  };
  </script>
  
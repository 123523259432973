<template>
  <page>
    <template v-slot:header>
      <div class="flex flex-row items-center">
        <router-link to="/store/inventory" class="btn-link text-sm mr-4">
          <i class="fas fa-arrow-left"></i>
          Назад
        </router-link>
        <div>Инвентаризация {{ inventory.created_at | datetime }}</div>
      </div>
    </template>
    <template v-slot:actions></template>

    <!-- Filters, collapsable -->
    <template v-slot:content>
      <div class="w-full overflow-x-auto text-left bg-white rounded-md">
        <table class="w-full">
          <thead class="border-b border-gray-200">
            <tr class="font-semibold text-xs  text-black">
              <th class="px-4 py-3">Штрихкод</th>
              <th class="px-4 py-3">Название</th>
              <th class="px-4 py-3">Остаток</th>
              <th class="px-4 py-3">Фактический</th>
              <th class="px-4 py-3">Статус</th>
              <th>Действие</th>
            </tr>
          </thead>
          <tbody class="text-sm">
            <tr
              v-for="item in inventory.cart"
              :key="item.id"
              :class="{
                'text-red-500 font-semibold': item.db_stock != item.qty,
                'text-green-500 font-semibold': item.db_stock == item.qty
              }"
            >
              <td>{{ item.barcode }}</td>
              <td>
                {{ item.title }}
                <div class="text-xs text-black">{{ item.attr }}</div>
              </td>
              <td>{{ item.db_stock }}</td>
              <td>{{ item.qty }}</td>
              <td>
                <span class="text-red-500" v-if="item.status == 'not_found'">
                  <i class="fas fa-times"></i>
                </span>

                <span class="text-green" v-if="item.db_stock == item.qty">
                  <i class="fas fa-check"></i>
                </span>
              </td>
              <td>
                <button
                  class="btn-outline btn-xs"
                  @click="doCorrect(item)"
                  v-if="item.db_stock != item.qty"
                >
                  Скорректировать
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        v-if="error"
        class="text-red-500 font-semibold border-red-500 text-sm border max-w-md mt-4 rounded-md px-4 py-2"
      >
        <span v-if="error == 'qty_is_equal'">
          Коррекция не требуется
        </span>

        <span v-else>
          {{ error }}
        </span>
      </div>
      <loading v-if="busy" />

      <button class="btn-action-sm mt-4" @click="sync()">Пересчитать остатки</button>
    </template>
  </page>
</template>

<script>
//import EditProductModal from "../products/EditProductModal.vue";
//import ViewProductModal from "../ViewProductModal.vue";
//import InventoryModal from "../InventoryModal.vue";
//import ProductRow from "../ProductRow.vue";

import { mapGetters } from "vuex";
import Page from "../../Page.vue";
import Info from "../../shared/Info.vue";
import Inventory from "../../../repo/InventoryRepo";
import StoreHistory from "../history/StoreHistoryRepo";
import Loading from "../../Loading.vue";
import api from "../../../api/api";

export default {
  components: { Page, Info, Loading },
  name: "view-inventory",
  props: ["id"],
  data() {
    return {
      inventory: {},
      busy: false,
      error: ""
    };
  },
  mounted() {
    this.getItem();
  },
  methods: {
    /**
     * Get invertory item deails
     */
    async getItem() {
      this.busy = true;
      this.error = false;
      try {
        this.inventory = await Inventory.get(this.id);
      } catch (e) {
        this.error = e.message;
      }

      this.busy = false;
    },
    async doCorrect(item) {
      let { success, msg, data } = await api.post(`/inventory/${this.id}/correct`, item);
      this.sync();
    },
  },
  computed: {
    //...mapGetters("products", ["products"])
  }
};
</script>

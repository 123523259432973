<template>
  <page>
    <template v-slot:header>
      Журнал инвентаризации
    </template>
    <template v-slot:actions>
      <button @click="doInventory()" class="btn-action-sm mr-2 ml-4">
        <i class="fas fa-barcode mr-1"></i>
        Провести инвентаризацию
      </button>

      <button @click="uploadCSV()" disabled class="btn-action-sm">
        <i class="fas fa-upload mr-1"></i>
        Загрузить остатки CSV
      </button>
    </template>

    <!-- Filters, collapsable -->
    <template v-slot:content>
      <div class="filters mb-4 flex flex-row items-center gap-4 bg-white px-4 py-2 rounded-md">
        <div class="mr-8 text-sm whitespace-nowrap">
          Всего:
          <span class="font-semibold">{{ total }}</span>
        </div>

        <div>
          <input type="text" placeholder="Поиск по дате" v-model="searchDate" />
        </div>
        <div>
          <button class="btn-action-sm" @click="getItems()">
            Искать
          </button>
        </div>
      </div>

      <div v-if="total > 0" class="mt-4 mb-4 flex justify-between">
        <div>
          <template v-for="page of generatePageRange()">
            <button v-if="page != '...'" class="rounded-md w-8 h-8 mr-1 text-sm bg-white" @click.prevent="setPage(page)"
              :key="page" :class="{ 'bg-blue-600 text-white': page == currentPage }">
              {{ page }}
            </button>

            <span v-if="page == '...'" class="mr-1">
              ...
            </span>
          </template>
        </div>
      </div>

      <div class="w-full overflow-x-auto text-left bg-white rounded-md">
        <table class="w-full">
          <thead>
            <tr class="font-semibold text-xs  text-gray-600">
              <th class="px-4 py-2">Номер</th>
              <th class="px-4 py-2">Дата</th>
              <th class="px-4 py-2">Сотрудник</th>
              <th class="px-4 py-2">Результат</th>
            </tr>
          </thead>
          <tbody class="text-sm">
            <tr v-for="item in items" :key="item.id">
              <td>
                <router-link :to="'/store/inventory/edit/' + item.id" class="btn-link">
                  №{{ item.number }}
                </router-link>
              </td>
              <td>
                {{ item.created_at | datetime }}
              </td>
              <td>
                <div class="inline-block">
                  <user-card :id="item.user_id" />
                </div>
              </td>
              <td>
                <span v-if="item.status == 'draft'" class="badge gray">Черновик</span>
                <span v-if="item.status == 'corrected'" class="badge green">Скорректировано</span>
                <span v-if="item.status == 'success'" class="badge green">Отличий нет</span>

                <template>
                  <span class="badge red" v-if="item.status == 'error'">
                    Требуется корректировка
                  </span>
                </template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="error" class="text-red-500 font-semibold py-2">{{ error }}</div>
      <loading v-if="busy" />
    </template>
  </page>
</template>

<script>
//import EditProductModal from "../products/EditProductModal.vue";
//import ViewProductModal from "../ViewProductModal.vue";
//import InventoryModal from "../InventoryModal.vue";
//import ProductRow from "../ProductRow.vue";

import { mapGetters } from "vuex";
import Page from "../../Page.vue";
import Info from "../../shared/Info.vue";
import Inventory from "../../../repo/InventoryRepo";
import Loading from "../../Loading.vue";
import InventoryModal from "./InventoryModal.vue";
import StartInventoryModal from "./StartInventoryModal.vue";

export default {
  components: { Page, Info, Loading },
  name: "store",
  data() {
    return {
      items: [],
      currentPage: 1,
      total: 0,
      limit: 10,
      searchDate: "",
      busy: false,
      error: ""
    };
  },
  mounted() {
    this.getItems();
  },
  methods: {
    uploadCSV() {
      alert("В разработке");
    },
    // get inventory list
    async getItems() {
      this.busy = true;
      try {
        this.items = await Inventory.get();
      } catch (e) {
        this.error = e.message;
      }

      this.busy = false;
    },
    doInventory() {
      //this.$modals.open(InventoryModal);
      this.$modals.open(StartInventoryModal);
    }
  },
  computed: {
    //...mapGetters("products", ["products"])
  }
};
</script>

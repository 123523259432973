<template>
  <page>
    <template v-slot:header>
      Продажи
    </template>
    <template v-slot:actions></template>

    <!-- Filters, collapsable -->
    <template v-slot:content>
      <button @click="showCatalog=!showCatalog" class="mb-4 px-4 py-2 bg-blue-500 text-white rounded-md">{{ (!showCatalog) ? 'Создать заказ' : 'Свернуть' }}</button>
      <div v-if="showCatalog" class="mb-7">
        <!--            <router-link to="/sales/new-order" class="btn-link text-sm">-->
        <!--                <i class="fas fa-bars"></i>-->
        <!--                Каталог ({{showCatalog}})-->
        <!--              </router-link>-->

        <CreateSale />
      </div>

      <form @submit.prevent="doFilter()">
        <div class="filters mb-4 flex flex-row items-center gap-4 bg-white px-4 py-2 rounded-md">
          <div class="mr-8 text-sm whitespace-nowrap">
            Всего:
            <span class="font-semibold">{{ filter.total }}</span>
          </div>

          <div>
            <input type="text" placeholder="Поиск по номер заказа" v-model="filter.saleNumber" />
          </div>
          <div>
            <button :class="{ busy: filterBusy }" class="px-4 py-2 bg-blue-500 text-white rounded-md"
              @click="doFilter(true)">
              Искать
            </button>
          </div>
        </div>
      </form>

      <div class="mt-4 px-4">
        <pagination :total="filter.total" :limit="filter.limit" :items="list" :name="'sales'"
          @limit="limit => ((filter.limit = limit))" @page="page => ((filter.page = page), doFilter())" />
      </div>

      <div class="w-full border bg-white rounded-md">
        <table class="data-table">
          <thead>
            <tr class="font-semibold text-xs text-gray-600">
              <th class="pl-5 pr-0">
                <input type="checkbox" />
              </th>
              <th class="px-4 py-3">ID ↓</th>
              <th class="px-4 py-3">Дата ↓</th>
              <th class="px-4 py-3">Статус ↓</th>
              <th class="px-4 py-3">Клиент ↓</th>
              <th class="px-4 py-3">Сотрудник ↓</th>
              <th class="px-4 py-3">Оплата ↓</th>
              <th class="">Товаров ↓</th>
              <th class="">Итого, руб. ↓</th>
            </tr>
          </thead>
          <tbody class="text-sm">
            <tr v-for="item in list">
              <td>
                <input type="checkbox" />
              </td>
              <td>
                <sale-number :sale="item" />
              </td>
              <td>
                <div class="">
                  <span>{{ item.created_at | datetime }}</span>
                </div>
              </td>
              <td>
                <sale-status :status="item.status" />
              </td>
              <td>
                <div :key="keyIndex">
                  <customer :data="item.customer" v-if="item.customer && item.customer.id" />
                  <span v-if="!item.client_id" class="text-sm text-gray-500">Без клиента</span>
                </div>
              </td>
              <td>
                <div :key="`user-${keyIndex}`">
                  <user-card :id="item.user_id" />
                </div>
              </td>
              <td>{{ item.paymentMethod.title }}</td>
              <td class="text-right">{{ item.cart.length }}</td>
              <td class="text-right">{{ item.total | money }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
  </page>
</template>

<script>
import { mapGetters } from "vuex";
import api from "@/api/api.js";
import CreateSale from "@/components/sales/CreateSale.vue";

export default {
  name: "sales",
  components: { CreateSale },
  data() {
    return {
      keyIndex: 0,
      editProductModal: false,
      editableId: -1,
      list: [],
      filterBusy: false,
      filter: {
        saleNumber: "",
        limit: 50,
        page: 1,
        total: 0
      },
      checkedItems: [],
      clients: [],
      showCatalog:false
    };
  },
  mounted() {
    //this.$store.dispatch("products/getProducts");
    //this.products = productsJson.products;
    this.doFilter();
    let vm = this;
  },
  methods: {
    async doFilter(btnClicked) {
      if (btnClicked) {
        this.filterBusy = true;
        return;
      }

      if (!this.filterBusy) {
        this.busy = true;
      }

      let vm = this;

      //let { success, msg, data } = await Product.filter(this.filter);
      let query = encodeURIComponent(JSON.stringify(this.filter));
      let { success, msg, data } = await api.get(`/sales?filter=${query}`);
      this.list = data.sales;
      this.filter.total = data.total;

      // show clients data
      await this.loadCustomers();
      vm.filterBusy = false;
      vm.busy = false;
    },

    view(id) {
      //  this.$modals.open(ViewProductModal, { id: id });
    },
    toggleCheckboxes() { },
    async loadCustomers() {
      let ids = this.list.map(el => el.client_id);
      ids = ids.filter(el => el != false);
      let vm = this;
      let { success, msg, data } = await api.get(`/customers?ids=${ids.join(",")}`);
      if (success) {
        this.customers = data.customers;
        this.list.map(el => {
          el.customer = vm.getCustomer(el.client_id);
        });
      }
      this.keyIndex++;
    },
    getCustomer(id) {
      return this.customers ? this.customers.find(el => el.id == id) : {};
    }
  },
  computed: {
    //...mapGetters("products", ["products"])
  }
};
</script>

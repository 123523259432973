<template>
  <div class="bg-white px-5 py-4 rounded-md">

    <div class="py-2 text-gray-600 mb-10">
      Выберите какие даигностические линзы доступны в этом филиале
    </div>
    <div class="grid grid-cols-1 lg:grid-cols-3 w-full grid-flow-row">
      <div v-for="item in list" class="flex flex-row mb-2">
        <div
          class="px-2 py-1 rounded-md bg-gray-200 text-sm hover:bg-opacity-50 cursor-pointer"
          :class="{
            'bg-green-500 text-white':
              optic.lenses != undefined && optic.lenses.indexOf(item.id) != -1
          }"
          @click="toggleItem(item)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>

    <div class="mt-8">
      <button
        type="button"
        @click="$emit('save')"
        :class="{ busy: busy }"
        :disabled="busy"
        class="btn-primary"
      >
        Сохранить
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["optic", "busy"],
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    this.loadLenses();
  },
  methods: {
    async loadLenses() {
      // load lenses from db
      let query = encodeURIComponent(JSON.stringify({ diag: true, limit: 0 }));
      const { data, msg, success } = await this.$api.get(`/g/products?filter=${query}`);

      if (success) {
        this.list = data.products;
      }
    },
    toggleItem(product) {
      if (this.optic.lenses == undefined) {
        this.optic.lenses = [];
      }

      // add or remove from optic
      let foundIndex = this.optic.lenses.indexOf(product.id);

      if (foundIndex == -1) {
        this.optic.lenses.push(product.id);
      } else {
        this.optic.lenses.splice(foundIndex, 1);
      }
    }
  }
};
</script>

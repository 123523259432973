<template>
    <div>
        <h2 class="font-semibold text-lg mb-4 flex flex-row items-center">
            <div>Электронная почта</div>
        </h2>
        <div class="bg-white px-5 py-4 rounded-md w-2/3">
            <div class="mb-3">
                <label class="block mb-1 text-sm text-gray-600 font-medium" for="itigris_login">Белый список
                    адресов</label>
                <div class="text-sm mt-1 mb-2 text-gray-600">Укажите адреса электронной почты с которых разрешено
                    получать накладные. Каждый адрес с новой строки</div>
                <textarea v-model="emailWhiteList" class="w-2/3 min-h-150px"></textarea>
            </div>

            <!--      <error v-if="tgError">{{ tgError }}</error>-->
            <button class="btn-action mt-4" :disabled="busy" @click="saveEmailSettings()" :class="{ busy: busy }">Сохранить</button>
        </div>
    </div>
</template>

<script>
export default {
    name: "integration-settings",
    props: ["settings"],
    data() {
        return {
            keys: [],
            busy: false,
            emailWhiteList: ''
        };
    },
    mounted() {
        this.getSetting()
    },
    methods: {
        async getSetting() {
            let { success, msg, data } = await this.$api.get("/settings?type=email");
            if (success) {
                let emailWhiteList = data.data.emailWhiteList;
                this.emailWhiteList = emailWhiteList ? emailWhiteList.join('\n') : '';
            }
        },
        async saveEmailSettings() {

            this.busy = true;

            let { success, msg, data } = await this.$api.post("/settings/email", {
                data: {
                    emailWhiteList: this.emailWhiteList.split('\n'),
                }
            });

            if (success) {
                this.status = "success";
            } else {
                this.status = msg;
            }

            setTimeout(() => {
                this.busy = false;
            }, 250);

        },
    }
}
</script>
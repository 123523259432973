import Vue from "vue";

const state = {
  user: {
    firstname: "",
    lastName: "",
    id: "",
    token: "",
    picture: "",
    roles: [],
    lastLogin: "",
    tgChatId: '',
    root: false,
    muted: false,
    notificationsAsked: false
  },
  settings: {
    sidebar: true,
    network: {},
    tg: {
      botname: ""
    },
  }
};

const getters = {
  getUser: state => {
    state.user.firstName = state.user.firstName || "username";
    return state.user;
  },
  getSidebarState: state => {
    return state.settings.sidebar;
  },
  getSetting: (key) => {
    return state.settings[key];
  }

};

// actions
const actions = {
  setUser({ state, commit }, user) {
    commit("setUser", user);
  },
  setUserField({ state, commit }, { key, value }) {
    //state.user[key] = value;
    commit('mutateUserField', { key, value })
  },
  loginUser({ state, commit }, user) {
    commit("setUser", user);
  },
  logoutUser({ state, commit }) {
    commit("resetUser");
  },
  setDefaults() { },
  setSetting({ state, commit }, { key, value }) {
    commit("setSetting", { key, value });
  },
  setNetworkSettings({ state, commit }, data) {
    commit("setNetworkSettings", data);
  }
};

// mutations
const mutations = {
  mutateUserField(state, option) {
    state.user[option.key] = option.value;
  },
  setUser(state, user) {
    state.user = Object.assign({}, user);
  },
  setSetting(state, option) {
    state.settings[option.key] = option.value;
  },
  resetUser(state) {
    Object.keys(state.user).forEach(key => {
      state.user[key] = "";
    });
  },
  setNetworkSettings(state, data) {
    state.settings.network = Object.assign({}, data);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

<template>
  <div>
    <div class="grid grid-cols-1 lg:grid-cols-3 gap-x-3 gap-y-4 md:gap-y-8 read-fields">
      <div>
        <label class="hidden md:block">Статус</label>
        <span class="badge" :class="record.status">
          {{ getStatus(record.status) }}
        </span>
      </div>

      <div v-if="record.client">
        <label class="hidden md:block">Клиент</label>
        <div class="text-sm italic text-black">
          <button class="text-xs text-blue-500" @click="viewCustomer()">
            <i class="fas fa-pen-square mr-2"></i>
          </button>
          {{ record.client.fio || "" }}
        </div>
        <div class="text-sm">
          {{ record.client.phone | VMask("(###) ###-##-##") }}
        </div>
      </div>

      <div v-if="record.note">
        <label class="hidden md:block">Заметка</label>
        <div class="border-blue-300 text-sm italic text-black">
          <i class="far fa-comment mr-2"></i>
          {{ record.note || "" }}
        </div>
      </div>

      <div>
        <label class="hidden md:block">Когда</label>
        <div class="read-field">
          <i class="far fa-calendar-alt mr-2 text-gray-400"></i>
          {{ record.startTime | humantime }}
          <span class="font-normal text-gray-500">{{ record.startTime | humandate }}</span>
        </div>
      </div>

      <div>
        <label class="hidden md:block">Услуга</label>
        <div class="read-field">
          <i class="fas fa-arrow-right mr-2 text-gray-400"></i>
          {{ getServiceTitle() }}
        </div>
      </div>

      <div>
        <label class="hidden md:block">Сотрудник</label>
        <user-card :id="record.host_id" v-if="record.host_id" />
        <div v-if="!record.host_id" class="font-semibold text-sm flex flex-row items-center">
          <i class="fas fa-user mr-2 text-gray-500"></i>
          {{ record.host.name }}
        </div>
      </div>
    </div>

    <div class="mt-8 flex-row flex justify-between items-center">
      <button class="btn-primary-sm" v-if="record.status == 'new'" @click="$emit('status', 'confirmed')">
        🗸 Подтвердить
      </button>

      <button class="btn-danger-sm ml-2" v-if="record.status == 'new'" @click="$emit('status', 'canceled')">
        ❌ Отменить
      </button>

      <router-link
        :to="{ name: 'record', params: { id: record.id, step: 'start' } }"
        class="btn-primary"
        v-if="record.status == 'confirmed'"
      >
        Начать прием
      </router-link>

      <router-link
        :to="{ name: 'record', params: { id: record.id, step: 'start' } }"
        class="btn-primary-sm"
        v-if="record.status == 'started'"
      >
        Продолжить прием
      </router-link>

      <div v-if="record.status == 'completed'" class="text-green-500 font-semibold">
        Прием завершен
      </div>
    </div>
    <!-- details -->

    <div v-if="record.status == 'completed' || record.status == 'manual'">
      <div class="mt-4 text-sm ">
        <div class="flex flex-row gap-6 mb-4 justify-between bg-blue-50 rounded-md py-3 px-4 ">
          <div class="read-field">
            <i class="far fa-calendar-alt mr-2 text-gray-400"></i>
            {{ record.startTime | humantime }}
            <span class="font-normal text-gray-500">{{ record.startTime | date }}</span>
          </div>
          <div>
            <i class="far fa-clock"></i>
            {{ record.duration }} мин.
          </div>
        </div>

        <div v-if="showSpoiler">
          <div class="flex flex-row gap-6 mb-4 justify-between bg-blue-50 rounded-md py-3 px-4 ">
            <button @click="printPrescription()" class="btn-action-sm" :class="{ busy: prescriptionBusy }">
              🖨️ Распечатать рецепт
            </button>
          </div>
          <!--  Medic Details -->
          <div class="rounded-md overflow-hidden">
            <div class="font-semibold bg-gray-200 px-2 py-1">Медкарта</div>
            <div class="px-2 bg-gray-100 pt-2 pb-2">
              <div class="gap-y-4 lg:gap-y-2 grid lg:grid-cols-2">
                <div v-for="field in ['pastDiseases', 'chronic', 'eyeOperations', 'allergic']">
                  <div v-if="notEmpty(record.medic[field])">
                    <span class="font-semibold">{{ $t(field) }}</span>
                    <div class="mt-1 flex flex-row gap-x-1 gap-y-1 flex-wrap">
                      <span
                        v-for="item in record.medic[field]"
                        class="rounded-md px-2 py-0.5 text-xs bg-green-500 text-white"
                      >
                        {{ item }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--  Eyes -->
          <div class="mt-4" v-if="notEmptyObj(record.eyes) && record.eysChecked == true">
            <div class="rounded-md overflow-hidden">
              <div class="font-semibold bg-gray-200 px-2 py-1">Офтальмологический паспорт</div>
              <div class="px-2 bg-gray-100 pt-2 pb-2">
                <div class="grid lg:grid-cols-2 text-sm gap-x-4 mt-2">
                  <div>
                    <div class="font-extrabold text-black mb-2">OD</div>
                    <div v-for="(item, key) in record.eyes.od">
                      <div class="mb-2" v-if="notEmpty(item)">
                        <div class="text-black text-xs font-bold">{{ $t(key) }}</div>
                        <span v-if="Array.isArray(item)">{{ item.join(", ") }}</span>
                        <span v-if="!Array.isArray(item)">{{ item }}</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="font-extrabold text-black mb-2">OS</div>
                    <div v-for="(item, key) in record.eyes.os">
                      <div class="mb-2" v-if="notEmpty(item)">
                        <div class="text-black text-xs font-bold">{{ $t(key) }}</div>
                        <span v-if="Array.isArray(item)">{{ item.join(", ") }}</span>
                        <span v-if="!Array.isArray(item)">{{ item }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END -->

          <!-- Жалобы -->
          <div class="rounded-md overflow-hidden mt-4">
            <div class="font-semibold bg-gray-200 px-2 py-1">Жалобы</div>
            <div class="px-2 bg-gray-100 pt-2 pb-2">
              <div class="gap-y-4 lg:gap-y-2 grid lg:grid-cols-2">
                <div v-if="notEmpty(record.complaints)">
                  <span class="font-semibold">Основные жалобы</span>
                  <div class="mt-1 flex flex-row gap-x-1 gap-y-1 flex-wrap">
                    <span v-for="item in record.complaints" class="rounded-md px-2 py-0.5 text-xs bg-green-500 text-white">
                      {{ item }}
                    </span>
                  </div>
                </div>

                <div v-if="notEmpty(record.complaintsLenses)">
                  <span class="font-semibold">Жалобы МКЛ</span>
                  <div class="mt-1 flex flex-row gap-x-1 gap-y-1 flex-wrap">
                    <div
                      v-for="item in record.complaintsLenses"
                      class="rounded-md px-2 py-0.5 text-xs bg-green-500 text-white"
                    >
                      {{ item }}
                    </div>
                  </div>
                </div>

                <div v-if="notEmpty(record.eyeStrain)">
                  <span class="font-semibold">Зрительные нагрузки</span>
                  <div class="mt-1 flex flex-row gap-x-1 gap-y-1 flex-wrap">
                    <span v-for="item in record.eyeStrain" class="rounded-md px-2 py-0.5 text-xs bg-green-500 text-white">
                      {{ item }}
                    </span>
                  </div>
                </div>

                <div
                  v-if="notEmpty(record.complaintsComment)"
                  class="py-3 text-sm text-gray-506 border border-dashed border-gray-200 rounded-md px-4 mt-1 mb-2"
                >
                  <i class="fas fa-info-circle mr-1"></i>
                  {{ record.complaintsComment }}
                </div>
              </div>
            </div>
          </div>

          <!-- Metrics -->
          <div class="mt-4 rounded-md overflow-hidden">
            <div v-if="notEmptyObj(record.metrics.refractometria)" class="bg-gray-100">
              <div class="font-semibold bg-gray-200 px-2 py-1">Авторефрактометрия</div>
              <div
                class="grid grid-cols-4 text-xs font-semibold pb-1 mt-1 text-gray-600 text-center border-b border-gray-100 px-1"
              >
                <div></div>
                <div>Sph</div>
                <div>Cyl</div>
                <div>Axis</div>
              </div>
              <div
                class="grid grid-cols-4 gap-x-2 border-b border-gray-100 px-2 py-1"
                v-for="(item, index) in record.metrics.refractometria"
              >
                <div>{{ index }}</div>
                <div v-for="(k, index) in item" class="hide-if-empty text-center">
                  {{ k | withzero }}
                </div>
              </div>
            </div>

            <!--  -->
            <div class="mt-6" v-if="notEmptyObj(record.metrics.ceratometria)">
              <span class="font-semibold">Кератометрия</span>
              <div
                class="grid grid-cols-4 text-xs font-semibold pb-1 text-gray-600 text-center border-b border-gray-100 px-1"
              >
                <div></div>
                <template v-for="(item, index) in record.metrics.ceratometria.od">
                  <div v-if="item != ''">{{ $t(index) }}</div>
                </template>
              </div>

              <div
                class="grid grid-cols-4 gap-x-2 border-b border-gray-100 px-1 py-1"
                v-for="(item, index) in record.metrics.ceratometria"
              >
                <div>{{ index }}</div>
                <template v-for="(k, index) in item">
                  <div v-if="k != ''" class=" text-center">{{ k | withzero }}</div>
                </template>
              </div>
            </div>

            <!--  -->
            <div class="mt-6 rounded-md overflow-hidden" v-if="notEmptyObj(record.metrics.visus)">
              <div class="font-semibold border-b border-white px-2 py-2 bg-gray-200">
                Visus (острота зрения)
              </div>
              <div class="bg-gray-100 px-2 py-2">
                <div
                  class="grid grid-cols-4 text-xs font-semibold pb-1 text-gray-600 text-center border-b border-gray-100 px-1"
                >
                  <div class="col-span-2"></div>
                  <div>OD</div>
                  <div>OS</div>
                </div>

                <div
                  class="grid grid-cols-4 text-xs lg:text-sm gap-x-2 border-b px-1 py-1"
                  v-for="(metric, key) in record.metrics.visus.od"
                >
                  <div class="col-span-2">{{ $t(key) }}</div>
                  <template v-if="key == 'leading'">
                    <div class="text-center">{{ record.metrics.visus.od[key] ? "✔️" : "x" }}</div>
                    <div class="text-center">{{ record.metrics.visus.os[key] ? "✔️" : "x" }}</div>
                  </template>
                  <template v-else-if="key == 'duo_test'">
                    <div class="text-center">{{ $t(`duo_test_${record.metrics.visus.od[key]}`) }}</div>
                    <div class="text-center">{{ $t(`duo_test_${record.metrics.visus.os[key]}`) }}</div>
                  </template>
                  <template v-else>
                    <div class="text-center">{{ record.metrics.visus.od[key] }}</div>
                    <div class="text-center">{{ record.metrics.visus.os[key] }}</div>
                  </template>
                </div>
              </div>
            </div>

            <div class="mt-6" v-if="notEmpty(record.metrics.comment)">
              <span class="font-semibold">Комментарии</span>
              <div class="py-3 text-sm text-gray-506 border border-dashed border-gray-200 rounded-md mt-2 px-4 mb-2">
                <i class="fas fa-info-circle mr-1"></i>
                {{ record.metrics.comment }}
              </div>
            </div>
          </div>

          <!-- Glasses -->
          <div class="mt-4" v-for="glassType in ['currentGlasses', 'newGlasses']">
            <button
              class="font-bold text-sm text-blue-500 border-b border-dashed border-blue-500"
              @click="visibleLayers[glassType] = !visibleLayers[glassType]"
            >
              {{ $t(glassType) }} очки и мкл
              <i class="fas fa-angle-down" v-if="visibleLayers[glassType] == false"></i>
              <i class="fas fa-angle-up" v-if="visibleLayers[glassType] == true"></i>
            </button>

            <template v-if="visibleLayers[glassType] == true">
              <div v-for="field in ['old0', 'old1', 'old2', 'oldProgressive', 'old3']">
                <div
                  class="mt-4  rounded-md overflow-hidden"
                  v-if="notEmptyObj(record[glassType][field]) || record[glassType][field].items.length > 0"
                >
                  <div class="font-semibold border-b border-white px-2 py-2 bg-gray-200">
                    {{ $t(`${record[glassType][field].type}`) }}
                  </div>

                  <template v-if="record[glassType][field].items.length > 0">
                    <div
                      v-for="(item, index) in record[glassType][field].items.filter(el => Object.keys(el).length != 0)"
                      class="bg-white border rounded-md py-2 px-2 mb-1"
                    >
                      <div class="text-xs mb-1 font-bold text-gray-500">Рецепт #{{ index + 1 }}</div>
                      <div v-for="key in ['od', 'os']" class="flex flex-row mb-1">
                        <div class="uppercase font-semibold mr-2">{{ $t(key) }}</div>
                        <div class="flex flex-row flex-wrap gap-x-2">
                          <div v-for="(metric, index) in $constants.glassesRecipeFields" class="px-0.5 rounded-md border">
                            <span class="font-medium text-xs bg-gray-100 px-0.5">{{ $t(metric) }}</span>
                            <span class="bg-white font-bold px-0.5 text-xs">{{ item[key][metric] }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>

                  <template v-if="notEmptyObj(record[glassType][field])">
                    <param-row :obj="record[glassType][field]" />
                  </template>

                  <!-- mkl -->
                  <template v-if="record[glassType][field].type == 'mkl'">
                    <div class="w-full bg-gray-100 py-2 px-2 mt-2">
                      <div v-for="p in record[glassType][field].products" class="text-sm grid grid-cols-5 gap-x-2">
                        <div class="col-span-3 text-blue-500">{{ p.name }}</div>
                        <div>
                          {{ p.add }}
                          <span class="text-xs font-semibold">D</span>
                        </div>
                        <div>
                          {{ p.bc }}
                          <span class="text-xs font-semibold">mm</span>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>

              <div class="mt-4 space-y-2" v-if="notEmpty(record[glassType].comment)">
                <span class="font-semibold">Комментарии</span>
                <div class="py-3 text-sm text-gray-506 border border-dashed border-gray-200 rounded-md mt-2 px-4 mb-2">
                  <i class="fas fa-info-circle mr-1"></i>
                  {{ record[glassType].comment }}
                </div>
              </div>
            </template>
          </div>

          <!-- Test lenses -->
          <div
            class="mt-4 space-y-2"
            v-if="
              record.testLenses &&
                record.testLenses.items.length > 0 &&
                record.testLenses.items.filter(el => {
                  return el.options ? el.options.filter(o => o.status != 0).length > 0 : false;
                }).length > 0
            "
          >
            <span class="font-semibold">Подбор линз</span>
            <div class="divide-y">
              <div
                v-for="(item, index) in record.testLenses.items.filter(el => {
                  return el.options.filter(o => o.status != 0).length > 0;
                })"
                class="py-2"
              >
                <span class="">
                  {{ item.name }}
                  <i
                    class="font-semibold px-2  rounded-md py-0.5 not-italic ml-2"
                    v-for="o in item.options"
                    :class="{
                      'bg-green-500 text-white': o.status == 1 || o.status === true,
                      'text-gray-500 bg-gray-300': o.status == 0 || o.status == 0,
                      'bg-red-500 text-white': o.status == 2 || o.status === false
                    }"
                  >
                    {{ o.radius }}
                  </i>
                </span>
              </div>
            </div>
          </div>

          <div class="mt-4 space-y-2" v-if="notEmptyObj(record.testLenses.controlCheck)">
            <span class="font-semibold">Осмотр после адаптации</span>
            <div
              class="grid grid-cols-3 text-sm border-b border-gray-100 py-1 gap-x-2"
              v-for="key in ['complaints', 'placement']"
              :key="key"
            >
              <div class="text-sm">{{ $t(key) }}</div>

              <div class="">
                {{ record.testLenses.controlCheck.od[key] }}
              </div>

              <div class="">
                {{ record.testLenses.controlCheck.os[key] }}
              </div>
            </div>
          </div>

          <div class="mt-4 space-y-2" v-if="notEmpty(record.testLenses.comment)">
            <span class="font-semibold">Комментарий</span>
            {{ record.testLenses.comment }}
          </div>

          <div class="mt-4" v-if="notEmpty(record.goods.comment)">
            <span class="font-semibold">Товары</span>
            <div class="mt-2 py-3 text-sm text-gray-506 border border-dashed border-gray-200 rounded-md mt-2 px-4 mb-2">
              <i class="fas fa-info-circle mr-1"></i>
              {{ record.goods.comment }}
            </div>
          </div>
        </div>

        <div
          class="text-center py-2 mt-4 px-2 cursor-pointer rounded-md  text-sm font-semibold shadow-sm"
          @click="showSpoiler = !showSpoiler"
          :class="showSpoiler ? 'bg-gray-100 text-gray-600' : 'bg-blue-500 text-white'"
        >
          <template v-if="!showSpoiler">Показать подробности</template>
          <template v-if="showSpoiler">Скрыть подробности</template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ParamRow from "../records/ParamRow.vue";
import CustomerModal from "../customers/EditCustomerModal.vue";

export default {
  props: ["record", "services"],
  components: { ParamRow, CustomerModal },
  data() {
    return {
      showSpoiler: false,
      visibleLayers: {
        currentGlasses: false,
        newGlasses: false
      },
      prescriptionBusy: false
    };
  },
  methods: {
    viewCustomer() {
      this.$modals.open(CustomerModal, { id: this.record.client_id });
    },
    notEmpty(obj) {
      return obj.length > 0;
    },
    notEmptyObj(obj) {
      // go through each field and check is it empty or not
      let empties = 0;
      for (let f in obj.od) {
        if (obj.od[f].length != 0 || obj.os[f].length != 0) {
          empties++;
        }
      }

      return empties > 0;
    },
    getServiceTitle() {
      // legacy support
      let service;
      console.log(this.record);
      if (!this.record.service_id) {
        service = this.services[this.record.serviceId];
      } else {
        console.log(this.record.service_id);
        console.log(this.services);
        service = this.services.find(item => item.id == this.record.service_id);
      }

      if (service != undefined) {
        return service.title;
      }

      return "";
    },
    async printPrescription() {
      this.prescriptionBusy = true;
      const resp = await this.$api.file(`/records/${this.record.id}/print-prescription`);

      const a = document.createElement("a");
      a.href = URL.createObjectURL(resp.data);
      a.download = "prescription.pdf";
      document.body.appendChild(a);
      a.click();

      URL.revokeObjectURL(a.href);
      this.prescriptionBusy = false;
    }
  },
  computed: {
    host() {
      return this.$hosts.find(item => item.id == this.record.host_id);
    },
    ...mapGetters("records", ["getStatus"])
  }
};
</script>

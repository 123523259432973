<template>
  <div class="flex flex-row bg-yellow-100 rounded-lg p-4 mb-2 text-sm text-yellow-700 mt-2">
    <i class="fas fa-exclamation-triangle mt-0.5 mr-2"></i>
    <div>
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: "error"
};
</script>

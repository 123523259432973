<template>
  <page>
    <template v-slot:title>
      <back-btn></back-btn>
      <template v-if="order.id">Заявка #{{ order.number | pad }}</template>
      <template v-else>Новая заявка</template>
    </template>
    <template v-slot:actions></template>

    <!-- Filters, collapsable -->
    <template v-slot:content>
      <form class="flex flex-col md:flex-row  gap-x-4" @submit.prevent="saveOrder()">

        <div class="md:w-1/5">
          <div class="flex flex-col space-y-4 rounded-md bg-white px-4 py-4">
            <div v-if="order.created_at">
              <label class="form-label">Дата</label>
              <template v-if="order.id">
                <span class="font-medium text-sm">{{ order.created_at | datetime }}</span>
              </template>
            </div>
            <div>
              <label class="form-label">Статус</label>
              <template v-if="order.status == 'draft'">
                <span class="badge gray">Черновик</span>
              </template>
              <template v-if="order.status == 'sent'">
                <span class="badge blue">Отправлен</span>
              </template>
              <template v-if="order.status == 'completed'">
                <span class="badge green">Завершен</span>
              </template>
            </div>

            <div v-if="order.id">
              <label class="form-label">Кто создал</label>
              <template v-if="order.id && order.created_by">
                <i class="fas fa-date"></i>
                {{ order.created_by }}
              </template>
              <template v-if="order.auto">
                <div class="text-sm">
                  <i class="fas fa-robot"></i>
                  Автоматическая
                </div>
              </template>
            </div>

            <div>
              <label class="form-label">
                Поставщик
              </label>
              <select class=" w-full px-1 py-1 leading-none" v-model="order.supplier_id" required
                :disabled="isReadOnly">
                <option :value="s.id" v-for="s in suppliers">{{ s.name }}</option>
              </select>
            </div>

            <!-- actions -->
            <template v-if="order.id">

              <div>
                <span class="form-label">XML файл</span>
                <button @click="downloadXMLUrl()" class="btn-link text-sm" type="button">
                  <i class="fas fa-download mr-1"></i>
                  Скачать XML
                </button>
              </div>

              <action-btn v-if="order.status == 'draft'" :busy="xmlSending" @click="sendToSupplier()"
                class="btn-action-sm" :disabled="!isSentIsAvailable">
                Отправить поставщику
              </action-btn>

              <div v-if="order.sent_at">
                <span class="form-label">Отправлено поставщику</span>
                <span class="text-sm font-semibold">
                  {{ order.sent_at | datetime }}
                </span>
              </div>

              <action-btn v-if="order.status == 'sent'" :busy="xmlSending" @click="sendToSupplier()"
                class="btn-action-sm">
                Отправить заново
              </action-btn>

            </template>

            <div class="" :class="{ 'border-t pt-4': !isReadOnly }">
              <button v-if="order.status == 'sent'" @click="completeOrder()" type="button" class="btn-action-xs">
                Завершить заявку
              </button>

              <template v-if="!isReadOnly">

                <button class="btn-primary" v-if="!order.id">Создать заявку</button>
                <button class="btn-primary" v-if="order.id">Сохранить заявку</button>
              </template>
            </div>

          </div>

          <div class="px-4 mt-4">
            <button class="btn-delete" type="button" v-if="order.id" @click="deleteOrder()">Удалить заявку</button>
          </div>

        </div>

        <div class="md:w-4/5">

          <!-- errors -->
          <div class="alert alert-danger mb-2" v-if="errorXML">
            <div class="font-bold">{{ errorXML }}</div>
            <template v-if="errorXML.includes('guid_not_found_for')">Это значит, что для товара с таким ШК, отсутствует
              нужный для заказа у этого поставщика уникальный идентфикатор. <br /> Сообщите об этом администратору базы
              товаров, без этого индентификатора отправить заявку или сформировать XML файл невозможно. Или удалите
              товар
              с таким ШК из заявки и попробуйте заново.</template>
          </div>

          <error v-if="error">{{ error }}</error>

          <div v-if="status == 'success'"
            class="bg-green-50 text-green-600 rounded-md mt-2 border border-green-300 font-semibold py-2 px-3 text-sm">
            Заявка сохранена успешно.
            <router-link to="/store/external">Вернуться к заявкам</router-link>
          </div>

          <div class="py-2 font-semibold px-0 text-sm">
            Всего в заявке {{ order.items.length }} товаров и
            {{ order.items.reduce((acc, item) => (acc += parseFloat(item.orderQuantity)), 0) }} штук
          </div>

          <div class="w-full overflow-x-auto text-left bg-white rounded-md">
            <table class="data-table">
              <thead>
                <tr>
                  <th class="w-450">Название</th>
                  <th>Штрихкод</th>
                  <th>
                    Количество
                  </th>
                  <th class="w-24"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  <td></td>
                  <td>
                    <div class="grid grid-cols-3 text-right">
                      <div>мин.</div>
                      <div>тек.</div>
                      <div>закуп.</div>
                    </div>
                  </td>
                  <td></td>
                </tr>
                <tr v-for="(item, index) in order.items">
                  <td>
                    <input type="text" v-model="item.name" class="w-full" :disabled="isReadOnly" />
                  </td>
                  <td>
                    <input type="text" v-model="item.barcode" required :disabled="isReadOnly" />
                  </td>
                  <td>
                    <div class="grid grid-cols-3">
                      <div class="text-right">
                        <qty-input class="text-right py-0 px-1 rounded-none w-11 border-blue-300" v-model="item.minVal"
                          readonly disabled />
                      </div>
                      <div class="text-right">
                        <qty-input class="text-right py-0 px-1 rounded-none w-11 border-red-300" v-model="item.stockVal"
                          readonly disabled />
                      </div>
                      <div class="text-right">
                        <qty-input class="text-right py-0 px-1 rounded-none w-11" v-model="item.orderQuantity" required
                          :disabled="isReadOnly" />
                      </div>
                    </div>
                  </td>
                  <td>
                    <button type="button" @click="order.items.splice(index, 1)" class="btn-secondary"
                      v-if="!isReadOnly">
                      Удалить
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="px-3 py-2" v-if="!isReadOnly">
              <button type="button" class="text-xs text-blue-500" @click="order.items.push({})" :disabled="isReadOnly">
                + Добавить позицию
              </button>
            </div>
            <div class="row py-1 items-center px-3 border-t" v-if="!isReadOnly">
              <span class="text-xs mr-2">Добавить по ШК</span>
              <barcode-scanner @scan="addProductFromBarcode" mode="localfirst" autofocus="true" />
            </div>
          </div>



        </div>






      </form>

      <loading v-if="busy" />
    </template>
  </page>
</template>

<script>
import { mapGetters } from "vuex";
import Page from "../../Page.vue";
import Info from "../../shared/Info.vue";
import Loading from "../../Loading.vue";
import ExternalModal from "./ExternalModal";

import api from "../../../api/api.js";
import { toHandlers } from "@vue/runtime-dom";
import dayjs from "dayjs";

export default {
  components: { Page, Info, Loading },
  name: "external",
  data() {
    return {
      order: {
        id: false,
        items: [],
        supplier_id: "",
        status: "draft"
      },
      busy: false,
      error: "",
      suppliers: [],
      status: "",
      statusMessage: "",
      saved: false,
      changedOnLoad: false,
      errorXML: false,
      xmlSending: false
    };
  },
  watch: {
    order: {
      deep: true,
      handler() {
        console.log("order changed");
        console.log(this.changedOnLoad);
        if (!this.changedOnLoad) {
          this.saved = false;
        }
      }
    }
  },
  mounted() {
    if (this.$route.params.id != "new") {
      this.getOrder(this.$route.params.id);
    } else {
      document.title = `Новая заявка`;
    }

    this.getSuppliers();
  },
  methods: {
    addProductFromBarcode(product) {
      if (product.id) {
        // if such product already exists, increment qty
        let index = this.order.items.findIndex(i => i.barcode == product.barcode);
        if (index > -1) {
          this.order.items[index].qty++;
        } else {
          this.order.items.push({
            barcode: product.barcode,
            title: product.name,
            qty: 1
          });
        }
      }
    },
    async getOrder(id) {
      // load suppliers list
      await this.getSuppliers();
      let vm = this;
      this.busy = true;
      vm.changedOnLoad = true;
      let { success, msg, data } = await api.get(`/external-orders/${id}`);
      if (success) {
        this.order = data;
        document.title = `Заявка #${this.order.number}`;
        setTimeout(() => {
          vm.changedOnLoad = false;
          vm.saved = true;
        }, 0);
      }

      vm.busy = false;
    },
    async getSuppliers() {
      let { success, msg, data } = await api.get(`/settings?type=suppliers`);
      this.suppliers = data.data;
    },
    async saveOrder() {
      this.errorXML = false;
      this.busy = true;
      let vm = this;
      this.error = "";
      this.statusMessage = "";

      // remove all empty items
      this.order.items = this.order.items.filter(i => i.orderQuantity > 0 && i.barcode != "");

      // order should have at least on item in items
      if (!this.order.items.length) {
        this.error = "Заявка должна содержать хотя бы одну позицию";
        this.busy = false;
        return;
      }

      let { success, msg, data } = await api.post("/external-orders", this.order);

      setTimeout(() => {
        vm.busy = false;
      }, 100);

      if (success) {
        vm.changedOnLoad;
        this.status = "success";
        this.order = data;

        setTimeout(() => {
          vm.changedOnLoad = false;
          vm.saved = true;
        }, 0);
      } else {
        this.statusMessage = msg;
      }
    },
    async sendToSupplier() {
      this.errorXML = false;
      this.xmlSending = true;
      let { success, msg, data } = await api.post(`/external-orders/${this.order.id}/send`);
      if (success) {
        this.order = data;
      } else {
        this.errorXML = msg;
      }
      this.xmlSending = false;
    },
    async downloadXMLUrl() {

      this.errorXML = false;
      //let url = process.env.VUE_APP_BACKEND_URL + `/external-orders/${this.order.id}/xml`;
      let { success, msg, data } = await api.get(`/external-orders/${this.order.id}/xml`);

      if (success) {
        var blob = new Blob([data], { type: "text/xml" });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `Заказ #${this.order.number} от ${dayjs(this.order.created_at).format("DD.MM.YYYY-HH-mm")}.xml`;
        link.click();
      } else {
        this.errorXML = msg;
      }

    },
    async deleteOrder() {
      if (!confirm("Вы уверены?")) {
        return;
      }
      let { success, msg, data } = await api.delete(`/external-orders/${this.order.id}`);
      if (success) {
        // redirect to list page
        this.$router.push({ name: "external" });
      }
    },
    async completeOrder() {
      if (confirm('Вы уверены что хотите отметить заявку как Завершен?')) {
        let { success, msg, data } = await api.post(`/external-orders/${this.order.id}/complete`);
        if (success) {
          // update page
          this.getOrder(this.$route.params.id);
        } else {
          alert(msg);
        }
      }
    }
  },
  computed: {
    // disable edit order if it's not a draft
    isReadOnly() {
      return this.order.status != "draft";
    },
    isSentIsAvailable() {
      // order should be saved first
      return this.order.status == "draft" && this.saved;
    }
    //...mapGetters("products", ["products"])
  }
};
</script>

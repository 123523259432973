<template>
  <div class="bg-white px-5 py-4 rounded-md">
    <h2 class="font-semibold text-xl mb-4">Эквайринг и комиссии</h2>

    <div class="w-2/3 overflow-x-auto text-left bg-white rounded-md">
      <table class="w-full data-table">
        <thead>
          <tr class="font-semibold text-xs border-b-2 text-gray-600">
            <th class="px-4 py-3">Тип платежа</th>
            <th class="px-4 py-3">Название</th>
            <th class="px-4 py-3">Комиссия</th>
            <th></th>
          </tr>
        </thead>
        <tbody class="text-sm">
          <tr v-for="(item, index) in merchants" class="hover:bg-blue-50" :key="index">
            <td>
              <select v-model="item.type" class="" :title="`${item.type}`">
                <option value="cash">Наличные</option>
                <option value="credit_card">Кредитная карта</option>
                <option value="express_yandex">Заказ Яндекс</option>
              </select>
            </td>
            <td>
              <input type="text" v-model="item.title" class="py-1 px-2 w-full" placeholder="Название" />
            </td>
            <td>
              <div class="border rounded-md flex flex-row items-center justify-between w-24">
                <input type="text" v-number="{ min: 0, max: 99, len: 5 }" v-model="item.fee"
                  class="border-0 w-full text-center px-0" max="99" placeholder="Комиссия, %" />
                <span class="bg-gray-100 font-semibold text-black px-2 py-1">%</span>
              </div>
            </td>
            <td>
              <button class="btn-delete" @click="removeMerchant(index)">
                <i class="fas fa-times"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <button @click="addMerchant()" class="mt-4 btn-link text-sm">
      <i class="fas fa-plus"></i>
      Добавить метод платежа
    </button>

    <loading v-if="busy" />
  </div>
</template>

<script>
export default {
  name: "merchants",
  props: ["merchants"],
  data() {
    return {
      busy: false
    };
  },
  mounted() { },
  methods: {
    removeMerchant(index) {
      this.merchants.splice(index, 1);
    },
    addMerchant() {
      this.merchants.push({
        type: "",
        title: "",
        fee: ""
      });
    }
  },
  computed: {}
};
</script>

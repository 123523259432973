<template>
  <div class="topbar text-white py-2.5 px-6 sticky border-b border-gray-200 top-0 z-10">
    <div class="flex flex-row justify-between items-center">
      <div class="flex flex-row items-center">
        <button @click="$events.$emit('togglePrimarySidebar')">
          <i class="fas fa-bars"></i>
        </button>

        <div class="ml-2 text-xs capitalize hidden md:block">
          {{ date }}
        </div>
      </div>

      <div class="md:w-1/3">
        <quick-search />
      </div>
      <div class="">
        <ul class="flex flex-row items-center gap-x-4">
          <li class="hidden md:block">
            <router-link to="/store/external" class="text-xs text-gray-500 hover:text-white"
              v-if="$requireRole('manager')">
              <span>Заявки</span>
            </router-link>
          </li>
          <li class="hidden md:block">
            <router-link to="/settings#inventory" class="text-xs text-gray-500 hover:text-white"
              v-if="$requireRole('manager')">
              <span>Минимальные остатки</span>
            </router-link>
          </li>
          <li class="hidden md:block">
            <router-link to="/store/stock" class="text-xs text-gray-500 hover:text-white"
              v-if="$requireRole('manager')">
              <span>Текущие остатки</span>
            </router-link>
          </li>
          <li class="hidden md:block">
            <router-link to="/network#prices" class="text-xs text-gray-500 hover:text-white"
              v-if="$requireRole('manager')">
              <span>Цены</span>
            </router-link>
          </li>
          <li class="">
            <alerts />
          </li>
          <li class="">
            <calls />
          </li>
          <li>

            <dropdown>
              <template v-slot:trigger>
                <div class="rounded-md bg-white bg-opacity-10 pr-2 py-0.5 hover:bg-opacity-20">
                  <div class="row">
                    <div class="absolute left-0 w-5">
                      <img class="h-5 w-5 rounded-full mr-2 ring-2 ring-black" :src="$pic($me.picture)"
                        v-if="$me.picture" />
                      <div :class="{
                        'bg-red-500': $me.online == 'disconnected',
                        'bg-green-500': $me.online == 'connected',
                        'bg-yellow-500': $me.online == 'connecting'
                      }" class="w-2.5 h-2.5 rounded-full absolute -top-1.5 right-1 border border-black">
                      </div>
                    </div>

                    <span class="text-sm pl-8 hidden md:block">{{ $me.email }}</span>
                  </div>
                </div>
              </template>
              <template v-slot:content>
                <div class="w-full divide-y">
                  <div class="px-1 py-1">
                    <!-- on click redirect to bot and autorize user -->
                    <button @click="connectTelegramBot()" v-if="!$me.tgChatId" class="btn-action-xs">
                      Подключить Телеграм
                    </button>

                    <!-- if user already connect, just redirect to bot -->
                    <a :href="`tg://${botname}`" v-if="$me.tgChatId" class="dropdown-item">
                      <i class="fab fa-telegram mr-1"></i> Открыть чат
                    </a>
                  </div>

                  <div class="px-1 py-1">
                    <button @click="logout" class="dropdown-item">
                      <i class="fas fa-sign-out-alt mr-1"></i>
                      <span>Завершить сеанс</span>
                    </button>
                  </div>
                </div>

              </template>
            </dropdown>

          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import ConnectTelegramBotModal from "../modals/ConnectTelegramBotModal";
import { mapGetters, mapState } from "vuex";
import Alerts from "../alerts/Alerts.vue";
import QuickSearch from "../shared/QuickSearch.vue";
import Calls from "@/components/calls/Calls.vue";

export default {
  components: { Calls, Alerts, QuickSearch },
  data() {
    return {
      date: "",
      botname: "",
    };
  },
  mounted() {
    let vm = this;
    setInterval(() => {
      vm.date = vm.$dayjs().format("dddd, DD MMMM YYYY, HH:mm");
    }, 1000);

    // if user not connected, generate once time token
    if (!this.$me.gtChatId) {
      this.checkTelegramConnection();
    }

    // if tg bot name missing, get it
    if (!this.botname) {
      this.loadNetworkSettings();
    }

    // watch for socket connection
    this.$events.$on("system", msg => {
      if (msg.type) {
        if (["connected", "connecting", "disconnected"].includes(msg.type)) {
          vm.$store.dispatch("app/setUserField", { key: "online", value: msg.type });
          // on connected, reload records
          if (msg.type == "connected") {
            vm.$events.$emit("record-reload");
          }
        }
      }
    });
  },
  methods: {
    async logout() {
      let { success, msg, data } = await this.$api.post("/auth/logout");
      this.$events.$emit('logout');
    },
    async loadNetworkSettings() {
      let { success, msg, data } = await this.$api.get("/settings?type=telegram");
      if (success) {
        this.botname = data.data.botname;
      }
    },
    async checkTelegramConnection() {
      // call api to get me user
      let { success, msg, data } = await this.$api.call("/me");
      if (success) {
        let { tgChatId } = data;
        if (tgChatId) {
          //this.$me.tgChatId = tgChatId;
          this.$store.dispatch("app/setUserField", { key: "tgChatId", value: tgChatId });
        } else {
          // reset tgChatId
          this.$store.dispatch("app/setUserField", { key: "tgChatId", value: false });
        }
      }
    },
    connectTelegramBot() {
      // show modal window with instructions what to do
      this.$modals.open(ConnectTelegramBotModal, { botname: this.botname });
    }
  },
  computed: {
    ...mapState("app", ["settings"])
  }
};
</script>

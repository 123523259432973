<template>
  <div
    class="border-green-200 border text-green-600 py-2 text-sm rounded-md px-4 mt-2"
  >
    <slot>Сохранено.</slot>
  </div>
</template>

<script>
export default {
  name: "result",
  data() {
    return {};
  },
};
</script>
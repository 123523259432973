import Vue from "vue";
export default new Vue({
  methods: {
    open(component, props = {}, callback = () => {}) {
      return new Promise((resolve, reject) => {
        this.$emit("open", { component, props, resolve, reject, callback });
      });
    },
    close() {
      this.$emit("close");
    }
  }
});

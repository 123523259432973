<template>
  <page :notitle="true">
    <template v-slot:content>
      <div class="lg:max-w-2xl lg:mx-auto">
        <div class="relative">
          <button
            @click="prev()"
            class="absolute left-0 lg:left-0 text-blue-600 text-xl md:text-2xl"
            v-if="showPrev"
          >
            <i class="fas fa-angle-left mr-2"></i>
            <span class=" text-xs -top-1 relative">Назад</span>
          </button>
          <div class="w-full px-4 text-center">
            <span class="text-xs text-gray-500">
              {{ record.startTime | humantime }}
              {{ services[record.serviceId] ? services[record.serviceId].title : "" }}
            </span>
            <h1 class="text-lg md:text-xl font-bold mt-1" v-if="!completed">
              {{ steps[stepIndex].title }}
            </h1>
          </div>

          <button
            class="absolute right-4 -top-4 text-red-600 text-lg md:text-sm"
            @click="cancel()"
            v-if="!completed"
          >
            <i class="fas fa-times"></i>
            <span class="hidden ml-2 text-xs -top-0.5 relative">Отменить</span>
          </button>
        </div>

        <div class="h-2 mt-4 relative w-full rounded-full overflow-hidden" v-if="!completed">
          <div class=" w-full h-full bg-gray-200 absolute "></div>
          <div class=" h-full bg-green-500 absolute" :style="'width:' + progressWidth + '%'"></div>
        </div>

        <!-- Metrics -->
        <div v-if="!completed" class="bg-white px-4 py-4 pb-4 mt-6 rounded-md hide-if-empty">
          <!-- Customer -->
          <div v-if="steps[stepIndex].slug == 'start'" class="read-fields relative">
            <div class="" v-if="record.client">
              <medic-notes :id="record.client_id" :key="customerRevision" />
            </div>
            <button
              @click="editCustomer()"
              class="bg-blue-500 text-white text-xs rounded-md px-2 py-1 absolute top-0 right-0"
            >
              <i class="fas fa-pen"></i>
            </button>
          </div>

          <!-- medic details -->
          <div v-if="steps[stepIndex].slug == 'medic-details'">
            <medic-details :record="record" />
          </div>

          <!-- Eye passport -->
          <div v-if="steps[stepIndex].slug == 'eye-passport'">
            <eye-passport :record="record" />
          </div>

          <!-- Complaints -->
          <div v-if="steps[stepIndex].slug == 'complaints'">
            <complaints :record="record" />
          </div>

          <!-- Metrics  -->
          <div v-if="steps[stepIndex].slug == 'metrics'">
            <metrics :record="record" />
          </div>

          <!-- Glasses -->
          <div v-if="steps[stepIndex].slug == 'current-glasses'">
            <glasses :obj="record.currentGlasses" />
          </div>

          <!-- New glasses -->
          <div v-if="steps[stepIndex].slug == 'new-glasses'">
            <glasses :obj="record.newGlasses" :is-new="true" :metrics="record.metrics" />
          </div>

          <!-- Select lenses -->
          <div v-if="steps[stepIndex].slug == 'diagnostic'">
            <test-lenses :record="record" />
          </div>

          <!-- Goods -->
          <div v-if="steps[stepIndex].slug == 'items'">
            <!-- Comment -->
            <div class="font-semibold text-sm mb-2">Комментарий</div>
            <textarea class="w-full" v-model="record.goods.comment"></textarea>
          </div>

          <!-- Checkout -->
          <div v-if="steps[stepIndex].slug == 'checkout'">
            Завершить прием.
          </div>
        </div>

        <!-- Navigation -->
        <div class="sticky bg-body bottom-0 py-2">
          <div class="grid grid-cols-3 gap-x-2" v-if="!completed">
            <div>
              <button
                @click="prev()"
                class="bg-gray-200 rounded-md font-medium lg:py-2 py-2 px-4 w-full"
                v-if="showPrev"
              >
                Назад
              </button>
            </div>
            <button @click="next()" class="btn-primary w-full col-span-2" v-if="!lastStep">
              Продолжить
            </button>
            <button @click="complete()" class="btn-primary w-full  col-span-2" v-if="lastStep">
              Завершить
            </button>
          </div>
        </div>

        <div v-if="completed && $route.query.step == undefined" class="text-center">
          <img src="/img/success.svg" class="mx-auto mt-4 h-24" />

          <div class="mt-4">Прием завершен за {{ elapsedTime }} мин.</div>

          <router-link to="/schedule">
            <button class="btn-primary mx-auto mt-6">Открыть расписание</button>
          </router-link>
        </div>
      </div>
    </template>
  </page>
</template>

<script script>
import Record from "../../repo/RecordRepo";
import UserCard from "../shared/UserCard.vue";
import ClientCard from "../shared/ClientCard.vue";
import MedicNotes from "../customers/MedicNotes.vue";
import { mapGetters } from "vuex";
import CustomerModal from "../customers/EditCustomerModal.vue";

import { common, mkl, eyeStrain } from "../../data/complains";
import Info from "../shared/Info.vue";
import Autocomplete from "../shared/Autocomplete.vue";

import { sph } from "../../data/values";

import Complaints from "./steps/Complaints.vue";
import Glasses from "./Glasses.vue";
import Metrics from "./Metrics.vue";
import TestLenses from "./steps/TestLenses";
import EyePassport from "./steps/EyePassport";
import MedicDetails from "./steps/MedicDetails";

export default {
  components: {
    UserCard,
    ClientCard,
    MedicNotes,
    Info,
    Autocomplete,
    Complaints,
    Glasses,
    Metrics,
    TestLenses,
    EyePassport,
    MedicDetails
  },
  props: ["id", "step"],
  data() {
    return {
      customerRevision: "",
      loading: false,
      record: {},
      stepIndex: 0,
      steps: [
        {
          title: "Клиент",
          slug: "start"
        },
        {
          title: "Медкарта",
          slug: "medic-details"
        },
        {
          title: "Офтальмологический паспорт",
          slug: "eye-passport"
        },
        {
          title: "Жалобы",
          slug: "complaints"
        },
        {
          title: "Текущие очки",
          slug: "current-glasses"
        },
        {
          title: "Показатели",
          slug: "metrics"
        },
        {
          title: "Подбор МКЛ",
          slug: "diagnostic"
        },
        {
          title: "Новые очки",
          slug: "new-glasses"
        },

        {
          title: "Добавить в заказ",
          slug: "items"
        },
        {
          title: "Оплата",
          slug: "checkout"
        }
      ],
      options: [
        {
          title: "Ношение очков",
          variants: ["Да"],
          selected: []
        },
        {
          title: "Ношение МКЛ",
          variants: ["Да"],
          selected: []
        },
        {
          title: "Стаж ношения МКЛ",
          condition: "Ношение МКЛ",
          variants: [],
          selected: []
        }
      ],
      glassesOptions: ["SPH", "CYL", "AXIS", "ADD", "PD"],
      lensesOptions: ["PWR", "CYL", "BC", "DIA"],
      receptType: false,
      recept: {},
      complaintsOptions: [],
      complaintsMkl: [],
      eyeStrainOptions: [],
      sph: []
    };
  },
  async mounted() {
    this.complaintsOptions = common;
    this.complaintsMkl = mkl;
    this.eyeStrainOptions = eyeStrain;

    this.sph = sph;

    console.log(this.step);
    console.log(this.stepIndex);

    let vm = this;
    // hide modals
    this.$modals.close();
    this.getRecord(this.id);
    if (this.step == "start") {
      this.stepIndex = this.steps.findIndex(item => item.slug == vm.step);
      console.log(this.stepIndex);
    }

    // update customer if customer changed via modal
    this.$events.$on("customer-updated", function(id) {
      if (id == vm.record.client_id) {
        vm.customerRevision++;
      }
    });
  },
  methods: {
    editCustomer() {
      this.$modals.open(CustomerModal, { id: this.record.client_id });
    },
    // hide & show options that linked with others
    checkCondition(option) {
      if (option.condition) {
        // find this condition option
        let conditionOption = this.options.find(opt => opt.title == option.condition);
        // check value
        if (conditionOption) return conditionOption.selected.length > 0;

        return false;
      }

      return true;
    },
    // add custom option to list
    addCustomOption(option) {
      option.variants.push(option.addCustomVal);
      option.selected.push(option.addCustomVal);
      option.addCustomVal = "";
    },
    next() {
      if (this.stepIndex < this.steps.length) {
        this.stepIndex++;
      }

      // is it hidden step, move next

      if (this.steps[this.stepIndex].slug == "recept") {
        this.setReceptOptions();
      }

      if (this.steps[this.stepIndex].hidden) {
        this.next();
      }

      this.save();
    },
    prev() {
      if (this.stepIndex > 0) {
        this.stepIndex--;
      }

      if (this.steps[this.stepIndex].slug == "recept") {
        this.setReceptOptions();
      }

      if (this.steps[this.stepIndex].hidden) {
        this.prev();
      }
    },
    /**
     * Cancel record
     */
    async cancel() {
      this.record.status = "confirmed";
      await this.save();
      this.$router.push({ name: "schedule" });
    },
    /**
     * Complete record
     */
    async complete() {
      // prepare data
      this.record.end = this.$dayjs()
        .utc()
        .unix();
      this.record.status = "completed";

      await this.save();
    },
    async getRecord(id) {
      this.loading = true;
      const { data, msg, success } = await Record.getByid(this.id);
      if (success) {
        this.record = data;

        // update options with data from record history
        if (this.record.receptType) {
          this.receptType = this.record.receptType;
        }

        // set current step if exists
        if (this.record.step && this.$route.query.step == undefined) {
          this.stepIndex = this.record.step;
        }

        if (this.steps[this.stepIndex].slug == "recept") {
          this.setReceptOptions();
        }

        // save start time
        if (this.step == "start" && !this.completed) {
          this.record.status = "started";
          this.record.start = this.$dayjs()
            .utc()
            .unix();
          this.save();
        }

        // open neded step
        if (this.$route.query.step != undefined) {
          let index = this.steps.findIndex(el => el.slug == this.$route.query.step);
          if (index != -1) {
            this.stepIndex = index;
          }
        }
      }
      this.loading = false;
    },
    async save() {
      this.loading = true;

      let history = [];
      this.options.map(item => {
        history.push({
          title: item.title,
          selected: item.selected
        });

        return item;
      });

      this.record.receptType = this.receptType;

      this.record.step = this.stepIndex;

      let { success, msg, data } = await Record.update(this.record);
      if (success) {
        this.record = data;
      }
      this.loading = false;
    },
    setReceptOptions() {
      let vm = this;
      vm.receptType = false;

      let conditionOption = this.options.find(opt => opt.title == "Ношение МКЛ");
      if (conditionOption.selected.length > 0) {
        if (conditionOption.selected.indexOf("Да") !== -1) {
          vm.receptType = "lenses";
        }
      }

      conditionOption = this.options.find(opt => opt.title == "Ношение очков");

      if (conditionOption.selected.length > 0) {
        if (conditionOption.selected.indexOf("Да") !== -1) {
          vm.receptType = "glasses";
        }
      }

      console.log(this.receptType);
      let ind = this.steps.findIndex(step => step.slug == "recept");
      if (vm.receptType !== false) {
        if (ind !== -1) {
          this.steps[ind].hidden = false;
        }
      } else {
        this.steps[ind].hidden = true;
      }

      // fill the record object
      if (vm.receptType) {
        let opts = vm.receptType == "lenses" ? this.lensesOptions : this.glassesOptions;

        //this.record.recept = {};

        // if record recept data doesnt' exists
        console.log(Object.keys(this.record.recept).length);
        if (Object.keys(this.record.recept).length == 0) {
          for (let opt of opts) {
            this.record.recept[opt] = { os: "", od: "" };
            console.log(opt);
          }
        }

        console.log(this.record.recept);
      }

      this.record.receptType = vm.receptType;
    }
  },
  computed: {
    ...mapGetters("optics", ["optics", "active", "getOpticService", "getById"]),
    ...mapGetters("records", ["getStatus"]),
    services() {
      let optic = this.optics.find(item => item.id == this.record.opticId || this.active.id);

      if (this.active) {
        return this.active.services;
      }

      return [];
    },
    progressWidth() {
      let width = Math.abs(100 / this.steps.length);
      return width * (this.stepIndex + 1);
    },
    showPrev() {
      return this.stepIndex > 0 && !this.completed;
    },
    lastStep() {
      return this.stepIndex == this.steps.length - 1;
    },
    completed() {
      return this.record.status == "completed" && this.$route.query.step == undefined;
    },
    elapsedTime() {
      return Math.ceil((this.record.end - this.record.start) / 60);
    }
  }
};
</script>

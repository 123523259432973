<template>
  <div id="app">
     <modal-root/>
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import BasicLayout from "./components/BasicLayout";
import BlankLayout from "./components/BlankLayout";
import ModalRoot from "./components/modals/ModalRoot.vue";

export default {
  name: "App",
  components: {
    BasicLayout, BlankLayout, ModalRoot
  },
  computed: {
    layout() {
      //return "basic-layout";
      return (this.$route.meta.layout || 'basic') + '-layout'
    },
  },
};
</script>

<style lang="scss">
// @import "./assets/scss/app.scss";
</style>

<template>
  <div class="cmodal-mask" @click="closeModalOnBackgroundClick" ref="modalBackground">
    <!-- <div class="fixed mr-3 inset-0 bg-black bg-opacity-20"></div> -->

    <div class="px-4" ref="container">
      <div
        class="cmodal-container top-10 overflow-visible my-8 shadow-xl relative mx-auto bg-white py-8 px-8 md:px-12 rounded-2xl mb-20"
        :class="styles && styles.length > 0 ? styles : 'min-w-2xl max-w-2xl'"
      >
        <div
          v-if="success"
          class="gback rounded-2xl bg-white bg-opacity-60  flex items-center text-center"
        >
          <div class="mx-auto max-w-sm">
            <div class="checkmark">
              <svg
                class="checkmark__check"
                height="36"
                viewBox="0 0 48 36"
                width="48"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M47.248 3.9L43.906.667a2.428 2.428 0 0 0-3.344 0l-23.63 23.09-9.554-9.338a2.432 2.432 0 0 0-3.345 0L.692 17.654a2.236 2.236 0 0 0 .002 3.233l14.567 14.175c.926.894 2.42.894 3.342.01L47.248 7.128c.922-.89.922-2.34 0-3.23"
                ></path>
              </svg>
              <svg
                class="checkmark__back"
                height="115"
                viewBox="0 0 120 115"
                width="120"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M107.332 72.938c-1.798 5.557 4.564 15.334 1.21 19.96-3.387 4.674-14.646 1.605-19.298 5.003-4.61 3.368-5.163 15.074-10.695 16.878-5.344 1.743-12.628-7.35-18.545-7.35-5.922 0-13.206 9.088-18.543 7.345-5.538-1.804-6.09-13.515-10.696-16.877-4.657-3.398-15.91-.334-19.297-5.002-3.356-4.627 3.006-14.404 1.208-19.962C10.93 67.576 0 63.442 0 57.5c0-5.943 10.93-10.076 12.668-15.438 1.798-5.557-4.564-15.334-1.21-19.96 3.387-4.674 14.646-1.605 19.298-5.003C35.366 13.73 35.92 2.025 41.45.22c5.344-1.743 12.628 7.35 18.545 7.35 5.922 0 13.206-9.088 18.543-7.345 5.538 1.804 6.09 13.515 10.696 16.877 4.657 3.398 15.91.334 19.297 5.002 3.356 4.627-3.006 14.404-1.208 19.962C109.07 47.424 120 51.562 120 57.5c0 5.943-10.93 10.076-12.668 15.438z"
                  fill="#25D660"
                ></path>
              </svg>
            </div>

            <span class="font-semibold text-lg">Сохранено</span>
          </div>
        </div>
        <button class="absolute top-4 right-6" @click="$modals.close()" type="button">
          <i class="fas fa-times"></i>
        </button>
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["styles"],
  data() {
    return {
      success: false
    };
  },
  mounted() {
    document.addEventListener("keydown", e => {
      if (e.key === "Escape") {
        this.$emit("dismiss");
        this.$modals.close();
      }
    });
  },
  methods: {
    closeOnExitIcon(e) {
      this.$emit("dismiss");
      this.$modals.close();
    },
    closeModalOnBackgroundClick(e) {
      // if it's not a child
      let bg = this.$refs.modalBackground;
      if (bg) {
        if ((bg.contains(e.target) && e.target == this.$refs.container) || e.target == bg) {
          this.$emit("dismiss");
          this.$modals.close();
        }
      }
    }
  }
};
</script>

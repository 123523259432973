<template>
  <div>
    <label>
      <div
        class="relative inline-block w-8 mr-2 align-middle select-none transition duration-200 ease-in"
      >
        <div>
          <input
            type="checkbox"
            :checked="value"
            @change="emitt"
            class="toggle-checkbox"
          />
          <span
            class="toggle-label block overflow-hidden h-5 rounded-full bg-gray-200 cursor-pointer"
          ></span>
        </div>
      </div>
      <slot></slot>
    </label>
  </div>
</template>

<script>
export default {
  props: ["value"],
  methods: {
    emitt(event) {
      this.$emit("input", event.target.value == "on" && this.value == false);
      console.log(this.value)
      console.log(event.target.value)
      this.$emit("updated", event.target.value);
    }
  }
};
</script>

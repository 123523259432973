<template>
  <div class="">
     <catalog :store="store" :typeview="'inventory'"></catalog>
  </div>
</template>

<script>
import Catalog from "@/components/catalogTable/Catalog.vue";

export default {
  components: { Catalog },
  props: ["store"],
  data() {
    return {
    };
  },
  mounted() {

  },
  methods: {

  },
  computed: {},
  watch: {}
};
</script>

import api from "../api/api";
const resource = "/g/lensmodels";

export default {
  async get(id = false) {
    let { success, msg, data } = await api.call(`${resource}${id ? '/' + id : ''}`);
    return data;
  },
  async query(query) {
    let { success, msg, data } = await api.call(`${resource}?filter=${query}`);
    return data;
  },
  async getCompetitorPrices(models=[]) {
    let { success, msg, data } = await api.call(`/ext/competitors/${models}`);
    return data;
  },
  async getByBrand(brand) {
    let { success, msg, data } = await api.call(`${resource}?brand=${brand}`);
    return data;
  },
  async getByMaker(maker) {
    // replace ampersand
    maker = maker.replace(/&/g, "%26");
    let { success, msg, data } = await api.call(`${resource}?maker=${maker}`);
    return data;
  },
  getGlobal() {
    return api.call(`${resource}?all`);
  },
  search(term) {
    if (term) return api.call(`${resource}/search/${term}`, { method: "get" });
  },
  getByid(id) {
    return api.call(`${resource}/${id}`, { method: "get" });
  },
  update(payload) {
    return api.call(`${resource}`, { method: "post", data: payload });
  },
  delete(id) {
    return api.call(`${resource}/${id}`, { method: "delete" });
  }
};

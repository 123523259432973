<template>
  <modal :styles="'max-w-lg'">
    <div>
      <h3 class="text-black font-bold text-xl mb-6 text-center">
        {{ $t("addProduct") }}
      </h3>

      <div>
        <!-- scan barcode -->
        <div class="" v-if="screen == 'scanner'">
          <form @submit.prevent="next()">
            <div class="flex flex-row items-center justify-center w-full gap-x-2 mb-4">
              <div>
                <input
                  type="text"
                  class="flex-1"
                  v-model="barcode"
                  placeholder="Штрихкод"
                  ref="barcode"
                  required
                />
              </div>
              <button
                type="submit"
                class="bg-blue-500 text-white rounded-md px-4 py-3 text-sm font-semibold disabled:opacity-50"
                :class="{ busy: busy }"
              >
                Дальше
              </button>
            </div>

            <div class="flex flex-row gap-x-2 opacity-50" v-if="extendMode">
              <input
                type="text"
                class="text-sm py-1 px-2 w-2/4"
                v-model="lot"
                placeholder="Код лота"
                required
              />
              <input
                type="text"
                class="text-sm py-1 px-2 w-1/4"
                v-model="expireMonth"
                required
                placeholder="MM"
              />
              <input
                type="text"
                class="text-sm py-1 px-2 w-1/4"
                v-model="expireYear"
                required
                placeholder="YYYY"
              />
            </div>
          </form>

          <div class="text-gray-700 py-2 text-lg text-center mt-2">{{ product.name }}</div>

          <div class="text-green-500 font-semibold mt-2 text-sm text-center" v-if="success">
            Добавлено.
          </div>

          <div class="text-red-500 font-semibold mt-2 text-sm text-center" v-if="error">
            {{ $t(error) }}
          </div>

          <div v-if="scannedItems.length > 0" class="mt-6">
            <div class="text-sm mb-2 mt-4 hidden">
              Отсканировано товаров: {{ scannedItems.length }}
            </div>

            <div
              class="grid grid-cols-4  gap-x-2 text-sm border-b border-gray-200 py-2 relative"
              v-for="(item, index) in scannedItems"
              :key="item.barcode"
            >
              <div class="col-span-3">
                {{ item.name }}
                <div class="flex flex-row gap-x-4 mt-1 text-xs items-center">
                  <div class="whitespace-nowrap font-mono" v-if="item.barcode">
                    <i class="fas fa-barcode"></i>
                    {{ item.barcode }}
                  </div>
                  <div v-if="item.lots.length > 0" class="">
                    <i class="fas fa-bars mr-1"></i>
                    {{ item.lots.length }}
                  </div>
                </div>
              </div>
              <div class="font-semibold">{{ item.qty }} шт.</div>
              <button
                type="button"
                class="absolute right-0 top-2"
                @click="removeScannedItem(index)"
              >
                <i class="fas fa-times text-red-500"></i>
              </button>
            </div>
          </div>
          <div
            class="border-t mt-20 border-gray-200 pt-4 flex flex-row items-center justify-between"
            v-if="scannedItems.length > 0"
          >
            <button
              class="btn-save"
              @click="complete()"
              :class="{ busy: completeBusy }"
              :disabled="completeBusy"
            >
              Завершить
            </button>
          </div>

          <div class="mt-6">
            <div class="flex flex-row items-center justify-between ">
              <!-- toggler -->
              <div class="row text-gray-300  text-xs font-semibold cursor-pointer">
                <div
                  class="mr-2"
                  :class="{ 'text-black': extendMode == false }"
                  @click="(extendMode = false), tgkey++"
                >
                  ШК
                </div>
                <sm-toggle
                  v-on:updated="extendMode = $event"
                  class=""
                  title="Расширенный режим"
                  :default="extendMode"
                  :key="tgkey"
                />
                <div :class="{ 'text-black': extendMode }" @click="(extendMode = true), tgkey++">
                  Лот + Дата
                </div>
              </div>
              <div
                class="row ml-8 gap-x-4 text-gray-500 text-xs font-semibold cursor-pointer"
                v-if="extendMode"
              >
                <a :href="url" class="link">
                  <i class="fas fa-print mr-0.5"></i>
                  Месяц / Год
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "../modals/Modal.vue";
import ModalMixin from "../modals/modal.mixin";
import Product from "../../repo/ProductRepo";
import Inventory from "../../repo/InventoryRepo.js";

export default {
  components: { Modal },
  mixins: [ModalMixin],
  props: [],
  data() {
    return {
      busy: false,
      completeBusy: false,
      barcode: "",
      expire: "",
      expireYear: "",
      expireMonth: "",
      lot: "",
      mode: "single",
      screen: "scanner",
      product: {},
      item: {},
      inventory: {},
      success: false,
      error: false,
      scannedItems: [],
      extendMode: false,
      tgkey: 0,
      url: process.env.VUE_APP_BACKEND_URL + "/utils/print-barcodes"
    };
  },
  mounted() {
    this.$refs.barcode.focus();
  },
  methods: {
    async next() {
      let vm = this;

      vm.success = false;
      vm.error = false;
      vm.product = {};

      try {
        let scannedItem = await Inventory.getProduct(this.barcode);
        this.product = scannedItem;

        // add to scanned items or add qty
        let found = this.scannedItems.find(item => item.barcode == scannedItem.barcode);
        if (found) {
          found.qty++;
          // add lot
        } else {
          found = {
            barcode: scannedItem.barcode,
            qty: 1,
            lots: [],
            product_id: scannedItem.id,
            name: scannedItem.name
          };

          this.scannedItems.push(found);
        }
        // add lot
        if (this.lot) {
          found.lots.push({ lot: this.lot, expire: this.expireMonth + "." + this.expireYear });
        }

        vm.success = true;
        vm.barcode = "";
        vm.lot = "";
        vm.expireMonth = "";
        vm.expireYear = "";

        //this.$events.$emit("reload");
      } catch (e) {
        vm.error = e.message;
      }
    },
    removeScannedItem(index) {
      this.scannedItems.splice(index, 1);
    },
    /**
     * Complete the inventory
     * save it and show result
     */
    async complete() {
      // save it
      try {
        this.completeBusy = true;
        let scannedItem = await Inventory.addScannedItems(this.scannedItems);
        this.$events.$emit("reload");
        this.close();
      } catch (e) {
        this.error = e.message;
      }

      this.completeBusy = false;
    }
  }
};
</script>

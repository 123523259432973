import Vue from "vue";
import UserRepo from "../../repo/UserRepo";

const state = {
  users: [],
  selected: {}
};

const getters = {
  users: state => {
    return state.users;
  },
  getById: state => ({ id }) => {
    return state.users.find(item => item.id == id);
  },
  getByDept: state => ({ id }) => {
    return state.users.filter(item => item.depts.indexOf(id) !== -1);
  },
  roles: (state, id) => {
    return [{}];
  }
};

// actions
const actions = {
  async pullUsers(context, payload) {
    const { data } = await UserRepo.get();
    context.commit("setUsers", data);
  },
  wipe: ({ commit }) => {
    commit("wipe");
  }
};

// mutations
const mutations = {
  setUsers(state, payload) {
    // store only id name picture role
    state.users = payload;
  },
  wipe(state) {
    state.users = [];
    state.selected = {};
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

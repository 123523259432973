<template>
  <div class="content mb-12">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "page-content",
  props: [""],
  data() {
    return {};
  },
};
</script>
<template>
    <modal :styles="''">
        <div class="flex flex-col space-y-2">
            <h3 class="text-black font-bold text-xl">
                <span v-if="type == 'collect'">Новая инкассация</span>
                <span v-if="type == 'deposit-cash'">Внести</span>
                <span v-if="type == 'withdraw-cash'">Получить</span>
            </h3>

            <div class="py-4 px-4 bg-gray-100 text-gray-500 rounded-md text-sm" v-if="type == 'collect'">
                Будет создана новая инкассация с указанной суммой и комменатрием.
                Инкассация имеет 3 статуса. В ожидании, в процессе, выполнена.
                <ul class="flex flex-col space-y-2 mt-2">
                    <li>
                        <span class="badge gray">В ожидании</span> - деньги все еще в кассе, ожидают инкассатора.
                    </li>
                    <li><span class="badge blue">В процессе</span> - денег в кассе уже нет, но и на счете еще не
                        отобразились.</li>
                    <li><span class="badge green">Выполнена</span> - деньги пришли на счет в
                        банке.</li>
                </ul>

            </div>

            <div class="flex flex-row items-center justify-between">
                <div class="w-1/3">
                    <label class="form-label">Сумма</label>
                    <input type="number" v-model="tr.amount" class="w-full" />
                </div>
                <div class="text-sm px-4" v-if="maxAmount">
                    Максимально возможная сумма
                    <span class="block font-mono text-lg font-semibold">
                        {{ maxAmount | money }} руб.
                    </span>
                </div>
            </div>


            <div>
                <label class="form-label">Комментарий</label>
                <textarea v-model="tr.note" class="w-2/3"></textarea>
            </div>

            <div>
                <button type="button" class="btn-save" @click="createTransaction()" :disabled="busy"
                    :class="{ busy: busy }">
                    Сохранить
                </button>
            </div>
        </div>
    </modal>
</template>

<script>
import ModalMixin from "../modals/modal.mixin";
export default {
    props: ["type", "maxAmount"],
    mixins: [ModalMixin],
    data() {
        return {
            tr: {
                type: '',
                status: '',
                amount: '',
                note: '',
            },
            busy: false
        }
    },
    mounted() {
        if (this.type) {
            this.tr.type = this.type;
        }
        if (this.maxAmount) {
            this.tr.amount = this.maxAmount;
        }
    },
    methods: {
        async createTransaction() {

            if (parseFloat(this.tr.amount) > parseFloat(this.maxAmount)) {
                alert('Сумма больше максимальной');
                return;
            }

            this.busy = true;
            let { success, msg, data } = await this.$api.post('/transactions', this.tr);
            this.busy = false;

            this.close(true);
        }
    }
}
</script>
<template>
  <div class="my-8">
    <div class="flex items-center">
      <h1 class="text-2xl font-bold mr-4">{{ title }}</h1>
      <div class="flex iems-center space-x-3 mt-1">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "page-header",
  props: ["title"],
  data() {
    return {};
  },
};
</script>
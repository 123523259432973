import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";
Vue.use(VueRouter);

import Homepage from "../components/Homepage";

import Dashboard from "../components/Dashboard";
import OpticPage from "../components/optic/OpticPage";
import GlobalProductsV2 from "../components/GlobalProductsV2";
import GlobalProducts from "../components/GlobalProducts";
import Suppliers from "../components/suppliers/Suppliers.vue";
import SupplierPage from "../components/suppliers/SupplierPage.vue";

import Calendar from "../components/Calendar";
import Schedule from "../components/Schedule";
import Customers from "../components/Customers";
import Sales from "../components/sales/Sales.vue";
import Records from "../components/Records";

import Record from "../components/records/Record.vue";
import StoreProducts from "../components/store/Products.vue";
import Inventory from "../components/store/inventory/Inventory.vue";
import InventoryView from "../components/store/inventory/View.vue";
import AddInventory from "../components/store/inventory/AddInventory.vue";
import StoreHistory from "../components/store/history/History.vue";
import StoreSupply from "../components/store/supply/SupplyPage.vue";
import CurrentStock from "../components/store/CurrentStock.vue";


import AddSupply from "../components/store/supply/AddSupply";
import StoreExternalOrders from "../components/store/external/ExternalPage.vue";
import ViewExternalOrderPage from "../components/store/external/ViewExternalOrderPage";

import Transactions from '../components/kassa/Transactions.vue';
import CashboxReports from '../components/kassa/ReportsPage.vue';
import NetworkPage from '../components/network/NetworkPage.vue';
import HomeHelpPage from "../components/help/Home.vue";

import ManageEmails from "../components/network/ManageEmails.vue";
import DocIndex from "../components/docs/DocIndex.vue";
import DocPage from "../components/docs/DocPage.vue";

const Role = { admin: "admin", manager: "manager", seller: "seller", medic: "medic" };

export default new VueRouter({
  mode: "history",
  linkActiveClass: "active",
  routes: [
    {
      path: "/auth/login",
      name: "auth",
      component: Homepage,
      props: true,
      meta: {
        layout: "blank",
        title: "Homepage"
      }
    },
    {
      path: "/",
      name: "homepage",
      component: Homepage,
      props: true,
      meta: {
        layout: "blank",
        title: "Homepage"
      }
    },
    {
      path: "/dashboard",
      name: "dashboard",
      component: Dashboard,
      props: true,
      meta: {
        title: "Главная",
        requiresAuth: true
      }
    },
    {
      path: "/calendar",
      name: "calendar",
      component: Calendar,
      props: true,
      meta: {
        title: "Табель работы",
        requiresAuth: true
      }
    },
    {
      path: "/schedule",
      name: "schedule",
      component: Schedule,
      props: true,
      meta: {
        title: "Раписание",
        requiresAuth: true
      }
    },
    {
      path: "/customers",
      name: "customers",
      component: Customers,
      props: true,
      meta: {
        title: "Клиенты",
        requiresAuth: true
      }
    },
    {
      path: "/suppliers",
      name: "suppliers",
      component: Suppliers,
      props: true,
      meta: {
        title: "Справочник поставщиков",
        requiresAuth: true
      }
    },
    {
      path: "/suppliers/:id",
      name: "supplier-page",
      component: SupplierPage,
      props: true,
      meta: {
        title: "",
        requiresAuth: true
      }
    },
    // docs
    {
      path: "/docs",
      name: "docs",
      component: DocIndex,
      props: true,
      meta: {
        title: "Документы",
        requiresAuth: true
      }
    },
    {
      path: "/docs/:id",
      name: "doc-page",
      component: DocPage,
      props: true,
      meta: {
        title: "",
        requiresAuth: true
      }
    },
    {
      path: "/sales",
      name: "sales",
      component: Sales,
      props: true,
      meta: {
        title: "Продажи",
        requiresAuth: true
      }
    },
    {
      path: "/records",
      name: "records",
      component: Records,
      props: true,
      meta: {
        title: "Записи",
        requiresAuth: true
      }
    },
    {
      path: "/records/:id",
      name: "record",
      component: Record,
      props: true,
      meta: {
        title: "Запись",
        requiresAuth: true
      }
    },
    {
      path: "/optics/:id",
      name: "optic-page",
      component: OpticPage,
      props: true,
      meta: {
        title: "Оптика",
        requiresAuth: true,
        authorize: [Role.admin, Role.manager]
      }
    },
    {
      path: "/settings",
      name: "settings",
      component: OpticPage,
      props: true,
      meta: {
        title: "Оптика",
        requiresAuth: true,
        authorize: [Role.admin, Role.manager]
      },
      beforeEnter: (to, from, next) => {
        // add current department id
        //to.params.id = store.getters["optics/active"].id;
        return next();
      }
    },
    {
      path: "/products",
      name: "products",
      component: GlobalProducts,
      props: true,
      meta: {
        title: "Справочник товаров",
        requiresAuth: true
      }
    },
    {
      path: "/store",
      name: "store",
      component: StoreProducts,
      props: true,
      meta: {
        title: "Товары",
        requiresAuth: true
        //authorize: [Role.admin, Role.manager]
      }
    },
    {
      path: "/store/inventory",
      name: "inventory",
      component: Inventory,
      props: true,
      meta: {
        title: "Инвентаризация",
        requiresAuth: true
        //authorize: [Role.admin, Role.manager]
      }
    },
    {
      path: "/store/external",
      name: "external",
      component: StoreExternalOrders,
      props: true,
      meta: {
        title: "Заявки поставщикам",
        requiresAuth: true
        //authorize: [Role.admin, Role.manager]
      },
    },
    {
      path: "/store/external/:id",
      name: "view-external",
      component: ViewExternalOrderPage,
      props: true,
      meta: {
        requiresAuth: true
        //authorize: [Role.admin, Role.manager]
      },
    },
    {
      path: "/store/inventory/edit/:id",
      name: "add-inventory",
      component: AddInventory,
      props: true,
      meta: {
        title: "Новая инвентаризация",
        requiresAuth: true
        //authorize: [Role.admin, Role.manager]
      }
    },
    {
      path: "/store/inventory/:id",
      name: "view-inventory",
      component: InventoryView,
      props: true,
      meta: {
        title: "Результаты инвентаризации",
        requiresAuth: true
        //authorize: [Role.admin, Role.manager]
      }
    },
    {
      path: "/store/history",
      name: "history",
      component: StoreHistory,
      props: true,
      meta: {
        title: "Движение товаров",
        requiresAuth: true
        //authorize: [Role.admin, Role.manager]
      }
    },
    {
      path: "/store/supply",
      name: "supply",
      component: StoreSupply,
      props: true,
      meta: {
        title: "Поступления",
        requiresAuth: true
        //authorize: [Role.admin, Role.manager]
      }
    },
    {
      path: "/store/supply/:id",
      name: "view-supply",
      component: AddSupply,
      props: true,
      meta: {
        title: "Новое поступление",
        requiresAuth: true
        //authorize: [Role.admin, Role.manager]
      }
    },
    {
      path: "/store/stock",
      name: "current-stock",
      component: CurrentStock,
      props: true,
      meta: {
        title: "Текущие остатки",
        requiresAuth: true
        //authorize: [Role.admin, Role.manager]
      }
    },
    {
      path: "/kassa/t",
      name: "t",
      component: Transactions,
      props: true,
      meta: {
        title: "Транзакции",
        requiresAuth: true
        //authorize: [Role.admin, Role.manager]
      }
    },
    {
      path: '/kassa',
      redirect: '/kassa/t'
    },
    {
      path: "/kassa/reports",
      name: "cash-reports",
      component: CashboxReports,
      props: true,
      meta: {
        title: "Отчеты",
        requiresAuth: true
        //authorize: [Role.admin, Role.manager]
      }
    },
    {
      path: "/network",
      name: "network",
      component: NetworkPage,
      props: true,
      meta: {
        title: "Настройки сети",
        requiresAuth: true
      }
    },
    {
      path: "/help",
      name: "help",
      component: HomeHelpPage,
      props: true,
      meta: {
        title: "База знаний",
        requiresAuth: true
      }
    },
    {
      path: "/help/:slug",
      name: "view-help",
      component: HomeHelpPage,
      props: true,
      meta: {
        title: "База знаний",
        requiresAuth: true
      }
    },
    {
      path: "/emails",
      name: "emails",
      component: ManageEmails,
      props: true,
      meta: {
        title: "Входящая почта",
        requiresAuth: true
      }
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    // return desired position
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

import api from "../api/api";
const resource = "/optics";

export default {
  get() {
    return api.call(`${resource}`);
  },
  getByid(id) {
    return api.call(`${resource}/${id}`, { method: "get" });
  },
  update(payload) {
    return api.call(`${resource}`, { method: "post", data: payload });
  },
  create(payload) {
    return api.call(`${resource}`, { method: "post", data: payload });
  },
  delete(id) {
    return api.call(`${resource}/${id}`, { method: "delete" });
  },
  getTimeSlots(id, date, service_id) {
    return api.call(`${resource}/${id}/timeslots/${date}/${service_id}`, { method: "get" });
  }
};

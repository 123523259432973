import api from "../api/api";
const resource = "/g/suppliers";

export default {
  get() {
    return api.call(`${resource}`);
  },
  getByid(id) {
    return api.call(`${resource}/${id}`, { method: "get" });
  },
  update(payload) {
    return api.call(`${resource}`, { method: "post", data: payload });
  },
  delete(id) {
    return api.call(`${resource}/${id}`, { method: "delete" });
  },
  async list() {
    let { success, msg, data } = await api.call(`${resource}`);
    if (success) {
      return data.suppliers;
    }
  },
  search(filter) {
    let query = encodeURIComponent(JSON.stringify(filter));
    return api.call(`${resource}?filter=${query}`, { method: "get" });
  },
};

<template>
  <div class="max-w-md mx-auto my-10">
    <div class="text-center">
      <h1 class="my-3 text-3xl font-semibold text-gray-700 dark:text-gray-200">Войти в {{ company }}</h1>
      <p class="text-gray-500 dark:text-gray-400"></p>
    </div>
    <form @submit.prevent="doLogin()">
      <div class="mb-6">
        <label for="email" class="block mb-2 text-sm text-gray-600 dark:text-gray-400">Электронная почта</label>
        <input
          type="email"
          name="email"
          v-model="login.email"
          id="email"
          placeholder="you@company.com"
          class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
        />
      </div>
      <div class="mb-6">
        <div class="flex justify-between mb-2">
          <label for="password" class="text-sm text-gray-600 dark:text-gray-400">Пароль</label>
          <a
            href="#!"
            class="hidden text-sm text-gray-400 focus:outline-none focus:text-indigo-500 hover:text-indigo-500 dark:hover:text-indigo-300"
          >
            Восстановить пароль
          </a>
        </div>
        <input
          type="password"
          name="password"
          v-model="login.password"
          id="password"
          placeholder="Пароль"
          class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
        />
      </div>

      <div class="mb-6">
        <button
          type="submit"
          :disabled="busy"
          :class="{ busy: busy }"
          class="w-full px-3 py-4 text-white bg-indigo-500 rounded-md focus:bg-indigo-600 focus:outline-none"
        >
          Войти
        </button>
      </div>
    </form>
    <div
      v-if="error == 'password_wrong'"
      class="rounded text-sm my-4 py-2 px-4 bg-yellow-100 text-yellow-800 border border-yellow-300"
    >
      Пароль неверный, обратитесь к администратору.
    </div>

    <div
      v-if="error == 'user_not_found'"
      class="rounded text-sm my-4 py-2 px-4 bg-yellow-100 text-yellow-800 border border-yellow-300"
    >
      Такого пользователя не существует.
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      company: "",
      login: {
        email: "", //process.env.VUE_APP_MODE == "dev" ? "dev@aleksey.co" : "",
        password: "" //process.env.VUE_APP_MODE == "dev" ? "1234" : "",
      },
      busy: false,
      error: ""
    };
  },
  mounted() {
    // pull the subdomain
    this.company = window.location.host.split(".")[0];
  },
  methods: {
    async doLogin() {
      this.busy = true;
      this.login.company = this.company;

      try {
        let resp = await this.$axios.post("/auth/login", this.login);

        if (resp.data.status == "error") {
          this.error = resp.data.msg;
        } else {
          // login user
          let user = resp.data.user;
          user.lastLogin = Date.now();
          console.log(user);
          this.$store.dispatch("app/loginUser", user);
          document.location = "/optics";
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.busy = false;
      }
    }
  }
};
</script>

<template>
  <page>
    <template v-slot:header>Текущие остатки // {{ $dept.title }} // {{ $dept.addr }}</template>
    <template v-slot:actions></template>

    <template v-slot:details>
      {{ $t("info.current_stock_details") }}
    </template>
    <!-- Filters, collapsable -->
    <template v-slot:content>
      <div class="">
        <catalog :store="$dept" :typeview="'stock'"/>
      </div>
    </template>
  </page>
</template>

<script>
import Catalog from "@/components/catalogTable/Catalog.vue";

export default {
  components: { Catalog },
  data() {
    return {};
  },
  mounted() {
    // this.selectMaker(this.makers[0]);
    // this.store = this.$dept;
  },
  methods: {},
  computed: {},
  watch: {}
};
</script>

<template>
    <div class="relative">
        <button @click="toggle()">
            <slot name="trigger" />
        </button>

        <div class="absolute top-8 right-0 rounded-md bg-white w-full min-w-200 dropdown" v-if="show">
            <slot name="content" />
        </div>
    </div>
</template>

<script>

export default {
    name: "Dropdown",
    data() {
        return {
            show: false
        }
    },
    methods: {
        toggle() {

            // close on click outside
            window.addEventListener('click', (e) => {
                if (this.show && !this.$el.contains(e.target)) {
                    this.show = false
                    // remove this listener
                    window.removeEventListener('click', this)
                }
            })

            this.show = !this.show
        }
    }
};

</script>
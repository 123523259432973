<template>
  <div>
    <form @submit.prevent>
      <div class="flex flex-row flex-wrap">
        <div>
          <div>
            <i class="fas fa-barcode"></i>
            <input
              ref="code"
              type="text"
              placeholder="Штрихкод / Лот"
              v-model="code"
              class="py-1 px-2 ml-2"
              autofocus
              required
              name="barcode"
              :disabled="wait"
              tabindex="1"
              :class="{ wait: wait }"
              v-on:keyup.enter="scan()"
            />
          </div>
        </div>
        <div v-if="showLotField">
          <input
            ref="lot"
            type="text"
            placeholder="Лот"
            v-model="lot"
            class="py-1 px-2 ml-2"
            :disabled="wait"
            :class="{ wait: wait }"
            tabindex="2"
            v-on:keyup.enter="scanLot()"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import ProductRepo from "../../repo/ProductRepo.js";
import GlobalProduct from "../../repo/GlobalProductRepo.js";

export default {
  props: ["mode"],
  data() {
    return {
      code: "",
      wait: false,
      product: {},
      showLotField: false,
      lot: ""
    };
  },
  mounted() {},
  methods: {
    async scanLot() {
      let vm = this;
      this.peaksound();

      // search for lot
      let lotFound = this.product.lots.find(el => el.lot == this.lot);
      if (lotFound) {
        this.product.lot = lotFound;
        this.emitProduct();
        this.oksound();
      } else {
        this.failsound();
        setTimeout(function() {
          vm.lot = "";
          alert("Такой лот не найден в товаре.");
          vm.$refs.lot.focus();
        }, 100);
      }

      //   let { success, msg, data } = await Product.isLotExists(this.lot);
      //   if (success) {
      //     if (data.items.length > 0) {
      //       this.product = data.items[0];
      //       this.product.lot = this.lot;
      //       this.emitProduct();
      //     }
      //   }
    },
    async scan() {
      let vm = this;

      //let lot = "L003G0326X"; // 10 symbols
      let codeType = this.getCodeType(this.code);

      if (codeType == "lot") {
        this.peaksound();

        let { success, msg, data } = await ProductRepo.isLotExists(this.code);
        if (success) {
          if (data.items.length > 0) {
            this.product = data.items[0];
            this.lot = this.code;
            this.scanLot();
            return;
          } else {
            // lot not found
            this.showLotField = true;
            this.lot = this.code;
            this.code = "";
            this.$refs.code.focus();
            return;
          }
        }
      }

      if (!codeType) {
        this.code = "";
        this.$refs.code.focus();
        this.failsound();
        setTimeout(function() {
          alert("Неизвестный формат ШК");
        }, 100);
        return;
      }

      this.peaksound();

      // if barcode scanned
      this.code = this.code.replace(/ /gi, "");

      if (codeType == "barcode") {
        let { success, msg, data } = await ProductRepo.scanBarcode(this.code);

        if (success) {
          if (data.items.length > 0) {
            this.product = data.items[0];

            // check lot
            if (this.product.lots && this.product.lots.length > 0 && this.lot == "") {
              this.showLotField = true;

              setTimeout(function() {
                vm.$refs.lot.focus();
              }, 0);
              return;
            }

            // else emit product
            this.emitProduct();
          } else {
            // such product not found
            vm.failsound();
            setTimeout(function() {
              alert("Такой ШК не найден.");
              vm.code = "";
              vm.$refs.code.focus();
            }, 100);
            return;
          }
        }
      }
    },
    emitProduct() {
      let vm = this;
      if (this.showLotField && this.lot != "") {
        if (this.isLotNotFound(this.lot)) {
          this.failsound();
          setTimeout(function() {
            alert("Такой лот не найден в товаре.");
            vm.lot = "";
            vm.$refs.lot.focus();
          }, 100);
          return;
        }
      }

      this.$emit("scan", this.product);
      this.$refs.code.focus();
      this.oksound();
      this.product = {};
      this.lot = "";
      this.code = "";
      this.showLotField = false;
    },
    isLotNotFound(lot) {
      return this.product.lots.findIndex(el => el.lot == lot) == -1;
    },
    failsound() {
      let audio = new Audio("/sounds/error.wav");
      audio.play();
    },
    oksound() {
      let audio = new Audio("/sounds/success.wav");
      audio.play();
    },
    peaksound() {
      let audio = new Audio("/sounds/peak.wav");
      audio.play();
    },
    getCodeType(code) {
      if (code.length >= 12 && code.length <= 15 && /^[0-9]+$/.test(code)) {
        return "barcode";
      }

      if ((code.length < 15 && /[a-zA-Z]/.test(code)) || (code.length > 15 && /^[0-9]+$/.test(code))) {
        return "lot";
      }

      return false;
    }
  }
};
</script>

<template>
  <div
    class="px-3 relative flex items-center bg-white cursor-pointer border-b border-gray-100  hover:bg-gray-200"
  >
    <div>
      <div
        class="h-8 w-8 rounded-md bg-yellow-400"
        v-if="!optic.picture"
      ></div>
      <img v-if="optic.picture" class="h-8 w-8 rounded-md" :src="'https://178107.selcdn.ru/monkl-picture/' + optic.picture" />
    </div>
    <div class="ml-4 flex-1 py-2">
      <div class="">
        <span class="font-semibold text-black text-sm">
          {{ optic.title }}
        </span>
      </div>
      <div class="text-black text-xs">
        {{ optic.addr }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProjectCard",
  props: ["optic"]
};
</script>

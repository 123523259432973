<template>
  <input
    type="text"
    v-mask="mask"
    placeholder="00,00"
    v-bind:value="value"
    class="font-mono"
    v-on:input="$emit('input', $event.target.value.replace(' ', ''))"
  />
</template>
<script>
import createNumberMask from "text-mask-addons/dist/createNumberMask";
const currencyMask = createNumberMask({
  prefix: "",
  allowDecimal: true,
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ' ',
  decimalSymbol: '.',
  allowNegative: false
});
export default {
  name: "PriceInput",
  props: ["value"],
  data() {
    return {
      mask: currencyMask
    };
  }
};
</script>

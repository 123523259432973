export let sph = getValues();

function getValues() {
  let step = 0.25;

  let max = 30;
  let values = [];

  for (let k of [-1, 1]) {
    for (let val = 0; Math.abs(val) <= max; val = val + step * k) {
      if (Math.abs(val) < 12) {
        step = 0.25;
      }

      if (Math.abs(val) >= 12) {
        step = 0.5;
      }

      if (Math.abs(val) >= 20) {
        step = 1;
      }

      if (values.indexOf(val) === -1) {
        values.push(val);
      }
    }
  }

  return values.sort((a, b) => a - b);
}

const range = (start, stop, step = 1) =>
  Array(Math.ceil((stop - start) / step))
    .fill(start)
    .map((x, y) => x + y * step);

let addRange = range(0.25, 5.25, 0.25);

// only for glasses
export let addGlasses = addRange;
// only for lenses
export let add = ["+1.50 N", "+2.00 D", "+2.00 N", "+2.50 D", "+2.50 N", "high", "low", "med"];

export let bc = [7.91, 8.4, 8.5, 8.6, 8.7, 8.8, 8.9, 9.0];
export let dia = [14.0, 14.2, 14.5, 42.75, 43.5];

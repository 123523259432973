<template>
  <div>
    <catalog :store="$dept" :typeview="'price'"/>
  </div>
</template>
<script>
import Catalog from "@/components/catalogTable/Catalog.vue";

export default {
  components: { Catalog },
  name: "Prices",
  data() {
    return {}
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {}
};
</script>

<template>
  <page>
    <template v-slot:header>Табель работы</template>

    <template v-slot:content>
      <div v-if="!active.id">
        <p>
          Адрес не выбран, выберите.
          <a href="/optics" class="text-blue-500">Список адресов</a>
        </p>
      </div>

      <div class="custom-calendar" v-if="active.id">
        <vc-calendar
          class="max-w-min"
          disable-page-swipe
          is-expanded
          v-on:update:to-page="updatedCalendar"
          :first-day-of-week="2"
          ref="calendar"
        >
          <template v-slot:day-content="{ day }" v-class="'bg-gray-200'">
            <template v-if="isClosed(day)">
              <div class="text-xs text-red-300">
                <span class="day-label text-sm text-gray-900">{{ day.day }}</span>
                <div class="text-center text-md w-full pt-4 text-red-200">
                  Закрыто
                </div>
              </div>
              <div class="bg-gray-200 top-0 opacity-60 absolute left-0 h-full w-full"></div>
            </template>

            <div class="h-full" @click="showDay(day)" v-if="!isClosed(day)">
              <span class="day-label text-sm text-gray-900">{{ day.day }}</span>
              <span class="text-gray-900 text-xs">
                ({{ getWorkingHours(day.weekday)[0] }}:00 -
                {{ getWorkingHours(day.weekday)[1] }}:00)
              </span>

              <template v-if="!events[day.id] || events[day.id].slots.length == 0">
                <div class="text-center text-md w-full pt-3 text-gray-200">
                  <i class="fas fa-plus"></i>
                </div>
              </template>

              <div class="mt-1" v-if="events[day.id]">
                <event-slot
                  :item="item"
                  v-for="(item, index) in events[day.id].slots"
                  :key="index"
                />
              </div>
            </div>
          </template>
        </vc-calendar>
      </div>
    </template>
  </page>
</template>

<script>
import { mapGetters } from "vuex";
import PageHeader from "./shared/PageHeader.vue";
import PageContent from "./shared/PageContent.vue";
import EditCalendarEventModal from "./calendar/EditCalendarEventModal.vue";
import EventSlot from "./calendar/EventSlot.vue";

export default {
  name: "calendar",
  components: { PageHeader, PageContent, EditCalendarEventModal, EventSlot },
  data() {
    return {
      selectedDate: new Date(),
      page: {},
      events: [],
      calendar: {}
    };
  },
  mounted() {
    this.calendar = this.$refs.calendar;
    let vm = this;

    this.$events.$on("reload-calendar", () => {
      vm.loadDays();
    });
  },
  methods: {
    updatedCalendar(page) {
      this.page = page;
      this.loadDays();
    },
    // add a new row to clicked date
    showDay(day) {
      this.$modals.open(EditCalendarEventModal, { day: day });
      this.selectedDate = day;
    },
    // check does the office work in this day regarding to working hours
    isClosed(day) {
      // get day indes
      let index = day.weekday - 2;
      if (day.weekday == 1) {
        index = 6;
      }

      if (this.active.workingHours[index] && this.active.workingHours[index][0] == "-1") {
        return true;
      }
      return false;
    },
    async loadDays() {
      let start = this.$dayjs(
        this.page.year +
          "-" +
          (this.page.month > 9 ? this.page.month : "0" + this.page.month) +
          "-01"
      );

      let r = await this.$schedule.getByDates(
        this.active.id,
        start.format("YYYY-MM-DD"),
        start.endOf("month").format("YYYY-MM-DD")
      );
      let events = r.data;

      // group events by date
      events = events.reduce((acc, item) => {
        acc[item.date] = item;
        return acc;
      }, []);

      this.events = events;
    },
    removeRow() {},
    getWorkingHours(weekday) {
      // calendar 1-Sun 7 Sat
      let index = weekday - 2;
      if (weekday == 1) {
        index = 6;
      }
      
      return this.active.workingHours.length>0 ? this.active.workingHours[index] : [];
    }
  },
  computed: {
    ...mapGetters("optics", ["active"]),
    ...mapGetters({ users: "users", getUserById: "users/getById" })
  }
};
</script>

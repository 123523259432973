<template>
  <div class="bg-white px-5 py-4 rounded-md">
    <div class="grid grid-cols-4 gap-6 lg:w-3/5">
      <div class="col-span-2">
        <div class="">
          <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
            Название
          </label>
          <input
            class="w-full "
            id="title"
            name="title"
            type="text"
            required=""
            v-model="optic.title"
            placeholder="Название"
            aria-label="title"
          />
        </div>
      </div>

      <div class="col-span-2">
        <div class="">
          <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
            Название юрлица
          </label>
          <input
            class="w-full "
            id="title"
            name="title"
            type="text"
            required=""
            v-model="optic.legalName"
            placeholder="Название юрлица"
            aria-label="title"
          />
        </div>
      </div>

      <!-- common settigs -->

      <div class="col-span-1">
        <div class="">
          <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">ИНН</label>
          <input class="w-full" v-mask="'###########'" type="text" required="" v-model="optic.inn" placeholder="7802000000" />
        </div>
      </div>

      <div class="col-span-1">
        <div class="">
          <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">КПП</label>
          <input class="w-full" v-mask="'#########'" type="text" required="" v-model="optic.kpp" placeholder="" />
        </div>
      </div>

      <div class="col-span-1">
        <div class="">
          <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">Форма</label>
          <select class="w-full" v-model="optic.form">
            <option v-for="item in forms" :key="item.id" :value="item.id">{{ item.name }}</option>
          </select>
        </div>
      </div>

      <div class="col-span-1">
        <div class="">
          <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">Режим</label>
          <select class="w-full" v-model="optic.nalog">
            <option v-for="item in nalogs" :key="item.id" :value="item.id">{{ item.name }}</option>
          </select>
        </div>
      </div>

      <!-- end -->
      <div class="col-span-1">
        <div class="">
          <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">Городской телефон</label>
          <input
            class="w-full"
            id="phone"
            name="phone"
            v-mask="'###-##-##'"
            type="text"
            required=""
            v-model="optic.phone"
            placeholder="000-00-00"
            aria-label="phone"
          />
        </div>
      </div>

      <div class="col-span-1">
        <div class="">
          <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">Мобильный телефон</label>
          <input
            class="w-full"
            id="mobi-phone"
            name="mobi-phone"
            v-mask="'+7 (###) ###-##-##'"
            type="text"
            v-model="optic.mobiPhone"
            placeholder="+7 (000) 000-00-00"
          />
        </div>
      </div>

      <div class="col-span-1">
        <div class="">
          <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">Электронная почта</label>
          <input
            class="w-full"
            id="email"
            name="email"
            type="text"
            required=""
            v-model="optic.email"
            placeholder="info@example.ru"
            aria-label="phone"
          />
        </div>
      </div>
      <div class="col-span-1">
        <div class="">
          <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">Контактное лицо</label>
          <input
            class="w-full"
            id="contactName"
            name="contactName"
            type="text"
            required=""
            v-model="optic.contactName"
            placeholder="ФИО"
          />
        </div>
      </div>

      <div class="col-span-2">
        <div class="">
          <label class="block mb-1 text-sm text-gray-600 font-medium" for="">Адрес</label>
          <input
            class="w-full"
            id="addr"
            name="addr"
            type="text"
            required=""
            placeholder="Гражданский пр. 111"
            aria-label="addr"
            v-model="optic.addr"
          />
        </div>
        <div class="mt-2">
          <label class="block mb-1 text-sm text-gray-600 font-medium" for="">Город</label>
          <select v-model="optic.city" class="w-full">
            <option v-for="item in cities" :value="item">{{ item }}</option>
          </select>
        </div>
      </div>

      <div class="col-span-2">
        <div class="flex flex-col h-full">
          <label class="block mb-1 text-sm text-gray-600 font-medium" for="">Заметка</label>
          <textarea
            class="w-full h-full"
            id="note"
            name="note"
            required=""
            v-model="optic.note"
            placeholder="Что-нибудь что будет отличать ее от других"
          ></textarea>
        </div>
      </div>
      <div class="col-span-2">
        <div class="relative">
          <label class="block mb-1 text-sm text-gray-600 font-medium" for="">Картинка</label>
          <div class="flex flex-row gap-2 items-center">
            <div class="">
              <img
                :src="'https://178107.selcdn.ru/monkl-picture/' + optic.picture"
                class="w-12 h-12 object-cover rounded-md"
                v-if="optic.picture"
              />
            </div>
            <div class="w-full">
              <input
                class="w-full"
                id="picture"
                name="picture"
                type="file"
                accept="image/*"
                placeholder=""
                @change="filesChange($event)"
                aria-label="picture"
              />
            </div>
          </div>

          <input type="hidden" v-model="optic.picture" />

          <div class="absolute top-10 right-0">
            <div v-if="isSuccess" class="text-sm px-4">
              ✔️
            </div>
            <svg
              v-if="isSaving"
              class="animate-spin -ml-1 mr-3 h-5 w-5 text-indigo-600"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { upload } from "../../api/upload";
const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;

export default {
  props: ["optic"],
  data() {
    return {
      uploadedFiles: [],
      uploadError: null,
      currentStatus: STATUS_INITIAL,
      forms: [
        { id: 1, name: "ООО" },
        { id: 2, name: "ИП" },
        { id: 3, name: "АО" },
        { id: 4, name: "ПАО" }
      ],
      nalogs: [
        { id: 1, name: "УСН 6%" },
        { id: 2, name: "УСН 15%" },
        { id: 3, name: "ОСНО" },
        { id: 4, name: "АУСН 8%" },
        { id: 5, name: "АУСН 20%" },
        { id: 6, name: "ПСН" }
      ],
      cities: [
        "Москва",
        "Санкт-Петербург",
        "Новосибирск",
        "Екатеринбург",
        "Казань",
        "Нижний Новгород",
        "Красноярск",
        "Челябинск",
        "Самара",
        "Уфа",
        "Ростов-на-Дону",
        "Краснодар",
        "Омск",
        "Воронеж",
        "Пермь",
        "Волгоград",
        "Саратов",
        "Тюмень"
      ]
    };
  },
  mounted() {},
  methods: {
    async filesChange(evt) {
      let vm = this;

      let fileList = evt.target.files;
      // prepare form data
      //const formData = new FormData();
      //formData.append(evt.target.name, fileList[0], fileList[0].name);

      const reader = new FileReader();
      // read as base64
      reader.readAsDataURL(fileList[0]);
      vm.currentStatus = STATUS_SAVING;
      reader.onloadend = async function() {
        let { data } = await upload(reader.result);
        // save to optic image src
        vm.optic.picture = data.filename;

        setTimeout(() => {
          vm.currentStatus = STATUS_SUCCESS;
        }, 500);
      };
    }
  },
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    }
  }
};
</script>

<template>
  <page>
    <template v-slot:header>
      Движение товаров
    </template>
    <template v-slot:actions></template>

    <!-- Filters, collapsable -->
    <template v-slot:content>
      <div class="filters mb-4 flex flex-row items-center gap-4 bg-white px-4 py-2 rounded-md">
        <div class="mr-8 text-sm whitespace-nowrap">
          Всего:
          <span class="font-semibold">{{ total }}</span>
        </div>

        <form class="flex flex-row items-center space-x-4" @submit.prevent="doFilter(true)">
          <input type="text" placeholder="Поиск по ШК" v-model="filter.term" />
          <input type="date" placeholder="Поиск по дате" v-model="filter.date" />
          <button type="submit" class="px-4 py-2 bg-blue-500 text-white rounded-md" :class="{ busy: filterBusy }">
            Искать
          </button>
        </form>
      </div>

      <pagination :total="total" :limit="filter.limit" :items="items" @page="page => ((filter.page = page), doFilter())"
        @limit="limit => ((filter.limit = limit), doFilter())" />

      <div class="w-full overflow-x-auto text-left bg-white rounded-md">
        <table class="w-full">
          <thead>
            <tr class="font-semibold text-xs  text-gray-600 border-b">
              <th class="px-4 py-2">Название</th>
              <th class="px-4 py-2">ШК</th>
              <th class="px-4 py-2">Операция</th>
              <th class="px-4 py-2">Кол-во</th>
              <th class="px-4 py-2">Дата</th>
              <th class="px-4 py-2">Сотрудник</th>
              <th class="px-4 py-2">Примечание</th>
            </tr>
          </thead>
          <tbody class="text-sm">
            <tr v-if="items.length == 0">
              <td colspan="7">
                {{ $t("no_results") }}
              </td>
            </tr>
            <tr v-for="item in items" :key="item.id">
              <td>
                <div>
                  {{ item.name }}
                </div>
                <div v-if="item.attr" class="text-xs">{{ item.attr }}</div>
              </td>
              <td>
                <div class="barcode" v-if="item.barcode">
                  <i class="fas fa-barcode"></i>
                  {{ item.barcode }}
                </div>
              </td>
              <td>
                {{ $t(`stock.${item.op}`) }}
              </td>
              <td class="font-semibold">
                <span :class="{ 'text-red-500': item.qty < 0, 'text-green-500': item.qty > 0 }"
                  v-if="item.op != 'correction'">
                  {{ item.qty > 0 ? `+${item.qty}` : item.qty }}
                </span>
                <span v-else class="font-bold text-blue-600">
                  = {{ item.qty }}
                </span>
              </td>
              <td>{{ item.created_at | datetime }}</td>
              <td><user-card :id="item.user_id" /></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="error" class="text-red-500 font-semibold py-2">{{ error }}</div>
      <loading v-if="busy" />
    </template>
  </page>
</template>

<script>
//import EditProductModal from "../products/EditProductModal.vue";
//import ViewProductModal from "../ViewProductModal.vue";
//import InventoryModal from "../InventoryModal.vue";
//import ProductRow from "../ProductRow.vue";

import { mapGetters } from "vuex";
import Page from "../../Page.vue";
import Info from "../../shared/Info.vue";
import StoreHistoryRepo from "./StoreHistoryRepo";
import Loading from "../../Loading.vue";
import api from "../../../api/api";

export default {
  components: { Page, Info, Loading },
  name: "store",
  data() {
    return {
      items: [],
      total: 0,
      searchDate: "",
      busy: false,
      error: "",
      filter: {
        page: 1,
        limit: 10,
        term: "",
        date: ""
      },
      btnClicked: false,
      filterBusy: false
    };
  },
  mounted() {
    this.doFilter();
  },
  methods: {
    // get inventory list
    async doFilter(btnClicked) {
      if (btnClicked) {
        this.filterBusy = true;
        this.filter.page = 1;
      }

      if (!this.filterBusy) {
        this.busy = true;
      }

      let vm = this;

      //let { success, msg, data } = await Product.filter(this.filter);
      let query = encodeURIComponent(JSON.stringify(this.filter));
      let { success, msg, data } = await api.get(`/history?filter=${query}`);

      if (success) {
        this.items = data.list;
        this.total = data.total;
      }

      vm.filterBusy = false;
      vm.busy = false;
    }
  },
  computed: {
    //...mapGetters("products", ["products"])
  }
};
</script>

<template>
  <modal :styles="''">
    <h3 class="text-black font-bold text-xl mb-1">
      Массовое редактирование
    </h3>

    <div class="py-2 text-sm mb-6 text-gray-600">
      <i class="fas fa-info-circle text-blue-500"></i>
      Оставьте поле пустым, чтобы оставить текущее значение
    </div>
    <form class="" @submit.prevent="save()">
      <div class="grid grid-cols-4 gap-6">
        <div class="col-span-1">
          <div class="">
            <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">Цена</label>
            <input class="w-full  font-mono" type="text" v-model="edit.price" placeholder="Цена" />
          </div>
        </div>

        <div class="col-span-2">
          <div class="">
            <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
              Параметры
            </label>
            <input class="w-full font-mono" type="text" v-model="edit.attr" placeholder="Параметры" />
          </div>
        </div>

        <div class="col-span-2">
          <div class="">
            <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
              Категория
            </label>

            <auto-suggest styles="w-full" placeholder="Категория" v-model="edit.category" :component="'Category'">
              <template v-slot:default="slotProps">
                <div>
                  <span class="text-sm font-normal">{{ slotProps.item.name }}</span>
                </div>
              </template>
            </auto-suggest>

            <p class="text-xs text-gray-400 leading-normal mt-1">
              Начните вводить, будет подсказка. Если такой категории нет, будет создана новая.
            </p>
          </div>
        </div>
        <div class="col-span-2">
          <div class="">
            <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
              Бренд
            </label>

            <auto-suggest styles="w-full" placeholder="Бренд" v-model="edit.brand" :component="'Brand'">
              <template v-slot:default="slotProps">
                <div>
                  <span class="text-sm font-normal">{{ slotProps.item.name }}</span>
                </div>
              </template>
            </auto-suggest>

            <p class="text-xs text-gray-400 leading-normal mt-1">
              Начните вводить, будет подсказка.
            </p>
          </div>
        </div>

        <div class="col-span-4">
          <div>
            <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
              Поставщики
            </label>
            <div>
              <selector styles="w-full" placeholder="ИНН или название" :component="'Supplier'" @select="pickSupplier">
                <template v-slot:default="slotProps">
                  <div>
                    <span class="text-sm font-normal">{{ slotProps.item.name }}</span>
                  </div>
                </template>
              </selector>

              <div class="flex flex-row flex-wrap space-x-2 mt-2" v-if="suppliers.length > 0">
                <div
                  v-for="(supId, index) in edit.suppliers"
                  class="text-sm bg-green-500 rounded-md px-2 py-0.5 text-white font-semibold"
                >
                  {{ supplierDetails(supId).name }}
                  <button type="button" class="text-xs" @click="edit.suppliers.splice(index, 1)">
                    <i class="fas fa-times ml-1"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-6">
        <button type="submit" :class="{ busy: busy }" :disabled="busy" class="btn-primary-sm">
          Обновить {{ ids.length }} товаров
        </button>
      </div>
    </form>
  </modal>
</template>

<script>
import Modal from "../modals/Modal.vue";
import ModalMixin from "../modals/modal.mixin";
import GlobalProduct from "../../repo/GlobalProductRepo";
import Product from "../../repo/ProductRepo";
import Category from "../../repo/CategoryRepo";
import Brand from "../../repo/BrandRepo";
import AutoSuggest from "../shared/AutoSuggest.vue";
import Selector from "../shared/Selector.vue";
import api from "../../api/api.js";

export default {
  components: { Modal, AutoSuggest, Selector },
  mixins: [ModalMixin],
  props: ["ids", "mode"],
  data() {
    return {
      loading: false,
      busy: false,
      tags: [],
      tag: "",
      status: "",
      edit: {
        price: "",
        brand: "",
        suppliers: [],
        category: "",
        attr: ""
      },
      suppliers: []
    };
  },
  mounted() {
    this.getSuppliers();
  },
  methods: {
    pickSupplier({ id }) {
      if (this.edit.suppliers.indexOf(id) == -1) {
        this.edit.suppliers.push(id);
      }
    },
    updateSuppliers(tags) {
      console.log(tags);
    },
    async getSuppliers() {
      let { success, msg, data } = await api.get("/g/suppliers");
      if (success) {
        this.suppliers = data.suppliers;
      }
    },
    addPicture() {
      alert("in progress...");
    },
    async save() {
      this.busy = true;

      let success, msg, data;

      if (this.mode && this.mode == "addr") {
        ({ success, msg, data } = await Product.bulkUpdate(this.ids, this.edit));
      } else {
        ({ success, msg, data } = await GlobalProduct.bulkUpdate(this.ids, this.edit));
      }

      this.busy = false;

      if (success) {
        this.product = data;
        this.status = "save-success";
        this.close();
        this.$events.$emit("reload");
      } else {
        this.status = msg;
      }
    },
    supplierDetails(id) {
      let sup = this.suppliers.find(el => el.id == id);
      if (sup != undefined) {
        return sup;
      }

      return {
        name: ""
      };
    }
  }
};
</script>

<template>
  <div class="" v-cloak>
    <div class="container mt-4 lg:mt-4  mx-auto lg:mb-12 px-6">
      <div class="w-full">
        <div class="font-semibold text-lg flex flex-row items-center" v-if="!notitle">
          <slot name="title" />
        </div>

        <div class="flex flex-row items-center justify-between" v-if="!notitle" :class="!!$slots.details ? '' : 'mb-6'">
          <div class="flex flex-row items-center">
            <h1 class="text-lg md:text-lg font-semibold mr-4">
              <slot name="header"></slot>
            </h1>
            <slot name="actions" class=""></slot>
          </div>
          <slot name="right" class=""></slot>
        </div>

        <div class="py-2 text-sm mb-4" v-if="!!$slots.details">
          <slot name="details"></slot>
        </div>

        <div class="content">
          <div class="mb-8" v-if="$slots.secondary">
            <slot name="secondary"></slot>
          </div>
          <div class="">
            <slot name="content"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["notitle"],
  name: "Page"
};
</script>

import FullCalendar from "@fullcalendar/vue";
import "vue-multiselect/dist/vue-multiselect.min.css";

import Vue from "vue";
import App from "./App.vue";
import "./assets/tailwind.css";
import axios from "axios";
import api from "./api/api";
import sockets from "./api/sockets";

import dayjs from "dayjs";
import ru from "dayjs/locale/ru";
import weekday from "dayjs/plugin/weekday";
import calendar from "dayjs/plugin/calendar";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";

import VueDirectiveTooltip from "vue-directive-tooltip";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

dayjs.locale(ru);
dayjs.extend(weekday);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(calendar);

Object.defineProperties(Vue.prototype, {
  $dayjs: {
    get() {
      return dayjs;
    }
  }
});

import VCalendar from "v-calendar";

import VueMask from 'v-mask'
Vue.use(VueMask);


Vue.use(VCalendar, {
  componentPrefix: "vc" // Use <vc-calendar /> instead of <v-calendar />
});
Vue.use(VueDirectiveTooltip);
Vue.use(VueSweetalert2);

Vue.component("FullCalendar", FullCalendar);
Vue.component("loading", require("./components/Loading").default);
//Vue.component("modal", require("./components/shared/Modal.vue").default);

import Header from "./components/shared/PageHeader.vue";
import Content from "./components/shared/PageContent.vue";
import Page from "./components/Page.vue";
import PageShort from "./components/PageShort.vue";
import UserCard from "./components/shared/UserCard";
import Modal from "./components/modals/Modal.vue";
import Alert from "./components/shared/Alert.vue";
import ErrorMessage from "./components/shared/Error.vue";
import Toggle from "./components/shared/Toggle.vue";
import SmToggle from "./components/shared/SmallToggle.vue";
import Multiselect from "vue-multiselect";
import AutoSuggest from "./components/shared/AutoSuggest.vue";
import Tags from "./components/shared/Tags.vue";
import Autocomplete from "./components/shared/Autocomplete.vue";

import Customer from "./components/shared/Customer";
import SaleStatus from "./components/shared/SaleStatus";
import SaleNumber from "./components/shared/SaleNumber";
import Tag from './components/shared/Tag.vue';
import TaskStatus from "./components/shared/TaskStatus.vue";
import BarcodeScanner from './components/shared/BarcodeScanner.vue';
import Scanner from './components/shared/Scanner.vue';
import Pagination from './components/shared/Pagination.vue';
import ProductTitle from './components/shared/ProductTitle';
import BackButton from './components/shared/BackButton';
import SuccessMessage from './components/shared/SuccessMessage';
import HelpMessage from './components/shared/HelpMessage';
import Popover from './components/shared/Popover';
import InputGroup from './components/shared/InputGroup';
import TelInput from './components/shared/TelInput';
import PriceInput from './components/shared/PriceInput';
import QtyInput from './components/forms/QtyInput';
import Dropdown from './components/shared/Dropdown';
import ActionButton from './components/shared/ActionButton';
import Switcher from './components/shared/Switcher';


Vue.component("page-header", Header);
Vue.component("page-content", Content);
Vue.component("product-title", ProductTitle);
Vue.component("page", Page);
Vue.component("page-short", PageShort);
Vue.component("user-card", UserCard);
Vue.component("modal", Modal);
Vue.component("alert", Alert);
Vue.component("error", ErrorMessage);
Vue.component("toggle", Toggle);
Vue.component("switcher", Switcher);
Vue.component("sm-toggle", SmToggle);
Vue.component("multiselect", Multiselect);
Vue.component("auto-suggest", AutoSuggest);
Vue.component("tags", Tags);
Vue.component("autocomplete", Autocomplete);
Vue.component("customer", Customer);
Vue.component("sale-status", SaleStatus);
Vue.component("sale-number", SaleNumber);
Vue.component("tag", Tag);
Vue.component("pagination", Pagination);
Vue.component("task-status", TaskStatus);

Vue.component("barcode-scanner", BarcodeScanner);
Vue.component("scanner", Scanner);
Vue.component("back-btn", BackButton);
Vue.component("success", SuccessMessage);
Vue.component("help", HelpMessage);
Vue.component("popover", Popover);
Vue.component("input-group", InputGroup);
Vue.component("tel-input", TelInput);
Vue.component("price", PriceInput);
Vue.component("qty-input", QtyInput);
Vue.component("dropdown", Dropdown);
Vue.component("action-btn", ActionButton);


window.axios = axios;

Vue.config.productionTip = false;

window.Event = new Vue({});
Vue.prototype.$events = window.Event;
Vue.prototype.$title = (title) => {
  document.title = title;
};

import ScheduleRepo from "./repo/ScheduleRepo";
Vue.prototype.$schedule = ScheduleRepo;

import SuppliersRepo from "./repo/SupplierRepo";
Vue.prototype.$suppliers = SuppliersRepo;

import { glassesRecipeFields } from './data/consstants';
Vue.prototype.$constants = {
glassesRecipeFields: glassesRecipeFields
}

Vue.prototype.$timeslots = () => {
  let slots = [];
  for (let t = 15; t <= 120;) {
    slots.push(t);
    t = t + 15;
  }

  return slots;
};



/* Money */
const formatMoney = money => {
  return Vue.filter("currency")(money)
};

Vue.filter("money", formatMoney);

Vue.filter('currency', (value) => {

  if (value === '' || value === null || value === undefined) return '';

  if (isNaN(parseFloat(value))) {
    return '';
  }

  let numberStr = parseFloat(value).toFixed(2);
  let parts = numberStr.includes('.') ? numberStr.split('.') : numberStr.split(',');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  if (parts.length == 1) {
    parts.push('00');
  }

  return parts.join(',');


})

Vue.filter('pad', (num) => {
  return String(num).padStart(10, '0');
});

Vue.prototype.$axios = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL
});




Vue.prototype.$secondaryComponent = false;

import ModalService from "./components/modals/modal.service";
Vue.prototype.$modals = ModalService;
Vue.prototype.$pic = filename => {
  return "https://178107.selcdn.ru/monkl-picture/" + filename;
};

Vue.prototype.$picture = item => {
  return (
    '<img src="' +
    Vue.prototype.$pic(item.picture) +
    '" class="h-8 w-8 rounded-full object-cover"/>'
  );
};
import store from "./store";
import router from "./router";

// selected department
// Vue.mixin({
//   data: function() {
//     return {
//       get dept() {
//         console.log('dept mixin');
//         console.log()
//         return store.getters["optics/active"];
//       }
//     };
//   }
// });

Vue.prototype.$getSupplier = (id) => {
  let suppliers = store.getters["optics/suppliers"];
  return suppliers.find(item => item.id == id) ?? { name: 'unknow' };
}

Vue.prototype.$requireRole = (role) => {

  // if user is root, always return true
  if (store.getters["app/getUser"].root) {
    return true;
  }
  return store.getters["app/getUser"].roles.indexOf(role) != -1;
};

/**
 * Listen for editing record event
 */
import EditRecordModal from "./components/schedule/EditRecordModal.vue";
Vue.prototype.$events.$on("show-record", (id) => {
  // Add your logic here to handle the editing record event
  ModalService.open(EditRecordModal, { id: id });
});


router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} - Монокль`;

  // check the auth rules
  const { authorize } = to.meta;

  // close mobile menu after clicked
  Vue.prototype.$events.$emit("hidePrimarySidebar");


  if (to.matched.some(record => record.meta.requiresAuth)) {
    store.commit("initializeStore");



    let logged = !!store.getters["app/getUser"].id || !!store.getters["app/getUser"].token;

    // if nt authorized rol
    let currentUserRoles = store.getters["app/getUser"].roles;
    let activeOptic = store.getters["optics/active"];

    // does user contains needed roles in the list
    if (authorize && authorize.length > 0) {
      let filtered = currentUserRoles.filter(item => {
        return authorize.indexOf(item) !== -1;
      });

      // if (filtered.length == 0) {
      //   next({
      //     path: "/403"
      //   });
      //   return;
      // }
    }

    if (!logged) {
      next({
        path: "/auth/login"
      });
    }
  }
  next();
});

router.addRoute(
  {
    path: '/auth/logout',
    name: 'logout',
    beforeEnter: (to, from, next) => {
      store.dispatch("app/logoutUser");
      store.dispatch("optics/wipe");
      store.dispatch("users/wipe");
      store.dispatch("records/wipe");
      store.dispatch("customers/wipe");
      document.location.href = '/auth/login';
    }
  }
)


// Subscribe to store updates
store.subscribe((mutation, state) => {
  // Store the state object as a JSON string
  localStorage.setItem("store", JSON.stringify(state));

  Vue.prototype.$depts = store.getters["optics/optics"];
  Vue.prototype.$hosts = store.getters["users/users"];
  Vue.prototype.$dept = store.getters["optics/active"];
});

Vue.filter("date", function (value, time) {
  return dayjs
    .unix(value)
    .utc()
    .format("DD.MM.YYYY");
});

Vue.filter("rdate", function (value, time) {
  return dayjs(value)
    .format("DD.MM.YYYY");
});

/**
 * Add zero to integer numbers
 */
Vue.filter("withzero", function (value, time) {
  if (Number.isInteger(value)) {
    value = value.toFixed(1);
  }

  return value;
});

Vue.filter("datetime", function (value, time) {
  if (value == '') return '';
  return dayjs(value)
    .format("DD.MM.YYYY HH:mm");
});

Vue.filter("recently", function (value, time) {
  // if value is less than 10 minutes ago
  //if (dayjs().unix() - dayjs(value).unix() < (60 * 10)) {
  return dayjs(value).fromNow();
  //}

});


Vue.filter("humandate", function (value, time) {
  return dayjs
    .unix(value)
    .utc()
    .format("D MMMM, dddd, YYYY");
});

Vue.filter("humantime", function (value, time) {
  return dayjs
    .unix(value)
    .utc()
    .format("HH:mm");
});

Vue.filter("supplyStatus", function (value, time) {
  if (value == 'cancelled') {
    return 'Отменен';
  }

  return value;
});

// preapre query
const serialize = function (obj) {
  var str = [];
  for (let key in obj) {
    console.log(key + Object.keys(obj[key]).length);
    if (typeof obj[key] === "object") {
      // iterate it over
      //str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      //str = [...serialize(obj[key]), ...str];
      let r = serialize(obj[key]).map(item => `${key}.${item}`);
      str = [...r, ...str];
    } else {
      str.push(encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]));
    }
  }
  return str;
};

const hostStatus = {
  active: "Активен",
  fired: "Уволен",
  disabled: "Заблокирован"
};
Vue.filter("humanHostStatus", val => {
  return hostStatus[val];
});

// to role
const roles = {
  admin: "Директор",
  manager: "Управляющий",
  seller: "Продавец",
  medic: "Врач",
  operator: "Оператор",
  optometrist: "Оптометрист",
  intern: "Стажер"
};

const rules = {
  manager: ['settings', 'calendar', 'customers', 'suppliers', 'products', 'reports'],
  admin: ['services', 'optics', 'users'],
  medic: ['schedule', 'dashboard'],
  seller: ['sales', 'dashboard'],
  operator: [],
  optometrist: [],
  intern: []
}

Vue.prototype.$roles = roles;
Vue.prototype.$rules = rules;

Vue.filter("toRole", function (key) {
  return roles[key];
});

Vue.filter("toDeptTitle", function (key) {
  return store.getters["optics/getById"]({ id: key })?.title || "not_found";
});

Vue.filter("phone", function (value) {
  var cleaned = ("" + value).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{1})?(\d{3})(\d{3})(\d{2})(\d{2})$/);
  if (match) {
    if (match[1] == undefined) {
      match[1] = 7;
    }
    return ["+" + match[1] + " ", "(", match[2], ") ", match[3], "-", match[4], "-", match[5]].join(
      ""
    );
  }
  return null;
});

/**
 * Disable fields if mode of component is read
 */
// Register a global custom directive called `v-focus`
// Vue.directive('read', {
//   // When the bound element is inserted into the DOM...
//   inserted: function (el, binding, vnode) {
//     // Focus the element
//     let mode = vnode.context.$data.mode;
//     console.log(mode);
//     //if (ready && process.env.VUE_APP_MODE === 'production') {
//     //if (busy) {
//     el.classList.add("read");
//     //} else {
//     //el.classList.remove("busy");
//     //}//

//     //}

//   }
// })

/**
 * In progress button
 */

Vue.directive("busy", {
  // When the bound element is inserted into the DOM...
  inserted: function (el, binding, vnode) {
    // Focus the element
    let mode = vnode.context.$data.mode;
  },
  update: (el, binding, vnode, oldVnode) => {
    // if no changes, pass it
    if (binding.oldValue == binding.value) return;

    if (!binding.oldValue && binding.value) {
      el.classList.add("busy");
      el.innerHTML = "Сохраняем...";
    } else {
      el.classList.remove("busy");

      setTimeout(function () {
        el.innerHTML = "Готово";
        el.classList.add("loading-done");
      }, 100);

      setTimeout(function () {
        // remove class saved
        el.classList.remove("loading-done");
        el.innerHTML = "Сохранить";
      }, 1000);
    }
    console.log(binding.value);
    console.log(binding.oldValue);
    console.log("btn updated");
  }
});

/**
 * Only letters
 */
Vue.directive("letters", {
  // When the bound element is inserted into the DOM...
  inserted: function (el, binding, vnode) { },
  bind: function (el, binding, vnode) {
    el.addEventListener("keydown", e => {
      let lettersRegex = new RegExp(/[A-Za-zа-яА-Я]/, "i");

      // check meta keys
      const baseAllowedRegex = new RegExp(
        "Backspace|ArrowLeft|ArrowRight|ArrowUp|ArrowDown|Tab|Delete"
      );

      if (lettersRegex.test(e.key) || baseAllowedRegex.test(e.key)) {
        return true;
      } else {
        e.preventDefault();
        return false;
      }
    });
  }
});

/**
 * Only numbers with limits
 */
Vue.directive('number', {
  bind(el, binding, vnode) {

    let { min, max, len, defaultValue } = binding.value;

    el.addEventListener('keydown', (e) => {

      let special = [46, 8, 9, 27, 13, 188, 190]  // delete, backspace, tab, escape, enter , .
      // allow special keys
      if (special.includes(e.keyCode) || (e.keyCode >= 35 && e.keyCode <= 39) || e.key == '.') {
        return
      }

      if (((!e.shiftKey && (e.keyCode >= 48 && e.keyCode <= 57)) ||
        // numpad number keys
        (e.keyCode >= 96 && e.keyCode <= 105))) {
        return // allow
      }

      e.preventDefault()
    })

    // limits
    el.addEventListener('blur', () => {

      if (el.value != '') {

        el.value = el.value.replace(/^0+/, '');

        if (max) {
          if (el.value > max) {
            el.value = defaultValue != undefined ? defaultValue : max
            vnode.elm.dispatchEvent(new CustomEvent('input'));
          }
        }

        if (min) {
          if (el.value < min) {
            el.value = defaultValue != undefined ? defaultValue : min
            vnode.elm.dispatchEvent(new CustomEvent('input'));
          }
        }

        // limit to length
        if (len) {
          if (el.value.length > len) {
            el.value = el.value.slice(0, len)

            vnode.elm.dispatchEvent(new CustomEvent('input'));
          }
        }

        vnode.elm.dispatchEvent(new CustomEvent('input'));

      }


    });
  }
})


import VueI18n from "vue-i18n";
import Speech from "./api/speech";

Vue.use(VueI18n);

const messages = {
  ru: require("./lang/ru")
};
const i18n = new VueI18n({
  locale: "ru", // set locale
  messages // set locale messages
});


new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
  beforeCreate() {
    store.commit("initializeStore");

    Vue.prototype.i18n = i18n;

    Vue.prototype.$logged =
      !!store.getters["app/getUser"].id || !!store.getters["app/getUser"].token;

    Vue.prototype.$me = store.getters["app/getUser"];

    // logout user if more than 24 hours from last login elapsed
    let lastLoginVal = store.getters["app/getUser"].lastLogin;
    let lastLogin = (Date.now() - lastLoginVal) / 1000 / 60 / 60;
    if (lastLogin >= 4 && lastLoginVal != "") {
      console.log('logout user');
      store.dispatch("app/logoutUser");
      document.location = "/auth/login";
    }

    // selected optic
    // try to use the last selected optic
    let lastActive = store.getters["optics/active"]
    var host = window.location.host;
    var subdomain = host.split(".")[0];

    window.axios = axios.create({
      baseURL: process.env.VUE_APP_MODE === 'dev' ? process.env.VUE_APP_BACKEND_URL : `https://${subdomain}.api.mnkl.ru/api/v1`,
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "x-token": store.getters["app/getUser"].token || "",
        "x-user-id": store.getters["app/getUser"].id || "",
        "x-addr-id": lastActive && lastActive.id ? lastActive.id : ''
      }
    });

    Vue.prototype.$api = api;
    Vue.prototype.$speaker = Speech;

    if (Vue.prototype.$logged) {
      console.log('user logged, before create')
      Vue.prototype.$sockets = new sockets(
        store.getters["app/getUser"].id,
        store.getters["app/getUser"].token,
        Vue.prototype.$events,
        subdomain
      );
    }

    if (process.env.VUE_APP_MODE == "production") {
      console.log = function () { };
    }
  }
}).$mount("#app");

<template>
  <page>
    <template v-slot:header>
      {{ optic.title }}
    </template>
    <template v-slot:secondary>
      <div class="flex flex-row gap-x-1">
        <button @click="tab = 'main'" :class="{ active: tab == 'main' }" class="secondary-menu-link text-left">
          <i class="fas fa-circle "></i>
          Основное
        </button>
        <button @click="tab = 'services'" :class="{ active: tab == 'services' }" class="secondary-menu-link">
          <i class="fas fa-list-alt"></i>
          Услуги
        </button>
        <button @click="tab = 'hours'" :class="{ active: tab == 'hours' }" class="secondary-menu-link">
          <i class="fas fa-clock "></i>
          Режим работы
        </button>
        <button @click="tab = 'suppliers'" :class="{ active: tab == 'suppliers' }" class="secondary-menu-link">
          <i class="fas fa-boxes"></i>
          Поставщики
        </button>
        <button @click="tab = 'inventory'" :class="{ active: tab == 'inventory' }" class="secondary-menu-link">
          <i class="fas fa-truck"></i>
          Минимальные остатки
        </button>
        <button @click="tab = 'lenses'" :class="{ active: tab == 'lenses' }" class="secondary-menu-link">
          <i class="fas fa-eye"></i>
          Диагностические линзы
        </button>
        <button @click="tab = 'integration'" :class="{ active: tab == 'integration' }" class="secondary-menu-link">
          <i class="fas fa-link"></i>
          Интеграция
        </button>
        <button @click="tab = 'merchants'" :class="{ active: tab == 'merchants' }" class="secondary-menu-link">
          <i class="fas fa-money-check"></i>
          Эквайринг
        </button>
      </div>
    </template>
    <template v-slot:content v-if="optic.id">
      <div v-show="tab == 'main'">
        <main-settings :optic="optic" />
      </div>

      <div v-show="tab == 'services'">
        <services-settings :services="optic.services" />
      </div>

      <div v-show="tab == 'hours'">
        <working-hours :workingHours="optic.workingHours" :key="optic.id || 1" />
      </div>

      <div v-if="tab == 'suppliers'">
        <suppliers-settings :key="optic.id || 1" :optic="optic" />
      </div>

      <div v-if="tab == 'lenses'">
        <lenses :optic="optic" @save="save()" :busy="busy" />
      </div>

      <div v-if="tab == 'integration'">
        <integration-settings :optic="optic" />
      </div>

      <div v-if="tab == 'merchants'">
        <merchants :merchants="optic.merchants" />
      </div>

      <div v-if="tab == 'inventory'">
        <inventory-settings :store="optic" />
      </div>

      <div class="inline-block">
        <success v-if="status == 'save-success' && !busy"></success>

        <div class="border-green-200 border text-green-600 py-2 text-sm rounded-md px-4 mt-2"
          v-if="status == 'delete-success' && !busy">
          Оптика удалена.
        </div>
      </div>

      <div class="mt-2" v-if="tab != 'lenses' && tab != 'integration' && tab != 'suppliers'">
        <button type="button" @click="save()" :class="{ busy: busy }" :disabled="busy" class="btn-primary">
          Сохранить
        </button>
      </div>

      <div class=" mt-32 text-sm text-gray-400" v-if="optic.id && tab == 'main'">
        <span>Решили удалить этот адрес?</span>
        <button type="button" @click="remove()"
          class="ml-2 py-1 px-4 bg-red-100 text-red-500 rounded-md font-medium text-xs opacity-50 hover:opacity-100">
          Удалить
        </button>
      </div>
    </template>
  </page>
</template>

<script>
import Optic from "../../repo/OpticRepo";

import OpticRepo from "../../repo/OpticRepo";
import MainSettings from "./MainSettings.vue";
import WorkingHours from "./WorkingHours.vue";
import SuppliersSettings from "./SuppliersSettings.vue";
import Lenses from "./Lenses.vue";
import ServicesSettings from "./ServicesSettings.vue";
import IntegrationSettings from "./IntegrationSettings.vue";
import Merchants from "./Merchants.vue";
import InventorySettings from "./InventorySettings.vue";

import SuccessAlert from "../shared/SuccessAlert.vue";

export default {
  components: {
    MainSettings,
    WorkingHours,
    ServicesSettings,
    SuccessAlert,
    Lenses,
    SuppliersSettings,
    IntegrationSettings,
    Merchants,
    InventorySettings
  },
  data() {
    return {
      id: this.$route.params.id ?? this.$dept.id,
      tab: "main",
      optic: {
        suppliers: []
      },
      busy: false,
      status: ""
    };
  },
  watch: {
    tab: function (newval, oldval) {
      this.status = "";
      switch (newval) {
        case "main":
          this.$title("Настройки филиала - " + this.optic.title);
          break;
        case "suppliers":
          this.$title("Поставщики - " + this.optic.title);
          break;
        case "services":
          this.$title("Услуги - " + this.optic.title);
          break;
        case "hours":
          this.$title("Режим работы - " + this.optic.title);
          break;
        case "lenses":
          this.$title("Диагностические линзы - " + this.optic.title);
          break;
        case "integration":
          this.$title("Интеграция - " + this.optic.title);
          break;
        case "merchants":
          this.$title("Эквайринг - " + this.optic.title);
          break;
        case "inventory":
          this.$title("Минимальные остатки - " + this.optic.title);
          break;
        default:
          this.$title("Настройки филиала - " + this.optic.title);
      }
    },
    $route(to, from) {
      this.checkHash();
    }
  },
  mounted() {
    this.get();
    this.checkHash();
  },
  methods: {
    checkHash() {
      if (location.hash == "#main") {
        this.tab = "main";
      }
      if (location.hash == "#suppliers") {
        this.tab = "suppliers";
      }
      if (location.hash == "#hours") {
        this.tab = "hours";
      }
      if (location.hash == "#services") {
        this.tab = "services";
      }
      if (location.hash == "#api") {
        this.tab = "integration";
      }
      if (location.hash == "#inventory") {
        this.tab = "inventory";
      }
    },
    async get() {
      this.busy = true;
      // load from api
      const { data, msg, success } = await this.$api.get(`/optics/${this.id}`);
      if (success) {
        this.optic = data;
        this.$title("Настройки филиала - " + this.optic.title);
      }
      this.busy = false;
    },
    setSuppliers(suppliers) {
      this.optic.suppliers = suppliers;
    },
    async save() {
      this.busy = true;

      let { success, msg, data } = await Optic.update(this.optic);

      this.busy = false;

      if (success) {
        this.optic = data;
        this.status = "save-success";
        // reload items in store
        this.$store.dispatch("optics/pullOptics");
      } else {
        this.status = msg;
      }
    },
    async remove() {
      if (!confirm("Удалить этот адрес?")) {
        return;
      }

      this.loading = true;
      const { data, msg, success } = await Optic.delete(this.id);
      if (success) {
        this.status = "delete-success";
        this.$store.dispatch("optics/pullOptics");
        this.$router.push({ path: "/network" });
      }
      /**@todo: add error handlers */
      this.loading = false;
    }
  },
  computed: {}
};
</script>

<template>
  <div>
    <transition name="modal" v-if="show">
      <div class="cmodal-mask bg-black bg-opacity-20">
        <div class="backdrop" @click.self="!noclose ? $emit('close') : ''"></div>
        <div class="flex flex-col items-center justify-center w-full min-h-full">
          <div
            class="cmodal-container  my-8 shadow-xl relative md:w-160 md:min-w-160 mx-auto bg-white py-8 px-8 md:px-12 rounded-2xl"
            :class="styles"
          >
            <div class="gback" v-if="busy"></div>
            <button
              class="absolute top-4 right-6"
              
              v-if="!noclose"
              type="button"
            >
              <i class="fas fa-times"></i>
            </button>

            <slot></slot>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "CustomModal",
  props: ["show", "busy", "noclose", "styles"],
  data() {
    return {};
  },
  mounted() {
    document.addEventListener("keydown", e => {
      if (this.show && e.key === "Escape") {
        this.$emit("close");
      }
    });
  },
  watch: {
    show: function(newval, oldval) {
      if (this.show) {
        // hide scroll on body
        document.body.classList.add("noscroll");
      } else {
        document.body.classList.remove("noscroll");
      }
    }
  },
  methods: {}
};
</script>

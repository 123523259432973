import api from "../api/api";
const resource = "/records";

const metric = {
  sph: "",
  cyl: "",
  axis: "",
  visus_near: "",
  visus_distance: "",
  add: "",
  bc: "",
  dia: "",
  dpp: ""
};
const compiledMetric = { od: metric, os: metric };

export default {
  get({ opticId, start, end }) {
    return api.call(`${resource}?opticId=${opticId}&start=${start}&end=${end}`);
  },
  getByClientId(client_id) {
    return api.call(`${resource}?client_id=${client_id}`);
  },
  getByid(id) {
    return api.call(`${resource}/${id}`, { method: "get" });
  },
  update(payload) {
    return api.call(`${resource}`, { method: "post", data: payload });
  },
  delete(id) {
    return api.call(`${resource}/${id}`, { method: "delete" });
  },
  SCHEMA: {
    status: "new",
    client_id: "",
    client: {
      fio: ""
    },
    host: {
      id: "",
      name: ""
    },
    metrics: {
      comment: "",
      refractometria: {
        od: { sph: "", cyl: "", axis: "", bc: "", dia: "", dpp: "" },
        os: { sph: "", cyl: "", axis: "", bc: "", dia: "", dpp: "" }
      },
      ceratometria: {
        od: { sph: "", cyl: "", axis: "", bc: "", dia: "", dpp: "" },
        os: { sph: "", cyl: "", axis: "", bc: "", dia: "", dpp: "" }
      },
      visus: {
        od: { sph: "", cyl: "", axis: "", nocorr: "", wide: "", corr: "" },
        os: { sph: "", cyl: "", axis: "", nocorr: "", wide: "", corr: "" }
      }
    },
    currentGlasses: {
      old0: compiledMetric,
      old1: compiledMetric,
      old2: compiledMetric,
      old3: compiledMetric,
      comment: ""
    }
  }
};

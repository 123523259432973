<template>
  <div class="rounded-md shadow-lg">
    <div
      class="flex flex-row relative items-start py-4 px-8 border rounded-md"
      :class="{
        'text-blue-800 bg-blue-50': alert.type == 'info',
        'text-green-800 bg-green-50': alert.type == 'success',
        'text-red-800 bg-red-50': alert.type == 'error'
      }"
    >
      <div class="mr-2">
        <span v-if="alert.type == 'error'">❗</span>
        <span v-if="alert.type == 'success'">✔️</span>
        <span v-if="alert.type == 'info'">
          <i class="fas fa-info-circle"></i>
        </span>
      </div>
      <div class="text-sm">
        <div v-if="alert.title" class="font-bold">
          {{ alert.title }}
          <div class="text-gray-500 text-xs font-extralight mb-2">{{ alert.created_at | recently }}</div>
        </div>
        {{ alert.text }}

        <div class="mt-2" v-if="alert.record && alert.record._id">
          <router-link to="/schedule" class="btn-action-xs">Перейти в Раписание</router-link>
        </div>
      </div>
      <div class="absolute right-2.5 top-4">
        <button
          type="button"
          class="ms-auto -mx-1.5 -my-1.5  rounded-lg  p-1.5 hover:text-black inline-flex items-center justify-center h-8 w-8"
          aria-label="Close"
          @click="$emit('dismiss')"
        >
          <span class="sr-only">Close</span>
          <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["alert"],
  data() {
    return {};
  },
  mounted() {
    console.log(this.alert);
  }
};
</script>

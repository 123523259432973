<template>
  <div>
    <form>
      <div>
        <div class="mb-4 md:w-1/3" id="phone">
          <label class="" for="cus_name">Телефон</label>

          <small v-if="status == 'such_phone_exists'" class="block text-red-400">
            Клиент с таким номером уже есть.
          </small>
          <auto-suggest styles="w-full" placeholder="9110002200" v-model="customer.phone" :component="'Customer'"
            v-mask="'##########'" :field="'phone'" v-on:select="emitCustomerFound" :focus="true">
            <template v-slot:default="slotProps">
              <div>
                <span class="text-sm font-normal"> <i v-if="slotProps.item.fromAcuvue"
                    class="text-blue-500 font-bold text-xs">
                    @acuvue
                  </i> {{ slotProps.item.fio }}</span>
                <div class="text-xs">{{ slotProps.item.phone }} {{ slotProps.item.email }}</div>
              </div>
            </template>
          </auto-suggest>
        </div>

        <div class="relative mb-0">
          <label class="" for="cus_name">ФИО</label>
          <div class="flex flex-col md:flex-row gap-y-2 md:gap-0 md:gap-x-4 ">
            <input type="text" v-model="customer.lastname" v-letters class="w-full" placeholder="Фамилия" />
            <input type="text" v-model="customer.firstname" v-letters class="w-full" placeholder="Имя" />
            <input type="text" v-model="customer.thirdname" v-letters class="w-full" placeholder="Отчество" />
          </div>
        </div>

        <div class="grid grid-cols-1 lg:grid-cols-3 gap-4">
          <div class="mt-4">
            <label>
              Электронная почта
            </label>
            <input class="w-full" type="text" required="" v-model="customer.email" placeholder="email@example.com" />
          </div>

          <div class="mt-4">
            <div class="flex flex-row justify-between">
              <label>Дата рождения</label>
              <label>Возраст</label>
            </div>
            <div class="flex flex-row gap-x-2">
              <input class="w-2/3" type="date" required="" v-model="customer.birthday" placeholder="DD.MM.YYYY" />
              <input class="w-1/3" type="text" required="" v-model="customer.age" placeholder="33" maxlength="2" />
            </div>
          </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
          <div class="">
            <label class="" for="cus_name">Город</label>
            <!-- <input type="text" v-model="customer.city" class="w-full" placeholder="Город" /> -->
            <auto-suggest styles="w-full" placeholder="Санкт-Петербург" v-model="customer.city" component="city"
              v-on:select="item => (customer.city = item.value)">
              <template v-slot:default="slotProps">
                <div>
                  <span class="text-sm font-normal">
                    {{ slotProps.item.value }}
                  </span>
                </div>
              </template>
            </auto-suggest>
          </div>

          <div class="md:col-span-2">
            <label>Адрес доставки</label>

            <auto-suggest styles="w-full" placeholder="Адрес доставки" v-model="customer.shippingAddress"
              component="address" v-on:select="item => (customer.shippingAddress = item.value)">
              <template v-slot:default="slotProps">
                <div>
                  <span class="text-sm font-normal">
                    {{ slotProps.item.value }}
                  </span>
                </div>
              </template>
            </auto-suggest>
          </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
          <!--  -->
          <div class="">
            <label>Откуда узнали</label>
            <select v-model="customer.sourceChannel" class="w-full">
              <option :value="source" v-for="source of sources">{{ source }}</option>
            </select>
          </div>

          <div class="md:col-span-2">
            <label>Заметка</label>
            <textarea class="w-full" v-model="customer.note"></textarea>

          </div>


        </div>
        <div class="mt-4 grid grid-cols-1 md:grid-cols-3 gap-4">
          <div>
            <div class="flex flex-row gap-x-4 mt-2">
              <button class="px-2 border rounded-md py-1" :class="{ 'bg-green-500': customer.gender == 'male' }"
                @click="customer.gender = 'male'" type="button">
                👨
              </button>
              <button @click="customer.gender = 'female'" class="px-2 border rounded-md py-1"
                :class="{ 'bg-green-500': customer.gender == 'female' }" type="button">
                👩
              </button>
            </div>
          </div>

          <div v-if="customer.notifications">
            <label>Уведомления</label>
            <div class="flex flex-row gap-x-4 mt-2">
              <label class="text-black">
                <input type="checkbox" v-model="customer.notifications.sms" />
                SMS
              </label>
              <label class="text-black">
                <input type="checkbox" v-model="customer.notifications.email" />
                Email
              </label>
            </div>
          </div>
        </div>


      </div>
    </form>
  </div>
</template>

<script>
import operations from "../../data/operations";
import allergicData from "../../data/allergic";
import { heritable, diseases, chronic } from "../../data/diseases";
import { common, mkl, eyeStrain } from "../../data/complains";
import { sources } from '../../data/sources';

import Info from "../shared/Info.vue";

export default {
  components: { Info },
  props: ["customer", "readOnly", "status"],
  name: "main-customer-settings",
  data() {
    return {
      allergicOptions: [],
      complains: { common: [], mkl: [], eyeStrain: [] },
      eyeOperations: [],
      options: { heritable: [], diseases: [], chronic: [] },
      sources: []
    };
  },
  mounted() {
    this.eyeOperations = operations;
    this.allergicOptions = allergicData;
    this.complains.common = common;
    this.complains.mkl = mkl;
    this.complains.eyeStrain = eyeStrain;

    this.options = { heritable, diseases, chronic };

    this.sources = sources;

  },
  methods: {
    emitCustomerFound(customer) {
      this.$emit("customer-found", customer);
    }
  }
};
</script>

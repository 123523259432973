<template>
  <modal :styles="''">
    <h3 class="text-black font-bold text-xl mb-6">
      Редактирование товара
    </h3>

    <form class="" @submit.prevent="save()">
      <div class="grid grid-cols-4 gap-6">
        <div class="col-span-2">
          <div class="">
            <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
              Название
            </label>
            <input
              class="w-full "
              id="title"
              name="title"
              type="text"
              required=""
              disabled
              v-model="product.name"
              placeholder="Название"
              aria-label="title"
            />
          </div>
        </div>

        <div class="col-span-1">
          <div class="">
            <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">Прайс</label>
            <input
              class="w-full  font-mono"
              type="text"
              required=""
              v-model="product.base_price"
              placeholder="Прайс"
            />
          </div>
        </div>

        <div class="col-span-1">
          <div class="">
            <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">НДС</label>
            <select class="w-full" v-model="product.nds" required disabled>
              <option value="">Выбрать</option>
              <option :value="opt.val" v-for="opt in ndsOptions">{{ opt.title }}</option>
            </select>
          </div>
        </div>

        <div class="col-span-2">
          <div class="">
            <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
              Штрихкод
            </label>
            <input disabled class="w-full  font-mono" type="text" v-model="product.barcode" placeholder="Артикул" />
          </div>
        </div>

        <div class="col-span-2">
          <div class="">
            <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
              Производитель
            </label>
            <input disabled class="w-full font-mono" type="text" v-model="product.manufacturer" placeholder="Параметры линз" />
          </div>
        </div>

        <div class="col-span-2">
          <div class="">
            <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
              Категория
            </label>

            <auto-suggest styles="w-full" placeholder="Категория" v-model="product.category" :component="'Category'">
              <template v-slot:default="slotProps">
                <div>
                  <span class="text-sm font-normal">{{ slotProps.item.name }}</span>
                </div>
              </template>
            </auto-suggest>

            <small>
              Начните вводить, будет подсказка. Если такой категории нет, будет создана новая.
            </small>
          </div>
        </div>
        <div class="col-span-2">
          <div class="">
            <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
              Бренд
            </label>

            <auto-suggest styles="w-full" placeholder="Бренд" v-model="product.brand" :component="'Brand'">
              <template v-slot:default="slotProps">
                <div>
                  <span class="text-sm font-normal">{{ slotProps.item.name }}</span>
                </div>
              </template>
            </auto-suggest>

            <small>
              Начните вводить, будет подсказка.
            </small>
          </div>
        </div>

        <div class="col-span-4">
          <div>
            <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
              Поставщики
            </label>
            <div>
              <selector styles="w-full" placeholder="ИНН или название" :component="'Supplier'" @select="pickSupplier">
                <template v-slot:default="slotProps">
                  <div>
                    <span class="text-sm font-normal">{{ slotProps.item.name }}</span>
                  </div>
                </template>
              </selector>

              <div class="flex flex-row flex-wrap space-x-2 mt-2" v-if="product.suppliers.length > 0">
                <div
                  v-for="(supId, index) in product.suppliers"
                  class="text-sm border-blue-500 border rounded-md px-2 py-0.5 text-blue-500 font-semibold"
                >
                  {{ supplierDetails(supId).name }}
                  <button type="button" class="text-xs" @click="product.suppliers.splice(index, 1)">
                    <i class="fas fa-times ml-1"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-span-4">
          <div>
            <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
              Параметры
            </label>
            <div class="grid grid-cols-4 gap-x-2 text-xs gap-y-1 items-center" v-if="product.params">
              <template v-for="param in Object.keys(product.params)">
                <div class="border rounded-md px-1" v-if="product.params[param]">
                  <span class="text-gray-500">{{ param }}:</span>
                  <br />
                  <span class="text-black font-semibold">{{ product.params[param] }}</span>
                </div>
              </template>
            </div>
          </div>
        </div>
        
      </div>

      <div class="flex flex-col mt-6">
        <button type="submit" :class="{ busy: busy }" :disabled="busy" class="btn-primary-sm">
          Сохранить
        </button>
      </div>
    </form>
  </modal>
</template>

<script>
import Modal from "../modals/Modal.vue";
import ModalMixin from "../modals/modal.mixin";
import GlobalProduct from "../../repo/GlobalProductRepo";
import Category from "../../repo/CategoryRepo";
import Brand from "../../repo/BrandRepo";
import AutoSuggest from "../shared/AutoSuggest.vue";
import Selector from "../shared/Selector.vue";
import api from "../../api/api.js";

import { ndsOptions } from "../../data/consstants.js";

export default {
  components: { Modal, AutoSuggest, Selector },
  mixins: [ModalMixin],
  props: ["id"],
  data() {
    return {
      loading: false,
      busy: false,
      ndsOptions: ndsOptions,
      tags: [],
      tag: "",
      status: "",
      product: {
        id: "",
        name: "",
        suppliers: [],
        nds: ""
      },
      suppliers: []
    };
  },
  mounted() {
    console.log(this.id);
    if (this.id) {
      this.getProduct();
    }

    this.getSuppliers();
  },
  methods: {
    pickSupplier({ id }) {
      if (this.product.suppliers.indexOf(id) == -1) {
        this.product.suppliers.push(id);
      }
    },
    updateSuppliers(tags) {
      console.log(tags);
    },
    async getSuppliers() {
      let { success, msg, data } = await api.get("/g/suppliers");
      if (success) {
        this.suppliers = data.suppliers;
      }
    },
    addPicture() {
      alert("in progress...");
    },
    async getProduct() {
      this.busy = true;
      let { success, msg, data } = await GlobalProduct.get(this.id);
      this.product = data;
      this.busy = false;
    },
    async save() {
      this.busy = true;

      let { success, msg, data } = await GlobalProduct.update(this.product);

      this.busy = false;

      if (success) {
        this.product = data;
        this.status = "save-success";
        this.close();
        this.$events.$emit("reload");
      } else {
        this.status = msg;
      }
    },
    supplierDetails(id) {
      let sup = this.suppliers.find(el => el.id == id);
      if (sup != undefined) {
        return sup;
      }

      return {
        name: ""
      };
    }
  }
};
</script>

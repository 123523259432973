<template>
  <div class="overflow-x-auto">
    <div v-if="records.length == 0 && !busy" class="text-sm text-gray-500 mb-4">
      Приемов не было.
    </div>

    <button class="btn-action-sm mb-6" type="button" @click="addRecord">Добавить прием</button>

    <div class="flex flex-col" v-for="record in records">
      <div class="py-2 px-3 lg:py-2 rounded-md border mb-2">
        <div class="grid grid-cols-2 lg:grid-cols-5 gap-y-2 lg:gap-x-4  text-xs lg:text-sm overflow-hidden  w-full">
          <div class="font-semibold">{{ $dayjs(record.startTime).format("DD.MM.YYYY HH:mm") }}</div>
          <div class="text-right lg:text-left">
            <span class="inline-status inline-block rounded-full w-2 h-2 mr-1" :class="record.status"></span>
            <span :class="`text-${record.status}`" class="font-medium">
              {{ getStatus(record.status) }}
            </span>
          </div>

          <div class="overflow-hidden whitespace-nowrap pr-10 w-full col-span-2 lg:col-span-1">
            <user-card :id="record.host_id" v-if="record.host_id" />
          </div>

          <div>{{ record.serviceTitle || "" }}</div>

          <button type="button" class="btn-view" @click="viewRecord(record.id)">
            Просмотреть
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Records from "../../repo/RecordRepo";
import { mapGetters } from "vuex";
import EditRecordModal from "../schedule/EditRecordModal.vue";
import AddRecordModal from "../records/AddRecordModal.vue";
import ReadRecordModal from "../records/ReadRecordModal.vue";

export default {
  props: ["id", "customer"],
  data() {
    return {
      records: [],
      busy: true,
      opened: [],
      openKey: 0
    };
  },
  name: "customer-history",
  mounted() {
    let vm = this;
    this.pullHistory();
    this.$events.$on("update-records", () => {
      vm.pullHistory();
    });
  },
  methods: {
    addRecord() {
      this.$modals.open(AddRecordModal, { client_id: this.id, customer: this.customer }).then(() => {
        console.log("resolved");
      });
    },
    viewRecord(id) {
      this.$modals.open(ReadRecordModal, { id: id });
    },
    async pullHistory() {
      let vm = this;
      vm.busy = true;
      // get records by customer id
      let { success, data, msg } = await Records.getByClientId(this.id);

      if (success) {
        this.records = data;
        // sort records by startTime
        this.records.sort((a, b) => {
          return (this.$dayjs(a.startTime) - this.$dayjs(b.startTime)) * -1;
        });
      }

      setTimeout(() => {
        vm.busy = false;
      }, 500);
    }
  },
  computed: {
    ...mapGetters("records", ["getStatus"]),
    sorted() {
      return [...this.records].sort((a, b) => {
        return (a.startTime - b.startTime) * -1;
      });
    }
  }
};
</script>

import api from "../api/api";
const resource = "/schedule";

export default {
  get() {
    return api.call(`${resource}`);
  },
  getByDate(id, date) {
    return api.call(`${resource}/${id}/${date}`, { method: "get" });
  },
  // get slots per month
  getByMonth(id, month, year){
    return api.call(`${resource}/${id}/${month}/${year}`, { method: "get" });
  },
  getByDates(id, start, end){
    return api.call(`${resource}/${id}/${start}/${end}`, { method: "get" });
  },
  update(payload) {
    return api.call(`${resource}`, { method: "post", data: payload });
  },
  delete(id) {
    return api.call(`${resource}/${id}`, { method: "delete" });
  },
};

<template>
  <div class="max-w-md mx-auto my-10">
    <div class="text-center">
      <h1 class="my-3 text-3xl font-semibold text-gray-700 dark:text-gray-200">
        Новая компания
      </h1>
      <p class="text-gray-500 dark:text-gray-400">
        Будет создана новая компания
      </p>
    </div>
    <div class="m-7">
      <div class="mb-6">
        <label for="domain" class="block mb-2 text-sm text-gray-600 dark:text-gray-400">
          Домен
        </label>
        <div class="flex">
          <input
            type="text"
            name="domain"
            id="domain"
            v-model="register.domain"
            placeholder="bestco"
            class="w-full px-3 py-2 border-r-0 rounded-r-none placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
          />
          <div class="flex -mr-px">
            <span
              class="flex items-center leading-normal bg-gray-50 rounded rounded-l-none border border-l-1 px-6 whitespace-no-wrap text-grey-dark text-sm"
            >
              .mnkl.ru
            </span>
          </div>
        </div>
      </div>

      <div class="mb-6">
        <label for="email" class="block mb-2 text-sm text-gray-600 dark:text-gray-400">
          Электронная почта
        </label>
        <input
          type="email"
          name="email"
          v-model="register.email"
          id="email"
          placeholder="you@company.com"
          class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
        />
      </div>
      <div class="mb-6">
        <div class="flex justify-between mb-2">
          <label for="password" class="text-sm text-gray-600 dark:text-gray-400">Пароль</label>
          <a
            href="#!"
            class="text-sm text-gray-400 focus:outline-none focus:text-indigo-500 hover:text-indigo-500 dark:hover:text-indigo-300"
          >
            Восстановить пароль
          </a>
        </div>
        <input
          type="password"
          name="password"
          v-model="register.password"
          id="password"
          placeholder="Пароль"
          class="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 dark:focus:border-gray-500"
        />
      </div>

      <div class="mb-6">
        <button
          @click="doRegister()"
          type="button"
          :disabled="register.busy"
          :class="{ busy: register.busy }"
          class="w-full px-3 py-4 text-white bg-indigo-500 rounded-md focus:bg-indigo-600 focus:outline-none"
        >
          Создать компанию
        </button>
      </div>
      <p class="text-sm text-center text-gray-400">
        Уже есть компания?
        <a
          href="#!"
          class="text-indigo-400 focus:outline-none focus:underline focus:text-indigo-500 dark:focus:border-indigo-800"
        >
          Войти
        </a>
        .
      </p>

      <div
        v-if="result == 'registered'"
        class="rounded text-sm my-4 py-2 px-4 bg-green-100 text-green-800 border border-green-300"
      >
        Компания создана успешно!
        <a :href="companyUrl">Войти</a>
      </div>

      <div
        v-if="result == 'such_domain_not_available'"
        class="rounded text-sm my-4 py-2 px-4 bg-yellow-100 text-yellow-800 border border-yellow-300"
      >
        Такой домен уже занят, попробуйте другой.
      </div>

      <div
        v-if="result == 'such_email_exists'"
        class="rounded text-sm my-4 py-2 px-4 bg-yellow-100 text-yellow-800 border border-yellow-300"
      >
        Такая почта уже зарегистрирована, используйте другую.
      </div>

      <div
        v-if="result == 'something_broken'"
        class="rounded text-sm my-4 py-2 px-4 bg-yellow-100 text-yellow-800 border border-yellow-300"
      >
        Что-то сломалось, обратитесь в службу поддержки.
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "create-company",
  data() {
    return {
      register: {
        email: "dev@aleksey.co",
        domain: "test",
        password: "1234",
        busy: false
      },
      result: "",
      error: "",
      companyUrl: ""
    };
  },
  methods: {
    async doRegister() {
      this.register.busy = true;
      try {
        let resp = await this.$axios.post("/auth/register", this.register);
        this.result = resp.data.msg;

        if (resp.data.status == "success") {
          this.companyUrl = "https://" + resp.data.tenant.domain + "." + process.env.VUE_APP_URL;
        }
      } catch (err) {
        this.result = "something_broken";
        this.error = err;
      } finally {
        this.register.busy = false;
      }
    }
  }
};
</script>

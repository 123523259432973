<template>
  <page>
    <template v-slot:header>
      <back-btn></back-btn>
      Новый документ
    </template>
    <template v-slot:actions></template>

    <!-- Filters, collapsable -->
    <template v-slot:content>


    </template>
  </page>
</template>

<script>

export default {
  data() {
    return {

    };
  },
  mounted() {

  },
  methods: {

  },
  computed: {

  }
};
</script>

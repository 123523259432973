<template>
  <div class="">
    <h2 class="text-lg font-semibold">Новая продажа</h2>

    <div class="max-w-5xl mt-4 md:flex">
      <div class="mt-2 flex md:flex-row justify-between md:w-2/3 pr-6">
        <div>
          <scanner @scan="addProductFromBarcode" autofocus="true" :key="key" />
        </div>
        <div class="flfex flex-row justify-end">
          <button class="btn-link text-sm" @click="openCatalog()">
            <i class="fas fa-bars"></i>
            Каталог
          </button>
        </div>
      </div>
    </div>
    <div class="max-w-5xl mt-4 md:flex lg:space-x-6">
      <div class="rounded-lg md:w-2/3">
        <div class="mb-2 rounded-lg border bg-white px-6 py-2">
          <!-- header -->
          <div
            class="hidden md:grid grid-cols-7 mb-1 mt-2 text-xs border-b pb-2 last:border-0 items-center font-semibold">
            <div class="col-span-6 lg:col-span-4 flex flex-row pl-6">
              Название
            </div>
            <div>
              Цена, руб.
            </div>
            <div class="w-20 text-center">
              Кол-во
            </div>
            <div class="text-right">Остаток</div>
          </div>

          <!-- row -->
          <div v-for="(item, index) in cart"
            class="grid grid-cols-7 mb-1 text-sm border-b py-1 last:border-0 items-center" :class="{
              highlight: highlightIndex === index,
              'bg-red-50 rounded-md': item.available < item.qty
            }">
            <div class="col-span-6 lg:col-span-4">
              <div class="flex flex-row">
                <button class="text-xs text-red-500 mr-4" @click="cart.splice(index, 1)">
                  <i class="fas fa-times"></i>
                </button>
                <div>
                  <span class="font-semibold">{{ item.name }}</span>
                  <div class="text-gray-500 text-xs" v-if="$dept.isVat">{{ item.attr }} ндс: {{ item.nds }}</div>
                </div>
              </div>
              <!-- lots -->
              <div v-if="item.lots && item.lots.length > 0"
                class="bg-white px-4 flex flex-row flex-wrap py-2 opacity-60 hover:opacity-100 transition-opacity">
                <div v-for="(lot, lotindex) in item.lots"
                  class="rounded-md border px-2 py-0.5 text-sm flex flex-row mb-0.5 mr-0.5 items-center">
                  <button v-if="item.lots.length > 1" class="text-yellow-500 text-xs mr-1"
                    @click="removeLot(item, lotindex)">
                    <i class="fas fa-times text-red"></i>
                  </button>
                  <div class="text-xs font-semibold font-mono">{{ lot.lot }}</div>
                  <div class="text-xs bg-gray-200 rounded-md px-1 py-0.5 ml-1 font-mono">
                    {{ lot.date }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-4 lg:col-span-1 font-semibold">
              {{ item.price | money }}
            </div>
            <div class="md:w-20 col-span-2 lg:col-span-1 flex flex-row items-center">
              <input type="number" :disabled="item.lots.length > 0" class="w-full px-2 py-1 text-center"
                v-model="item.qty" />
            </div>
            <div class="text-right">
              <span v-if="item.available > 0">{{ item.available }}</span>
              <i class="fas fa-circle text-red-500" v-if="item.available < item.qty"></i>
            </div>
          </div>
        </div>

        <div class="mt-4">
          <textarea class="w-full" placeholder="Комментарий к заказу"></textarea>
        </div>
      </div>
      <!-- Sub total -->
      <div class="mt-2 h-full md:mt-0 md:w-1/3">
        <div class="rounded-lg border bg-white px-6 py-2 text-sm mb-2 relative">
          <div class="absolute -top-2 text-xs bg-gray-100 text-gray-500 px-1 rounded-md">
            Клиент
          </div>
          <auto-suggest styles="w-full mt-2" placeholder="9110002200" v-model="client.phone" :component="'Customer'"
            :field="'phone'" :focus="false" v-on:select="customerFound" v-if="!client.id" :addnew="true">
            <template v-slot:default="slotProps">
              <div>
                <span class="text-sm font-normal">{{ slotProps.item.lastname }} {{ slotProps.item.firstname }}</span>
                <div class="text-xs">{{ slotProps.item.phone }} {{ slotProps.item.email }}</div>
              </div>
            </template>
          </auto-suggest>
          <div v-if="client.id" class="mt-1 relative">
            <button @click="viewCustomer(client.id)" class="font-bold text-blue-600 text-left">
              <i class="fas fa-user mr-2 text-gray-500"></i>
              {{ client.firstname }} {{ client.lastname }}
            </button>
            <div class="text-xs text-gray-500 pl-6">{{ client.phone }}</div>
            <div class="text-xs text-gray-500 pl-4">{{ client.email }}</div>
            <button class="absolute right-0 top-1" @click="client = {}">
              <i class="fas fa-times text-red-500"></i>
            </button>
          </div>
        </div>

        <!-- add discount -->
        <div class="rounded-lg border bg-white px-6 py-2 text-sm mb-2 mt-2 relative">
          <div class="absolute -top-2 text-xs bg-gray-100 text-gray-500 px-1 rounded-md">
            Скидка
          </div>
          <div>
            <input type="text" v-model="discount" class="w-full" @change="checkDiscountValue()" />
          </div>
        </div>
        <!-- end -->

        <div class="rounded-lg border bg-white px-6 py-2 text-sm mb-2 mt-2 relative">
          <div class="absolute -top-2 text-xs bg-gray-100 text-gray-500 px-1 rounded-md">
            <i class="fas fa-truck-fast mr-2 text-gray-500"></i> Номер экспресс заказа
          </div>
          <div>
            <input type="text" v-model="expressOrderNumber" class="w-full" />
          </div>
        </div>

        <div class="rounded-lg border bg-white p-6" v-if="cart.length > 0">
          <div class="mb-2 flex justify-between text-sm">
            <p class="text-gray-700">Товары</p>
            <p class="text-gray-700">{{ total | money }}</p>
          </div>
          <div class="mb-2 flex justify-between text-sm" v-if="discountValue > 0">
            <p class="text-gray-700">Скидка ({{ discount.includes("%") ? discount : discount + " руб." }})</p>
            <p class="text-gray-700">{{ discountValue | money }}</p>
          </div>
          <hr class="my-4" />
          <div class="flex justify-between">
            <p class="text-lg font-bold">Итого</p>
            <div class="">
              <p class="mb-1 text-lg font-bold">{{ totalOrderPrice | money }} руб.</p>
            </div>
          </div>
          <button class="btn-action block w-full mt-2" @click="checkout()" v-if="!isExpressOrder"
            :disabled="cart.length == 0 || total <= 0 || !saleEnabled">
            Оплатить
          </button>

          <button class="btn-action block w-full mt-2" @click="checkoutExpressOrder()" v-if="isExpressOrder"
            :disabled="cart.length == 0 && expressOrderNumber == ''">
            <i class="fas fa-truck-fast"></i> Отгрузить заказ
          </button>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductsCatalog from "../shared/ProductsCatalogModal.vue";
import Product from "../../repo/ProductRepo.js";
import AutoSuggest from "../../components/shared/AutoSuggest";
import CheckoutModal from "./CheckoutModal";
import SuccessModal from "./SuccessModal.vue";
import api from "../../api/api.js";
import EditCustomerModal from "../customers/EditCustomerModal.vue";

export default {
  components: { AutoSuggest },
  data() {
    return {
      client: { phone: "" },
      cart: [],
      highlightIndex: false,
      wait: {},
      errors: [],
      key: 0,
      discount: 0,
      expressOrderNumber: ''
    };
  },
  mounted() {
    // 733905577192
  },
  methods: {
    viewCustomer(id) {
      this.$modals.open(EditCustomerModal, { id: id });
    },
    // creaete an order and mark it as paid by express
    checkoutExpressOrder() {
      this.checkout(true)
    },
    error(err) {
      this.errors.push("product_not_found");

      var audio = new Audio("audio_file.mp3");
      audio.play();
    },
    isError(error) {
      return this.errors.indexOf(error) != -1;
    },
    async customerFound(customer) {
      this.client = customer;
    },
    async addProductFromBarcode(product) {
      this.addToCart(product);
    },
    addToCart(product) {
      if (!product) {
        return;
      }
      let vm = this;
      let newCartItem = {
        product_id: product.id,
        name: product.name,
        attr: product.attr,
        price: product.salePrice,
        qty: 0,
        barcode: product.barcode,
        available: product.quantity,
        lots: [],
        nds: product.nds
      };

      console.log(newCartItem);

      // check if we have it in cart already
      let foundIndex = this.cart.findIndex(el => el.product_id == newCartItem.product_id);

      if (foundIndex == -1) {
        this.cart.push(newCartItem);
        foundIndex = this.cart.length - 1;
      }

      let cartItem = this.cart[foundIndex];

      if (!cartItem.lots) {
        cartItem.lots = [];
      }

      /**
       * If addable product has lot, need to check is it already exists in this cart item
       * if not, add lot to the same cart item
       * if yes, show the error lot_already_added
       *
       */
      if (product.lot) {
        let isLotAddded = cartItem.lots.findIndex(lot => lot.lot == product.lot.lot) != -1;
        if (isLotAddded) {
          this.failsound();
          setTimeout(function () {
            alert("Такой лот уже добавлен в заказ.");
          }, 100);

          return;
        }

        // add lot
        cartItem.lots.push(product.lot);
      }

      // increment cart item quantity
      cartItem.qty++;
      this.highlightIndex = foundIndex;

      setTimeout(() => {
        vm.highlightIndex = -1;
      }, 300);
    },
    openCatalog(items) {
      let vm = this;
      this.$modals.open(ProductsCatalog, {}, async product => {
        vm.addToCart(product);
      });
    },
    failsound() {
      let audio = new Audio("/sounds/error.wav");
      audio.play();
    },
    removeLot(cartItem, lotIndex) {
      cartItem.lots.splice(lotIndex, 1);
      --cartItem.qty;
    },
    async checkout(expressOrder = false) {
      // check qties
      for (let index in this.cart) {
        let cartItem = this.cart[index];
        if (cartItem.lots && cartItem.lots.length > 0 && cartItem.qty != cartItem.lots.length) {
          alert("Количество товара не совпадает с количеством лотов. Проверьте " + cartItem.name);
          return;
        }
      }

      let vm = this;
      // show checkout modal
      this.$modals.open(CheckoutModal, { isExpressOrder: this.isExpressOrder }, async paymentMethod => {
        // save order
        if (paymentMethod) {
          let { success, msg, data } = await api.post("/sales", {
            cart: this.cart,
            client: this.client,
            frontendTotal: vm.totalOrderPrice,
            paymentMethod: paymentMethod,
            discount: this.discount,
            expressOrderNumber: this.expressOrderNumber
          });

          vm.cart = [];
          vm.client = {};

          vm.key++;

          if (success) {
            vm.showSuccessModal();
            vm.expressOrderNumber = '';
          } else {
            alert(msg);
          }
        }
      });
    },
    showSuccessModal() {
      this.$modals.open(SuccessModal);
      this.discount = 0;
      this.client = {};
    },
    checkDiscountValue() {
      console.log(this.discount);
      // if it has %
      if (this.discount && this.discount.includes("%")) {
        let discountPercent = parseInt(this.discount);
        if (discountPercent > 100 || discountPercent < 0 || discountPercent > 100) {
          this.discount = "0%";
        }
      } else {
        // discount coulnd be more than total
        if (parseFloat(this.discount) > this.total) {
          this.discount = 0;
        }
      }
    }
  },
  computed: {
    isExpressOrder() {
      return this.expressOrderNumber != ''
    },
    total() {
      let total = this.cart.reduce((acc, el) => acc + el.qty * parseFloat(el.price), 0);
      return total > 0 ? total : 0;
    },
    saleEnabled() {
      if (this.cart && this.cart.length > 0) {
        for (let item of this.cart) {
          if (item.available < item.qty || isNaN(parseFloat(item.available))) {
            return false;
          }
        }

        return true;
      }

      return false;
    },
    discountValue() {
      // check that discount is number
      if (this.discount && this.discount.includes("%")) {
        let discountPercent = parseInt(this.discount);
        return (this.total * parseInt(discountPercent)) / 100;
      } else {
        if (!this.discount || isNaN(parseFloat(this.discount))) {
          return 0;
        }

        return parseFloat(this.discount);
      }
    },
    totalOrderPrice() {
      return this.total - this.discountValue;
    }
  }
};
</script>

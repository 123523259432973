<template>
  <tr class="hover:bg-gray-100">
    <td class="p-4 w-4">
      <div class="flex items-center">
        <input id="checkbox-1" aria-describedby="checkbox-1" type="checkbox"
          class="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-cyan-200 h-4 w-4 rounded" />
        <label for="checkbox-1" class="sr-only">checkbox</label>
      </div>
    </td>
    <td class="p-4 flex items-center whitespace-nowrap space-x-6 mr-12 lg:mr-0">
      <img class="h-10 w-10 rounded-full" v-if="user.picture"
        :src="'https://178107.selcdn.ru/monkl-picture/' + user.picture" alt="" />
      <div v-if="!user.picture" class="bg-gray-300 w-10 h-10 rounded-full"></div>

      <div class="text-sm font-normal text-gray-500">
        <div class="text-base font-semibold text-gray-900">{{ user.name }}</div>
        <div class="text-sm font-normal text-gray-500">{{ user.email }}</div>
        <div class="text-sm font-normal text-gray-500 mt-2">
          
          <div class="flex flex-row flex-wrap space-y-1 items-center">
            <i class="fas fa-map-marker-alt text-xs mr-2"></i>
            <span v-for="dept of user.depts" class="mr-2 rounded-md bg-indigo-100 px-2 py-0.5">
              <router-link :to="'/optics/' + dept">{{ dept | toDeptTitle }}</router-link>
            </span>
          </div>
        </div>
      </div>
    </td>
    <td class="p-4 whitespace-nowrap text-base font-medium text-gray-900">
      {{ user.phone }}
    </td>
    <td class="p-4 whitespace-nowrap text-base font-medium text-gray-900">
      <div>{{ user.roles[0] | toRole }}</div>

      <template v-if="user.roles && user.roles.length > 1">
        <span v-for="role of user.roles.slice(1)" class="text-xs text-gray-400">
          {{ role | toRole }}
        </span>
      </template>
    </td>
    <!-- <td class="p-4 whitespace-nowrap text-base font-medium text-gray-900">
      <div v-if="getOptic({ id: user.dep })">
        <div class="pt-1">{{ getOptic({ id: user.dep }).title }}</div>
        <span class="">
          {{ getOptic({ id: user.dep }).addr }}
        </span>
      </div>
    </td> -->
    <td class="p-4 whitespace-nowrap text-base font-normal text-gray-900">
      <div class="flex items-center">
        <div class="h-2.5 w-2.5 rounded-full bg-green-400 mr-2" v-if="user.status == 'active'"></div>
        <div class="h-2.5 w-2.5 rounded-full bg-gray-400 mr-2" v-if="user.status !== 'active'"></div>
        {{ user.status | humanHostStatus }}
      </div>
    </td>
    <td class="p-4 whitespace-nowrap space-x-2">
      <button type="button" @click="$emit('edit', user.id)" data-modal-toggle="user-modal"
        class="text-white bg-indigo-400 hover:bg-indigo-500 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm inline-flex items-center px-3 py-2 text-center">
        <i class="fas fa-cog"></i>
      </button>
    </td>
  </tr>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["user"],
  mounted() { },
  computed: {
    ...mapGetters("optics", { optics: "optics", getOptic: "getById" })
  }
};
</script>

<template>
  <modal :styles="''">
    <h3 class="text-black font-bold text-xl mb-6">
      Редактирование поставщика
    </h3>

    <form class="" @submit.prevent>
      <div class="grid grid-cols-6 gap-6">
        <div class="col-span-4">
          <div class="">
            <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">Название</label>
            <input
              class="w-full "
              id="title"
              name="title"
              type="text"
              required=""
              v-model="supplier.name"
              placeholder="Название"
              aria-label="title"
            />
          </div>
        </div>

        <div class="col-span-2">
          <div class="">
            <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">ИНН</label>
            <input class="w-full" type="text" v-model="supplier.inn" placeholder="ИНН" maxlength="11" />
          </div>
        </div>

        <div class="col-span-3">
          <div class="">
            <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
              Адрес
            </label>
            <input class="w-full" type="text" v-model="supplier.details.address" placeholder="Адрес" />
          </div>
        </div>

        <div class="col-span-3">
          <div class="">
            <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
              Контактное лицо
            </label>
            <input class="w-full" type="text" v-model="supplier.details.contact" placeholder="ФИО" />
          </div>
        </div>

        <div class="col-span-2">
          <div class="">
            <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
              Телефон
            </label>
            <input class="w-full" type="text" v-model="supplier.details.phone" placeholder="Телефон" maxlength="11" />
          </div>
        </div>

        <div class="col-span-3">
          <div class="">
            <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
              Почта для заказов
            </label>
            <input class="w-full" type="email" v-model="supplier.details.email" placeholder="Email" />
          </div>
        </div>

        <div class="col-span-3">
          <div class="">
            <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
              Название шаблона XML
            </label>
            <input class="w-full" type="text" v-model="supplier.details.xml_template" placeholder="test" />
          </div>
        </div>

        <div class="col-span-3">
          <div class="">
            <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
              Идентификатор для XML
            </label>
            <input class="w-full" type="text" v-model="supplier.details.guid" placeholder="" />
          </div>
        </div>

        <div class="col-span-3">
          <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
            Режим работы
          </label>
          <input class="w-full" type="text" v-model="supplier.details.hours" placeholder="9-18 пн-пт" />
        </div>
        <div class="col-span-3">
          <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
            Категория
          </label>
          <select v-model="supplier.category">
            <option value="">Категория</option>
            <option value="lenses">Контактные линзы</option>
            <option valaue="glass-lenses">Очковые линзы</option>
            <option value="frames">Оправы</option>
          </select>
        </div>

        <div class="col-span-6">
          <div class="">
            <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
              Название в XML файлах
            </label>
            <div class="flex flex-row gap-x-1 flex-wrap gap-y-1 rounded-md border border-gray-200 py-1 px-1">
              <div v-for="(tag, index) in supplier.altnames" class="input-tag" :key="index">
                {{ tag }}
                <button class="ml-1" type="button" @click="supplier.altnames.splice(index, 1)">
                  <i class="fas fa-times text-xs"></i>
                </button>
              </div>
              <input type="text" class="py-0.5 px-1 border-0 bg-gray-50 flex-1" placeholder="Введите новый..." @keydown.enter.self="addTag" />
            </div>
          </div>
        </div>

        <div class="col-span-6">
          <div class="">
            <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
              Заметка
            </label>
            <textarea class="w-full" type="text" v-model="supplier.details.comment"></textarea>
          </div>
        </div>
      </div>

      <div class="flex flex-col mt-6">
        <button type="button" @click="save()" :class="{ busy: busy }" :disabled="busy" class="btn-primary-sm">
          Сохранить
        </button>
      </div>
    </form>
  </modal>
</template>

<script>
import Modal from "../modals/Modal.vue";
import ModalMixin from "../modals/modal.mixin";
import api from "../../api/api.js";

export default {
  components: { Modal },
  mixins: [ModalMixin],
  props: ["id"],
  data() {
    return {
      loading: false,
      busy: false,
      status: "",
      supplier: {
        id: "",
        name: "",
        inn: "",
        details: {
          phone: "",
          contact: "",
          address: "",
          comment: "",
          hours: ""
        },
        altnames: []
      }
    };
  },
  mounted() {
    if (this.id) {
      this.getItem();
    }
  },
  methods: {
    addTag(event) {
      // check if such tag not exist yet
      if (event.target.value != "") {
        if (!this.supplier.altnames.includes(event.target.value)) {
          this.supplier.altnames.push(event.target.value);
          event.target.value = "";
        }
      }
    },
    async getItem() {
      this.busy = true;
      let { success, msg, data } = await api.get(`/g/suppliers/${this.id}`);
      this.supplier = data;
      this.busy = false;
    },
    async save() {
      this.busy = true;

      let { success, msg, data } = await api.post("/g/suppliers", this.supplier);

      this.busy = false;

      if (success) {
        this.supplier = data;
        this.status = "save-success";
        this.close();
        this.$events.$emit("reload");

        // update suppliers list in store
        this.$store.dispatch("optics/pullSuppliers");
      } else {
        this.status = msg;
      }
    }
  }
};
</script>

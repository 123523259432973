<template>
  <div>
    <div class="bg-white px-5 py-4 rounded-md">
      <div class="flex flex-row space-x-4 mb-2 text-sm text-gray-400 font-medium lg:w-3/5" v-if="services && services.length > 0">
        <div class="w-3/5">Название услуги</div>
        <div class="w-1/5">Длительность</div>
        <div class="w-1/5">Стоимость</div>
      </div>

      <div class="flex flex-row space-x-4 mb-2 relative  lg:w-3/5" v-for="(service, index) in services" :key="index">
        <input class="w-3/5" type="text" v-model="service.title" placeholder="Название" />

        <select v-model="service.time" class="w-1/5 ">
          <option v-for="(opt, ind) of timeSlots" :key="ind">{{ opt }}</option>
        </select>

        <input class="w-1/5" type="text" v-model="service.price" placeholder="100" />

        <button type="button" @click="removeByIndex(index)" class="absolute -right-5 top-4 text-red-500 text-sm">
          <i class="fas fa-times"></i>
        </button>
      </div>

      <div v-if="services && services.length == 0" class="mb-6">
        <span class="text-sm text-gray-600">Услуг пока нет, добавьте новые.</span>
      </div>

      <button type="button" class="btn-default mt-2" @click="addService()">
        <i class="mr-1 fas fa-plus-circle"></i>
        Добавить услугу
      </button>
      <div class="text-xs mt-2">
        <span class="mr-2">Быстрый доступ:</span>
        <button @click="addService(ex)" v-for="(ex, index) in examples" :key="index" type="button" class="text-blue-600 mr-2">
          {{ ex.title }}
        </button>
      </div>

      <div class="rounded-md mt-8">
        <div class="flex flex-row gap-x-4">
          <label class="block py-3 rounded-md cursor-pointer px-3 " :class="{ 'bg-white ': savingOption == 'update-all' }">
            <span class="text-sm">
              <input type="radio" v-model="savingOption" value="update-all" />
              <span class="font-semibold ml-2">Обновить везде</span>
            </span>

            <div class="text-xs mt-1 text-gray-700">
              Если нужно обновить цены и длительность услуг во всех адресах.
            </div>
          </label>

          <label class="block py-3 cursor-pointer px-3 rounded-md" :class="{ 'bg-white hover:bg-white': savingOption == 'update' }">
            <span class="text-sm">
              <input type="radio" v-model="savingOption" value="update" />
              <span class="font-semibold ml-2">Обновить в {{ $dept.title }} ({{ $dept.addr }})</span>
            </span>

            <div class="text-xs mt-1 text-gray-700">
              Цены и длительность услуг будут обновлены только в текущем адресе.
            </div>
          </label>
        </div>
      </div>

      <error v-if="error">{{ $t(`errors.${error}`) }}</error>
      <success v-if="success">{{ $t(`success.${success}`) }}</success>
      <button type="button" :class="{ busy: busy }" :disabled="busy || services.length == 0" @click="save()" class="btn-primary mt-4">
        Сохранить
      </button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  name: "Services",
  data() {
    return {
      editOpticModal: false,
      editableId: -1,
      busy: false,
      services: [],
      error: "",
      success: false,
      savingOption: "update-all",
      examples: [
        {
          title: "Подбор очков",
          time: "60"
        },
        {
          title: "Подбор линз",
          time: "60"
        },
        {
          title: "Вторичный подбор",
          time: "30"
        },
        {
          title: "Консультация",
          time: "30"
        },
        {
          title: "Осмотр",
          time: "30"
        }
      ]
    };
  },
  mounted() {
    this.getServices();
    console.log(this.$dept);
  },
  methods: {
    async getServices() {
      let { data, success } = await this.$api.call("/services");

      this.services = data;
    },
    addService(ex) {
      if (!ex) {
        ex = { title: "", time: 15 };
      }
      this.services.push(ex);
    },
    removeByIndex(index) {
      if (confirm("Удалить эту услугу?")) {
        if (this.services[index].id) {
          this.$api.call("/services/" + this.services[index].id, { method: "delete" });
          this.getServices();
        } else {
          this.services.splice(index, 1);
        }
      }
    },
    async save() {
      let vm = this;
      vm.busy = true;
      vm.error = "";
      vm.success = false;
      // save each service
      let { success, msg, data } = await vm.$api.post(`/services/bulk?option=${this.savingOption}`, this.services);
      if (success) {
        this.services = data;
        this.success = true;
      } else {
        this.error = msg;
      }
      setTimeout(() => {
        vm.busy = false;
      }, 250);
    }
  },
  computed: {
    ...mapGetters("optics", ["optics"]),
    timeSlots() {
      let slots = [];
      for (let t = 15; t <= 120; ) {
        slots.push(t);
        t = t + 15;
      }

      return slots;
    }
  }
};
</script>

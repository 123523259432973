<template>
    <div>
        <transition name="cmodal" v-if="show">
            <div class="cmodal-mask bg-black bg-opacity-20">
                <div class="backdrop" @click.self="!noclose ? $emit('close') : ''"></div>
                <div class="cmodal-table">

                    <div class="cmodal-wrapper mt-16">
                        <div class="cmodal-container relative w-11/12 md:3/4 lg:w-2/3 mx-auto bg-white py-8 px-8 md:px-12 rounded-lg"  :class="styles">
                            <div class="gback" v-if="busy"></div>
                            <button class="absolute top-4 right-6" @click="$emit('close')" v-if="!noclose" type="button">
                                <i class="fas fa-times"></i>
                            </button>

                            <slot></slot>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        props: ['show', 'busy', 'noclose', 'styles'],
        data() {
            return {}
        },
        mounted() {

            document.addEventListener("keydown", (e) => {
                if (this.show && e.key === 'Escape') {
                    this.$emit('close');
                }
            });

        },
        watch: {
            show: function (newval, oldval) {
                
                if (this.show) {
                    // hide scroll on body
                    document.body.classList.add('noscroll');
                } else {
                    document.body.classList.remove('noscroll');
                }
            }
        },
        methods: {}
    }
</script>

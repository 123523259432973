<template>
  <div class="text-xs bg-green-500 rounded-md px-1.5 py-0.5 text-white font-semibold inline-flex">
    {{ title }}
  </div>
</template>

<script>
export default {
  props: ["title", "index"],
  data() {
    return {};
  },
  mounted() {}
};
</script>

<template>
  <modal :styles="''">
    <h3 class="text-black font-bold text-xl mb-2">
      {{ product.name }}
    </h3>

    <div class="mb-4">
      {{ product.attr }}
    </div>

    <form class="" @submit.prevent="save()">
      <div class="grid grid-cols-4 gap-6">
        <div class="col-span-2">
          <div class="">
            <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
              Штрихкод
            </label>
            <input class="w-full  font-mono  bg-gray-100" type="text" v-model="product.barcode" placeholder="Артикул"
              disabled />
          </div>
        </div>

        <div class="col-span-1">
          <div class="">
            <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
              Цена, руб.
            </label>
            <input class="w-full  font-mono" type="text" required="" v-model="product.price" placeholder="Цена" />
          </div>
        </div>

        <div class="">
          <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
            Тек. остаток, шт.
          </label>

          <div class="relative">
            <input class="w-full font-mono" type="number" required="" v-model="product.quantity" placeholder="100"
              disabled />
            <template v-if="$requireRole('manager')">
              <button type="button" @click="changeQty()" class="btn-link btn-xs absolute right-0 top-2.5"
                v-if="!product.lots || product.lots.length === 0">
                <i class="fas fa-pen mr-1"></i>
              </button>
            </template>
          </div>
        </div>

        <div class="col-span-2">
          <div class="">
            <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
              Место хранения
            </label>
            <input class="w-full  font-mono" type="text" v-model="product.storeNumber" placeholder="Место хранения" />
          </div>
        </div>
      </div>

      <div class="mt-4">
        <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
          Лоты
        </label>
        <div class="border rounded-md overflow-hidden">
          <div class="grid grid-cols-2 border-b text-xs py-1 bg-gray-50 font-semibold">
            <div class="px-4 border-r">Код лота</div>
            <div class="px-4">Срок годности</div>
          </div>
          <div class="grid grid-cols-2 border-b text-sm last:border-0 py-0.5" v-for="item in product.lots">
            <!-- print -->
            <div class="border-r px-4">{{ item.lot }}</div>
            <div class="px-4">{{ item.date }}</div>
          </div>
        </div>

        <button type="button" @click="addLot()" class="btn-link btn-xs mt-2">
          <i class="fas fa-pen-square mr-1"></i>
          Редактировать лоты
        </button>
      </div>

      <div class="flex flex-col mt-6">
        <button type="submit" :class="{ busy: busy }" :disabled="busy" class="btn-primary-sm">
          Сохранить
        </button>
      </div>
    </form>
  </modal>
</template>

<script>
import Modal from "../modals/Modal.vue";
import ModalMixin from "../modals/modal.mixin";
import Product from "../../repo/ProductRepo";
import Category from "../../repo/CategoryRepo";
import Brand from "../../repo/BrandRepo";
import AutoSuggest from "../shared/AutoSuggest.vue";
import { ndsOptions } from "../../data/consstants.js";
import AddProductLotModal from "./AddProductLotModal.vue";
import ChangeStockQty from "./ChangeStockQty.vue";

export default {
  components: { Modal, AutoSuggest },
  mixins: [ModalMixin],
  props: ["id"],
  data() {
    return {
      loading: false,
      busy: false,
      status: "",
      ndsOptions: ndsOptions,
      product: {
        id: "",
        name: ""
      },
      updated: false
    };
  },
  mounted() {
    if (this.id) {
      this.getProduct();
    }
  },
  methods: {
    changeQty() {
      let vm = this;
      this.$modals.open(ChangeStockQty, { product: this.product }, async updated => {
        if (updated) {
          this.updated = true;
          vm.getProduct();
          this.$events.$emit("reload");
        }
      });
    },
    addLot() {
      let vm = this;
      this.$modals.open(AddProductLotModal, { product: this.product }, async updated => {
        if (updated) {
          this.updated = true;
          vm.getProduct();
          this.$events.$emit("reload");
        }
      });
    },
    addPicture() {
      alert("in progress...");
    },
    async getProduct() {
      this.busy = true;
      let { success, msg, data } = await Product.get(this.id);
      this.product = data;
      this.busy = false;
    },
    async save() {
      this.busy = true;

      let { success, msg, data } = await Product.update(this.product);
      this.updated = true;

      this.busy = false;

      if (success) {
        this.product = data;
        this.status = "save-success";
        this.close(this.updated);
        this.$events.$emit("reload");
      } else {
        this.status = msg;
      }
    }
  }
};
</script>

<template>
  <page>
    <template v-slot:header>
      Справочник товаров
    </template>
    <template v-slot:actions>
      <button @click="addNew()" class="text-sm font-medium bg-indigo-400 text-white rounded-md px-4 py-2 ml-6">
        <i class="fas fa-plus-circle mr-1"></i>
        Добавить товар
      </button>
    </template>

    <!-- Filters, collapsable -->
    <template v-slot:content>
      <form @submit.prevent="doFilter()" class="sticky top-0">
        <div class="filters mb-4 flex flex-row flex-wrap items-center gap-4 bg-white px-4 py-2 rounded-md">
          <div class="mr-8 text-sm whitespace-nowrap">
            Всего:
            <span class="font-semibold">{{ total }}</span>
          </div>

          <div>
            <input type="text" placeholder="Поиск по артикулу, штрихкоду или названию" v-model="filter.term" />
          </div>
          <!-- <div>
            <select v-model="filter.category">
              <option value="">Категория</option>
              <option v-for="cat in categories" :value="cat.name">{{ cat.name }}</option>
            </select>
          </div>
          <div>
            <select v-model="filter.brand">
              <option value="">Бренд</option>
              <option v-for="cat in brands" :value="cat">{{ cat }}</option>
            </select>
          </div>
          <div>
            <select v-model="filter.supplier_id">
              <option value="">Поставщик</option>
              <option v-for="sup in suppliers" :value="sup.id">{{ sup.name }}</option>
            </select>
          </div>
          <div>
            <div class="grid grid-cols-2 gap-1">
              <input type="text" placeholder="Цена от" v-model="filter.price.start" />
              <input type="text" placeholder="Цена до" v-model="filter.price.end" />
            </div>
          </div> -->
          <div>
            <button :class="{ busy: filterBusy }" class="px-4 py-2 bg-blue-500 text-white rounded-md"
              @click="doFilter(true)">
              Искать
            </button>
          </div>
        </div>
      </form>

      <div v-if="total > 0" class="mt-4 mb-4 flex justify-between">
        <div>
          <template v-for="page of generatePageRange()">
            <button v-if="page != '...'" class="rounded-md w-8 h-8 mr-1 text-sm bg-white" @click.prevent="setPage(page)"
              :key="page" :class="{ 'bg-blue-600 text-white': page == currentPage }">
              {{ page }}
            </button>

            <span v-if="page == '...'" class="mr-1">
              ...
            </span>
          </template>
        </div>
        <select v-model="filter.limit" class="py-2">
          <option value="10">10</option>
          <option value="30">30</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>

      <div class="w-full  bg-white rounded-md">
        <table class="data-table w-full">
          <thead>
            <tr class="">
              <th class="">
                <input type="checkbox" @click="toggleCheckboxes($event)" />
              </th>
              <th class="">Название ↓</th>
              <th class="">Штрихкод ↓</th>
              <th class="">Прайс ↓</th>
              <th class="">Категория ↓</th>
              <th class="">Бренд ↓</th>
              <th class="">Поставщики ↓</th>
            </tr>
            <!-- <tr class="text-xs">
              <td colspan="6"></td>
              <td>qty</td>
              <td>b</td>
              <td>m2</td>
              <td>m22</td>
            </tr> -->
          </thead>
          <tbody class="">
            <tr v-for="p in products" class="">
              <td class="">
                <input type="checkbox" class="w-3 h-3" v-model="checkedItems" :value="p.id" />
              </td>
              <td>
                <div class="flex py-1 flex-row items-center gap-4">
                  <img :src="p.image" class="w-8 h-8 object-cover rounded-md" v-if="p.image" />
                  <div class="w-6 h-6 bg-gray-200 text-center rounded-sm" v-else>
                    <i class="fas fa-image mt-1.5"></i>
                  </div>

                  <button @click="edit(p.id)" class="btn-link">
                    <span>{{ p.name || "no title" }}</span>
                    <div class="text-xs text-gray-500">{{ p.attr }}</div>
                  </button>
                </div>
              </td>
              <td>
                <div class="flex flex-col">
                  <div v-if="p.article">{{ p.article }}</div>
                  <div class="whitespace-nowrap font-mono" v-if="p.barcode">
                    <i class="fas fa-barcode"></i>
                    {{ p.barcode }}
                  </div>
                </div>
              </td>
              <td class="text-right font-mono">{{ p.base_price }}</td>
              <td>{{ p.category }}</td>
              <td>{{ p.brand }}</td>
              <td>
                <div v-if="p.suppliers && p.suppliers.length > 0" class="text-xs">
                  <div v-for="(id, index) in p.suppliers">{{ supplierDetails(id).name }}</div>
                </div>
              </td>

              <!-- <td>{{ p.quantity }}</td>
              <td>{{ p.balance }}</td>
              <td>{{ p.balance_m2 }}</td>
              <td>{{ p.balance_m22 }}</td> -->
              <!-- <td>
                <span
                  class="px-2 py-1 font-semibold text-xs leading-tight text-green-500 bg-green-100 rounded-sm"
                >
                  Cтатус
                </span>
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>

      <div class="sticky mt-2 bottom-2 w-full " v-if="checkedItems.length">
        <div class="rounded-md bg-gray-600 shadow-2xl px-8 py-3 space-x-2  flex items-center flex-row">
          <div class=" mr-4 text-white text-sm">
            Выбрано
            <strong>{{ checkedItems.length }}</strong>
          </div>
          <button class="btn-primary-sm" @click="bulkEdit()">
            <i class="fas fa-pen-square mr-2"></i>
            Редактировать
          </button>
          <button class="btn-primary-sm">
            <i class="fas fa-print mr-2"></i>
            Напечатать ценники
          </button>
          <!-- <button class="px-4 py-2 text-sm bg-white text-black rounded-md">
            <i class="fas fa-print mr-2"></i>
            Напечатать ценники
          </button>
          <button class="px-4 py-2 text-sm bg-white ml-2 text-black rounded-md" disabled>
            <i class="fas fa-file-csv mr-2"></i>
            Экспорт в CSV
          </button> -->
        </div>
      </div>
    </template>
  </page>
</template>
<script>
import EditProductModal from "./products/EditProductModal.vue";
import BulkProductsEdit from "./products/BulkProductsEdit.vue";

import api from "../api/api.js";

import { mapGetters } from "vuex";
import Page from "./Page.vue";
import productsJson from "../../products.json";
import Info from "./shared/Info.vue";

//import Product from "../repo/ProductRepo";
import Category from "../repo/CategoryRepo";
import Brand from "../repo/BrandRepo";

export default {
  components: { Page, Info, EditProductModal, BulkProductsEdit },
  name: "global-products",
  data() {
    return {
      editProductModal: false,
      editableId: -1,
      products: [],
      categories: [],
      brands: [],
      filterBusy: false,
      filter: {
        term: "",
        category: "",
        brand: "",
        price: { start: "", end: "" },
        limit: 50,
        page: 1
      },
      currentPage: 1,
      total: 0,
      checkedItems: [],
      suppliers: []
    };
  },
  watch: {
    "filter.limit": function (oldval, newval) {
      this.doFilter();
    }
  },
  mounted() {
    this.doFilter();

    let vm = this;

    // load categories
    this.getCategories();
    this.getBrands();
    this.getSuppliers();

    // subscribe to product save event
    this.$events.$on("reload", function () {
      vm.doFilter();
    });
  },
  methods: {
    /**
     * Edit prroducts
     */
    bulkEdit() {
      this.$modals.open(BulkProductsEdit, { ids: this.checkedItems });
    },
    async getSuppliers() {
      let { success, msg, data } = await api.get("/g/suppliers");
      if (success) {
        this.suppliers = data.suppliers;
      }
    },
    async getBrands() {
      let { success, msg, data } = await Brand.getGlobal();
      this.brands = data.filter((val, index, self) => {
        return self.indexOf(val) === index && val != "";
      });
    },
    // get caegories
    async getCategories() {
      let { success, msg, data } = await Category.get();
      this.categories = data;
    },
    async doFilter(btnClicked) {
      if (btnClicked) {
        this.setPage(1);
        return;
      }
      let vm = this;
      this.filterBusy = true;
      //let { success, msg, data } = await Product.filter(this.filter);
      this.filter.term = this.filter.term.trim();
      let query = encodeURIComponent(JSON.stringify(this.filter));
      let { success, msg, data } = await api.call(`/g/products?filter=${query}`, {
        method: "get"
      });
      this.products = data.products;
      this.total = data.total;
      setTimeout(function () {
        vm.filterBusy = false;
      }, 500);
    },
    addNew() {
      this.$modals.open(EditProductModal);
    },
    edit(id) {
      this.$modals.open(EditProductModal, { id: id });
    },
    toggleCheckboxes($event) {
      let items = document.querySelectorAll('input[type="checkbox"]');
      for (let el of items) {
        if (($event.target.checked && !el.checked) || (!$event.target.checked && el.checked)) {
          el.click();
        }
      }
    },
    setPage(page) {
      this.currentPage = parseInt(page);
      this.filter.page = page;
      this.doFilter();
    },
    generatePageRange(delta = 4) {
      // creates array with base 1 index
      let currentPage = parseInt(this.currentPage);
      let lastPage = Math.ceil(this.total / this.filter.limit);

      const range = Array(lastPage)
        .fill()
        .map((_, index) => index + 1);

      return range.reduce((pages, page) => {
        // allow adding of first and last pages
        if (page === 1 || page === lastPage) {
          return [...pages, page];
        }

        // if within delta range add page
        if (page - delta <= currentPage && page + delta >= currentPage) {
          return [...pages, page];
        }

        // otherwise add 'gap if gap was not the last item added.
        if (pages[pages.length - 1] !== "...") {
          return [...pages, "..."];
        }

        return pages;
      }, []);
    },
    supplierDetails(id) {
      let sup = this.suppliers.find(el => el.id == id);
      if (sup != undefined) {
        return sup;
      }

      return {
        name: ""
      };
    }
  },
  computed: {
    //...mapGetters("products", ["products"])
    //...mapGetters("optics", ["suppliers"])
  }
};
</script>

<template>
  <page :notitle="true">
    <template v-slot:content>
      <div v-if="!$dept.id">
        <p>
          Оптика не выбрана, выберите.
          <a href="/optics" class="text-blue-500">Список оптик</a>
        </p>
      </div>

      <mnkl-calendar/>
    </template>
  </page>
</template>

<script>
import { mapGetters } from "vuex";
import PageHeader from "./shared/PageHeader.vue";
import PageContent from "./shared/PageContent.vue";
import EditCalendarEventModal from "./calendar/EditCalendarEventModal.vue";
import EventSlot from "./calendar/EventSlot.vue";
import EditCustomerModal from "./customers/EditCustomerModal.vue";
import MnklCalendar from './schedule/MnklCalendar.vue'

import "@fullcalendar/core/vdom"; // solves problem with Vite

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import ruLocale from "@fullcalendar/core/locales/ru";

import EditRecordModal from "./schedule/EditRecordModal.vue";
import Record from "../repo/RecordRepo";

export default {
  name: "calendar",
  components: { PageHeader, PageContent, EditCalendarEventModal, EventSlot, MnklCalendar },
  data() {
    return {
      selectedDate: new Date(),
      editDayModal: false,
      page: {},
      userEvents: [],
      calendarOptions: {
        businessHours: {},
        height: "auto",
        selectable: true,
        slotDuration: "00:15:00",
        slotLabelInterval: "01:00",
        events: this.events,
        locale: "ru",
        firstDay: 1,
        allDaySlot: false,
        slotMinTime: "06:00",
        slotMaxTime: "24:00",
        nowIndicator: true,
        buttonText: {
          today: "Сегодня",
          month: "Месяц",
          week: "Неделя",
          day: "День"
        },
        headerToolbar: {
          right: "prev,next,today",
          center: "title",
          left: "dayGridMonth,timeGridWeek,timeGridDay"
        },
        dateClick: this.showDay,
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
        initialView: "timeGridWeek",
        datesSet: this.reloadCalendarEvents,
        eventClick: this.edit
      },
      calendar: "",
      range: { start: "", end: "" },
      backgroundEvents: [],
      recordEvents: [],
      disableReRender: false,
      hosts: []
    };
  },
  async mounted() {
    // if (!this.active.id) return;

    // let calendarApi = this.$refs.fullCalendar.getApi();
    // this.calendar = calendarApi;

    // this.range.start = this.$dayjs(this.calendar.view.activeStart);
    // this.range.end = this.$dayjs(this.calendar.view.activeEnd);

    // this.getWorkingEvents();
    // this.loadRecords();

    // this.$events.$on("record-reload", () => {
    //   this.loadRecords();
    // });
  },
  methods: {
    createCustomer() {
      this.$modals.open(EditCustomerModal, { id: "", opticId: this.$dept.id, showSearch: true });
    },
    async reloadCalendarEvents(date) {
      console.log("date changed");

      if (this.calendar) {
        this.disableReRender = true;

        let oldRange = { ...this.range };

        this.range.start = this.$dayjs(date.startStr);
        this.range.end = this.$dayjs(date.endStr);

        console.log(this.range.start.format("YYYY MM DD"));
        console.log(oldRange.start.format("YYYY MM DD"));
        console.log(oldRange.start.format("YYYY MM DD") == this.range.start.format("YYYY MM DD"));

        if (oldRange.start.format("YYYY MM DD") != this.range.start.format("YYYY MM DD")) {
          console.log("not the same");
          this.getWorkingEvents();
          this.loadRecords();
        }
      }
    },
    // add a new row to clicked date
    showDay(day) {
      //this.editDayModal = true;
      this.selectedDate = this.$dayjs(day.dateStr);

      // if this date is not disabled
      let aDay = this.$dayjs(day.date).day();
      // is address work at this day?
      aDay = aDay == 0 ? 6 : aDay - 1;
      let isAvailable = this.$dept.workingHours[aDay][0] != -1;
      // get hosts for this day
      // filter hosts for selected date
      if (isAvailable) {
        let hosts = this.hosts.filter(
          host => host.date == this.selectedDate.utc().format("YYYY-MM-DD")
        );

        this.$modals.open(EditRecordModal, { id: "", date: this.selectedDate, hosts: hosts });
      }
    },
    async loadRecords() {
      //this.$store.dispatch("records/pull", { opticId: this.active.id });
      // get records for selected date start \ end
      const { data, msg, success } = await Record.get({
        opticId: this.active.id,
        start: this.range.start.format("YYYY-MM-DD"),
        end: this.range.end.format("YYYY-MM-DD")
      });
      // compile to calendar events

      this.recordEvents = data.map(item => {
        let bgColor = "",
          textColor = "";
        switch (item.status) {
          case "new":
            bgColor = "#fff";
            textColor = "#000";
            break;
          case "confirmed":
          case "started":
            bgColor = "#22c55e";
            break;
          case "canceled":
            bgColor = "#64748b";
            break;
          case "completed":
            bgColor = "#3b82f6";
            break;
          case "expired":
            bgColor = "#22c55e";
            break;
        }
        return {
          id: item.id,
          title: item.client.fio,
          start: this.$dayjs
            .unix(item.startTime)
            .utc()
            .format("YYYY-MM-DDTHH:mm"),
          end: this.$dayjs
            .unix(item.endTime)
            .utc()
            .format("YYYY-MM-DDTHH:mm"),
          backgroundColor: bgColor,
          textColor: textColor,
          borderColor: "transparent"
        };
      });

      this.calendarOptions.events = this.events;

      this.disableReRender = true;
    },
    removeRow() {},

    // get available working hours
    async getWorkingEvents() {
      // pull the employee working hours as background events for selected date range
      let r = await this.$schedule.getByDates(
        this.active.id,
        this.range.start.format("YYYY-MM-DD"),
        this.range.end.format("YYYY-MM-DD")
      );
      let events = r.data;

      // group events by date
      let backgroundEvents = [];
      events = events.reduce((acc, item) => {
        acc[item.date] = item;
        return acc;
      }, []);

      // split and compile to one array
      let hosts = [];

      // set red background for days when noone works
      let businessHours = [];
      for (let day in this.$dept.workingHours) {
        let h = this.$dept.workingHours[day];

        if (h[0] !== -1) {
          let obj = {
            daysOfWeek: [parseInt(day) + 1]
          };

          obj.startTime = h[0] + ":00";
          obj.endTime = h[1] + ":00";
          businessHours.push(obj);
        }
      }

      this.calendarOptions.businessHours = businessHours;
      this.calendarOptions.businessHours = businessHours;

      let min = "24:00",
        max = "00:00";
      for (let date in events) {
        for (let slot of events[date].slots) {
          let obj = {
            title: this.getUserById({ id: slot.userId }).name,
            start: date + "T" + slot.timeStart,
            end: date + "T" + slot.timeStop,
            display: "background"
          };

          // only if address work at this day
          // get day 0 - is Sunday, 6 - Sat, 1 - Mon
          let day = this.$dayjs(date).day();
          // is address work at this day?
          day = day == 0 ? 6 : day - 1;
          let isAvailable = this.$dept.workingHours[day][0] != -1;

          if (isAvailable) {
            backgroundEvents.push(obj);
          }

          this.hosts.push({
            name: this.getUserById({ id: slot.userId }).name,
            id: slot.userId,
            date: date,
            services: this.getUserById({ id: slot.userId }).services.filter(
              serv => serv.available == true
            )
          });

          if (hosts.findIndex(item => item.id == slot.userId) == -1) {
            // hosts.push({
            //   id: slot.userId,
            //   name: obj.title
            // });
          }
          if (min > slot.timeStart) min = slot.timeStart;
          if (slot.timeStop > max) max = slot.timeStop;
        }
      }

      this.calendarOptions.slotMinTime = min;
      this.calendarOptions.slotMaxTime = max;

      // get the minimum when they start working
      this.backgroundEvents = backgroundEvents;
      //this.calendarOptions.events = this.backgroundEvents;

      // if couple of users has the same time, place them one after another
      let sortedEvents = [];

      backgroundEvents.map(item => {
        // if this event has the same start, append the title to it
        let evtIndex = sortedEvents.findIndex(s => s.start == item.start && s.title != item.title);
        if (evtIndex !== -1) {
          // append title
          sortedEvents[evtIndex].title += ", " + item.title;
          item.title = "";
        } else {
          sortedEvents.push(item);
        }

        return item;
      });

      this.calendarOptions.events = this.events;
    },
    edit(el) {
      this.$modals.open(EditRecordModal, {
        id: el.event ? el.event.id : "",
        date: el.event && el.event.startStr ? el.event.startStr : new Date()
      });
    }
  },
  computed: {
    ...mapGetters("optics", ["active"]),
    ...mapGetters({ users: "users", getUserById: "users/getById" }),
    services() {
      return this.active.services;
    },
    events() {
      // merge background events and client events
      return this.recordEvents.concat(this.backgroundEvents);
    }
  }
};
</script>

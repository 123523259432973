<template>
  <div class="flex bg-yellow-100 rounded-lg p-4 mb-4 text-sm text-yellow-700 mt-4" role="alert">
    <i class="fas fa-exclamation-triangle mr-4 mt-2"></i>
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "alert"
};
</script>

<template>
  <page>
    <template v-slot:header>
      Текущий баланс
    </template>
    <template v-slot:actions>

      <button @click="depositCash()" class="btn-action-sm mr-2 ml-4">
        <i class="fas fa-plus mr-1"></i>
        Внести
      </button>
      <button @click="withdrawCash()" class="btn-action-sm mr-2 ml-4" v-if="stat.cash > 0">
        <i class="fas fa-minus mr-1"></i>
        Получить
      </button>
    </template>

    <template v-slot:right>
    </template>
    <!-- Filters, collapsable -->
    <template v-slot:content>

      <div class="grid grid-cols-4 gap-4 mb-6">
        <div class="info-box flex flex-row items-center justify-between">
          <div>
            Наличные <popover>Текущее количество наличных денег в кассе.</popover>
            <span v-if="stat.cash">{{ stat.cash | money }}</span>
            <span v-else>0</span>
          </div>
          <div class="" v-if="stat.cash">
            <button @click="addNewCollect()"
              class="text-blue-500 text-sm font-medium bg-gray-100 rounded-md transition-colors hover:text-blue-600 px-1 hover:bg-gray-200 py-0.5">
              <i class="fas fa-cash-register"></i> Провести инкассацию
            </button>
          </div>
        </div>
      </div>

      <!-- collect table -->
      <div class="py-4" v-if="collectItems.length > 0">
        <h2 class="font-medium text-base mb-2">Активные инкассации</h2>
        <loading v-if="collectBusy" />
        <div class="grid grid-cols-3 gap-4">
          <div v-for="item in collectItems" class="bg-white rounded-md px-4 py-2">

            <div class="flex flex-row items-center justify-between">
              <div class="flex flex-col space-y-2">
                <div class="text-sm  text-gray-500"><i class="fas fa-calendar text-gray-400 text-xs mr-1"></i> {{
                  item.created_at | datetime }}</div>

                <div>
                  <span class="badge gray" v-if="item.status == 'pending'">В ожидании</span>
                  <span class="badge blue" v-if="item.status == 'in_progress'">В процессе</span>
                </div>

                <div>
                  <user-card :id="item.user_id" />
                </div>
              </div>
              <div class="text-right flex flex-col space-y-2">
                <div class="text-lg font-mono font-bold">{{ item.amount | money }}</div>

                <button @click="processCollect(item)" v-if="item.status == 'pending'"
                  class="text-blue-500 text-sm font-medium bg-gray-100 rounded-md transition-colors hover:text-blue-600 px-1 hover:bg-gray-200 py-0.5">
                  <i class="fas fa-cash-register"></i> Передать инкассатору
                </button>

                <button @click="completeCollect(item)" v-if="item.status == 'in_progress'"
                  class="text-blue-500 text-sm font-medium bg-gray-100 rounded-md transition-colors hover:text-blue-600 px-1 hover:bg-gray-200 py-0.5">
                  <i class="fas fa-cash-register"></i> Подтвердить
                </button>

              </div>

            </div>
          </div>
        </div>
      </div>




      <div class="py-4">

        <div class=" flex flex-row items-center space-x-4 mb-3">
          <div>
            <h2 class="font-medium text-base">Операции за период</h2>
          </div>
          <div class="flex flex-row items-center">
            <div class="text-sm mr-3"><i class="fas fa-calendar text-gray-400"></i></div>
            <date-range-picker ref="picker" :locale-data="localeData" :maxDate="new Date()" v-model="dateRange"
              :ranges="getRanges()" class="" @update="pullTransactions()">
              <template v-slot:input="picker" style="min-width: 350px;">
                {{ picker.startDate | rdate }} - {{ picker.endDate | rdate }}
              </template>
            </date-range-picker>
            <select v-model="filter.trType" class="ml-3 text-sm py-1 border-gray-300" @change="pullTransactions()">
              <option value="">Все операции</option>
              <option value="cash">Наличные</option>
              <option value="credit_card">Безналичные</option>
              <option value="collect">Инкассация</option>
            </select>
          </div>

        </div>
        <!-- stat -->
        <div class="grid grid-cols-3 xl:grid-cols-5 space-x-4 mb-2">
          <div class="small-infobox">
            Безналичные: <span>{{ rangeStat.credit_card | money }} руб.</span>
          </div>
          <div class="small-infobox">
            Наличные: <span>{{ rangeStat.cash | money }} руб.</span>
          </div>
          <!-- <div class="small-infobox">
            Продаж: <span>{{ rangeStat.sales }} руб.</span>
          </div>
          <div class="small-infobox">
            Отмены: <span>{{ rangeStat.cancels }} руб.</span>
          </div> -->
          <div class="small-infobox">
            Инкассации: <span>
              {{ rangeStat.collects | money }}
              руб.</span>
          </div>
        </div>

        <div>
          <loading v-if="filterBusy" />
          <table class="w-full data-table">
            <thead class="">
              <tr class="">
                <th class="w-150px">Дата</th>
                <th class="w-150px">Сумма</th>
                <th class="w-150px">Статус</th>
                <th class="">Категория</th>
                <th class="">Сотрудник</th>
                <th class="">Комментарий</th>
                <th class="w-150px">Заказ</th>
                <th class="">Комиссия</th>
                <!-- <th class="">Статус ↓</th> -->
              </tr>
            </thead>
            <tbody class="">
              <tr v-for="item in transactions" :key="item.id">
                <td class="">{{ item.created_at | datetime }}</td>
                <td class="font-semibold">
                  <template v-if="['completed', 'refunded'].indexOf(item.status) > -1">
                    <span :class="item.amount < 0 ? 'text-red-500' : 'text-green-500'">
                      {{ item.amount | money }}
                    </span>
                  </template>
                  <template v-else>
                    <span class="text-gray-500"><i class="fas fa-clock text-xs"></i> {{ item.amount | money }}</span>
                  </template>
                </td>
                <td>
                  <span class="badge gray" v-if="item.status == 'pending'">В ожидании</span>
                  <span class="badge blue" v-if="item.status == 'in_progress'">В процессе</span>
                  <span class="badge green" v-if="item.status == 'completed'">Выполнена</span>
                  <span class="badge red" v-if="item.status == 'refunded'">Возврат</span>
                </td>
                <td class="">
                  <div v-if="item.type == ''">
                    <span v-if="item.merchant.type == 'cash'">Наличные</span>
                    <span v-if="item.merchant.type == 'credit_card'">Кредитная карта</span>
                    ({{ item.merchant.title }})
                    <span class="text-2xs rounded-md bg-blue-100 px-1 py-0.5 font-semibold">
                      {{ `${item.merchant.fee}%` || "" }}
                    </span>
                  </div>
                  <div v-else>
                    <span v-if="item.type == 'collect'">Инкассация</span>
                    <span v-if="item.type == 'deposit-cash'">Внесение</span>
                    <span v-if="item.type == 'withdraw-cash'">Выдача</span>
                  </div>
                </td>
                <td class=""><user-card :id="item.user_id" /></td>
                <td class="">
                  <div class="text-xs">
                    {{ item.note }}
                  </div>
                </td>
                <td class="">
                  <sale-number :sale="{ id: item.sale_id }" v-if="item.sale_id" />
                </td>
                <td class="font-semibold">
                  <span v-if="item.fee">{{ item.fee | money }}</span>
                  <span v-else>0</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="mt-2 px-4">
          <pagination :total="filter.total" :limit="filter.limit" :items="transactions" :name="'transactions'"
            @limit="limit => ((filter.limit = limit))" @page="page => ((filter.page = page), pullTransactions())" />
        </div>
      </div>

      <div v-if="transactions.length == 0" class=" text-gray-400 px-4 py-10 text-center text-lg">
        Ничего не найдено
      </div>
    </template>
  </page>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import dayjs, { utc } from "dayjs";
import AddTransactionModal from './AddTransactionModal.vue'

export default {
  name: "t",
  components: {
    DateRangePicker, AddTransactionModal
  },
  data() {
    return {
      filterBusy: false,
      transactions: [],
      localeData: {
        direction: "ltr",
        format: "dd/mm/yyyy",
        separator: " - ",
        applyLabel: "Сохранить",
        cancelLabel: "Отмена",
        weekLabel: "Нед",
        customRangeLabel: "Custom Range",
        daysOfWeek: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
        monthNames: ["Янв", "Фев", "Мар", "Апр", "Май", "Июнь", "Июль", "Авг", "Сент", "Окт", "Ноя", "Дек"],
        firstDay: 1
      },
      dateRange: { startDate: null, endDate: null },
      filter: {
        startDate: new Date(),
        endDate: new Date(),
        type: "", // cash, credit_card, qr_code
        total: 0,
        limit: 10,
        page: 1,
        trType: "",
      },
      stat: {
        balance: 0,
        cash: 0,
        nonCash: 0
      },
      collectItems: [],
      collectBusy: false,
      rangeStat: {
        cash: 0,
        credit_card: 0,
        sales: 0,
        cancels: 0,
        collects: 0
      }
    };
  },
  mounted() {
    let vm = this;
    this.dateRange = {
      startDate: this.getRanges()["Месяц"][0],
      endDate: this.getRanges()["Месяц"][1]
    };

    this.loadData();

  },
  methods: {
    processCollect(item) {
      let { success, msg, data } = this.$api.post(`/transactions/collect/${item.id}/status/process`);
      this.loadData()
    },
    completeCollect(item) {
      let { success, msg, data } = this.$api.post(`/transactions/collect/${item.id}/status/complete`);
      this.loadData()
    },
    loadData() {
      this.getActiveCollectItems()
      this.getStat();
      this.pullTransactions();
    },
    getRanges() {
      return {
        Сегодня: [new Date(), new Date()],
        Вчера: [
          new Date(
            dayjs()
              .subtract(1, "day")
              .startOf("day")
          ),
          new Date(
            dayjs()
              .subtract(1, "day")
              .endOf("day")
          )
        ],
        Месяц: [
          new Date(
            dayjs()
              .startOf("month")
              .startOf("day")
          ),
          new Date(
            dayjs()
              .endOf("month")
              .endOf("day")
          )
        ],
        "Прошлый месяц": [
          new Date(
            dayjs()
              .subtract(1, "month")
              .startOf("month")
              .startOf("day")
          ),
          new Date(
            dayjs()
              .subtract(1, "month")
              .endOf("month")
              .endOf("day")
          )
        ],
        Год: [
          new Date(
            dayjs()
              .startOf("year")
              .startOf("day")
          ),
          new Date(
            dayjs()
              .endOf("year")
              .endOf("day")
          )
        ]
      };
    },
    depositCash() {
      this.$modals.open(AddTransactionModal, {
        type: 'deposit-cash'
      }, data => {
        if (data) {
          this.loadData();
        }
      })
    },
    withdrawCash() {
      this.$modals.open(AddTransactionModal, {
        type: 'withdraw-cash',
        maxAmount: this.stat.cash
      }, data => {
        if (data) {
          this.loadData();
        }
      })
    },
    async getStat() {

      let { success, msg, data } = await this.$api.get(`/transactions/balance`);
      if (success) {
        // compile stat
        let nonCash = data.find(el => el._id == "credit_card");
        if (nonCash) {
          this.stat.nonCash = nonCash.totalAmount;
        }
        let cash = data.find(el => el._id == "cash");
        if (cash) {
          this.stat.cash = cash.totalAmount;
        }
      }
    },
    async getRangeStat(query) {
      let { success, msg, data } = await this.$api.get(`/transactions/stat?filter=${query}`);

      if (success) {
        this.rangeStat = data;
      }

    },
    /**
     * Pull data from API
     */
    async pullTransactions() {
      this.filterBusy = true;

      this.filter.startDate = this.dateRange.startDate;
      this.filter.endDate = this.dateRange.endDate;

      let query = encodeURIComponent(JSON.stringify(this.filter));
      let { success, msg, data } = await this.$api.get(`/transactions?filter=${query}`);
      if (success) {
        this.transactions = data.items;
        this.filter.total = data.total;
      }

      this.filterBusy = false;

      this.getRangeStat(query);
    },
    async getActiveCollectItems() {

      // it's a transactions type = 'collect';
      this.collectBusy = true;
      let query = encodeURIComponent(JSON.stringify({
        type: "collect",
        status: ['pending', 'in_progress'],
      }));
      let { success, msg, data } = await this.$api.get(`/transactions?filter=${query}`);
      if (success) {
        this.collectItems = data.items;
      }

      this.collectBusy = false;
    },
    /**
     * Create new collect
     * save new transaction with status = 'pending' and type = 'collect'
     */
    async addNewCollect() {
      this.$modals.open(AddTransactionModal, {
        type: 'collect',
        maxAmount: this.stat.cash
      }, data => {
        if (data) {
          this.loadData();
        }
      })
    }


  }
};
</script>

<template>
  <div class="">
    <div class="flex flex-row items-center mb-4 gap-x-4 text-sm">
      <button class="px-2 py-1 rounded-md" @click="category = ''"
        :class="{ 'text-blue-600 bg-blue-100': category == '' }">
        Все
      </button>
      <button class="px-2 py-1 rounded-md" @click="category = 'lenses'"
        :class="{ 'text-blue-600 bg-blue-100': category == 'lenses' }">
        Контактные линзы
      </button>
      <button class="px-2 py-1 rounded-md" @click="category = 'glass-lenses'"
        :class="{ 'text-blue-600 bg-blue-100': category == 'glass-lenses' }">
        Очковые линзы
      </button>
      <button class="px-2 py-1 rounded-md" @click="category = 'frames'"
        :class="{ 'text-blue-600 bg-blue-100': category == 'frames' }">
        Оправы
      </button>
    </div>

    <div v-if="list.length == 0" class="bg-white px-4 py-2 rounded-md">
      В этой категории поставщиков нет.
    </div>

    <div class="w-full text-left bg-white rounded-md" v-if="list.length > 0">
      <div v-if="busy">
        <loading />
      </div>

      <table class="w-full data-table">
        <thead>
          <tr class="">
            <th class=""></th>
            <th class="">Название</th>
            <th class="">
              ID салона
              <popover>
                <img src="/img/help/store_id.png" />
              </popover>
            </th>
            <th class="">
              ID Контрагента
              <popover>
                <img src="/img/help/store_guid.png" />
              </popover>
            </th>
            <th>
              Время заказа
              <popover>
                Время, когда нужно отправлять заявку поставщику
              </popover>
            </th>
            <th>Мин. сумма заказа</th>
            <th>Автозаказ</th>
          </tr>
        </thead>
        <tbody class="" :key="key">
          <tr v-for="(item, index) in sortedSuppliers" class="" :key="item.id">
            <td class="">
              <input type="checkbox" @click.prevent="event => testChecked(event, item)" v-model="item.checked" />
            </td>
            <td>
              {{ item.name }}
              <popover v-if="item.details.phone || item.details.email">
                Чтобы узнать ваши данные для интеграции, обратитесь к поставщику.
                <div>
                  Телефон:
                  <span class="font-semibold text-lg">{{ item.details.phone | VMask("+# (###) ###-##-##") }}</span>
                </div>
                <div v-if="item.details.email">
                  Электронная почта
                  <span class="font-semibold">{{ item.details.email }}</span>
                </div>
              </popover>
            </td>
            <td>
              <input type="text" v-model="item.store_id" class="py-1 px-2 w-full" v-if="item.checked" />
            </td>
            <td>
              <input type="text" v-model="item.store_guid" class="py-1 px-2 w-full" v-if="item.checked" />
            </td>
            <td>
              <select v-model="item.order_time" v-if="item.checked">
                <option v-for="time in 24" :value="time - 1">{{ renderTime(time - 1) }}</option>
              </select>
            </td>
            <td>
              <price type="text" v-model="item.minimumOrderAmount" class="w-150px" :disabled="!item.autoorder" />
            </td>
            <td>
              <switcher v-model="item.autoorder" @input="checkChanges()" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <button type="button" @click="save()" :class="{ busy: saveBusy }" :disabled="saveBusy" class="btn-primary mt-2">
      Сохранить
    </button>
  </div>
</template>

<script>
import Optic from "../../repo/OpticRepo";
export default {
  props: ['optic'],
  data() {
    return {
      list: [],
      checkedItems: {},
      busy: false,
      tab: "",
      savingOptions: "",
      category: "",
      key: 0,
      suppliers: [],
      saveBusy: false
    };
  },
  mounted() {
    if (this.optic) {
      this.getOpticDetails();
    }
  },
  methods: {
    checkChanges() {
      this.key++;
    },
    async getOpticDetails() {

      const { data, msg, success } = await this.$api.get(`/optics/${this.optic.id}`);
      if (success) {
        this.suppliers = data.suppliers;
      }
      this.loadSuppliers();
    },
    async save() {

      this.saveBusy = true;

      let postData = {
        id: this.optic.id,
        suppliers:
          this.list.map(el => {
            return {
              id: el.id,
              store_id: el.store_id,
              store_guid: el.store_guid,
              autoorder: el.autoorder,
              order_time: el.order_time,
              minimumOrderAmount: parseFloat(el.minimumOrderAmount),
              checked: el.checked,
              store_email: el.store_email,
              store_discount: el.store_discount
            };
          })
      };
      let { success, msg, data } = await Optic.update(postData);

      this.saveBusy = false;

    },
    renderTime(time) {
      // conver to 24 format
      if (time < 10) {
        return "0" + time + ":00";
      }
      return `${time}:00`;
    },
    updateAll() {
      if (confirm("Это действие установит текущие значения для всех оптик в сети. Вы действительно хотите это сделать?")) {
        this.$api.post(`/optics/batch/suppliers`, { suppliers: this.getFilteredSuppliers() });
      }
    },
    testChecked(event, item) {
      if (item.checked) {
        if (confirm("Вы действительно хотите отключить этого поставщика?")) {
          item.checked = false;
        } else {
          item.checked = true;
        }

        this.key++;

        return;
      }

      if (!item.checked) {
        item.checked = true;

        this.key++;
      }
    },
    // get default services
    async loadSuppliers() {
      let success, msg, data;

      ({ success, msg, data } = await this.$api.get(`/g/suppliers?filter=${JSON.stringify({ category: this.category })}`));
      let suppliers = data.suppliers;

      // leave only those that in a settings
      ({ success, msg, data } = await this.$api.get("/settings?type=suppliers"));

      if (success) {
        let availableSuppliers = data.data;
        this.list = suppliers.filter(el => availableSuppliers.find(elm => elm.id == el.id));
      }

      // attach current status
      for (let el of this.suppliers) {
        let item = this.list.find(elm => elm.id == el.id);
        if (item) {
          item.checked = el.checked ?? false;
          item.store_id = el.store_id;
          item.store_email = el.store_email;
          item.store_discount = el.store_discount;
          item.store_guid = el.store_guid;
          item.order_time = el.order_time;
          item.autoorder = el.autoorder;
          console.log(el.minimumOrderAmount);
          item.minimumOrderAmount = !el.minimumOrderAmount ? 0 : el.minimumOrderAmount;
        }
      }
    }
  },
  computed: {
    sortedSuppliers() {
      let vm = this;

      return this.list.slice().sort((a, b) => {
        if (a.checked && !b.checked) {
          return -1;
        }

        if (!a.checked && b.checked) {
          return 1;
        }
      })
    }
  },
  watch: {
    category: function (newval, oldval) {
      this.loadSuppliers();
    }
  }
};
</script>

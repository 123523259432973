<template>
  <page>
    <template v-slot:header>
      Клиенты
      <wip />
    </template>

    <template v-slot:content>
      <div class="filters mb-4 flex flex-row items-center gap-4 bg-white px-4 py-2 rounded-md">
        <div class="mr-8 text-sm">
          Всего:
          <span class="font-semibold">{{ total }}</span>
        </div>

        <div>
          <!-- <input v-model="filter.search" type="text" placeholder="Поиск.." /> -->
        </div>
      </div>

      <div v-if="total > 0" class="mt-4 mb-4">
        <template v-for="page of generatePageRange()">
          <button
            v-if="page != '...'"
            class="rounded-md w-8 h-8 mr-1 text-sm bg-white"
            @click.prevent="setPage(page)"
            :key="page"
            :class="{ 'bg-blue-600 text-white': page == currentPage }"
          >
            {{ page }}
          </button>

          <span v-if="page == '...'" class="mr-1">
            ...
          </span>
        </template>
      </div>

      <div class="">
        <table class="table-fixed min-w-full divide-y divide-gray-200">
          <thead class="bg-white">
            <tr>
              <th scope="col" class="p-4">
                <div class="flex items-center">
                  <input
                    id="checkbox-all"
                    aria-describedby="checkbox-1"
                    type="checkbox"
                    class="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-cyan-200 h-4 w-4 rounded"
                  />
                  <label for="checkbox-all" class="sr-only">checkbox</label>
                </div>
              </th>
              <th scope="col" class="p-4 text-left text-xs font-medium text-gray-500 uppercase">
                Имя
              </th>
              <th scope="col" class="p-4 text-left text-xs font-medium text-gray-500 uppercase">
                Телефон
              </th>
              <th scope="col" class="p-4 text-left text-xs font-medium text-gray-500 uppercase">
                Email
              </th>
              <th scope="col" class="p-4"></th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <customer-row :customer="customer" v-for="customer of sorted" :key="customer.id" />
          </tbody>
        </table>

        <div v-if="total > 0" class="mt-4">
          <template v-for="page of generatePageRange()">
            <button
              v-if="page != '...'"
              class="rounded-md w-8 h-8 mr-1 text-sm bg-white"
              @click.prevent="setPage(page)"
              :key="page"
              :class="{ 'bg-blue-600 text-white': page == currentPage }"
            >
              {{ page }}
            </button>

            <span v-if="page == '...'" class="mr-1">
              ...
            </span>
          </template>
        </div>
      </div>
    </template>
  </page>
</template>

<script>
import { mapGetters } from "vuex";
import Customers from "../repo/CustomerRepo";
import CustomerRow from "./customers/CustomerRow.vue";
import wip from "./shared/WIP.vue";

export default {
  components: { CustomerRow, wip },
  name: "customers",
  data() {
    return {
      customers: [],
      filter: {
        search: "",
        role: "",
        status: ""
      },
      currentPage: 1,
      limit: 20,
      total: 0
    };
  },
  mounted() {
    this.getCustomers();
  },
  methods: {
    async getCustomers() {
      let { data, success, msg } = await Customers.get({
        opticId: this.$dept.id,
        page: this.currentPage,
        limit: this.limit
      });
      if (success) {
        this.customers = data.customers;
        this.total = data.total;
      }
    },
    setPage(page) {
      this.currentPage = parseInt(page);
      this.getCustomers();
    },
    generatePageRange(delta = 4) {
      // creates array with base 1 index
      let currentPage = parseInt(this.currentPage);
      let lastPage = Math.ceil(this.total / this.limit);

      const range = Array(lastPage)
        .fill()
        .map((_, index) => index + 1);

      return range.reduce((pages, page) => {
        // allow adding of first and last pages
        if (page === 1 || page === lastPage) {
          return [...pages, page];
        }

        // if within delta range add page
        if (page - delta <= currentPage && page + delta >= currentPage) {
          return [...pages, page];
        }

        // otherwise add 'gap if gap was not the last item added.
        if (pages[pages.length - 1] !== "...") {
          return [...pages, "..."];
        }

        return pages;
      }, []);
    }
  },
  computed: {
    sorted() {
      // let start = (this.currentPage - 1) * this.limit;
      // let end = start + this.limit;

      // let customers = this.customers.slice(start, end);

      return this.customers;
    },
    pagination() {
      return null;
    }
  }
};
</script>

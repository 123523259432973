<template>
  <tr class="hover:bg-gray-100">
    <td class="p-4 w-4">
      <div class="flex items-center">
        <input
          id="checkbox-1"
          aria-describedby="checkbox-1"
          type="checkbox"
          class="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-cyan-200 h-4 w-4 rounded"
        />
        <label for="checkbox-1" class="sr-only">checkbox</label>
      </div>
    </td>
    <td class="p-4 flex items-center whitespace-nowrap space-x-6 mr-12 lg:mr-0">
      <img
        class="h-10 w-10 rounded-full"
        v-if="customer.picture"
        :src="'https://178107.selcdn.ru/monkl-picture/' + customer.picture"
        alt=""
      />
      <div v-if="!customer.picture" class="bg-gray-300 w-10 h-10 rounded-full"></div>

      <div class="text-sm font-normal text-gray-500">
        <div class="text-base font-semibold text-gray-900">{{ customer.fio }}</div>
      </div>
    </td>
    <td>
      <div class="text-sm font-normal text-gray-500">{{ customer.phone }}</div>
    </td>
    <td class="p-4 whitespace-nowrap text-base font-medium text-gray-900">
      {{ customer.email }}
    </td>
    <td class="p-4 whitespace-nowrap space-x-2">
      <button
        type="button"
        @click="view()"
        data-modal-toggle="customer-modal"
        class="text-white bg-indigo-400 hover:bg-indigo-500 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-sm inline-flex items-center px-3 py-2 text-center"
      >
        <i class="fas fa-cog"></i>
      </button>
    </td>
  </tr>
</template>

<script>
import { mapGetters } from "vuex";
import EditCustomerModal from "./EditCustomerModal.vue";
export default {
  props: ["customer"],
  mounted() {},
  methods: {
    view() {
      this.$modals.open(EditCustomerModal, { id: this.customer.id });
    }
  },
  computed: {}
};
</script>

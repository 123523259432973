<template>
  <page>
    <template v-slot:header>
      Поступления
    </template>
    <template v-slot:actions>
      <router-link to="/store/supply/new" class="btn-action-xs ml-4">
        <i class="fas fa-plus mr-1"></i>
        Добавить поступление
      </router-link>
    </template>

    <!-- Filters, collapsable -->
    <template v-slot:content>

      <div>
        <pagination :total="filter.total" :limit="filter.limit" :items="items" :name="'supply'"
          @limit="limit => ((filter.limit = limit), getItems())" @page="page => ((filter.page = page), getItems())" />

        <div class="w-full overflow-x-auto text-left bg-white rounded-md">
          <table class="w-full data-table">
            <thead>
              <tr class="font-semibold text-xs  text-gray-600">
                <th class="">Статус</th>
                <th class="">№ ТТН</th>
                <th class="">Добавили</th>
                <th class="">Дата ТТН</th>
                <th class="">Кто добавил</th>
                <th class="">Поставщик</th>
                <th class="">Товаров</th>
                <th class="w-10">Заметка</th>
              </tr>
            </thead>
            <tbody class="text-sm">
              <tr v-for="item in items" :key="item.id">
                <td>
                  <span v-if="item.status == 'cancelled'" class="badge gray">Отменен</span>
                  <span v-if="item.status == 'confirmed'" class="badge green">Проведен</span>
                  <span v-if="item.status == 'wait'" class="badge blue">В ожидании</span>
                  <span v-if="item.status == 'new'" class="badge blue">Черновик</span>
                </td>
                <td>
                  <router-link :to="`/store/supply/${item.id}`" class="btn-link">
                    {{ item.ttnNumber }}
                  </router-link>
                </td>
                <td>{{ item.created_at | datetime }}</td>
                <td>
                  {{ item.ttnDate }}
                </td>
                <td>
                  <user-card :id="item.user_id" />
                </td>
                <td>
                  {{ getSupplier(item.supplier_id).name }}
                </td>
                <td>
                  {{ item.items.length }}
                </td>
                <td class="text-sm">
                  {{ item.note }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div v-if="error" class="text-red-500 font-semibold py-2">{{ error }}</div>
        <loading v-if="busy" />

      </div>
    </template>
  </page>
</template>

<script>
import { mapGetters } from "vuex";
import Page from "../../Page.vue";
import Info from "../../shared/Info.vue";
import Loading from "../../Loading.vue";
import api from "../../../api/api.js";
import UserCard from "../../shared/UserCard.vue";

export default {
  components: { Page, Info, Loading, UserCard },
  name: "supply",
  data() {
    return {
      items: [],
      searchDate: "",
      busy: false,
      error: "",
      suppliers: [],
      filter: {
        page: 1,
        limit: 10,
        term: "",
        date: "",
        total: 0
      },
    };
  },
  mounted() {
    this.getSuppliers();
  },
  methods: {
    // get supply list
    async getItems() {
      this.busy = true;
      let query = encodeURIComponent(JSON.stringify(this.filter));
      let { success, msg, data } = await api.get(`/supplies?filter=${query}`);
      if (success) {
        this.items = data.items;
        this.filter.total = data.total;
      }

      this.busy = false;
    },
    async getSuppliers() {
      let { data } = await this.$api.get(`/g/suppliers`);
      this.suppliers = data.suppliers;
    },
    getSupplier(id) {
      return this.suppliers.find(el => el.id == id) || { name: "---" };
    }
  },
  computed: {
    ...mapGetters("users", ["getById"])
  }
};
</script>

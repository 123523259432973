<template>
    <input
      type="text"
      v-mask="'###'"
      placeholder="0"
      v-bind:value="value"
      v-on:input="$emit('input', $event.target.value)"
      v-on:change="$emit('change', $event.target.value)"
    />
  </template>
  <script>
  export default {
    name: "QtyInput",
    props: ["value"]
  };
  </script>
  
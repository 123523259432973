<template>
  <div>
    <div class="bg-white pb-4 rounded-md">
      <div class="font-semibold text-sm mb-2">Авторефрактометрия / Кератометрия</div>
      <div class="grid grid-cols-6 text-xs font-semibold text-gray-600 mb-2 text-center">
        <div></div>
        <div>Sph</div>
        <div>Cyl</div>
        <div>Axis</div>
        <div>BC</div>
        <div>Dpp</div>
      </div>
      <div class="grid grid-cols-6 gap-x-2">
        <!-- od -->
        <div>OD</div>
        <div>
          <autocomplete v-model="record.metrics.refractometria.od.sph" type="sph" format="float" />
        </div>
        <div>
          <autocomplete v-model="record.metrics.refractometria.od.cyl" type="cyl" format="float" />
        </div>
        <div>
          <autocomplete v-model="record.metrics.refractometria.od.axis" type="axis" :field="'glasses'" />
        </div>
        <div>
          <autocomplete v-model="record.metrics.ceratometria.od.bc" type="bc" format="float" />
        </div>
        <div class="">
          <autocomplete v-model="record.metrics.refractometria.od.dpp" type="dpp" format="float" />
        </div>
        <!-- os -->
        <div>OS</div>
        <div>
          <autocomplete v-model="record.metrics.refractometria.os.sph" type="sph" format="float" />
        </div>
        <div>
          <autocomplete v-model="record.metrics.refractometria.os.cyl" type="cyl" format="float" />
        </div>
        <div>
          <autocomplete v-model="record.metrics.refractometria.os.axis" type="axis" format="float" :field="'glasses'" />
        </div>
        <div>
          <autocomplete v-model="record.metrics.ceratometria.os.bc" type="bc" />
        </div>
        <div class="">
          <autocomplete v-model="record.metrics.refractometria.os.dpp" type="dpp" format="float" />
        </div>
      </div>
    </div>

    <div class="bg-white py-4 pb-4 mt-2 rounded-md">
      <div class="font-semibold text-sm mb-2">Visus (острота зрения)</div>
      <div class="grid grid-cols-3 text-center mt-2 mb-2 font-semibold text-sm">
        <div></div>
        <div>OD</div>
        <div>OS</div>
      </div>

      <template>
        <div
          class="grid grid-cols-3 text-sm border-b border-gray-100 py-2 gap-x-2 items-center"
          v-for="(opt, key) in record.metrics.visus.od"
          :key="key"
        >
          <div class="text-xs">{{ $t(key) }}</div>

          <div class="text-center">
            <autocomplete v-model="record.metrics.visus.od[key]" :type="key" :field="'glasses'" />
          </div>

          <div class="text-center">
            <autocomplete v-model="record.metrics.visus.os[key]" :type="key" :field="'glasses'" />
          </div>
        </div>
      </template>
    </div>

    <!-- Comment -->
    <div class="bg-white py-4 pb-4 mt-2 rounded-md">
      <div class="font-semibold text-sm mb-2">Комментарий</div>
      <textarea class="w-full" v-model="record.metrics.comment"></textarea>
    </div>
  </div>
</template>

<script>
import Autocomplete from "../shared/Autocomplete.vue";

export default {
  components: { Autocomplete },
  props: ["record"],
  data() {
    return {};
  }
};
</script>

<template>
  <div
    class="relative flex flex-col h-full items-start p-6 rounded-lg shadow bg-white hover:shadow-xl transition-shadow duration-150 ease-in-out">
    <div class="flex flex-row items-center">
      <div>
        <img v-if="!item.picture" class="h-8 w-8 rounded-md" src="/img/face.jpg" />
        <img class="h-8 w-8 rounded-md object-cover" v-if="item.picture"
          :src="'https://178107.selcdn.ru/monkl-picture/' + item.picture" />
      </div>
      <div class="ml-4 flex-1 py-2">
        <div class="flex items-bottom justify-between">
          <p class="font-semibold text-black text-sm">
            {{ item.title }}
          </p>
        </div>
        <p class="text-gray-500 mt-1 text-xs">
          {{ item.addr }}
        </p>
      </div>
    </div>
    <router-link :to="{ name: 'optic-page', params: { id: item.id } }"
      class="absolute right-3 top-3 text-sm text-indigo-500">
      <i class="fas fa-cog"></i>
    </router-link>
    <div class="text-sm text-gray-400 mt-3">
      {{ item.note }}
    </div>

    
    <!-- users -->
    <div class="py-3 absolute bottom-0">
      <div class="w-12 h-1 mt-6 border-t-2"></div>
      <span class="text-xs">
        Сотрудников: {{ people.length }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "optic-card",
  props: ["item"],
  computed: {
    ...mapGetters("users", ["getByDept"]),
    people() {
      return this.getByDept({ id: this.item.id });
    }
  }
};
</script>

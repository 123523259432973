<template>
  <section :class="{ 'modal-root': true }">
    <transition-group tag="div" name="modal">
      <component
        v-for="(modal, index) in modals"
        :key="index + 'modal'"
        :is="modal.component"
        v-bind:close="modal.close"
        v-bind:dismiss="modal.dismiss"
        v-bind="modal.props"
        :class="{ 'd-block': modal.component }"
      />
    </transition-group>
  </section>
</template>

<script>
import ModalService from "./modal.service";
import Modal from "./Modal.vue";
export default {
  components: { Modal },
  data() {
    return {
      //modal: {},
      state: "",
      modals: []
    };
  },
  created() {
    ModalService.$on("close", () => {
      let modal = this.modals.pop();
      console.log("ModalService close modal");

      if (modal && typeof modal.close !== "undefined") {
        //console.log("fire modal close function");
        //modal.component.methods.onClose();
        modal.close();
      }

      if (vm.modals.length == 0) {
        setTimeout(function() {
          document.body.classList.remove("noscroll");
        }, 500);
      }
    });

    let vm = this;

    ModalService.$on("open", ({ component, props, resolve, reject, callback }) => {
      console.log("open");
      document.body.classList.add("noscroll");
      //vm.state = "open";

      let modal = {
        component,
        props,
        test() {
          console.log("on test");
        },
        close: value => {
          //this.modal = {};

          console.log("close modal #" + modal.index);
          // //this.$refs.backdrop.$el.classList.remove("w-screen h-screen");

          console.log(value);
          callback(value);
          resolve(value);

          // remove modal from the list
          vm.modals.splice(modal.index, 1);

          // if no more modals, remove noscroll
          console.log(vm.modals.length);
          if (vm.modals.length == 0) {
            document.body.classList.remove("noscroll");
          }
        },
        dismiss: reason => {
          console.log("dismiss");
          //this.modal = {};
          reject(reason);
        },
        index: this.modals.length
      };

      this.modals.push(modal);
      console.log("addded modal" + modal.index);
    });
  }
};
</script>

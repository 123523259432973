<template>
  <div class="leading-loose">
    <div>
      <vc-date-picker
        :columns="2"
        v-model="selectedDate"
        show-weeknumbers="left-outside"
        v-on:input="loadSchedule"
        :attributes="attributes"
      />
    </div>

    <div v-if="selectedDate">
      <div class="mt-4">
        <span class="text-xl">Расписание </span>
        <span class="text-gray-400 font-medium text-lg">{{
          toLocalTime(selectedDate)
        }}</span>
      </div>

      <!--  Schedule list -->
      <div class="flex flex-row space-x-4 mb-2 mt-4 relative">
        <div class="w-1/3 text-sm font-medium text-gray-400">Начало</div>
        <div class="w-1/3 text-sm font-medium text-gray-400">Конец</div>
        <div class="w-1/3 text-sm font-medium text-gray-400">Оптика</div>
      </div>
      <div
        class="flex flex-row space-x-4 mb-2 relative"
        v-for="(slot, index) in slots"
        :key="index"
      >
        <select
          v-model="slot.timeStart"
          class="w-1/3 px-5 py-2 text-black bg-gray-100 focus:bg-gray-200 rounded-md font-medium"
        >
          <option v-for="(opt, ind) of timeSlots" :key="ind">{{ opt }}</option>
        </select>

        <select
          v-model="slot.timeStop"
          class="w-1/3 px-5 py-2 text-black bg-gray-100 focus:bg-gray-200 rounded-md font-medium"
        >
          <option v-for="(opt, ind) of timeSlots" :key="ind">{{ opt }}</option>
        </select>

        <select
          v-model="slot.opticId"
          class="w-1/3 px-5 py-2 text-black bg-gray-100 focus:bg-gray-200 rounded-md font-medium"
        >
          <option
            :value="optic.id"
            v-for="(optic, index) in optics"
            :key="index"
          >
            {{ optic.title }}
          </option>
        </select>

        <button
          type="button"
          @click="removeByIndex(index)"
          class="absolute -right-5 top-3 text-red-500 text-sm"
        >
          <i class="fas fa-times"></i>
        </button>
      </div>

      <div v-if="slots && slots.length == 0" class="mb-6">
        <span class="text-sm text-gray-600">Ничего нет, добавьте новые.</span>
      </div>

      <button
        type="button"
        class="text-sm font-medium text-indigo-500 mt-8"
        @click="addSlot()"
      >
        <i class="mr-1 fas fa-plus-circle"></i> Добавить слот
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["schedule"],
  data() {
    return {
      selectedDate: new Date(),
      slots: [],
      attributes: [
        {
          dot: true,
          dates: [
            new Date(2021, 3, 1), // Jan 1st
            new Date(2021, 3, 10), // Jan 10th
            new Date(2021, 3, 22), // Jan 22nd
          ],
        },
        {
          dot: 'red',
          dates: [
            new Date(2021, 3, 4), // Jan 4th
            new Date(2021, 3, 10), // Jan 10th
            new Date(2021, 3, 15), // Jan 15th
          ],
        },
      ]
    };
  },
  mounted() {},
  methods: {
    // load schedule for selected date
    saveDaySchedule() {},
    loadSchedule() {
      console.log("load schedule for a day");
    },
    toLocalTime(date) {
      return new Date(date).toLocaleDateString("ru-Ru", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    removeByIndex(index) {
      this.slots.splice(index, 1);
    },
    // add slot to selected date
    addSlot() {
      this.slots.push({
        timeStart: "09:00",
        timeStop: "18:00",
      });
    },
  },
  computed: {
    timeSlots() {
      let slots = [];
      for (let t = 0; t < 24 * 60; t++) {
        var hh = Math.floor(t / 60);

        hh = hh < 10 ? "0" + hh : hh;
        var mm = t % 60;
        mm = mm < 10 ? "0" + mm : mm;

        slots.push(hh + ":" + mm);
        t = t + 59;
      }

      return slots;
    },
    ...mapGetters("optics", { optics: "optics", getOptic: "getById" }),
  },
};
</script>

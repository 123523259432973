<template>
  <div class="relative">
    <div class="flex flex-row items-center">
      <button @click="isVisible = !isVisible;getCalls()" class="transform rotate-12 w-7 h-7 relative hover:bg-black rounded-full px-2">
        <i class="fas fa-phone"></i>
        <span class="absolute top-0.5 -right-0.5 w-2 h-2 bg-red-500 rounded-full" v-if="unreadCalls > 0"></span>
      </button>
    </div>
    <div
      v-show="isVisible"
      class="fixed top-10 overflow-hidden rounded-md border  mt-1 shadow-md bg-gray-100 w-300 h-full right-0"
    >
      <div class="overflow-y-auto h-full -mr-10 pr-10 text-black">
        <div class="row border-b py-4 items-center">
          <div class="font-semibold text-sm pl-4">Входящие вызовы</div>
        </div>
        <div v-if="calls.length == 0" class="py-4 text-sm px-4 text-gray-400">
          Пока ничего нет
        </div>
        <div class="flex flex-col">
          <div v-for="(call, index) in sortedCalls" class="border-b" :key="call._id">
            <div
              @click="readMessage(call)"
              :class="!call.read ? 'border-green-500 ' : 'border-transparent'"
              class="border-l-4 transition-all flex flex-row px-4 py-2 text-sm items-start  leading-none hover:bg-white"
            >
              <div class="">
                <div class="text-sm font-semibold mb-1">
                  {{ call.from }}
                  <span v-if="call.status=='answered'">📲 входящий</span>
                  <span v-if="call.status=='missed'">📵 пропущенный</span>
                </div>
                <div class="text-sm mb-1" v-if="call.customer.length!=0">
                  <div class="mb-2">{{ call.customer.lastname }} {{ call.customer.firstname }} {{ call.customer.email }}</div>
                  <div>
                    <div class="absolute bg-white opacity-50 w-full h-full top-0 left-0 right-0" v-if="busy"></div>
                    <a @click.prevent="doSearch(call.from)" href="#" aria-current="page" class="btn-action-xs router-link-exact-active">Открыть карточку клиента</a>
                  </div>
                </div>
                <div class="text-sm mb-1" v-else>
                  <div class="mb-2">Новый клиент</div>
<!--                  <div><a @click.prevent="addCustomer(call.from)" href="#" aria-current="page" class="btn-action-xs router-link-exact-active active">Создать карточку клиента</a></div>-->
                </div>
                <span class="text-2xs text-gray-400 font-medium">{{ formatDate(call.created_at) }} {{ call.to }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>

<script>
import EditCustomerModal from "@/components/customers/EditCustomerModal.vue";
import Customer from "@/repo/CustomerRepo";

export default {
  name: "Calls",
  components: { },
  data() {
    return {
      userId: this.$me.id,
      token: this.$me.token,
      isVisible: false,
      limit: 0,
      total: 0,
      refreshKey: 0,
      calls: [],
      busy:false
    };
  },
  computed: {
    unreadCalls() {
      this.refreshKey;
      return this.calls.filter(call => !call.read).length;
    },
    sortedCalls() {
      this.refreshKey;
      let sorted = [...this.calls];
      return sorted.sort((a, b) => this.$dayjs(b.created_at) - this.$dayjs(a.created_at));
    }
  },
  mounted() {
    this.getCalls();
  },
  methods: {
    async getCalls() {
      let vm = this;
      vm.busy = true;

      let { success, msg, data } = await this.$api.get("/calls");
      if (success) {
        this.calls = data.list;
        setTimeout(() => {
          vm.busy = false;
        }, 500);
      }
    },
    async readMessage(call) {
      let vm = this;
      let findMessage = this.calls.find(m => m._id == call._id);
      findMessage.read = true;
      await this.$api.post(`/calls/${call._id}/read`);
      vm.refreshKey++;
    },
    async doSearch(phone) {
      let vm = this;
      vm.busy = true;

      try {
        if (phone == 0) return;

        clearTimeout(vm.bounceTimer);
        vm.bounceTimer = setTimeout(async () => {
          // search by customer phone/email/name
          let { res, msg, data } = await Customer.search(phone, "any");
          this.$modals.open(EditCustomerModal, { id: data[0].id });
        }, 500);
      } catch (e) {
        console.log(e.message);
      } finally {
        setTimeout(() => {
          vm.busy = false;
        }, 500);
      }
    },
    async addCustomer(phone) {},
    formatDate(date) {
      return this.$dayjs(date).format("DD.MM.YYYY HH:mm");
    }
  },
};
</script>
export let chronic = ["Гипертония", "Сахарный диабет"];

export let heritable = ["Диффузный токсический зоб"];

let dis = [
  "Миопия",
  "Гиперметропия",
  "Астигматизм",
  "Пресбиопия",
  "Амблиопия",
  "Анизометропия",
  "Язва роговицы",
  "Кератит",
  "Кератоконъюктивит",
  "Неоваскуляризация роговицы",
  "Неоваскуляризация области лимба",
  "Другие рубцы и помутнения роговицы",
  "Пигментация и отложения в роговице",
  "Отёк роговицы",
  "Кератоконус",
  "Эрозия роговицы",
  "Кератит",
  "Ячмень",
  "Блефарит",
  "Воспаление века неуточнённое",
  "Лагофтальм",
  "Птоз века",
  "Др болезни , нарушающие функции века",
  "Синдром сухого глаза",
  "Дакриоцистит",
  "Конъюктивит ",
  "Птеригий",
  "Пингвекула",
  "Конъюктивальное кровоизлияние",
  "Конъюктивальная гиперемия",
  "Конъюктивит",
  "Иридоциклит",
  "Болезнь радужной оболочки и циллиарного тела",
  "Начальная катаракта",
  "Катаракта",
  "Афакия",
  "Отслойка сетчатки",
  "Дегенерация сетчатки",
  "Болезнь сетчатки неуточнённая",
  "Подозрение на глаукому",
  "Глаукома",
  "Болезни стекловидного тела",
  "Болезни зрительного нерва",
  "Косоглазие",
  "Нарушение содружественного движения глаз",
  "Спазм аккомодации",
  "Диплопия",
  "Нарушение бинокулярного зрения",
  "Дефекты поля зрения",
  "Аномалия цветового зрения",
  "Слепота одного глаза",
  "Нистагм"
];

export let diseases = dis.sort()
<template>
  <modal :styles="'max-w-xl'">
    <h3 class="text-black font-bold text-sm lg:text-xl mb-6">{{ $t(field) }} : Новое значениe</h3>

    <form class="" @submit.prevent="save()">
      <div class="">
        <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">Название</label>
        <input
          class="w-full "
          id="title"
          name="title"
          type="text"
          required=""
          v-model="tag.title"
          placeholder=""
          aria-label="title"
          @input="search"
        />
      </div>

      <div class="flex flex-row flex-wrap gap-x-2 mt-2 gap-y-2">
        <div
          class="select-tag text-sm rounded-md px-2 py-0.5 bg-gray-200 text-gray-600"
          v-for="tag in suggestions"
          @click="select(tag)"
        >
          {{ tag.title }}
        </div>
      </div>
      <div class="flex flex-row items-center justify-between mt-6">
        <button
          type="submit"
          :disabled="busy || tag.title.length == 0"
          class="btn-primary"
          v-busy="busy"
        >
          Сохранить
        </button>
      </div>
    </form>
  </modal>
</template>

<script>
import Modal from "../modals/Modal.vue";
import ModalMixin from "../modals/modal.mixin";

export default {
  props: ["field"],
  components: { Modal },
  mixins: [ModalMixin],
  data() {
    return {
      busy: false,
      tag: {
        title: "",
        field: ""
      },
      suggestions: []
    };
  },
  methods: {
    select(tag) {
      // notify parent with selection
      //this.$events.$emit(`toggle-tag-${this.field}`, this.field, tag);
      this.close(tag);
    },
    async save() {
      this.busy = true;

      this.tag.field = this.field;

      let { success, msg, data } = await this.$api.post(`/tags`, this.tag);

      this.busy = false;

      if (success) {
        this.close(this.tag);
      } else {
        this.status = msg;
      }
    },
    /**
     * Try to search such tag
     */
    async search() {
      if (this.tag.title.length >= 1) {
        let { success, msg, data } = await this.$api.get(
          `/tags?term=${this.tag.title}&field=${this.field}`
        );
        if (success) {
          this.suggestions = data;
        }
      }
    }
  }
};
</script>

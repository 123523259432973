<template>
  <modal :styles="'max-w-lg'">
    <div>
      <h3 class="text-black font-bold text-xl mb-6 text-center">
        {{ $t("addProductLot") }}
      </h3>

      <div class="mt-4">
        <label class="block mb-1 text-sm text-gray-600 font-medium" for="cus_name">
          Лоты
        </label>
        <div class="border rounded-md relative">
          <div class="grid grid-cols-2 border-b text-xs py-1 bg-gray-50 font-semibold">
            <div class="px-4 border-r">Код лота</div>
            <div class="px-4">Срок годности</div>
          </div>
          <div class="grid grid-cols-2 border-b text-sm last:border-0 py-0.5" v-for="(item, index) in scanned">
            <!-- print -->
            <div class="border-r px-4">{{ item.lot }}</div>
            <div class="px-4">{{ item.date }}</div>
            <button class="text-red-500 text-xs absolute -right-4" @click="remove(index)">
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>

      <div>
        <div class="flex flex-row gap-x-2 mt-2">
          <input
            type="text"
            class="text-sm py-1 px-2 w-2/4"
            ref="lot"
            v-model="lot"
            placeholder="Код лота"
            required
            v-on:keyup.enter="scan('month')"
          />
          <input
            type="text"
            class="text-sm py-1 px-2 w-1/4"
            ref="month"
            v-model="expireMonth"
            v-number="{ min: 1, max: 12, len: 2, defaultValue: '' }"
            maxlength="2"
            required
            placeholder="MM"
            v-on:keyup.enter="scan('year')"
          />
          <input
            type="text"
            class="text-sm py-1 px-2 w-1/4"
            ref="year"
            maxlength="4"
            v-model="expireYear"
            v-number="{ min: $dayjs().year(), max: $dayjs().year() + 10, len: 4, defaultValue: '' }"
            required
            placeholder="YYYY"
            v-on:keyup.enter="scan('save')"
          />
          <button type="button" class="btn-primary-sm btn-xs" @click="scan('save')">
            <i class="fas fa-save"></i>
          </button>
        </div>
      </div>

      <div class="flex flex-col mt-6">
        <button type="button" class="btn-primary-sm" @click="saveLots()">
          Сохранить весь список
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "../modals/Modal.vue";
import ModalMixin from "../modals/modal.mixin";

export default {
  components: { Modal },
  mixins: [ModalMixin],
  props: ["product"],
  data() {
    return {
      expireYear: "",
      expireMonth: "",
      lot: "",
      scanned: [],
      newCounter: 0
    };
  },
  mounted() {
    this.$refs.lot.focus();
    // add existed to scanned items
    if (this.product.lots) {
      this.scanned = [...this.product.lots];
    }
  },
  methods: {
    remove(index) {
      this.scanned.splice(index, 1);
    },
    async saveLots() {

      if (this.lot != "") {
        if (confirm("У вас есть недобавленный лот, хотите его добавить?")) {
          this.scan("save");
          return;
        }
      }

      // save lot list to product in db
      let { success, msg, data } = await this.$api.post(`/products/${this.product.id}/lots`, { lots: this.scanned });
      if (success) {
        // close modal
        this.close(true);
      } else {
        alert(msg);
      }
    },

    /**
     * Scans the given type of input field, if date - just change focus, if save - add lot to scanned items
     *
     * @param {string} type - The type to scan.
     */
    scan(type) {
      this.peaksound();

      if (type == "month") {
        if (this.isLotAlreadyScanned(this.lot)) {
          this.failAlert("Такой лот уже отсканирован");
          return;
        }

        if (this.isLotExists(this.lot)) {
          this.failAlert("Такой лот уже добавлен в товар");
          return;
        }

        if (!this.isLotCorrect(this.lot)) {
          this.failAlert("Неправильный код лота. Необходим 15 или 16 цифр, а также латинские буквы если меньше 15 символов");
          return;
        }

        if (this.lot == "") {
          this.failsound();
        } else {
          this.$refs.month.focus();
        }
      }

      if (type == "year") {
        if (this.expireMonth == "") {
          this.failsound();
          this.$refs.month.focus();
        } else {
          this.$refs.year.focus();
        }
      }

      if (type == "save") {
        if (this.expireMonth == "") {
          this.failsound();
          this.$refs.month.focus();
          return;
        }

        if (this.expireYear == "" || this.expireYear < this.$dayjs().year()) {
          this.failsound();
          this.$refs.year.focus();
          return;
        }

        if (this.lot == "") {
          this.failsound();
          this.$refs.lot.focus();
          return;
        }

        this.scanned.push({
          lot: this.lot,
          date: `${this.expireMonth}/${this.expireYear}`
        });

        this.newCounter++;
        // reset fields
        this.lot = "";
        this.expireMonth = "";
        this.expireYear = "";

        this.$refs.lot.focus();
      }
    },
    failAlert(msg) {
      this.failsound();
      setTimeout(() => {
        alert(msg);
      }, 10);
    },
    /**
     * Checks if a given lot has already been scanned.
     *
     * @param {any} lot - The lot to check for scanning.
     * @return {boolean} Returns true if the lot has already been scanned, false otherwise.
     */
    isLotAlreadyScanned(lot) {
      let isScanned = this.scanned.findIndex(el => el.lot == lot) != -1;
      return isScanned;
    },
    /**
     * Check if a given lot exists in the product's lots.
     *
     * @param {type} lot - The lot to check for existence.
     * @return {boolean} Returns true if the lot exists, false otherwise.
     */
    isLotExists(lot) {
      let isExists = this.product.lots.findIndex(el => el.lot == lot) != -1;
      return isExists;
    },
    /**
     * Play a peak sound.
     */
    peaksound() {
      let audio = new Audio("/sounds/peak.wav");
      audio.play();
    },
    failsound() {
      let audio = new Audio("/sounds/error.wav");
      audio.play();
    },
    isLotCorrect(lot) {
      // ensure that if lot length less that 15 symbols, it should contain letters, if more than 15 then only number
      if (this.lot.length < 15) {
        return /[a-zA-Z]/.test(lot);
      } else {
        return /^[0-9]+$/.test(lot);
      }
    }
  }
};
</script>

<template>
  <div v-html="htmlContent" class="blocks"></div>
</template>

<script>
export default {
  name: 'RenderNotionBlocks',
  props: {
    blocks: {
      type: Array,
      required: true
    }
  },
  computed: {
    htmlContent() {
      return this.blocksToHtml(this.blocks);
    }
  },
  methods: {
    blockToHtml(block) {
      switch (block.type) {
        case 'paragraph': {
          return `<p>${this.convertRichTextToHtml(block.paragraph.rich_text)}</p>`;
        }
        case 'heading_1': {
          return `<h1>${this.convertRichTextToHtml(block.heading_1.rich_text)}</h1>`;
        }
        case 'heading_2': {
          return `<h2>${this.convertRichTextToHtml(block.heading_2.rich_text)}</h2>`;
        }
        case 'heading_3': {
          return `<h3>${this.convertRichTextToHtml(block.heading_3.rich_text)}</h3>`;
        }
        case 'bulleted_list_item': {
          return `<ul><li>${this.convertRichTextToHtml(block.bulleted_list_item.rich_text)}</li></ul>`;
        }
        case 'numbered_list_item': {
          return `<ol><li>${this.convertRichTextToHtml(block.numbered_list_item.rich_text)}</li></ol>`;
        }
        case 'to_do': {
          return `<div><input type="checkbox" ${block.to_do.checked ? 'checked' : ''} disabled /> ${this.convertRichTextToHtml(block.to_do.rich_text)}</div>`;
        }
        case 'toggle': {
          return `<details><summary>${this.convertRichTextToHtml(block.toggle.rich_text)}</summary>${block.toggle.children ? this.blocksToHtml(block.toggle.children) : ''}</details>`;
        }
        case 'code': {
          return `<pre><code>${block.code.rich_text.map(text => text.plain_text).join('')}</code></pre>`;
        }
        case 'image': {
          const imageUrl = block.image.type === 'external' ? block.image.external.url : block.image.file.url;
          return `<img src="${imageUrl}" alt="${block.image.caption.map(text => text.plain_text).join('')}" />`;
        }
        case 'child_page': {
          return `<div class="child-page">Child page: ${block.child_page.title}</div>`;
        }
        case 'table': {
          return this.renderTableBlockToHTML(block.childrens);
        }
        default: {
          return `<div>Unsupported block type: ${block.type}</div>`;
        }
      }
    },
    convertRichTextToHtml(richTextArray) {
      return richTextArray.map(text => {
        if (text.type === 'text') {
          const content = text.text.content;
          let link = text.text.link ? `<a href="${text.text.link.url}" target="_blank">${content}</a>` : content;

          // add colors if it exists
          let classes = '';
          let style = text.annotations;

          if (style.underline) {
            classes += ' underline';
          }
          if (style.strikethrough) {
            classes += ' strikethrough';
          }
          if (style.italic) {
            classes += ' italic';
          }
          if (style.bold) {
            classes += ' bold';
          }

          if (style.color) {
            classes += ` color-${style.color.toLowerCase()}`
          }

          if (style.code) {
            classes += ' inline-code';
          }

          if (classes) {
            link = `<span class="${classes}">${link}</span>`;
          }

          return link;
        }
        return text.plain_text;
      }).join('');
    },
    blocksToHtml(blocks) {
      return blocks.map(block => this.blockToHtml(block)).join('');
    },
    renderTableBlockToHTML(blocks) {
      let html = '<table class="content-table"><thead><tr>';

      // Assume the first block is the header row
      const headerRow = blocks[0].table_row.cells;
      headerRow.forEach(cell => {
        html += `<th>${cell[0].text.content}</th>`;
      });
      html += '</tr></thead><tbody>';

      // Iterate over the rest of the rows
      blocks.slice(1).forEach(row => {
        html += '<tr>';
        row.table_row.cells.forEach(cell => {

          let cellHtml = cell[0] && cell[0].text ? cell[0].text.content : '';
          html += `<td>${cellHtml}</td>`

          //html += `<td>${cell[0].text ? cell[0].text.content : ''}</td>`;
        });
        html += '</tr>';
      });
      html += '</tbody></table>';

      return html;
    }
  }
};
</script>

<style scoped>
/* Add any required styles here */
</style>
import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import optics from './modules/optics'
import users from './modules/users'
import records from './modules/records'
import customers from './modules/customers'
import {version} from '../../package.json'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
    state: {
        version: '',
    },
    modules: {
        app,
        optics,
        users,
        records,
        customers
    },
    mutations: {
        initializeStore(state) {

            // Check if the ID exists
            if (localStorage != null) {

                if (localStorage.getItem('store')) {

                    let store = JSON.parse(localStorage.getItem('store'));

                    if (store.version == version) {

                        this.replaceState(
                            Object.assign(state, store)
                        );

                    } else {
                        state.version = version;
                        localStorage.clear();

                        console.log('clear state');
                        this.dispatch('app/setDefaults');
                        console.log('set defaults form initialize');

                    }

                } else {
                    state.version = version;
                    this.dispatch('app/setDefaults');
                }

            } else {
                state.version = version;
                this.dispatch('app/setDefaults');
            }

        },
    }
});

export let common = [
  "Головные боли",
  "Раздражает яркий свет",
  "Трудно перевести и сфокусировать взгляд",
  "Нет четкости вблизи",
  "Нет четкости вдали",
  "Снижение зрения вблизь",
  "Снижение зрения вдаль",
  "Плохо видно в сумерках",
  "Усталость глаз",
  "Покраснение глаз",
  "Хочется закрыть один глаз",
  "Повышенное слезоточение",
  "Ощущение зуда",
  "Отделяемое из глаз",
  "Отек век"
];

export let mkl = [
  "Сухость",
  "Сокращение времени комфортного ношения в течение дня",
  "Ощущение инородного тела (песчинки), ощущение края линзы",
  "Ощущение дискомфорта только при надевании и снятии линзы",
  "Глаза не выглядят белыми, здоровыми",
  "Кратковременное жжение, покраснение глаз сразу после надевания МКЛ",
  "Кратковременное затуманивание зрения",
  "Двоение изображения"
];

export let eyeStrain = [
  "Работа в офисе",
  "Вождение",
  "Занятия спортом",
  "Чтение бумажных текстов",
  "ТВ",
  "Хобби (шитье/рисование)",
  "Гаджеты"
];

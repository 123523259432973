<template>
  <div class="py-2 px-3 lg:py-2 rounded-md border mb-2 shadow-sm">
    <div class="grid grid-cols-2 lg:grid-cols-5 gap-y-2 lg:gap-x-4  text-xs lg:text-sm overflow-hidden  w-full items-center">
      <div class="font-semibold">
        {{ $dayjs(record.startTime).format("DD.MM.YYYY HH:mm") }}
      </div>
      <div class="text-right lg:text-left">
        <span class="inline-status inline-block rounded-full w-2 h-2 mr-1" :class="record.status"></span>
        <span :class="`text-${record.status}`" class="font-medium">
          {{ getStatus(record.status) }}
        </span>
      </div>

      <div class="overflow-hidden whitespace-nowrap pr-10 w-full col-span-2 lg:col-span-1">
        <user-card :id="record.host ? record.host_id : ''" v-if="record.host_id" />
      </div>

      <div>{{ record.serviceTitle || "" }}</div>

      <div class="text-right">
        <button type="button" class="btn-view" @click="viewRecord(record.id)">
          Просмотреть
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ReadRecordModal from "../records/ReadRecordModal.vue";

export default {
  props: ["record"],
  data() {
    return {};
  },
  methods: {
    viewRecord(id) {
      this.$modals.open(ReadRecordModal, { id: id });
    }
  },
  computed: {
    ...mapGetters("records", ["getStatus"])
  }
};
</script>

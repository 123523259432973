<template>
  <div class="">
    <div class="grid grid-cols-3 gap-x-3">
      <div>
        <label class="block mb-1 text-base text-gray-500" for="cus_name">Фамилия</label>
        <input class="w-full" type="text" required="" v-model="user.lastName" placeholder="Фамилия" />
      </div>
      <div>
        <label class="block mb-1 text-base text-gray-500" for="cus_name">Имя</label>
        <input class="w-full" type="text" v-model="user.firstName" placeholder="Имя" />
      </div>
      <div>
        <label class="block mb-1 text-base text-gray-500" for="cus_name">Отчество</label>
        <input class="w-full" type="text" v-model="user.thirdName" placeholder="Отчество" />
      </div>
    </div>

    <div class="grid grid-cols-2 gap-4 mt-4">
      <div class="">
        <label class="block mb-1 text-base text-gray-500" for="cus_name">Телефон</label>
        <input class="w-full" id="phone" name="phone" type="text" required="" v-model="user.phone" v-mask="'##########'"
          placeholder="9110001100" aria-label="phone" />
      </div>

      <div class="">
        <label class="block mb-1 text-base text-gray-500" for="">Электронная почта</label>
        <input type="email" class="w-full" placeholder="someone@example.com" v-model="user.email" />
      </div>
    </div>

    <div class="mt-4">
      <div class="grid grid-cols-2 gap-4">
        <div class="relative">
          <label class="block mb-1 text-base text-gray-500" for="">Картинка профиля</label>

          <div class="flex flex-row gap-2 items-center">
            <div class="">
              <img :src="'https://178107.selcdn.ru/monkl-picture/' + user.picture"
                class="w-12 h-12 object-cover rounded-md" v-if="user.picture" />
            </div>
            <div class="">
              <input class="w-full" id="picture" name="picture" type="file" accept="image/*" placeholder=""
                @change="filesChange($event)" aria-label="picture" />
            </div>
          </div>

          <input type="hidden" v-model="user.picture" />

          <div class="absolute top-10 right-0">
            <div v-if="isSuccess" class="text-sm px-4">
              ✔️
            </div>
            <svg v-if="isSaving" class="animate-spin -ml-1 mr-3 h-5 w-5 text-indigo-600"
              xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
              </path>
            </svg>
          </div>
        </div>

        <div class="">
          <label class="block mb-1 text-base text-gray-500" for="">Статус</label>
          <select required class="w-full" v-model="user.status">
            <option value="active">Активен</option>
            <option value="fired">Уволен</option>
          </select>
        </div>
      </div>
    </div>

    <div class="mt-4">
      <div class="grid grid-cols-2 gap-4">
        <div>
          <div class="">
            <label class="block text-base text-gray-500 mb-2" for="">Где работает</label>
            <label v-for="dept in $depts" class="text-sm text-black mb-2">
              <input type="checkbox" v-model="user.depts" :value="dept.id" />
              <span class="ml-2">{{ dept.title }}</span>
            </label>
          </div>
        </div>

        <div class="">
          <label class="block text-base text-gray-500 mb-2" for="">Роли</label>

          <div class="mb-3" v-for="(title, role) in $roles">
            <label class="text-sm text-black  font-semibold">
              <input type="checkbox" v-model="user.roles" :value="role" />
              <span class="ml-2">{{ title }}</span>
            </label>
            <div class="flex flex-row flex-wrap text-xs space-x-2 mt-1 text-gray-500">
              <div v-for="item of $rules[role]">{{ $t(item) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { upload } from "../../api/upload";
const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;

export default {
  props: ["user"],
  data() {
    return {
      uploadedFiles: [],
      uploadError: null,
      currentStatus: STATUS_INITIAL
    };
  },
  methods: {
    async filesChange(evt) {
      let vm = this;

      let fileList = evt.target.files;
      // prepare form data
      //const formData = new FormData();
      //formData.append(evt.target.name, fileList[0], fileList[0].name);

      const reader = new FileReader();
      // read as base64
      reader.readAsDataURL(fileList[0]);
      vm.currentStatus = STATUS_SAVING;
      reader.onloadend = async function () {
        let { data } = await upload(reader.result);
        // save to optic image src
        vm.user.picture = data.filename;

        setTimeout(() => {
          vm.currentStatus = STATUS_SUCCESS;
        }, 500);
      };
    }
  },
  mounted() {
    this.$store.dispatch("optics/pullOptics");
  },
  computed: {
    ...mapGetters("optics", ["optics"]),
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    }
  }
};
</script>

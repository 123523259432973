<template>
  <div class="bg-white px-5 py-4 rounded-md">
    <div class="flex flex-row space-x-4 mb-2 text-sm text-gray-400 font-medium lg:w-3/5" v-if="services && services.length > 0">
      <div class="w-3/5">Название услуги</div>
      <div class="w-1/5">Длительность</div>
      <div class="w-1/5">Стоимость</div>
    </div>

    <div
      class="flex flex-row space-x-4 border-b last:border-0 items-center py-2 relative  lg:w-3/5"
      v-for="(service, index) in services"
      :key="index"
    >
      <div class="w-3/5">
        <toggle v-model="service.available">
          <span class="" :class="service.available ? 'text-black' : 'text-gray-400'">
            {{ service.title }}
          </span>
        </toggle>
      </div>

      <select v-model="service.time" class="w-1/5 input-sm">
        <option v-for="(opt, ind) of timeSlots" :key="ind">{{ opt }}</option>
      </select>

      <input class="w-1/5 input-sm" type="text" v-model="service.price" placeholder="100" />
    </div>

    <help>
      <span class="text-sm text-gray-600">
        Список всех услуг можно отредактировать в
        <router-link to="/network#services" class="btn-link">Настройках сети</router-link>
      </span>
    </help>

    <div v-if="services && services.length == 0" class="mb-6">
      <span class="text-sm text-gray-600">Услуг пока нет, добавьте новые.</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["services"],
  data() {
    return {
      defaults: []
    };
  },
  mounted() {},
  methods: {},
  computed: {
    timeSlots() {
      let slots = [];
      for (let t = 15; t <= 120; ) {
        slots.push(t);
        t = t + 15;
      }

      return slots;
    }
  }
};
</script>

<template>
  <modal :styles="''">
    <h3 class="text-black font-bold text-xl mb-6">
      Оплата заказа
    </h3>

    <div class="grid grid-cols-1 md:grid-cols-3 gap-2 text-center">
      <button class="rounded-md px-4 border py-8 payment-select" v-for="(method, key) in merchants"
        @click="selectPayment(method)">
        {{ method.title }}
      </button>
    </div>

    <loading v-if="busy" />
  </modal>
</template>

<script>
import Modal from "../modals/Modal.vue";
import ModalMixin from "../modals/modal.mixin";
import api from "../../api/api.js";

export default {
  components: { Modal },
  mixins: [ModalMixin],
  props: ["id", "isExpressOrder"],
  data() {
    return {
      loading: false,
      busy: false,
      merchants: []
    };
  },
  mounted() {
    this.getMerchants();
  },
  methods: {
    selectPayment(method) {
      if (this.isExpressOrder && !method.type.includes('express')) {
        if (!confirm('Это экспресс-заказ. Вы уверены что хотите принять оплату?')) {
          return;
        }
      }

      this.close(method);
    },
    async getMerchants() {
      this.busy = true;
      let { success, msg, data } = await this.$api.get(`/optics/${this.$dept.id}?field=merchants`);
      if (success) {
        this.merchants = data;
        if (!this.isExpressOrder) {
          // remove express merchants for regular orders
          this.merchants = this.merchants.filter(m => !m.type.includes('express'));
        }
      }
      this.busy = false;
    }
  }
};
</script>

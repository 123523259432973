export default [
  "Витрэктомия",
  "Кератопластика",
  "Кросслинкинг",
  "Лазерная коагуляция сетчатки",
  "Лазерная коррекция зрения",
  "Операция исправления косоглазия",
  "Операция по лечению глаукомы",
  "Осложнения",
  "Рефракционная замена хрусталика",
  "Склеропластика",
  "Удаление катаракты",
  "Удаление образований на глазу",
  "Фоторефрактивная кератэктомия",
  "Энуклеация глаза"
];

<template>
  <page>
    <template v-slot:header>
      Отчеты
    </template>
    <template v-slot:actions>
      <button @click="printReceipt()"
        class="hidden text-sm font-medium bg-green-500 text-white rounded-md px-4 py-2 ml-6 hover:bg-green-600">
        <i class="fas fa-plus-circle mr-1"></i>
        Пробить чек
      </button>
    </template>

    <!-- Filters, collapsable -->
    <template v-slot:content>
      <div class="border-b pb-4 mb-4 grid grid-cols-4 gap-x-4 gap-y-2">
        <button class="btn-gray" @click="createTask(btn)" v-for="btn in reportTypes.filter(el => !el.hide)"
          :class="{ busy: busy[btn.type] }">
          {{ btn.title }} {{ btn.busy }}
          <!-- LIBFPTR_RT_KKT_INFO -->
        </button>
      </div>

      <h3 class="mb-4 text-lg font-medium">Очередь задач</h3>
      <div class="w-full overflow-x-auto text-left bg-white rounded-md">
        <table class="w-full">
          <thead>
            <tr class="font-semibold text-xs  text-gray-600">
              <th class="px-4 py-3">Дата</th>
              <th class="px-4 py-3">Категория</th>
              <th class="px-4 py-3"></th>
              <th class="px-4 py-3">ККМ</th>
              <th class="px-4 py-3">Статус</th>
              <th class="px-4 py-3">Сумма</th>
            </tr>
            <tr class="text-xs bg-gray-100 "></tr>
          </thead>
          <tbody class="text-sm">
            <tr v-for="task in tasks" class="">
              <td class="">{{ task.created_at | datetime }}</td>
              <td class="text-left">
                {{ getTaskBySlug(task.action, task.type) }}

                <div class="mt-2" v-if="$requireRole('admin') && task.status == 'failed'">
                  <button @click="retryTask(task)" class="btn-default" :class="{ busy: retryBusy == task.id }"
                    :disabled="retryBusy == task.id">
                    Повторить
                  </button>
                </div>

              </td>
              <td class="">
                <template v-if="task.data.receipt">
                  Заказ <sale-number
                    :sale="{ id: task.data.receipt.sale_id, saleNumber: task.data.receipt.saleNumber }" />
                  <div class="text-xs text-gray-400 mt-1">
                    Итого: {{ task.data.receipt.total }} | Скидка: {{ task.data.receipt.discount }} | Кассир : {{
                      task.data.receipt.username }}
                    <div class="rounded-md mt-2">
                      <table class="tiny-table w-full">
                        <thead>
                          <tr>
                            <th>Название</th>
                            <th>Цена</th>
                            <th>Ндс</th>
                            <th>Налог</th>
                            <th>Шт.</th>
                            <th>Подытог</th>
                            <th>Скидка</th>
                            <th>Итого</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in task.data.receipt.items">
                            <td>{{ item.name }}</td>
                            <td>{{ item.price }}</td>
                            <td>{{ item.nds }}</td>
                            <td>{{ item.tax }}</td>
                            <td>{{ item.qty }} шт.</td>
                            <td>{{ item.subtotal }}</td>
                            <td>{{ item.rowDiscount }}</td>
                            <td>
                              <span class="text-black font-semibold">{{ item.rowTotal }}</span>
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>{{ task.data.receipt.items.reduce((a, b) => a + b.subtotal, 0) | money }}</td>
                            <td>{{ task.data.receipt.items.reduce((a, b) => a + b.rowDiscount, 0) | money }}</td>
                            <td>
                              <span class="text-black font-semibold">
                                {{ task.data.receipt.items.reduce((a, b) => a + b.rowTotal, 0) | money }}
                              </span>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </template>

                <div v-else>
                  {{ task.data }}
                </div>
                <div v-if="task.status == 'failed'"
                  class="text-sm text-red-500 py-1 inline-block px-2 border border-red-200 mt-2 rounded-md">
                  Попыток: {{ task.fails }} | {{ task.result ? task.result.msg : '' }}
                </div>

              </td>
              <td class=""></td>
              <td class="">
                <task-status :status="task.status" v-if="task.status" />
                <i class="fa fa-question text-xs text-gray-400" v-else></i>
              </td>
              <td class="py-2">
                <span v-if="task.data.receipt">
                  {{ task.data.receipt.total | money }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="px-4">
          <pagination :total="total" :limit="limit" :items="tasks"
            @page="page => ((selectedPage = page), getTasks())" />
        </div>
      </div>
    </template>
  </page>
</template>

<script>
import Page from "@/components/Page.vue";
import PrintReceiptDialog from "@/components/kassa/PrintReceiptDialog";
import { data } from "autoprefixer";
const reportTypes = [
  {
    title: "Информация о ККТ",
    action: "print-info"
  },
  {
    title: "Открыть смену",
    action: "open-shift"
  },
  {
    title: "Закрыть смену",
    action: "close-shift"
  },
  {
    title: "X-отчет без гашения", // LIBFPTR_RT_X
    type: "x-report",
    action: "print-report"
  },
  {
    title: "Отчет о состоянии расчетов", // LIBFPTR_RT_OFD_EXCHANGE_STATUS
    type: "ofd-exchange-status",
    action: "print-report"
  },
  {
    title: "Отчет по отделам", // LIBFPTR_RT_DEPARTMENTS
    type: "dept-reports",
    action: "print-report"
  },
  {
    title: "Отчет по часам", // LIBFPTR_RT_HOURS
    type: "hours-reports",
    action: "print-report"
  },
  {
    title: "Отчет по количеству", // LIBFPTR_RT_QUANTITY
    type: "qty-reports",
    action: "print-report"
  },
  {
    title: "Печать итогов смены", //   LIBFPTR_RT_FN_SHIFT_TOTAL_COUNTERS
    type: "shift-totals",
    action: "print-report"
  },
  {
    title: "Печать чека", //   LIBFPTR_RT_FN_SHIFT_TOTAL_COUNTERS
    type: "",
    action: "print-receipt",
    hide: true
  }
];
export default {
  components: { Page },
  name: "kassa-reports",
  data() {
    return {
      busy: {
        "print-info": false
      },
      reportTypes: reportTypes,
      tasks: [],
      selectedPage: 1,
      total: -1,
      limit: 10,
      retryBusy: false
    };
  },
  mounted() {
    this.getTasks();
    setInterval(() => {
      this.getTasks();
    }, 5000)
  },
  methods: {
    printReceipt() {
      // show dialog
      this.$modals.open(PrintReceiptDialog, {}, async product => { });
    },
    async createTask(btn) {
      let vm = this;

      this.busy[btn.title] = true;

      let { success, msg, data } = await this.$api.post(`/tasks`, {
        optic_id: this.$dept.id,
        type: btn.type || "",
        target: "kkm",
        action: btn.action || ""
      });

      setTimeout(() => {
        vm.busy[btn.title] = false;
        vm.getTasks();
      }, 500);
    },
    async getTasks() {
      let { success, msg, data } = await this.$api.get(`/tasks?page=${this.selectedPage}&limit=${this.limit}`);
      if (success) {
        this.tasks = data.items;
        this.total = data.total;
      }
    },
    getTaskBySlug(action, type) {
      let item = this.reportTypes.find(el => el.action === action && (el.type ? el.type == type : true));
      return item ? item?.title : item?.action || item?.type;
    },
    // set task status new and reset result
    async retryTask(task) {
      this.retryBusy = task.id;
      let { success, msg, data } = await this.$api.post(`/tasks/${task.id}/retry`);
      if (success) {
        this.getTasks();
      }
      this.retryBusy = false;
    }
  }
};
</script>
